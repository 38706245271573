import { useContext, useState } from 'react'
import { getCurrentLanguage } from '../../languages'
import { AuthContext } from '../auth-context'

// components
import AccountSidebar from '../account-sidebar'
import Button from './../buttons'
import CompanyAccountModal from './../company-account-modal'
import { OrganizationContext } from './../organization-context/index'

// icons
import { VideoPersonCall } from '@styled-icons/fluentui-system-regular'
import { MarkEmailRead } from '@styled-icons/material-outlined'

// data
import { getData } from './data'

import {
  AccountType,
  AccountWrapper,
  AccountInfo,
  CWrapper,
  Div,
  Link,
  OuterWrapper,
  PersonalIcon,
  Section,
  Title,
  TripListTitle,
  Wrapper,
} from './styled'
import { useLocation } from 'react-router-dom'

const HIDE_EDIT_BUTTON = true

const SettingsList = ({ label, user, data }) => {
  const [modal, setModal] = useState(false)
  const { organization } = useContext(OrganizationContext)
  const { search } = useLocation()

  const renderIcon = {
    email: (
      <span>
        <MarkEmailRead />
        {user?.email}
      </span>
    ),
    call: (
      <span>
        <VideoPersonCall />
        {user?.phone_number}
      </span>
    ),
  }
  const generateSection = (item) => {
    switch (item.id) {
      case 'name':
        return (
          <div>
            {user?.name} {user?.family_name}
          </div>
        )
      case 'contact':
        return <CWrapper>{item.content.map((c) => renderIcon[c.id])}</CWrapper>
      case 'settings':
        return item.content.map((c, i) => (
          <Link href={`${c.href}${search}`} key={`link${i}`}>
            {c.label}
          </Link>
        ))
      case 'company':
        // since the variables are all string I only disable for an explicit true
        if (process.env.REACT_APP_AGENCY_JOURNEY_DISABLED === 'true') {
          return null
        } else
          return (
            <>
              {modal && (
                <CompanyAccountModal
                  isModalOpen={modal}
                  setIsModalOpen={setModal}
                />
              )}
              <AccountType>
                <AccountInfo>
                  <PersonalIcon size={20} />
                  <p>
                    {organization !== null && organization.userRole
                      ? 'Admin'
                      : 'Personal'}
                  </p>
                </AccountInfo>
                {organization === null && (
                  <Button
                    icon="company"
                    iconPosition="left"
                    label="Create an organization"
                    type="secondary"
                    onClick={() => setModal(true)}
                  />
                )}
              </AccountType>
            </>
          )
      default:
        break
    }
  }

  return (
    <OuterWrapper>
      <Wrapper>
        <TripListTitle>{label}</TripListTitle>
        {data.map((item, i) => (
          <Section key={`section${i}`}>
            <Title>{item.label}</Title>
            {generateSection(item)}
          </Section>
        ))}
      </Wrapper>
      {!HIDE_EDIT_BUTTON && <Button label="Edit Profile" type="secondary" />}
    </OuterWrapper>
  )
}
const SettingsListFunction = () => {
  const { userAttributes, loadingAttributes } = useContext(AuthContext)

  const accountData = getData()
  const lang = getCurrentLanguage()
  return (
    <AccountWrapper>
      <AccountSidebar />
      {!loadingAttributes ? (
        <SettingsList
          label={accountData.title}
          user={userAttributes}
          data={accountData.list}
        />
      ) : (
        <Div />
      )}
    </AccountWrapper>
  )
}

export default SettingsListFunction
