import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const DefaultMeta = () => (
  <>
    <title>Lelecabs</title>
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
  </>
)

const InjectMeta = ({ meta, lang }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <html lang={lang ? lang.toLowerCase() : 'en'} />
        {meta ? (
          meta.map((singleMeta, iter) => {
            switch (singleMeta.component) {
              case 'meta_title':
                return <title key={iter}>{singleMeta.data.content}</title>
              case 'meta_description':
                return (
                  <meta
                    name={singleMeta.data.name}
                    content={singleMeta.data.content}
                    key={iter}
                  />
                )
              default:
                return <></>
            }
          })
        ) : (
          <DefaultMeta />
        )}
      </Helmet>
    </HelmetProvider>
  )
}
export default InjectMeta
