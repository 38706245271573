import styled from 'styled-components'
import { Checkmark } from '@styled-icons/ionicons-outline/Checkmark'

export const Wrapper = styled.div`
  display: flex;
  gap: 0.3em;
  cursor: pointer;
  user-select: none;
  position: relative;
`

export const Menu = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: 2em;
  right: 0;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  min-width: 190px;
  width: 100%;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.25);
`

export const LanguageBoxWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.activeLang ? 'rgba(186, 1, 35, 0.08)' : '#FFF'};
  padding: 8px 16px;
  &:hover {
    background: rgba(26, 26, 26, 0.06);
  }
`

export const ArrowWrapper = styled.div`
  pointer-events: none;
`

export const LanguageBox = styled.div``

export const CheckMarkIcon = styled(Checkmark)`
  color: red;
`
