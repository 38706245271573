import React from 'react'
import { LeleCabsLogo } from './styled'

const Logo = ({ height = 100, colorFont, colorWheel, width }) => {
  return (
    <LeleCabsLogo
      height={height}
      colorFont={colorFont}
      colorWheel={colorWheel}
      width={width}
    />
  )
}

export default Logo
