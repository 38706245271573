import React from 'react'
import { PageFromJson } from './../../components/utilities/MainContainer'

import { languageData, getCurrentLanguage } from './../../languages/index'

const lang = getCurrentLanguage()

const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Lake Misurina',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content:
        'Drive with Lelecabs – Choose your hours, drive your own car, and make some cash. Work when you want and meet cool people. How much do Lelecabs drivers make? Find out average driver earnings in your city. Lelecabs driver requirements are simple, so start your application today to be a Lelecabs driver!',
    },
  },
]

const getData = () => {
  const lang = getCurrentLanguage()
  return [
    {
      id: 'itineraryDescription',
      data: {
        navigation: {
          itinerarySection: languageData[lang]['itinerary.dolomites.section'],
          itinerarySectionLink: '/itineraries/dolomites',
          previousStopsText:
            languageData[lang]['itinerary.section.previous.stops.text'],
          nextStopsText:
            languageData[lang]['itinerary.section.next.stops.text'],
          nextStepLink: '/itineraries/dolomites/faloria',
        },
        title: languageData[lang]['itinerary.misurina.section.title'],
        paragraphs: [
          languageData[lang]['itinerary.misurina.section.subtitle.0'],
          languageData[lang]['itinerary.misurina.section.subtitle.1'],
        ],
        experienceTitle:
          languageData[lang]['itinerary.misurina.section.experience.title'],
        rows: [
          {
            title:
              languageData[lang][
                'itinerary.misurina.section.experience.row.0.title'
              ],
            paragraph:
              languageData[lang][
                'itinerary.misurina.section.experience.row.0.content.0'
              ],
          },
          {
            title:
              languageData[lang][
                'itinerary.misurina.section.experience.row.1.title'
              ],
            paragraph:
              languageData[lang][
                'itinerary.misurina.section.experience.row.1.content.0'
              ],
          },
          {
            title:
              languageData[lang][
                'itinerary.misurina.section.experience.row.2.title'
              ],
            paragraph:
              languageData[lang][
                'itinerary.misurina.section.experience.row.2.content.0'
              ],
          },
        ],
        photoAlbumText: languageData[lang]['itinerary.section.album.text'],
        photos: [
          {
            src: '/images/misuria1.png',
            rowEnd: '3',
            rowStart: '1',
          },
          {
            src: '/images/misuria2.png',
            columnEnd: '4',
            rowStart: '-2',
          },
          {
            src: '/images/misuria3.png',
            rowStart: '1',
            columnEnd: '4',
            columnStart: '2',
          },
          {
            src: '/images/misuria4.png',
            rowEnd: '-1',
            rowStart: '-2',
            columnEnd: '3',
          },
        ],
        photosGalleryFull: [
          {
            src: '/images/misuria1.png',
          },
          {
            src: '/images/misuria2full.jpg',
          },
          {
            src: '/images/misuria3full.webp',
          },
          {
            src: '/images/misuria4full.jpg',
          },
        ],
      },
    },
  ]
}

function LakeMisurina() {
  const components = getData()
  return <PageFromJson metaPage={metaPage} components={components} />
}

export default LakeMisurina
