import React from 'react'
import { PageFromJson } from './../../components/utilities/MainContainer'

const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Account Settings - Lelecabs.com',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: 'Control the settings of your account',
    },
  },
]

const components = [
  // {
  //   id: 'page_intro',
  //   data: {
  //     noUnderline: true,
  //     children: {
  //       heading: 'Payment Options'
  //     }
  //   }
  // },
  { id: 'settingsList' },
]

function Settings() {
  return (
    <PageFromJson pageType="user" metaPage={metaPage} components={components} />
  )
}

export default Settings
