import styled from 'styled-components'
import Select from 'react-select'
import { ChevronDown } from '@styled-icons/boxicons-regular'
import { Man } from '@styled-icons/icomoon'
import { ShuttleVan, CarSide } from '@styled-icons/fa-solid'
import { Info } from '@styled-icons/evaicons-solid/Info'
import { Circle } from '@styled-icons/boxicons-solid/Circle'
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'
import { ExclamationOctagonFill } from '@styled-icons/bootstrap/ExclamationOctagonFill'
import { EyeOutline } from '@styled-icons/evaicons-outline/EyeOutline'
import { EyeOffOutline } from '@styled-icons/evaicons-outline/EyeOffOutline'

import { breakpoint, colors } from '../../styles/global.style'
import { ext } from './../../utils/utils'
import { DCol, ULList } from '../atomic/Layout'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export const StyledInput = styled.input`
  border: ${(props) =>
    props.withBorder
      ? `1px solid ${
          props.errorBorder ? props.theme.primary : props.theme.pageBorder
        }`
      : 'none'};
  height: 45px;
  padding: 0px 10px;
  padding-right: ${(props) => (props.hasIcon ? '40' : '0')}px;
  font-size: 16px;
  position: relative;
  width: ${(props) => (props.width ? props.width : '100%')};
  box-sizing: border-box;
  placeholder: ${(props) => props.placeholder};
  color: ${(props) => (props.readOnly ? colors.disabledButton : 'inherit')};
  ${(props) =>
    props.withBorder && 'border: 1px solid ' + props.theme.pageBorder + ';'}
  ${(props) =>
    props.hasError ||
    (props.routeError && 'border: 1px solid ' + props.theme.error + ';')}
    ${(props) =>
    props.withSelect
      ? `
      @media(max-width: ${breakpoint.large}) {
        max-width: 400px;
      }
      @media(max-width: ${breakpoint.medium}) {
        max-width: 380px;
      }
      @media(max-width: ${breakpoint.bigtablet}) {
        max-width: 500px;
      }
    `
      : ''}
    @media (max-width: ${breakpoint.bigtablet}) {
    width: 100%;
    //max-width: 500px;
  }
  @media (max-width: ${breakpoint.mobile}) {
    placeholder: ${(props) => props.mobilePlaceholder};
  }
  &:focus {
    outline: none;
  }
`

export const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInputInput {
    min-height: 40px;
    font-size: 16px;
    border: ${(props) =>
      props.withBorder ? '1px solid ' + props.theme.pageBorder : 'none'};
    ${(props) =>
      props.hasError && 'border: 1px solid ' + props.theme.primary + ';'}
  }
`

export const StyledTextarea = styled.textarea`
  border: ${(props) =>
    props.withBorder ? '1px solid ' + props.theme.pageBorder : 'none'};
  min-height: 150px;
  padding: 10px;
  font-size: 16px;
  width: ${(props) => (props.width ? props.width : '100%')};
  box-sizing: border-box;
  resize: vertical;
`

export const StyledSelect = styled.select`
  border: none;
  height: 45px;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '300')}px;
  padding: 0px 10px;
  width: ${(props) => (props.width ? props.width : 'auto')};
  appearance: none;
  border-radius: 0;
  border: 1px solid ${(props) => props.theme.pageBorder};
  background-color: #fff;
  font-size: 16px;
`

export const SelectControl = styled(Select)`
  width: ${({ width }) => width || '400px'};
  ${({ width }) =>
    !width
      ? `
      @media (max-width: ${breakpoint.large}) {
        width: 300px;
      }
      @media (max-width: ${breakpoint.medium}) {
        width: 180px;
      }
      @media (max-width: ${breakpoint.bigtablet}) {
        margin: 20px 0px 0px;
        width: 100%;
      }
    `
      : ''}
`

export const InputIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'row'};
  gap: ${(props) => (props.gap ? props.gap : '8px')};
`

export const LabelWrapper = styled.label`
  position: relative;
  display: block;
  margin-bottom: ${(props) =>
    props.margin ? (props.hasError ? '0' : props.margin) : '0'}px;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '0')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  ${(props) =>
    props.mobileOnly
      ? `@media (min-width: ${breakpoint.mobile}) { display: none; }`
      : ''}
  ${(props) =>
    props.exceptMobile
      ? `@media (max-width: ${breakpoint.mobile}) { display: none; }`
      : ''}
  margin-right: ${(props) => props.marginRight || '0'}px;
  @media (max-width: ${breakpoint.bigtablet}) {
    width: ${(props) => (props.width ? props.width : '100%')};
  }
  @media (max-width: ${breakpoint.tablet}) {
    width: ${(props) => (props.maintainWidth ? props.width : '100%')};
  }
  ${(props) =>
    props.withSelect
      ? `
      @media(max-width: ${breakpoint.large}) {
        max-width: 400px;
      }
      @media(max-width: ${breakpoint.medium}) {
        max-width: 380px;
      }
      @media(max-width: ${breakpoint.bigtablet}) {
        max-width: 500px;
      }
    `
      : ''}
  div.MuiFormControl-root {
    width: 100%;
    div.MuiInputBase-root {
      height: 45px;
      border-radius: 0;
      padding-top: 5px;
    }
  }
`

export const FlightCodeLabel = styled.div`
  display: flex;
  gap: 8;
  align-items: center;
`

export const InfoSVG = styled(Info)`
  color: #3959bc;
  cursor: pointer;
`

export const DownArrow = styled(ChevronDown)`
  position: absolute;
  pointer-events: none;
  right: 5px;
  top: ${(props) => (props.label ? 28 : 6)}px;
`

export const StyledError = styled.div`
  padding: 15px;
  font-size: 16px;
  background: ${(props) =>
    props.invertColors ? '#000000' : props.theme.primary};
  color: #fff;
  margin: 10px;
`

export const Required = styled.span`
  color: ${(props) => props.theme.primary};
`
export const LabelText = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  color: ${(props) =>
    props.hasError
      ? props.theme.primary
      : props.readOnly
      ? colors.disabledButton
      : 'inherit'};
`

export const FormError = styled.div`
  color: white;
  background-color: ${(props) => props.theme.primary};
  padding: 2px 15px;
  font-size: 11px;
  margin-bottom: ${(props) => props.margin || '0'}px;
`

export const OptionalLabelText = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  color: #7a7a7a;
`

export const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: 1px solid ${(props) => props.theme.pageBorder};
  background: white;
  position: absolute;
  top: 0;
  left: 0;
`

export const LabelWrapperCheckbox = styled.label`
  display: block;
  position: relative;
  padding: 2px;
  padding-left: 30px;
  font-size: 14px;
  border: 1px solid
    ${(props) => (props.hasError ? props.theme.primary : 'transparent')};
`

export const CheckboxWrap = styled.div`
  width: ${(props) => (props.width ? props.width + 'px' : '100%')};
  margin-bottom: ${(props) => (props.margin ? props.margin : '0')}px;
`

export const DOBLine = styled.span`
  color: ${(props) => props.theme.pageBorder};
  margin: 0 10px;
  margin-bottom: ${(props) => props.margin}px;
`

export const ErrorWrap = styled.div`
  width: 100%;
`

export const DOBBox = styled(ext(DCol))`
  ${(props) => props.hasError && 'border: 1px solid' + props.theme.primary};
`

export const DColFull = styled(ext(DCol))`
  width: 100%;
`

export const ORSpan = styled.span`
  position: relative;
  top: -10px;
  padding: 5px 10px;
  background: white;
`

export const ORDiv = styled.div`
  border-top: 1px solid ${colors.kfcBorderGrey};
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 15px;
`

export const Catpcha = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  ${(props) => props.maxWidth && 'max-width:' + props.maxWidth + 'px'}
  border: 1px solid ${(props) =>
    props.hasError ? props.theme.primary : 'transparent'};
`

export const DateWrapper = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.margin}px;
  padding-right: ${(props) => (props.hasIcon ? '40' : '0')}px;
  font-size: 16px;
  position: relative;
  width: ${(props) => props.width || '100%'};
`

export const PeopleWrapper = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.margin}px;
  justify-content: space-around;
  width: 100%;
`

export const RoundButton = styled.button`
  border-radius: 15px;
  height: 30px;
  width: 30px;
  font-size: 24px;
  padding: 0;
  text-align: center;
  border: 1px solid #ccc;
  :hover {
    background-color: #eee;
  }
`

export const StyledMan = styled(Man)`
  margin-top: ${(props) => props.top || 0}px;
`

export const PeopleNum = styled.h3`
  width: 35px;
  text-align: center;
`

export const ColH4 = styled.h4`
  flex: 1;
  text-align: center;
`

export const ColG = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

export const Box = styled.button`
  border: none;
  background-color: none;
  margin: 0px 25px;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  min-width: 125px;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.active ? 1 : 0.6)};
  :hover {
    cursor: pointer;
  }
`
export const BoxWrapper = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.margin || 0}px;
`

export const Van = styled(ShuttleVan)`
  width: 30px;
  color: #444;
`

export const Car = styled(CarSide)`
  width: 30px;
  color: #444;
`

export const CarTitle = styled.h4`
  font-weight: 800;
`

export const TripSelectWrapper = styled.button`
  background-color: ${({ selected }) => (selected ? '#C2C4C7' : 'none')};
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  height: 45px;
  min-width: 275px;
  border: 0.5px solid ${({ selected }) => (selected ? '#888' : '#ccc')};
  margin-bottom: ${({ margin }) => margin || 0}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  width: 100%;
  @media (max-width: ${breakpoint.mobile}) {
    min-width: 205px;
  }
`

export const Allowances = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  align-items: center;
`

export const ServiceTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const Service = styled.h3`
  font-size: 16px;
  font-weight: 400;
  min-width: 94px;
  text-align: left;
  @media (max-width: ${breakpoint.mobile}) {
    min-width: 74px;
  }
`

export const Cost = styled.h4`
  min-width: 75px;
`

export const TitleLabel = styled.p`
  text-align: left;
  margin-bottom: 5px;
`

export const FieldWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ margin }) => margin || 0}px;
`

export const WeigthsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const WeightBox = styled.div`
  margin: 0px 5px;
`

export const AutoComponentList = styled(ULList)`
  position: absolute;
  left: 0;
  right: 0;
  top: 44px;
  border: 1px solid #d9d9d9;
  height: 160px;
  overflow-y: scroll;
  z-index: 999;
`

export const InputWrapper = styled.div`
  position: relative;
  max-width: 500px;
  flex: 1 1 auto;
  ${(props) =>
    props.withSelect
      ? `
      @media(max-width: ${breakpoint.large}) {
        max-width: 400px;
      }
      @media(max-width: ${breakpoint.medium}) {
        max-width: 380px;
      }
      @media(max-width: ${breakpoint.bigtablet}) {
        max-width: 500px;
      }
    `
      : ''}
  @media(max-width: ${breakpoint.bigtablet}) {
    width: 100%;
    max-width: 500px;
  }
`

export const CheckListBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 10px;
  row-gap: 16px;
  padding-left: 10px;
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 22px;
  border-radius: 10px;
`

export const DropdownParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  margin-bottom: 25px;
`

export const IconItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;
  align-content: center;
  justify-content: start;
`

export const StyledListItem = styled.p`
  color: ${(props) => (props.isChecked ? '#058C3B' : `#7A7A7A`)};
`
export const Description = styled.p`
  padding-bottom: 22px;
`

export const FalseRequirement = styled(Circle)`
  max-width: 20px;
  max-height: 20px;
  color: #d9d9d9;
`

export const TrueRequirement = styled(CheckCircleFill)`
  max-width: 20px;
  max-height: 20px;
  color: #058c3b;
`

export const Divider = styled.div`
  border-bottom: 1px solid #c5c8ca;
  width: 100%;
  margin-bottom: 35px;
  p {
    transform: translate(148px, 13px);
    background: white;
    width: fit-content;
    padding: 5px 10px;
  }
`

export const ConfirmationErrorMessage = styled.div`
  background-color: #f9e9e7;
  border-left: 6px solid #ba0123;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 14px 24px;
  border-radius: 4px;
  gap: 4px;
  margin-bottom: 40px;
`

export const AttentionIcon = styled(ExclamationOctagonFill)`
  color: #ba0123;
  width: 26px;
  height: 26px;
`

export const Dropdown = styled.select`
  border: 1px solid rgb(208, 208, 208); 
  height: 45px;
  padding: 0px 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
 /* height: 48px; */
 -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url('/images/chevronDown.webp');
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: calc(100% - 16px);
  @media (max-width: ${breakpoint.bigtablet}) {
    width: 100%;
  }
  
  &::-ms-expand {
    display: none;
  }
  
  &::after {
    content: "\25BC";
    position: absolute;
    top: 50%;
    right: 15px; /* Adjust the value to match the padding */
    transform: translateY(-50%);
  }
`

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'row'};
  gap: ${(props) => (props.gap ? props.gap : '8px')};
  width: 100%;
`

export const LabelFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
`

export const PasswordInputWrapper = styled.div`
  border: 1px solid rgb(208, 208, 208);
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
`

export const EyeIcon = styled(EyeOutline)`
  color: black;
  cursor: pointer;
`

export const EyeIconOff = styled(EyeOffOutline)`
  color: black;
  cursor: pointer;
`

export const DirectionSelect = styled.div`
  display: flex;
  border: 1px solid #d4d9eb;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-top: 14px;
  align-self: flex-start;
`

export const DirectionTab = styled.div`
  min-width: 140px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  text-align: center;
  border-radius: 4px;
  background: ${(props) => (props.selected ? '#D4D9EB' : 'unset')};
  cursor: pointer;
  @media (max-width: ${breakpoint.mobile}) {
    min-width: 100px;
  }
`

export const ServiceWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`
