import React from 'react'
import { DefaultLayoutNew } from './../../components/utilities/DefaultLayoutNew'
import { SignUp } from './../../components/sign-up-form'
import { ErrorBoundary } from './../../components/compositions/Error'
import { OuterWrapper } from './../../components/super-mega-wrapper'

function RegisterPage() {
  return (
    <DefaultLayoutNew pageType="viewer">
      <OuterWrapper>
        <ErrorBoundary>
          <SignUp />
        </ErrorBoundary>
      </OuterWrapper>
    </DefaultLayoutNew>
  )
}

export default RegisterPage
