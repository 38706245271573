import styled from 'styled-components'
import * as T from '../atomic/Typography'
import { LocationPin } from '@styled-icons/entypo/LocationPin'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  padding: 0 85px;
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  margin-top: 128px;
  @media (min-width: ${breakpoint.verylarge}) {
    max-width: 1600px;
    margin: 128px auto 120px;
  }

  @media (max-width: ${breakpoint.smallTablet}) {
    padding: 0 10px;
  }
`

export const Title = styled(T.Uh3)`
  @media (max-width: ${breakpoint.large}) {
    text-align: center;
  }
`

export const InnerWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${breakpoint.large}) {
    justify-content: center;
    gap: 80px;
  }
`

export const TimelineWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
`

export const PinkMark = styled.div`
  display: flex;
  gap: 28px;
`

export const Time = styled(T.Uh3)`
  min-width: 160px;
  text-align: right;
  @media (max-width: ${breakpoint.mobile}) {
    min-width: 78px;
    font-size: 1.2em;
  }
`

export const PinIconPsueudo = styled.div`
  position: relative;
  margin-top: -8px;
  &:after {
    content: '';
    position: absolute;
    margin-top: 30px;
    width: 3px;
    height: ${(props) => (props.$last ? '0px' : 'calc(100% - 8px)')};
    top: 0;
    left: 13px;
    z-index: 0;
    border: 1px solid #a3c5ed;
    background: #a3c5ed;
  }
`

export const PinIcon = styled(LocationPin)`
  color: #3959bc;
  width: 32px;
  height: 40px;
  z-index: 1;
  position: relative;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 460px;
  width: 100%;
`

export const Destination = styled.h3`
  @media (max-width: ${breakpoint.mobile}) {
    font-size: 1.2em;
  }
`

export const Description = styled(T.P1)`
  color: #696871;
  word-break: break-word;
  @media (max-width: ${breakpoint.mobile}) {
    font-size: 1em;
  }
`

export const LearnMore = styled.a`
  color: #3959bc;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    color: black;
  }
`
