import styled from 'styled-components'
import { breakpoint } from '../../styles/global.style'
import * as T from './../atomic/Typography'
import ReactFlagsSelect from 'react-flags-select'
import { CallCheckmark } from '@styled-icons/fluentui-system-filled/CallCheckmark'

export const Wrapper = styled.div``

export const MessageWrapper = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  text-align: ${(props) => (props.centerContent ? 'center' : 'unset')};
  align-items: ${(props) => (props.centerContent ? 'center' : 'flex-start')};
  gap: 32px;
  border-radius: 16px;
  border: ${(props) => (props.simple ? 'unset' : '1px solid #e0e0e0')};
  background: ${(props) => (props.simple ? 'unset' : 'rgba(245, 245, 245, 1)')};
  @media (max-width: ${breakpoint.smallTablet}) {
    padding: 15px;
  }
`

export const Title = styled(T.Uh3)`
  font-size: 32px;
  font-weight: 700;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  a {
    text-decoration: none;
  }
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: ${breakpoint.mobile}) {
    flex-wrap: wrap;
  }
`

export const SingleInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: ${(props) => (props.full ? '100%' : 'unset')};
`

export const Input = styled.input`
  padding: 12px;
  border-radius: 4px;
  max-width: 530px;
  width: 100%;
  border: 1px solid #d4d9eb;
  background: #f7f7f8;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    color: green;
  }
`

export const CallIcon = styled(CallCheckmark)`
  color: green;
`

export const ReactFlags = styled(ReactFlagsSelect)`
  button {
    border: 1px solid #d4d9eb;
    max-width: 140px;
    width: 100%;
    min-height: 40px;
    background-color: #f7f7f8;
    @media (max-width: ${breakpoint.mobile}) {
      max-width: unset;
    }
  }
  ul {
    background-color: #f7f7f8;
  }

  li {
    color: black;
  }

  input {
    border: 1px solid #d4d9eb;
    border-radius: 4px;
    font-size: 16px;
  }

  .ReactFlagsSelect-module_filterBox__3m8EU {
    background-color: #f7f7f8;
  }
`
