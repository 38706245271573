import * as S from './styled'

const CancellationCoverage = ({ id, handler, value, label }) => {
  return (
    <S.CancellationCoverage>
      <S.CheckboxCoverage
        type="checkbox"
        checked={value[id]}
        id={id}
        onChange={(e) => handler(id, e.target.checked)}
      />
      <label htmlFor={id}>{label}</label>
    </S.CancellationCoverage>
  )
}

export default CancellationCoverage
