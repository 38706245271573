import * as S from './styled'
import * as T from './../atomic/Typography'
import { Link, useLocation } from 'react-router-dom'

const InformationBox = ({ data }) => {
  const { search } = useLocation()
  return (
    <S.Wrapper>
      <S.LeftImage src={data.photo} />
      <S.RightWrapper>
        <S.Title>{data.title}</S.Title>
        <S.ParagraphWrapper>
          {data.paragraphs.map((text, i) => (
            <T.P1 key={i}>{text}</T.P1>
          ))}
        </S.ParagraphWrapper>
        <Link to={`${data.button.href}${search}`} target="_blank">
          <S.Button>{data.button.label}</S.Button>
        </Link>
      </S.RightWrapper>
    </S.Wrapper>
  )
}

export default InformationBox
