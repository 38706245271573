export const globalConstants = {
  HEADER_HEIGHT: '80px',
  MIN_PAGE_PADDING: 64,
  CHILDREN_BOTTOM_MARGIN: 48,
  MIN_PAGE_PADDING_TABLE: 24,
  MENU_BOX_PADDING: 40,
  BORDER_WIDTH: 1,
  DISH_HORIZONTAL_GAP: 10,
  DISH_WIDTH: 380,
}
