import React, { useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from './../auth-context'
import { isCovered } from './../../utils/mapUtils'
import { saveSessionState, saveState } from './../../utils/localStorage'
import { askGoogle } from '../../utils/mapUtils'
import useQuotes, {
  formatQuotes,
  addParamsToQuotes,
} from '../../hooks/useQuotes'
import { getCurrentLanguage, languageData } from './../../languages'
import * as S from './styled'
import NewAuthForm from './../auth/AuthForm_new'
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker'

const searchParams = new URLSearchParams(window.location.search)
const isEditing = searchParams.get('edit') === 'true'

const getCoords = (data) => ({
  lng: data?.lng(),
  lat: data?.lat(),
})

function RideForm() {
  const router = useNavigate()
  const { userAttributes } = useContext(AuthContext)
  const lang = getCurrentLanguage()
  const gaEventTracker = useAnalyticsEventTracker('RideFormCompilation')

  const { state = {} } = useLocation()
  const { trip = [] } = state || []
  const [from, setFrom] = useState(trip.origin ?? '')
  const [to, setTo] = useState(trip.destination ?? '')
  const [when, setWhen] = useState()
  const [whenReturn, setWhenReturn] = useState()
  const [triptype, setTripType] = useState(trip.triptype ?? 'lele')
  const [error, setError] = useState([])
  const [directionDropdown, setDirectionDropdown] = useState(
    trip.direction ?? languageData[lang]['home.hero.form.one.direction'],
  )
  const [flightCode, setFlightCode] = useState(trip.flightCode || '')
  const [flightReturnCode, setFlightReturnCode] = useState(
    trip.flightReturnCode || '',
  )
  const [coverage, setCoverage] = useState(trip.coverage ?? false)
  const [quotesWithParams, setQuotesWithParams] = useState(0)
  const dropdown =
    directionDropdown === languageData[lang]['home.hero.form.return.direction']
      ? 'Return'
      : 'One-way'
  const [passangerName, setPassangerName] = useState('')
  const [passangerEmail, setPassangerEmail] = useState('')
  const [passangerPhone, setPassangerPhone] = useState('')
  const [phonePrefix, setPhonePrefix] = useState('IT')

  const { quotes, duration, distance, originCoords, destinationCoords } =
    useQuotes({ from, to })

  useEffect(() => {
    const updatedQuotes = {}
    if (!quotes) return
    Object.entries(quotes).forEach(([key, value]) => {
      updatedQuotes[key] = addParamsToQuotes({
        quotes: value,
        coverage,
        direction: dropdown,
      })
    })
    const formattedQuotes = formatQuotes(updatedQuotes)
    setQuotesWithParams(formattedQuotes)
  }, [quotes, coverage, dropdown])

  useEffect(() => {
    if (from && to) {
      gaEventTracker?.('RideForm', 'from/to complete')
    }
  }, [from, to])

  const [zoneCoverage, setZoneCoverage] = useState({
    from: '',
    to: '',
  })
  const [routeError, setRouteError] = useState(false)
  const [accountModal, setAccountModal] = useState(false)

  // use effect for the coverage region
  useEffect(() => {
    if (
      zoneCoverage?.to?.length === 0 ||
      zoneCoverage?.from?.length === 0 ||
      isCovered(zoneCoverage?.to) ||
      isCovered(zoneCoverage?.from)
    ) {
      setRouteError(false)
    } else {
      setRouteError(true)
    }
  }, [zoneCoverage])

  const currentValue = {
    from,
    to,
    when,
    triptype,
    dropdown,
    flightCode,
    flightReturnCode,
    whenReturn,
    quotes: quotesWithParams,
    duration,
    distance,
    originCoords,
    destinationCoords,
    coverage,
    passangerName,
    passangerEmail,
    phonePrefix,
    passangerPhone,
  }

  const getData = () => {
    return {
      formOptions: [
        {
          component: 'title',
          title: languageData[lang]['home.hero.form.pickup'],
          id: 'title',
          error: 'main',
          full: true,
        },
        {
          component: 'direction',
          id: 'direction',
          name: 'direction',
        },
        {
          id: 'from',
          component: 'geo',
          name: 'from',
          type: 'geo',
          label: languageData[lang]['home.hero.form.from'],
          required: true,
          value: null,
          withBorder: true,
          width: '100%',
          margin: 20,
          zoneCoverage: 'zoneCoverage',
          routeError: routeError,
        },
        {
          id: 'to',
          component: 'geo',
          name: 'from',
          type: 'geo',
          label: languageData[lang]['home.hero.form.to'],
          required: true,
          value: null,
          withBorder: true,
          width: '100%',
          margin: 20,
          zoneCoverage: 'zoneCoverage',
          routeError: routeError,
        },
        {
          id: 'when',
          component: 'date',
          name: 'when',
          type: 'date',
          required: true,
          value: null,
          withBorder: true,
          width: '100%',
          margin: 20,
        },
        {
          id: 'directionDropdown',
          component: 'return-trip-dropdown',
          name: 'directionDropdown',
          flightHandler: 'flightCode',
          flightReturnHandler: 'flightReturnCode',
          returnDateHandler: 'whenReturn',
          outwardDateValue: when,
          dateHourPickerConfig: {
            id: 'when',
            component: 'date',
            name: 'when',
            type: 'date',
            required: true,
            value: null,
            withBorder: true,
            width: '100%',
          },
        },
        {
          id: 'coverage',
          component: 'coverage',
          margin: 30,
          label: languageData[lang]['home.hero.form.coverage'],
          priceHandler: 'coverage',
          hidden: false,
        },
        {
          id: 'triptype',
          component: 'tripselect',
          margin: 30,
          label: languageData[lang]['home.hero.form.service'],
        },
        {
          id: 'routerError',
          component: 'routerError',
          label: languageData[lang]['home.hero.form.operation.coverage.error'],
        },
        {
          id: !userAttributes ? 'openModal' : 'action',
          key: 'next',
          className: 'submitButton',
          routeError: routeError,
          component: 'button',
          type: 'secondary',
          label: 'Proceed to Payment',
          disableFactor:
            directionDropdown !==
            languageData[lang]['home.hero.form.return.direction']
              ? ['from', 'to', 'when', 'triptype']
              : ['from', 'to', 'when', 'triptype', 'whenReturn'],
          disabled: false,
          full: true,
        },
        {
          id: 'accountModal',
          key: 'accountModal',
          component: 'accountModal',
          guestHandler: 'formStage',
          modalValue: accountModal,
        },
      ],
    }
  }

  const getCustomerInfo = () => {
    return {
      formOptions: [
        {
          component: 'title',
          title: 'Passanger',
          id: 'goBack',
          error: 'main',
          full: true,
          noBorder: true,
        },
        {
          component: 'passenger-info',
          title: languageData[lang]['home.hero.form.pickup'],
          id: 'title',
          error: 'main',
          full: true,
        },
        {
          id: 'actionGuests',
          key: 'next',
          component: 'button',
          type: 'secondary',
          label: 'Proceed to Payment',
          disableFactor: ['passangerName', 'passangerEmail', 'passangerPhone'],
          disabled: false,
          full: true,
        },
      ],
    }
  }

  const componentsForms = [getData, getCustomerInfo]
  const [formStage, setFormStage] = useState(0)
  useEffect(() => {
    if (formStage === 1) {
      gaEventTracker?.('RideForm', 'Proceeding')
    }
  }, [formStage])
  const formState = componentsForms[formStage]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [formStage])

  const openModal = () => {
    setAccountModal(true)
  }

  const progressToNextStep = async (value, error, router, userAttributes) => {
    error([])
    try {
      gaEventTracker?.('RideForm', 'Checkout with account')
      const result = await askGoogle(value)
      const currentDate = new Date().getTime()

      if (result.status === 'OK') {
        const reservation = {
          when: value.when.toLocaleString('sv'), // something like -> "2023-05-21 08:00:00"
          originCoords: getCoords(
            result?.routes?.at(0)?.legs?.at(0)?.start_location,
          ),
          destinationCoords: getCoords(
            result?.routes?.at(0)?.legs?.at(0)?.end_location,
          ),
          duration: result?.routes?.at(0)?.legs?.at(0)?.duration?.value,
          distance: result?.routes?.at(0)?.legs?.at(0)?.distance?.value,
          origin: result?.request?.origin?.query,
          destination: result?.request?.destination?.query,
          triptype: value.triptype,
          direction: value.dropdown,
          originalPrice: value.price,
          updatedPrice: isEditing ? value.price : null,
          flightCode: value.flightCode ? value.flightCode : null,
          whenReturn:
            value.dropdown === 'Return' && value.whenReturn
              ? value.whenReturn.toLocaleString('sv')
              : null,
          returnFlightCode:
            value.dropdown === 'Return' && value.flightReturnCode
              ? value.flightReturnCode
              : null,
          ...(isEditing
            ? { updatedOn: currentDate }
            : { createdOn: currentDate, updatedOn: null }),
          coverage: value.coverage,
        }

        saveState('trip-pending', reservation)
        saveState('sourcePath', location.pathname)
        if (!userAttributes) {
          router('account/login', {
            state: { previousPath: window.location.pathname },
          })
        } else if (isEditing) {
          router('/checkout?edit=true', {
            state: { previousPath: window.location.pathname },
          })
        } else {
          router(`/checkout`, {
            state: { previousPath: window.location.pathname },
          })
        }
      } else {
        console.log('API CALL ERROR, status not OK')
      }
    } catch (err) {
      error([`main.${err.message.split(':').pop()}`])
      console.log(err, error)
    }
  }

  const progressToUserInfo = async (value, error) => {
    error([])
    try {
      const result = await askGoogle(value)
      const currentDate = new Date().getTime()

      if (result.status === 'OK') {
        const reservation = {
          when: value.when.toLocaleString('sv'), // something like -> "2023-05-21 08:00:00"
          originCoords: getCoords(
            result?.routes?.at(0)?.legs?.at(0)?.start_location,
          ),
          destinationCoords: getCoords(
            result?.routes?.at(0)?.legs?.at(0)?.end_location,
          ),
          duration: result?.routes?.at(0)?.legs?.at(0)?.duration?.value,
          distance: result?.routes?.at(0)?.legs?.at(0)?.distance?.value,
          origin: result?.request?.origin?.query,
          destination: result?.request?.destination?.query,
          triptype: value.triptype,
          direction: value.dropdown,
          originalPrice: value.price,
          updatedPrice: isEditing ? value.price : null,
          flightCode: value.flightCode ? value.flightCode : null,
          whenReturn:
            value.dropdown === 'Return' && value.whenReturn
              ? value.whenReturn.toLocaleString('sv')
              : null,
          returnFlightCode:
            value.dropdown === 'Return' && value.flightReturnCode
              ? value.flightReturnCode
              : null,
          ...(isEditing
            ? { updatedOn: currentDate }
            : { createdOn: currentDate, updatedOn: null }),
          coverage: value.coverage,
        }

        saveSessionState('trip-pending', reservation)
        saveState('sourcePath', location.pathname)
        setFormStage(1)
      } else {
        console.log('API CALL ERROR, status not OK')
      }
    } catch (err) {
      error([`main.${err.message.split(':').pop()}`])
      console.log(err, error)
    }
  }

  const progressToNextStepGuests = async (value, error, router) => {
    error([])
    try {
      gaEventTracker?.('RideForm', 'Checkout as guest')
      const result = await askGoogle(value)
      const currentDate = new Date().getTime()

      if (result.status === 'OK') {
        const reservation = {
          when: value.when.toLocaleString('sv'), // something like -> "2023-05-21 08:00:00"
          originCoords: getCoords(
            result?.routes?.at(0)?.legs?.at(0)?.start_location,
          ),
          destinationCoords: getCoords(
            result?.routes?.at(0)?.legs?.at(0)?.end_location,
          ),
          duration: result?.routes?.at(0)?.legs?.at(0)?.duration?.value,
          distance: result?.routes?.at(0)?.legs?.at(0)?.distance?.value,
          origin: result?.request?.origin?.query,
          destination: result?.request?.destination?.query,
          triptype: value.triptype,
          direction: value.dropdown,
          originalPrice: value.price,
          updatedPrice: isEditing ? value.price : null,
          flightCode: value.flightCode ? value.flightCode : null,
          whenReturn:
            value.dropdown === 'Return' && value.whenReturn
              ? value.whenReturn.toLocaleString('sv')
              : null,
          returnFlightCode:
            value.dropdown === 'Return' && value.flightReturnCode
              ? value.flightReturnCode
              : null,
          ...(isEditing
            ? { updatedOn: currentDate }
            : { createdOn: currentDate, updatedOn: null }),
          coverage: value.coverage,
          passangerName: value.passangerName,
          passangerEmail: value.passangerEmail,
          phonePrefix: value.phonePrefix,
          passangerPhone: value.passangerPhone,
        }

        saveState('trip-pending', reservation)
        saveState('sourcePath', location.pathname)
        router(`/checkout/guests`, {
          state: { previousPath: window.location.pathname },
        })
      } else {
        console.log('API CALL ERROR, status not OK')
      }
    } catch (err) {
      error([`main.${err.message.split(':').pop()}`])
      console.log(err, error)
    }
  }

  const handlers = {
    from: setFrom,
    to: setTo,
    when: setWhen,
    triptype: setTripType,
    direction: setDirectionDropdown,
    flightCode: setFlightCode,
    flightReturnCode: setFlightReturnCode,
    whenReturn: setWhenReturn,
    action: () =>
      progressToNextStep(currentValue, setError, router, userAttributes),
    actionGuests: () =>
      progressToNextStepGuests(currentValue, setError, router),
    coverage: setCoverage,
    passangerName: setPassangerName,
    passangerEmail: setPassangerEmail,
    phonePrefix: setPhonePrefix,
    passangerPhone: setPassangerPhone,
    zoneCoverage: setZoneCoverage,
    accountModal: setAccountModal,
    openModal: () => openModal(),
    formStage: () => progressToUserInfo(currentValue, setError),
    goBack: () => setFormStage(0),
  }

  const handleChange = async (id, value) => {
    return handlers[id](value)
  }

  return (
    <S.Wrapper>
      <NewAuthForm
        noTopBorder
        {...formState()}
        value={currentValue}
        handler={handleChange}
        error={error}
        routeError={routeError}
        full
      />
    </S.Wrapper>
  )
}

export default RideForm
