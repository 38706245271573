import styled from 'styled-components'
import { breakpoint } from './../../styles/global.style'

export const FirstLine = styled.h2`
  color: ${(props) => props.color || 'inherit'};
`

export const Paragraph = styled.div`
  color: ${(props) => props.color || 'inherit'};
  padding: 0px 5%;
  text-align: center;
  ${(props) =>
    props.hideMobile
      ? `@media(max-width: ${breakpoint.mobile}) {
      display: none;
    }`
      : ''}
`

export const OpacityBox = styled.div`
  z-index: 2;
  background-color: ${(props) => {
    switch (props.opacity) {
      case 'white':
      case 'black':
        return props.opacity
      case 'transparent-white':
        return 'rgba(255, 255, 255, 0.6)'
      case 'transparent-black':
        return 'rgba(0, 0, 0, 0.6)'
      default:
        return 'none'
    }
  }};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 10px;
  width: 70%;
  @media (max-width: ${breakpoint.large}) {
    width: 75%;
  }
  @media (max-width: ${breakpoint.medium}) {
    width: 80%;
  }
  @media (max-width: ${breakpoint.bigtablet}) {
    width: 90%;
  }
  @media (max-width: ${breakpoint.tablet}) {
    width: 75%;
  }
  @media (max-width: ${breakpoint.mobile}) {
    width: 90%;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  a {
    margin: 0px 0px 10px;
  }
  a button {
    margin: 0px 15px;
  }
  @media (max-width: ${breakpoint.large}) {
    a button {
      margin: 0px 10px;
    }
  }
`
