import propPath from 'crocks/Maybe/propPath'
import Square from '../square'
import Carousel from '../carousel'
import CTA from '../cta'
import PageHeader from '../page-header'
import SafeHtmlParser from './../blocks/SafeHtmlParser'
import ThreeColText from './../blocks/ThreeColText'
import CTASideBySide from './../compositions/CTASideBySide'
import CardList from './../card-list'
import SettingsList from './../settings-list'
import TripList from './../trip-list'
import Error from './../compositions/Error'
import { DefaultLayoutNew } from './DefaultLayoutNew'
import InjectMeta from './InjectMeta'
import Menu from '../side-menu'
import Form1 from '../driver-acceptance-form'
import { DriverForm } from './../driver-form'
import ThreeColIcon from '../blocks/ThreeColIcon'
import TimelineBar from '../timeline-bar'
import ColumnsNoticeBox from '../notice-box-columns'
import ItineraryItem from '../itinerary-description'
import InformationBox from '../information-box'
import ConfirmationEmail from '../email-confirmation'
import OuterWrapper from './../super-mega-wrapper'

const getBtnStyle = (type) =>
  type ? (type === 'Quaternary' ? 'white' : type.toLowerCase()) : 'primary'

const SidebarTemplateComp = ({ data }) => (
  <div style={{ display: 'flex' }}>
    <Menu />
    <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
      {renderMainComponents(data.components)}
    </div>
  </div>
)

const components = {
  cardList: CardList,
  carousel: Carousel,
  'column-notice-box': ColumnsNoticeBox,
  'confirm-email': ConfirmationEmail,
  driver_account: Form1,
  driver_form: DriverForm,
  error: Error,
  'information-box': InformationBox,
  itineraryDescription: ItineraryItem,
  large_carousel: Carousel,
  page_intro: PageHeader,
  settingsList: SettingsList,
  sidebar_template: SidebarTemplateComp,
  timeline: TimelineBar,
  tripList: TripList,
  three_column_icon: ThreeColIcon,
  three_column_text: ThreeColText,
  two_column_cta: CTASideBySide,
  wysiwyg: SafeHtmlParser,
  wysiwyg_background: SafeHtmlParser,
  wysiwyg_bg: SafeHtmlParser,
}

const getData = (data, id) => {
  if (id === 'carousel' || id === 'large_carousel') {
    // historically there to sort with a position key the carousel
    return data.carousel.sort((a, b) => b.position < a.position)
  }
  if (
    id === 'page_intro' ||
    id === 'wysiwyg' ||
    id === 'wysiwyg_background' ||
    id === 'wysiwyg_bg'
  ) {
    return data?.children
  }
  return data
}

const renderMainComponents = (content) =>
  content.map((comp, iter) => {
    const theKey = `${comp.id}-${iter}`
    const first = iter === 0
    const Comp = components[comp.id]
    if (!Comp) return null
    return (
      <Comp
        first={first}
        spaced={comp?.data?.spaced}
        key={theKey}
        full={comp?.data?.indented === false}
        data={getData(comp.data, comp.id)}
      >
        {comp?.data?.children && comp?.data?.children?.sort
          ? comp.data.children
              .sort((a, b) => b.position < a.position)
              .map((item, iter) =>
                renderChild(item.type, item, first && iter === 0, iter),
              )
          : null}
      </Comp>
    )
  })

const renderChild = (type, comp, first = false, iter) => {
  if (type === 'empty-block') {
    const higresimg = propPath(['image', 'original', 'url'], comp).option('')
    const blurried = propPath(['image', 'low_res', 'url'], comp).option('')
    const opacity = propPath(['image_opacity_overlay'], comp)
      // .map(item => item === 'true' ? true : false)
      .option(false)
    return (
      <Square
        first={first}
        transparent={comp.transparent}
        alignItems={comp.alignItems}
        justifyContent={comp.justifyContent}
        higresimg={higresimg}
        blurried={blurried}
        opacityProp={opacity}
        key={`sq${iter}${Math.random()}`}
      >
        <CTA
          first={first}
          key={comp.position}
          data={{
            blockBehindText: comp.content_background,
            title: comp.heading,
            titleColor: comp.text_colour,
            paragraph: comp.body,
            paragraphColor: comp.text_colour,
            borderWhite: comp.heading_underline,
            buttons: ['', '2'].map((infix) => ({
              ctaButton: comp[`button${infix}_text`],
              ctaButtonStyle: getBtnStyle(comp[`button${infix}_style`]),
              ctaButtonLink: comp[`button${infix}_link`]
                ? comp[`button${infix}_link`].uri
                : '#',
              ctaButtonLinkTarget:
                comp[`button${infix}_link_target`] === 'same_tab'
                  ? '_self'
                  : '_blank',
            })),
          }}
        />
      </Square>
    )
  } else return null
}

export const PageFromJson = ({
  metaPage,
  components = [],
  pageType = 'viewer',
  children,
  lang,
}) => {
  return (
    <>
      <InjectMeta meta={metaPage} lang={lang} />
      <DefaultLayoutNew pageType={pageType} lang={lang}>
        <main style={{ minHeight: '65vh' }}>
          {children}
          {renderMainComponents(components)}
        </main>
      </DefaultLayoutNew>
    </>
  )
}
