import React from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import styled from 'styled-components'
import { PageFromJson } from './../../components/utilities/MainContainer'
import CoreHtmlParser from './../../components/blocks/CoreHtmlParser'
import { ext } from './../../utils/utils'
import { breakpoint } from './../../styles/global.style'
import Button from './../../components/buttons'
import { loadState, removeState } from '../../utils/localStorage'
import { isTripExpired } from './../page-checkout'
import { languageData, getCurrentLanguage } from './../../languages/index'

const lang = getCurrentLanguage()

const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Driver Registration Successful- Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: 'Driver Registered Successfully',
    },
  },
]

const Wrapper = styled.div`
  position: relative;
  width: 45%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 100px 0px 50px;
  margin: auto;
  @media (max-width: ${breakpoint.mobile}) {
    width: 50%;
    gap: 15px;
  }
`

const Title = styled.h2`
  font-family: 'Helvetica';
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 700, bold;
  font-size: 24px;
  line-height: 28px;
`

const Paragraph = styled(ext(CoreHtmlParser))`
  padding-bottom: 4px;
`

const VerifySignup = () => {
  const theTrip = loadState('trip-pending')
  const isTripValid = theTrip && !isTripExpired(theTrip.createdOn)
  if (!isTripValid) {
    removeState('trip-pending')
    removeState('tripPendingId')
  }

  const renderProps = {
    titleColor: '#701400',
    title: languageData[lang]['confirmation.section.title'],
    paragraphText: {
      content_background: 'white',
      text_colour: 'black',
      body: {
        content: `<p>
        ${languageData[lang]['confirmation.section.content.0']}
                </p>
                `,
        format: 'html',
      },
    },
    buttonLabel: isTripValid
      ? `${languageData[lang]['confirmation.section.button.checkout']}}`
      : `${languageData[lang]['confirmation.section.button.home']}}`,
  }

  const router = useNavigate()
  const location = useLocation()
  const isRouteValid = location?.state?.previousPath === '/account/register'
  if (!isRouteValid) return <Navigate to={'/'} />
  else
    return (
      <PageFromJson metaPage={metaPage}>
        <Wrapper>
          <Title style={{ color: renderProps.titleColor }}>
            {renderProps.title}
          </Title>
          <Paragraph data={renderProps.paragraphText} />
          <Button
            key="home"
            type="secondary"
            onClick={() =>
              router(isTripValid ? '/checkout' : '/', {
                state: { previousPath: window.location.pathname },
              })
            }
            label={renderProps.buttonLabel}
          />
        </Wrapper>
      </PageFromJson>
    )
}

export default VerifySignup
