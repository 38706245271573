import { imagesFiles } from '../../styles/images'
import * as S from './styled'

const ImageWithTitle = ({ data }) => (
  <S.Wrapper background={imagesFiles[data.imgSrc]} centerText={data.centerText}>
    <S.Title center={data.titleCentered}>{data.titleText}</S.Title>
  </S.Wrapper>
)

export default ImageWithTitle
