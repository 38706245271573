import * as S from './styled'
import { useEffect, useState } from 'react'
import * as T from '../atomic/Typography'

const FormSearchbarBoxFaq = ({ data, faqData }) => {
  const [value, setValue] = useState('')
  const [faqQuestions, setFaqQuestions] = useState([])
  const [matchingQuestion, setMatchingQuestion] = useState(null)

  const removeSymbols = (str) => {
    return str.replace(/[^\w\s]/g, '') // Removes all non-word characters and spaces
  }

  useEffect(() => {
    const questionTitles = faqData.questions.map((question) => ({
      title: question.questionTitle,
      answer: question.questionAnswer,
    }))
    setFaqQuestions(questionTitles)
  }, [faqData])

  useEffect(() => {
    if (value !== '' && faqQuestions.length > 1) {
      const inputWords = value.toLowerCase().split(' ')

      const matchingQuestion = faqQuestions.find((question) => {
        const keyWords = question.title.toLowerCase().split(' ')
        const simpleInputWords = inputWords.map(removeSymbols)
        const simpleKeyWords = keyWords.map(removeSymbols)
        return simpleInputWords.some((inputWord) =>
          simpleKeyWords.includes(inputWord),
        )
      })

      if (matchingQuestion) {
        setMatchingQuestion(matchingQuestion)
      } else {
        setMatchingQuestion(null)
      }
    } else {
      setMatchingQuestion(null)
    }
  }, [value, faqQuestions])

  return (
    <S.Wrapper>
      <S.Searchbar>
        <label htmlFor={data.input.id}>
          <S.InputIcon size={24} />
        </label>
        <S.Input value={value} onChange={(e) => setValue(e.target.value)} />
      </S.Searchbar>
      {matchingQuestion && (
        <S.Match>
          <S.MatchTitle>{data.match.matchboxTitle}</S.MatchTitle>
          <T.P2>
            <T.BoldSpan>{data.match.matchboxQuestion}</T.BoldSpan>{' '}
            {matchingQuestion.title}
          </T.P2>
          <T.P2>
            <T.BoldSpan>{data.match.matchboxAnswer}</T.BoldSpan>{' '}
            {matchingQuestion.answer}
          </T.P2>
        </S.Match>
      )}
    </S.Wrapper>
  )
}

export default FormSearchbarBoxFaq
