import { getCurrentLanguage, languageData } from './../../languages/index'

export const pageData = () => {
  const lang = getCurrentLanguage()
  return {
    searchboxData: {
      title: languageData[lang]['page.faq.searchbox.title.main'],
      subtitle: languageData[lang]['page.faq.searchbox.subtitle.main'],
      input: {
        inputType: 'faq',
        id: 'searchbox',
        type: 'text',
        placeholder: '',
      },
      error: {
        inputError:
          languageData[lang]['page.faq.searchbox.input.error.message'],
        errorMessage:
          languageData[lang]['page.faq.searchbox.secondary.error.message'],
        messageLinkText:
          languageData[lang][
            'page.faq.searchbox.secondary.error.message.keyword'
          ],
        messageLinkHref: '/',
      },
      match: {
        matchboxTitle: languageData[lang]['page.faq.searchbox.match.box.title'],
        matchboxQuestion:
          languageData[lang]['page.faq.searchbox.match.box.question'],
        matchboxAnswer:
          languageData[lang]['page.faq.searchbox.match.box.answer'],
      },
    },
    faqPageData: {
      sectionTitle: languageData[lang]['home.faq.footer.list.title'],
      longVersion: true,
      questions: [
        {
          questionTitle: languageData[lang]['home.faq.question.0'],
          questionAnswer: languageData[lang]['home.faq.answer.0'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.1'],
          questionAnswer: languageData[lang]['home.faq.answer.1'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.2'],
          questionAnswer: languageData[lang]['home.faq.answer.2'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.3'],
          questionAnswer: languageData[lang]['home.faq.answer.3'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.4'],
          questionAnswer: languageData[lang]['home.faq.answer.4'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.5'],
          questionAnswer: languageData[lang]['home.faq.answer.5'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.6'],
          questionAnswer: languageData[lang]['home.faq.answer.6'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.7'],
          questionAnswer: languageData[lang]['home.faq.answer.7'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.8'],
          questionAnswer: languageData[lang]['home.faq.answer.8'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.9'],
          questionAnswer: languageData[lang]['home.faq.answer.9'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.10'],
          questionAnswer: languageData[lang]['home.faq.answer.10'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.11'],
          questionAnswer: languageData[lang]['home.faq.answer.11'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.12'],
          questionAnswer: languageData[lang]['home.faq.answer.12'],
        },
      ],
      footer: {
        footerSubtitle: languageData[lang]['home.faq.footer.list.content'],
        linkWord: languageData[lang]['home.faq.footer.list.content.link'],
        linkHref: '/',
      },
    },
    ctaCardSimpleData: {
      title: languageData[lang]['page.contact.cta.card.2.title'],
      subtitle: languageData[lang]['page.contact.cta.card.2.subtitle'],
      simple: true,
      bottomContent: {
        type: 'simple',
      },
    },
  }
}

export const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'FAQ - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: `In Lelecabs we care about making your trip as seamless as possible, so we have gathered our most common questions to provide you with a care-free experience. `,
    },
  },
]
