import React from 'react'
import styled from 'styled-components'

import { DRow } from './../atomic/Layout'
import CoreHtmlParser from './../blocks/CoreHtmlParser'
import { breakpoint } from './../../styles/global.style'
import { ext } from './../../utils/utils'
import propPath from 'crocks/Maybe/propPath'
import { Icon } from '../atomic/DriveIcons'

const Wrapper = styled.div`
  width: ${(props) => `${props.width || 75}%`};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
`

const NewCol = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`

const RowWrapper = styled(ext(DRow))`
  background-color: white;
  position: relative;
  padding: 0px 5% 100px !important;
  display: flex;
  flex-direction: row;
  @media (max-width: ${breakpoint.tablet}) {
    min-height: 270px;
  }
  @media (max-width: ${breakpoint.mobile}) {
    padding: 25px 5% 140px !important;
    min-height: 200px;
    flex-direction: column;
    gap: 70px;
  }
`

const Title = styled.h4`
  color: ${(props) => props.color || '#000'};
  font-family: 'Helvetica';
  font-weight: 400;
  padding-top: 24px;
  padding-bottom: 6px;
  @media (max-width: ${breakpoint.mobile}) {
    padding-bottom: 31px;
  }
  letter-spacing: 0em;
`

const names = ['col1', 'col2', 'col3']

const ThreeColIcon = ({ data, spaced }) => {
  return (
    <RowWrapper
      invertColor
      alignItems="baseline"
      justifyContent="space-around"
      noPadding
    >
      {names.map((name, i) => {
        const col = data.children[name]
        const iconUrl = propPath(['icon', 'url'], col).option('')
        const label = propPath(['heading'], col).option('')

        return (
          <NewCol
            padding={10}
            vertical
            justifyContent="flex-start"
            key={`col${Math.random()}`}
          >
            <Icon url={iconUrl} />
            <Wrapper width={col.width}>
              <Title dangerouslySetInnerHTML={{ __html: label }} />
            </Wrapper>
            <Wrapper>
              <CoreHtmlParser data={col} />
            </Wrapper>
          </NewCol>
        )
      })}
    </RowWrapper>
  )
}

export default ThreeColIcon
