import styled from 'styled-components'
import { breakpoint, colors } from '../../../styles/global.style'
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle'
import CoreHtmlParser from '../../../components/blocks/CoreHtmlParser'
import { ext } from '../../../utils/utils'
import * as T from './../../../components/typography/index'

export const Wrapper = styled.div`
  position: relative;
  width: 45%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 70px 0px 50px;
  margin: auto;
  margin-bottom: 100px;
  gap: 40px;
  @media (max-width: ${breakpoint.mobile}) {
    width: 50%;
    gap: 15px;
  }
`

export const Title = styled(T.Uh2)`
  text-align: left;
  line-height: normal;
  padding-bottom: 16px;
`

export const Paragraph = styled(T.P2)`
  padding-bottom: 4px;
  text-align: left;
  margin-top: -20px;
`

export const CheckIcon = styled(CheckCircle)`
  color: #058c3b;
  align-self: flex-start;
`

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
`
