import { DefaultLayoutNew } from '../../components/utilities/DefaultLayoutNew'

import * as S from './styled'

import CtaCard from '../../components/cta-card'
import FaqList from '../../components/faq-list'
import ListStepsWithImage from '../../components/list-steps-with-image'
import ShowWhenLoggedOut from './../../components/show-when-logged-out'
import { metaPage, pageData } from './data'
import { getCurrentLanguage } from '../../languages'
import InjectMeta from '../../components/utilities/InjectMeta'
import PageLoadAnimation from '../../styles/animations'

const PageHowItWorks = () => {
  const data = pageData()
  const lang = getCurrentLanguage()
  return (
    <>
      <InjectMeta meta={metaPage} lang={lang} />
      <PageLoadAnimation>
        <DefaultLayoutNew pageType="viewer">
          <S.Wrapper>
            <ListStepsWithImage data={data.customThreeRowAdv} />
            <FaqList data={data.faqPageData} />
            <ShowWhenLoggedOut>
              <CtaCard data={data.ctaCardData} />
            </ShowWhenLoggedOut>
          </S.Wrapper>
        </DefaultLayoutNew>
      </PageLoadAnimation>
    </>
  )
}
export default PageHowItWorks
