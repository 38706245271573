import FaqList from '../../components/faq-list'
import FormSearchbarBox from '../../components/form-searchbar-box'
import { metaPage, pageData } from './data'
import { DefaultLayoutNew } from '../../components/utilities/DefaultLayoutNew'
import { Wrapper } from './styled'
import CtaCard from '../../components/cta-card'
import InjectMeta from '../../components/utilities/InjectMeta'
import { getCurrentLanguage } from '../../languages'
import PageLoadAnimation from '../../styles/animations'

const PageFaq = () => {
  const data = pageData()
  const lang = getCurrentLanguage()
  return (
    <>
      <InjectMeta meta={metaPage} lang={lang} />
      <PageLoadAnimation>
        <DefaultLayoutNew pageType="viewer" footerType="new">
          <Wrapper>
            <FormSearchbarBox
              data={data.searchboxData}
              faqData={data.faqPageData}
            />
            <FaqList data={data.faqPageData} />
            <CtaCard simple data={data.ctaCardSimpleData} />
          </Wrapper>
        </DefaultLayoutNew>
      </PageLoadAnimation>
    </>
  )
}

export default PageFaq
