import styled from 'styled-components'
import { breakpoint } from './../../styles/global.style'
import * as S from './styled'

/***********************************
 * Use the OuterWrapper to cancel the
 * effect of the super mega wrapper in
 * the internal components
 * ********************************/
export const OuterWrapper = styled.div`
  margin-left: -${S.websitePadding} !important;
  margin-right: -${S.websitePadding} !important;
  @media (max-width: ${breakpoint.bigtablet}) {
    margin-left: -${S.websitePaddingBigTablet} !important;
    margin-right: -${S.websitePaddingBigTablet} !important;
  }
  @media (max-width: ${breakpoint.tablet}) {
    margin-left: -${S.websitePaddingTablet} !important;
    margin-right: -${S.websitePaddingTablet} !important;
  }
  @media (max-width: ${breakpoint.mobile}) {
    margin-left: -${S.websitePaddingMobile} !important;
    margin-right: -${S.websitePaddingMobile} !important;
  }
`

export const SuperMegaWrapper = ({ children, backgroundColor, isHeader }) => (
  <S.Wrapper $backgroundColor={backgroundColor} $isHeader={isHeader}>
    {children}
  </S.Wrapper>
)

export default SuperMegaWrapper
