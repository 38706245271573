export const countryCodes = {
  US: { primary: '+1' },
  GB: { primary: '+44' },
  FR: { primary: '+33' },
  IT: { primary: '+39' },
  DE: { primary: '+49' },
  ES: { primary: '+44' },
  BG: { primary: '+359' },
  CN: { primary: '+86' },
  AE: { primary: '+86' },
  UA: { primary: '+86' },
  TR: { primary: '+86' },
  TH: { primary: '+86' },
  SY: { primary: '+86' },
  SE: { primary: '+86' },
  AF: { primary: '+93' },
  AL: { primary: '+355' },
  DZ: { primary: '+213' },
  AS: { primary: '+1-684' },
  AD: { primary: '376' },
  AO: { primary: '+244' },
  AI: { primary: '+1-264' },
  AG: { primary: '+1-268' },
  AR: { primary: '+54' },
  AM: { primary: '+374' },
  AW: { primary: '+297' },
  AU: { primary: '+61' },
  AT: { primary: '+43' },
  AZ: { primary: '+994' },
  BS: { primary: '+1-242' },
  BH: { primary: '+973' },
  BD: { primary: '+880' },
  BB: { primary: '+1-246' },
  BY: { primary: '+375' },
  BE: { primary: '+32' },
  BZ: { primary: '+501' },
  BJ: { primary: '+229' },
  BM: { primary: '+1-441' },
  BT: { primary: '+975' },
  BO: { primary: '+591' },
  BA: { primary: '+387' },
  BW: { primary: '+267' },
  BR: { primary: '+55' },
  IO: { primary: '+246' },
  BF: { primary: '+226' },
  BI: { primary: '+257' },
  KH: { primary: '+855' },
  CM: { primary: '+237' },
  CA: { primary: '+1' },
  CV: { primary: '+238' },
  KY: { primary: '+1-345' },
  CF: { primary: '+236' },
  TD: { primary: '+235' },
  CL: { primary: '+56' },
  CO: { primary: '+57' },
  CG: { primary: '+242' },
  CK: { primary: '+682' },
  CR: { primary: '+506' },
  CI: { primary: '+225' },
  HR: { primary: '+385' },
  CU: { primary: '+53' },
  CW: { primary: '+599' },
  CY: { primary: '+357' },
  CZ: { primary: '+420' },
  DK: { primary: '+45' },
  DJ: { primary: '+253' },
  DM: { primary: '+1-767' },
  DO: { primary: '+1-809' },
  EC: { primary: '+593' },
  EG: { primary: '+20' },
  SV: { primary: '+503' },
  GQ: { primary: '+240' },
  ER: { primary: '+291' },
  EE: { primary: '+372' },
  ET: { primary: '+251' },
  FK: { primary: '+500' },
  FO: { primary: '+298' },
  FJ: { primary: '+679' },
  FI: { primary: '+358' },
  PF: { primary: '+689' },
  GA: { primary: '+241' },
  GM: { primary: '+220' },
  GE: { primary: '+995' },
  GH: { primary: '+233' },
  GI: { primary: '+350' },
  GR: { primary: '+30' },
  GL: { primary: '+299' },
  GD: { primary: '+1-473' },
  GT: { primary: '+502' },
  GG: { primary: '+44-1481' },
  GN: { primary: '+224' },
  GW: { primary: '+245' },
  HT: { primary: '+509' },
  HN: { primary: '+504' },
  HK: { primary: '+852' },
  HU: { primary: '+36' },
  IS: { primary: '+354' },
  IN: { primary: '+91' },
  ID: { primary: '+62' },
  IR: { primary: '+98' },
  IQ: { primary: '+964' },
  IE: { primary: '+353' },
  IM: { primary: '+44-1642' },
  IL: { primary: '+972' },
  JM: { primary: '+1-876' },
  JP: { primary: '+81' },
  JE: { primary: '+44-1534' },
  JO: { primary: '+962' },
  KZ: { primary: '+7' },
  KE: { primary: '+254' },
  KI: { primary: '+686' },
  KP: { primary: '+850' },
  KR: { primary: '+82' },
  KW: { primary: '+965' },
  KG: { primary: '+996' },
  LA: { primary: '+856' },
  LV: { primary: '+371' },
  LB: { primary: '+961' },
  LS: { primary: '+266' },
  LR: { primary: '+231' },
  LI: { primary: '+423' },
  LT: { primary: '+370' },
  LU: { primary: '+352' },
  MO: { primary: '+853' },
  MK: { primary: '+389' },
  MG: { primary: '+261' },
  MW: { primary: '+265' },
  MY: { primary: '+60' },
  MV: { primary: '+960' },
  ML: { primary: '+223' },
  MT: { primary: '+356' },
  MR: { primary: '+222' },
  MU: { primary: '+230' },
  MX: { primary: '+52' },
  FM: { primary: '+691' },
  MD: { primary: '+373' },
  MC: { primary: '+377' },
  MN: { primary: '+976' },
  ME: { primary: '+382' },
  MS: { primary: '+1-664' },
  MA: { primary: '+212' },
  MZ: { primary: '+258' },
  MM: { primary: '+95' },
  NA: { primary: '+264' },
  NR: { primary: '+674' },
  NP: { primary: '+977' },
  NL: { primary: '+31' },
  NZ: { primary: '+64' },
  NI: { primary: '+505' },
  NE: { primary: '+227' },
  NG: { primary: '+234' },
  NU: { primary: '+683' },
  MP: { primary: '+1-670' },
  NO: { primary: '+47' },
  OM: { primary: '+968' },
  PK: { primary: '+92' },
  PW: { primary: '+680' },
  PS: { primary: '+970' },
  PA: { primary: '+507' },
  PG: { primary: '+675' },
  PY: { primary: '+595' },
  PE: { primary: '+51' },
  PH: { primary: '+63' },
  PN: { primary: '+64' },
  PL: { primary: '+48' },
  PT: { primary: '+351' },
  PR: { primary: '+1-787' },
  QA: { primary: '+974' },
  RO: { primary: '+40' },
  RU: { primary: '+7' },
  RW: { primary: '+250' },
  KN: { primary: '+1-869' },
  LC: { primary: '+1-758' },
  WS: { primary: '+685' },
  SM: { primary: '+378' },
  ST: { primary: '+239' },
  SA: { primary: '+966' },
  SN: { primary: '+221' },
  RS: { primary: '+381' },
  SC: { primary: '+248' },
  SL: { primary: '+232' },
  SG: { primary: '+65' },
  SX: { primary: '+1-721' },
  SK: { primary: '+421' },
  SI: { primary: '+386' },
  SB: { primary: '+677' },
  SO: { primary: '+251' },
  ZA: { primary: '+27' },
  SS: { primary: '+211' },
  LK: { primary: '+94' },
  SD: { primary: '+249' },
  SR: { primary: '+597' },
  SZ: { primary: '+268' },
  CH: { primary: '+41' },
  TW: { primary: '+886' },
  TJ: { primary: '+992' },
  TZ: { primary: '+255' },
  TG: { primary: '+228' },
  TK: { primary: '+690' },
  TO: { primary: '+676' },
  TT: { primary: '+1-868' },
  TN: { primary: '+216' },
  TM: { primary: '+993' },
  TC: { primary: '+1-649' },
  TV: { primary: '+688' },
  UG: { primary: '+256' },
  UY: { primary: '+598' },
  UZ: { primary: '+998' },
  VU: { primary: '+678' },
  VE: { primary: '+58' },
  VN: { primary: '+84' },
  VI: { primary: '+1-340' },
  ZM: { primary: '+260' },
  ZW: { primary: '+263' },
}
