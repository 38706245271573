import React from 'react'
import styled from 'styled-components'

import { DRow, DCol } from '../atomic/Layout'
import { UhDyn } from '../atomic/Typography'
import CoreHtmlParser from './CoreHtmlParser'
import { breakpoint } from '../../styles/global.style'
import { ext } from '../../utils/utils'

const Wrapper = styled.div`
  width: ${(props) => `${props.width || 75}%`};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ParagraphWrapper = styled(Wrapper)`
  padding-top: 2px;
  @media (max-width: ${breakpoint.mobile}) {
    padding-top: 23px;
  }
`

const NewCol = styled(ext(DCol))`
  ${(props) =>
    props.hideTablet
      ? `
    @media(max-width: ${breakpoint.bigtablet}) {
      display: none;
    }
  `
      : ''}
  ${(props) =>
    props.hideMobile
      ? `
    @media(max-width: ${breakpoint.tablet}) {
      display: none;
    }
  `
      : ''}
  @media(max-width: ${breakpoint.bigtablet}) {
    width: 100%;
  }
`

const RowWrapper = styled(ext(DRow))`
  position: relative;
  gap: 30px;
  flex-wrap: wrap;
  padding: 52px 5% 60px !important;
  display: flex;
  flex-direction: row;
  @media (max-width: ${breakpoint.tablet}) {
    min-height: 270px;
  }
  @media (max-width: ${breakpoint.bigtablet}) {
    padding: 70px 5% 70px !important;
    min-height: 200px;
    flex-direction: column;
    gap: 90px;
  }
`
const names = ['col1', 'col2', 'col3']

const ThreeColText = ({ data }) => {
  return (
    <DRow spaced>
      <RowWrapper
        invertColor
        alignItems="baseline"
        justifyContent="space-around"
        noPadding
      >
        {names.map((name, i) => {
          const col = data.children[name]
          return (
            <NewCol
              padding={10}
              vertical
              justifyContent="flex-start"
              key={`col${Math.random()}`}
            >
              <Wrapper width={col.width}>
                <UhDyn
                  paddingBottom="12px"
                  invertColors
                  label={col.heading}
                  extraSpace={11}
                  type="h2"
                />
              </Wrapper>
              <ParagraphWrapper>
                <CoreHtmlParser data={col} />
              </ParagraphWrapper>
            </NewCol>
          )
        })}
      </RowWrapper>
    </DRow>
  )
}
export default ThreeColText
