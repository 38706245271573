import React, { useEffect, useState } from 'react'
import { formatQuotes } from '../../hooks/useQuotes'
import { FormLikeLayout, TitleLayout } from '../atomic/FormLikeLayout'
import { TripTypeBanner } from '../forms'
import TripCards from '../trip-cards'

import Button from '../buttons'
import {
  Wrapper,
  ReserveTrip,
  SmallBold,
  DateLabel,
  InfoWrapper,
  DateWrapper,
  PriceWrapper,
  InfoGraphicWrapper,
  Box,
  ExtraFees,
  Vat,
} from './styled'
import { BoldSpan } from './../atomic/Typography'
import { languageData, getCurrentLanguage } from './../../languages/index'
import CarLoader from '../car-loader'
import { formatPrice, getDate, getHour } from '../../utils/rideUtils'
import { breakpoint } from '../../styles/global.style'

const lang = getCurrentLanguage()

const searchParams = new URLSearchParams(window.location.search)
const isEditing = searchParams.get('edit') === 'true'

const RideConfirmation = ({
  reservation,
  quotes,
  onSubmit,
  onCancel,
  sessionId,
}) => {
  const {
    triptype,
    when,
    origin,
    destination,
    coverage,
    direction,
    whenReturn,
    flightCode,
    returnFlightCode,
  } = reservation

  const [loading, setLoading] = useState(true)

  const handleCallback = (callback) => () => {
    if (callback) callback()
  }

  const formattedQuotes = formatQuotes(quotes)

  useEffect(() => {
    if (formattedQuotes[triptype]) setLoading(false)
  }, [formattedQuotes[triptype]])

  const tabletScreen = parseInt(breakpoint.smallTablet)
  return (
    <FormLikeLayout
      justifyContent={window.innerWidth > tabletScreen ? 'start' : 'center'}
      radius={16}
      minWidth={600}
      noTopBorder
      alignItems={'center'}
      height={565}
    >
      <TitleLayout
        label={languageData[lang]['checkout.confirmation.reservation.title']}
      />
      {loading ? (
        <CarLoader />
      ) : (
        <Wrapper>
          <InfoGraphicWrapper>
            <div style={{ width: '100%' }}>
              <ReserveTrip>
                {
                  languageData[lang][
                    'checkout.confirmation.reservation.subtitle'
                  ]
                }
              </ReserveTrip>
              <Box>
                <TripTypeBanner tripType={triptype} />
                <InfoWrapper>
                  <DateWrapper>
                    <DateLabel>
                      <BoldSpan>{getDate(when, lang)}</BoldSpan>
                    </DateLabel>
                    <BoldSpan>{getHour(when, lang)}</BoldSpan>
                  </DateWrapper>
                  <TripCards
                    from={origin}
                    to={destination}
                    flightCode={flightCode}
                  />
                </InfoWrapper>
                {direction === 'Return' && (
                  <InfoWrapper>
                    <DateWrapper>
                      <DateLabel>
                        <BoldSpan>{getDate(whenReturn, lang)}</BoldSpan>
                      </DateLabel>
                      <BoldSpan>{getHour(whenReturn, lang)}</BoldSpan>
                    </DateWrapper>
                    <TripCards
                      from={destination}
                      to={origin}
                      flightCode={returnFlightCode}
                    />
                  </InfoWrapper>
                )}
                <PriceWrapper>
                  {isEditing && (
                    <ExtraFees>
                      {
                        <b>
                          <span>
                            {
                              languageData[lang][
                                'checkout.confirmation.edit.original.trip.price'
                              ]
                            }{' '}
                            {formatPrice(priceData.originalReservationPrice)}
                          </span>
                        </b>
                      }
                      {
                        <b>
                          <span>
                            {
                              languageData[lang][
                                'checkout.confirmation.edit.fee'
                              ]
                            }
                          </span>
                        </b>
                      }
                      {
                        <b>
                          <span>{`${
                            languageData[lang][
                              'checkout.confirmation.edit.new.trip.price'
                            ]
                          } ${formatPrice(priceData.price)}`}</span>
                        </b>
                      }
                    </ExtraFees>
                  )}
                  <SmallBold>
                    <b>
                      {!isEditing
                        ? `${languageData[lang]['checkout.confirmation.total.price']}`
                        : `${languageData[lang]['checkout.confirmation.edit.price.difference']}`}
                    </b>
                    <h4>
                      <b>
                        {!isEditing
                          ? formatPrice(formattedQuotes[triptype])
                          : formatPrice(priceData.priceDifference)}
                      </b>
                    </h4>
                  </SmallBold>
                  <Vat>
                    <p>VAT included - Italy (10%)</p>
                    <p>
                      {((formattedQuotes[triptype] / 1.1) * 0.1).toFixed(2)}
                    </p>
                  </Vat>
                </PriceWrapper>
              </Box>
            </div>
            <Button
              type="secondary"
              data-stripe-url={sessionId}
              onClick={handleCallback(onSubmit)}
              label={
                languageData[lang]['checkout.confirmation.button.proceed.label']
              }
            />
          </InfoGraphicWrapper>
          <Button
            type="tertiary"
            onClick={handleCallback(onCancel)}
            label={
              languageData[lang]['checkout.confirmation.button.back.label']
            }
          />
        </Wrapper>
      )}
    </FormLikeLayout>
  )
}

export default RideConfirmation
