import styled from 'styled-components'
import * as T from './../atomic/Typography'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  display: flex;
  padding: 40px 30px;
  align-items: center;
  gap: 48px;
  background-color: ${(props) => (props.asHero ? 'unset' : '#f5f5f5')};
  justify-content: space-around;
  flex-direction: ${(props) =>
    props.reversed || props.asHero ? 'row-reverse' : 'row'};
  a {
    text-decoration: none;
  }
  @media (max-width: ${breakpoint.medium}) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: ${breakpoint.smallTablet}) {
    padding: 2.5em 1.5em;
    justify-content: space-around;
  }
  @media (min-width: ${breakpoint.siteWidth}) {
    justify-content: space-around;
  }
`

export const Image = styled.img`
  max-width: 100%;
`

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 566.786px;
  a {
    text-decoration: none;
  }
`

export const Title = styled(T.Uh3)`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
`
