import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { UhDyn } from './../atomic/Typography'
import CoreHtmlParser from './../blocks/CoreHtmlParser'
import Button from './../buttons'
import { FirstLine, Paragraph, OpacityBox, ButtonsWrapper } from './styled'

class CTA extends Component {
  render() {
    const { data, first } = this.props
    return (
      <>
        <OpacityBox opacity={data.blockBehindText}>
          {data.preTitle && (
            <FirstLine color={data.preTitleColor}>{data.preTitle}</FirstLine>
          )}
          {data.title && (
            <UhDyn
              hideMobile={data.hideMobile}
              borderWhite={data.borderWhite}
              paddingBottom="7px"
              color={data.titleColor}
              label={data.title}
              extraSpace={0}
              extraSpaceMobile={20}
              type="h2"
              first={first}
            />
          )}
          {data.paragraph && (
            <Paragraph hideMobile={data.hideMobile} color={data.paragraphColor}>
              <CoreHtmlParser
                data={{
                  body: data.paragraph,
                  text_colour: data.paragraphColor,
                }}
              />
            </Paragraph>
          )}
          <ButtonsWrapper>
            {data.buttons &&
              data.buttons.map(
                (btn, iter) =>
                  btn.ctaButton && (
                    <Link
                      key={`${Math.random()}-btn`}
                      to={btn.ctaButtonLink || '#'}
                      target={btn.ctaButtonLinkTarget}
                      rel="noreferrer noopener"
                    >
                      <Button
                        type={btn.ctaButtonStyle}
                        label={btn.ctaButton}
                        stretch
                      />
                    </Link>
                  ),
              )}
          </ButtonsWrapper>
        </OpacityBox>
      </>
    )
  }
}

export default CTA
