import React from 'react'
import styled from 'styled-components'
import propPath from 'crocks/Maybe/propPath'
import { breakpoint } from './../../styles/global.style'
import { DRow } from './../atomic/Layout'
import Square from './../square'
import CTA from './../cta'
import { ext } from './../../utils/utils'

const getBtnStyle = (type) =>
  type ? (type === 'Quaternary' ? 'white' : type.toLowerCase()) : 'primary'

const ResponsiveRow = styled(ext(DRow))`
  @media (max-width: ${breakpoint.tablet}) {
    flex-direction: column;
  }
  margin-top: ${(props) =>
    props.marginTop ? `${props.marginTop}px` : 'unset'};
  @media (min-width: ${breakpoint.siteWidth}) {
    padding-top: 45px;
  }
  ${(props) =>
    props.field_hide_copy_on_mobile
      ? `@media(max-width: ${breakpoint.mobile}) {
      display: none
    }`
      : ''}
`

const CTASideBySide = ({ data, grey, spaced, marginTop }) => {
  return (
    <ResponsiveRow grey={grey} justifyContent="center" marginTop={marginTop}>
      {data.children.map((item, iter) => {
        const higresimg = propPath(['image', 'original', 'url'], item).option(
          '',
        )
        const blurried = propPath(['image', 'low_res', 'url'], item).option('')
        const opacity = propPath(['image_opacity_overlay'], item)
          // .map(item => item === 'true' ? true : false)
          .option(false)
        return (
          <Square
            left={iter === 0}
            right={iter === 1}
            higresimg={higresimg}
            blurried={blurried}
            opacityProp={opacity}
            spaced={data.spaced}
            key={iter}
          >
            <CTA
              data={{
                hideMobile: data.hideMobile,
                blockBehindText: item.content_background,
                title: item.heading,
                titleColor: item.text_colour,
                paragraph: item.body,
                paragraphColor: item.text_colour,
                borderWhite: item.heading_underline,
                buttons: ['', '2'].map((infix) => ({
                  ctaButton: item[`button${infix}_text`],
                  ctaButtonStyle: getBtnStyle(item[`button${infix}_style`]),
                  ctaButtonLink: item[`button${infix}_link`]
                    ? item[`button${infix}_link`].uri
                    : '#',
                  ctaButtonLinkTarget:
                    item[`button${infix}_link_target`] === 'same_tab'
                      ? '_self'
                      : '_blank',
                })),
              }}
            />
          </Square>
        )
      })}
    </ResponsiveRow>
  )
}

export default CTASideBySide
