import styled from 'styled-components'
import { breakpoint } from '../../styles/global.style'
import * as T from '../atomic/Typography'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 69px;
  gap: 32px;
  /* width: 100%; */
  background: #f2f3f4;
  margin: 0 85px;
  margin-bottom: 96px;
  @media (max-width: ${breakpoint.mobile}) {
    padding: 10px 19px;
    margin: 0 25px;
    margin-bottom: 122px;
  }
`

export const Title = styled(T.Uh3)`
  font-weight: 700;
  @media (max-width: ${breakpoint.smallTablet}) {
    text-align: center;
  }
`

export const ColumnsWrapper = styled.div`
  display: flex;
  gap: 60px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${breakpoint.medium}) {
    flex-wrap: wrap;
  }
`

export const Column = styled.div`
  padding-right: 60px;
  &:not(:last-of-type) {
    border-right: 1px solid #cccccc;
    @media (max-width: ${breakpoint.smallTablet}) {
      border-right: none;
    }
  }
  @media (max-width: ${breakpoint.medium}) {
    width: 100%;
    border-right: 1px solid #cccccc;
    align-items: center;
  }
  @media (max-width: ${breakpoint.smallTablet}) {
    border-right: none;
    padding-right: 0px;
  }
`

export const ColumnTitle = styled(T.Uh4)`
  font-weight: 700;
`

export const UnorderedList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const ListItem = styled.li`
  color: #696871;
`
