import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { breakpoint } from '../../../styles/global.style'
import styled from 'styled-components'

import { languageData, getCurrentLanguage } from './../../../languages/index'

import { loadState, removeState, saveState } from '../../../utils/localStorage'
import { deleteApi, patchApi, getApi } from '../../../utils/restAPIUtils'
import { ext } from '../../../utils/utils'

import useAnalyticsEventTracker from './../../../hooks/useAnalyticsEventTracker'

import { AuthContext } from '../../../components/auth-context'

import { PageFromJson } from '../../../components/utilities/MainContainer'
import CarLoader from '../../../components/car-loader'
import Button from '../../../components/buttons'
import CoreHtmlParser from '../../../components/blocks/CoreHtmlParser'

const lang = getCurrentLanguage()

const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Payment Confirmation - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: 'Payment confirmation for your trip',
    },
  },
]

const Wrapper = styled.div`
  position: relative;
  width: 45%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 100px 0px 50px;
  margin: auto;
  @media (max-width: ${breakpoint.mobile}) {
    width: 50%;
    gap: 15px;
  }
`

const Title = styled.h2`
  font-family: 'Helvetica';
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 700, bold;
  font-size: 24px;
  line-height: 28px;
`

const Paragraph = styled(ext(CoreHtmlParser))`
  padding-bottom: 4px;
`

const ReservationResponse = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { getIdToken } = useContext(AuthContext)
  const router = useNavigate()
  const gaEventTracker = useAnalyticsEventTracker('PurchaseConfirmed')

  const renderProps = error
    ? {
        titleColor: '#FF0000',
        title: languageData[lang]['payment.response.fail.title'],
        paragraphText: {
          content_background: 'white',
          text_colour: 'black',
          body: {
            content: `<p>${languageData[lang]['payment.response.fail.content.0']}</p>
              `,
            format: 'html',
          },
        },
        buttonLabel: languageData[lang]['payment.response.fail.button'],
        action: () => router('/'),
      }
    : {
        titleColor: '#34A853',
        title: languageData[lang]['payment.response.success.title'],
        paragraphText: {
          content_background: 'white',
          text_colour: 'black',
          body: {
            content: `<p>${languageData[lang]['payment.response.success.content.0']}</p>
              `,
            format: 'html',
          },
        },
        buttonLabel: languageData[lang]['payment.response.success.button'],
        action: () => router('/account'),
      }

  useEffect(() => {
    const checkPaymentIntent = async () => {
      const token = (await getIdToken()) || ''
      const reservationId = loadState('tripPendingId')
      const originalReservationId = loadState('originalTripId')

      setLoading(true)
      if (!reservationId) return { error: 'not formed correctly' }

      if (originalReservationId) {
        const fetchReservation = async () => {
          // const token = await getIdToken();
          const result = await getApi(`reservations?id=${reservationId}`)({
            Authorization: token,
          })()((cb) => cb)
          saveState('trip-pending', result)

          return result
        }
        const updateTrip = async () => {
          const token = (await getIdToken()) || ''
          const theCompleteTrip = loadState('trip-pending')
          const result = await patchApi(
            `reservations?id=${originalReservationId}`,
          )({
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: token,
          })(theCompleteTrip)((cb) => cb)
          if (result?.items?.at(0)?.id) {
            setLoading(false)
            setError(false)
            gaEventTracker('RidePurchase', 'purchase done')
          } else {
            setLoading(false)
            setError(true)
          }
          removeState('trip-pending')
          removeState('tripPendingId')
        }
        const handleDeleteTrip = async () => {
          setLoading(true)
          const token = (await getIdToken()) || ''
          const res = await deleteApi('reservations')({
            Authorization: token,
          })({
            id: reservationId,
            originalTripId: originalReservationId,
            skipEmailNotification: true,
          })((cb) => cb)
          if (!res.length && !res.status) {
            setLoading(false)
            removeState('originalTripId')
            removeState('originalTripCreatedOn')
          } else {
            setLoading(false)
            removeState('originalTripId')
            removeState('originalTripCreatedOn')
          }
        }
        fetchReservation().then((result) => {
          updateTrip(result)
        })
        handleDeleteTrip()
      } else {
        const result = await getApi(`reservations/?id=${reservationId}`)({
          Authorization: token,
        })()((cb) => cb)

        if (result?.items?.at(0)?.id) {
          setError(false)
          setLoading(false)

          gaEventTracker('RidePurchase', 'purchase done')
        } else {
          setLoading(false)
          setError(true)
        }
        removeState('trip-pending')
        removeState('tripPendingId')
      }
    }
    checkPaymentIntent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <PageFromJson metaPage={metaPage}>
      {loading ? (
        <CarLoader />
      ) : (
        <Wrapper>
          <Title style={{ color: renderProps.titleColor }}>
            {renderProps.title}
          </Title>
          <Paragraph data={renderProps.paragraphText} />
          <Button
            key="home"
            type="secondary"
            onClick={renderProps.action}
            label={renderProps.buttonLabel}
            loading={loading}
          />
        </Wrapper>
      )}
    </PageFromJson>
  )
}

export default ReservationResponse
