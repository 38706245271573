import React from 'react'
import { PageFromJson } from './../components/utilities/MainContainer'

const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Terms and Conditions - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content:
        'Terms and Conditions of Lelecabs. Lelecabs allow you to take a ride to your destination.',
    },
  },
]

const components = [
  {
    id: 'page_intro',
    data: {
      children: {
        heading: 'Terms and Conditions',
      },
    },
  },
  {
    id: 'wysiwyg',
    spaced: true,
    data: {
      children: {
        text_colour: 'black',
        body: {
          format: 'html',
          content: `<ol>
            <li>If You wish to book a PRIVATE HIRE VEHICLE (lelecabs) via this website, www.lelecabs.com, You must agree to the terms below as the exclusive basis which governs such booking and must tick the "I agree to lelecabs's Terms &amp; Conditions" box on the main passenger's contact information page. If You do not agree to any of the terms, do not book a PRIVATE HIRE VEHICLE via this website. If you are booking on behalf of someone else, by clicking "I agree to lelecabs's Terms &amp; Conditions", You are representing that You have their authority to accept these terms and conditions on their behalf.</li>
            <li><strong>DEFINITIONS</strong><ol>
              <li>In these Terms and Conditions the following expressions shall have the following meanings save where the context otherwise requires:<ol>
              <li><strong>"Agreed Pick-Up Point" </strong>the appointed place for You to be picked up by PHO as set out in the Details.</li>
              <li><strong>"Agreed Time"</strong> the appointed date and time for You to be picked up by PHO as set out in the Details.</li>
              <li><strong>"Booking"</strong> the booking of a lelecabs effected via this Website for undertaking Trips. Bookings can be made for single Trips, return Trips or split Trips.</li>
              <li><strong>"PHO"</strong> the Private Hire Operator company with whom You place the Booking.</li>
              <li><strong>"PHO T&amp;C"</strong> the terms and conditions of the PHO which in addition to these Terms govern the Booking and (Trip).</li>
              <li><strong>"Card"</strong> the credit, debit or charge card, or PayPal account You use when making the Booking.</li>
              <li><strong>"Cash"</strong> the payment You make for the Fare to the PHO and/or its Driver.</li>
              <li><strong>"Lelecabs"</strong> Piratech Limited of 57 Canbury Park Road, Kingston, United Kingdom, KT2 6LQ</li>
              <li><strong>"Card Costs"</strong> are the charges due to the credit card companies or PayPal used by You to pay for the Trip.</li>
              <li><strong>"Details"</strong> the details which You must provide upon making a Booking including pick-up date and time, pick-up and drop-off addresses and Your Personal Data.</li>
              <li><strong>"Fare"</strong> the fee paid by You for the Quote You accept.</li>
              <li><strong>"Goods"</strong> any goods carried in a lelecabs which are booked via this Website.</li>
              <li><strong>"Trip"</strong> the journey You wish to make from the pick-up address to the drop-off address provided by You.</li>
              <li><strong>"Licensing Authority"</strong> any regional or national licensing authority responsible for licensing the operation of taxi or lelecabs companies.</li>
              <li><strong>"Particular Requirements"</strong> any specific requirements You may have relating to disabled facilities, the gender of the driver or otherwise.</li>
              <li><strong>"Passenger"</strong> any passenger carried in a lelecabs which is booked via this Website.</li>
              <li><strong>"Personal Data"</strong> the details which You provide regarding Your physical and email addresses, telephone number and Card details.</li>
              <li><strong>"Quote"</strong> a fixed price quote that You receive via the Website for the Trip.</li>
              <li><strong>"Terms"</strong> these terms and conditions between lelecabs and You.</li>
              <li><strong>"Website"</strong> lelecabs's public website and mobile site located at www.lelecabs.com, and lelecabs's social media, smartphone and tablet apps.</li>
              <li><strong>"You"</strong> means the person who enters Details.</li>
              <li><strong>"lelecabs"</strong> is a Private Hire Vehicle made available for pre-booked Trips, in compliance with the requirements of relevant Licensing Authorities.</li>
              <li>The clause headings and any other headings are inserted for convenience only and shall not affect the construction of these Terms.</li></ol></li></ol></li>
              <li><strong>USE OF WEBSITE</strong><ol>
              <li>In making a Booking via this Website You must provide accurate Details and are responsible for any failure to do so.</li>
              <li>lelecabs acts as a disclosed booking agent for PHO, and is not an Operator itself. Notwithstanding the provisions of clause 3.6, the contract for the provision of the transport service is directly between You and PHO. Where in the Booking You have selected more that one PHO to provide Your Trips, then the contract for the provison of the transport service relating to each Trip is directly between You and the PHO You have selected for that Trip in the Booking.</li>
              <li>You hereby warrant to lelecabs that You are at least eighteen years of age and legally able to make the Booking.</li>
              <li>lelecabs does not guarantee that it will be able to fulfill Your requirements and reserves the rights to refuse any Bookings which You wish to make and to cancel any Bookings You have made, and in such case to refund to You, in full and final settlement of any claims you might have, the Fee paid by You.</li>
              <li>Your Details and data relating to Your use of the Website will be recorded by lelecabs but Your Personal Data shall not, subject to the provisions of the lelecabs Privacy Policy presented below and Clause 3.6 below, be disclosed to third parties other than the PHO or used for any purpose unrelated to the provision of the services pursuant hereto.</li>
              <li>lelecabs may send a small file to Your computer when You visit the Website. This "cookie" will enable lelecabs to track Your behaviour on the Website and to identify particular areas of interest so as to enhance Your future visits to the Website. The cookie will not enable lelecabs to identify You and lelecabs shall not use it otherwise than in relation to this Website. You can set Your computer browser to reject cookies but this may preclude use of certain parts of this Website. lelecabs may disclose Your Personal Data if compelled to do so by law, or at the request of a law enforcement agency or governmental authority.</li>
              <li>If You do not want lelecabs to use Your email address to send information concerning matters other than those related to Your Booking, You should send a message to info@lelecabs.com and insert "unsubscribe" as the subject heading.</li></ol></li>
              <li><strong>BOOKINGS</strong><ol>
              <li>When You enter the details of the Trip, lelecabs will endeavour to provide You via the Website with Quotes from one or more Private Hire Operators that are able to fulfil Your requirement. The Quotes are the current special "one off" offers available from Private Hire Operators, which are only available at the time of making Your Booking.</li>
              <li>It is Your responsibility to ensure that Your Details are correct. When You make a Booking, this constitutes an offer which lelecabs may or may not accept. Your Booking is not confirmed until you see the confirmation screen on the Website.</li>
              <li>Any Booking You make may also be subject to PHO Terms &amp; Conditions, although where there is any conflict between the PHO Terms &amp; Conditions and the provisions of this agreement, the provisions contained in this agreement override those in the PHO Terms &amp; Conditions.</li>
              <li>If PHO wishes to impose PHO Terms &amp; Conditions on You, it shall be responsible for achieving this.</li>
              <li>lelecabs cannot confirm the ability of the PHO to accommodate any Particular Requirements and in the event that You have such Particular Requirements You should not use this Website but instead make alternative travel arrangements that address your Particular Requirements.</li>
              <li>lelecabs reserves the right to correct typographical errors in any elements of the information that appears on the Website including pricing mistakes. If, once You are informed of such error, You wish to withdraw Your offer and do not wish to proceed with the purchase, You shall be free to do so and any monies already paid by You will be refunded in full.</li>
              <li>You acknowledge and accept that lelecabs is acting as the agent of the PHO and that whilst lelecabs will try to assist with any complaints You may have in respect of the PHO's services, any legal remedy You seek will be sought against the PHO not lelecabs.</li>
              <li>For bookings that are to be pre-paid by Card, lelecabs is entitled to collect all monies due for the booked Trip from You acting entirely in its own right, and settle all Card Costs of the booked Trip, in advance of making the payment due to the PHO for the booked Trip as PHO booking agent.</li>
              <li>You agree that in the fulfillment of the Booking, where PHO feels it is appropriate, PHO gives lelecabs the right to novate the contract for the provision of transport services between You and PHO to another suitable party, to ensure the fulfillment of the Booking as effected via this Website.</li></ol></li>
              <li><strong>PAYMENT</strong><ol>
              <li>If booking with the "Pay by Card" option, You agree to pay the Fare at the time You make Your Booking. If booking with the "Pay by Cash" option, You agree to pay the Fare to the PHO's Driver.</li>
              <li>Once You have confirmed Your Booking on the payment screen,
                        it cannot be cancelled or changed except in accordance with clause 7 of these Terms.</li>
              <li>If paying by Card, in the event that Your Card payment is refused by Your Card issuer for whatever reason, lelecabs will not be able to fulfill Your Booking.</li>
              <li>lelecabs will provide You with a receipt for Payment by email to the email address you registered with the booking.&nbsp;This will include a link to retrieve a VAT receipt for lelecabs's fees. You will need to contact the PHO directly for a VAT receipt relating to their fees.</li>
              <li>Payment will not cover any extra requirements you request, such as child seats. If you do not indicate any additional luggage when booking, resulting in a larger size vehicle being required, then the PHO may charge you extra, which you must pay them directly for. If You wish to give a tip to the lelecabs driver, You should do so directly at your own discretion.</li>
              <li>In the event that You make any deviations from the route that You entered into the Website, You may be subject to additional charges by PHO in respect thereof and will make such payment directly to PHO.</li>
              <li>For the avoidance of doubt, all Quotes provided by lelecabs are inclusive of VAT, where applicable, at the standard rate. Any promotional code applied to the Fare will first reduce the lelecabs fee. Any excess discount will then be deducted from the PHO-related fee.</li>
              <li>Only one promotional offer or prize can be applied to a booking, and only one entry per customer (as defined by their mobile number and/or email address) will be considered in any competition run by lelecabs. Where 2 or more promotional offers are requested to be applied to a booking, lelecabs at its discretion will apply the higher valued offer.</li></ol></li>
              <li><strong>INSURANCE</strong><ol>
              <li>Neither lelecabs nor PHO is responsible to You for, or insured in respect of, any damage or loss that may be sustained by the Goods in the course of a Trip and it is Your exclusive responsibility to have such Goods insured in respect of such damage or loss.</li></ol></li>
              <li><strong>CANCELLATION OF BOOKINGS AND REFUNDS</strong><ol>
              <li>You have the right to cancel a Booking made through the Website.</li>
              <li>If You decide to cancel a Booking You must notify lelecabs by submitting a cancellation request via the form on the Contact Us page, quoting the booking reference, as per the guidance provided on the Website.</li>
              <li><ol>
              <li>In the event of the cancellation of a Booking in accordance with Clause 7.1 above that is before the cancellation cut off time specified on the confirmation page and your booking confirmation email, then if paying by Card, You will be entitled to a full refund of the Fare (excluding any Card Costs). Where the cancellation is after the cancellation cut off time specified in the confirmation page and your booking confirmation email, if you paid by Card then no refund will be due to You, and if you were to pay by Cash, then your profile will be marked which may prevent you from booking through lelecabs again.</li>
              <li>In the event of the cancellation of a Booking, for all vehicles carrying 9 or more passengers, in accordance with Clause 7.1 above, up to one week (unless otherwise specified on the confirmation page and your booking confirmation email) before the Agreed time, then if paying by Card, You will be entitled to a full refund of the Fare (excluding any Card Costs). Where the cancellation is less than one week, for all vehicles carrying 9 or more passengers (unless otherwise specified on the confirmation page and your booking confirmation email) before the agreed time, if you paid by Card then no refund will be due to You.</li></ol></li>
              <li>In the event that PHO does not turn up at the Agreed Pick-Up Point within 15 minutes of the Agreed Time and You do not use PHO for the Trip, You will be entitled to a full refund of the Fare, along with any Card Costs, if you paid them in advance by Card, as full and final settlement of any claim You might have in respect of such failure. However should You, at Your own discretion, decide to continue with the Trip where the PHO turns up after 15 minutes from the Agreed time, then you will not be entitled to any such refund.</li>
              <li>In the event that You are not available at the Agreed Pick-Up Point within 15 minutes of the Agreed Time, it will be at PHO's discretion to treat Your non-availability as a cancellation, and You will not be entitled to any refund of the Fare , and any Card Costs that you may have paid in advance. Where the PHO decides to wait more than 15 minutes from the Agreed Time, then You may be subject to additional charges by PHO in respect of any additional waiting time and/or parking charges and will make such payment directly to PHO.</li>
              <li>For pre-paid bookings, any refunds due to you for cancellations or complaints will be made to the card used to make the payment within upto 3-5 business days (business days being Monday to Friday), depending on your bank or card issuer.</li></ol></li>
              <li><strong>WARRANTY AND LIMITATION OF LIABILITY</strong><ol>
              <li> PHO has warranted to lelecabs that it is licensed by the appropriate Licensing Authority(ies) but lelecabs shall not be responsible for verification of such warranty and if You are in any doubt, You should contact the Private Hire Operator and rely on Your own verification.</li>
              <li> lelecabs shall not be liable for any delays in pick-up or drop-off times or for any failure of PHO to arrive at all or for any failure of PHO to provide its services in accordance with Your requirements or expectations.</li>
              <li> lelecabs shall not in any event be liable (whether in contract or otherwise) for any indirect loss or consequential loss or loss of revenue, howsoever arising, suffered by You in connection with any failure by PHO or otherwise in connection with these Terms or otherwise and if You consider that You have any claim in respect of any failure by PHO You agree to make such claim directly against PHO.</li>
              <li> lelecabs's aggregate liability in respect of these Terms and any matter arising out of it (including claims whether made in contract or tort) shall be limited to £500.</li>
              <li> Subject as expressly provided in these Terms all warranties, conditions or other terms implied by statute or common law are excluded as far as legally possible.</li>
              <li> Nothing in this clause shall operate so as to exclude any rights You may have under consumer legislation or limit PHO's liability for death or personal injury arising out of its negligence.</li>
              <li> Any estimated trip times and times of arrival presented by lelecabs on its Public website or otherwise, are only to be taken as indicative, and are not subject to traffic and/or weather conditions and final route taken by driver. Therefore, You agree that You are responsible for setting an appropriate Agreed Time that allows You sufficient time to reach Your destination to subsequently check-in for a flight, take a train or fulfill any other dependencies You might have.</li>
              <li> lelecabs may keep You informed of the status of your Booking by sending You email, and SMS text alerts, although lelecabs does not guarantee the delivery nor the timing of these email and SMS text alerts, and shall not in any event be liable (whether in contract or otherwise) for any indirect loss or consequential loss or loss of revenue, howsoever arising from the omission or delay of such email and SMS text alerts.</li>
              <li> Each foregoing sub-clause of this clause shall constitute a separate and severable provision. The provisions of this clause shall continue in effect notwithstanding the termination, completion or any other matter which might otherwise cause these Terms to become ineffective.</li></ol></li>
              <li><strong>BEHAVIOUR</strong><ol>
              <li>PHO reserves the right within its reasonable discretion to terminate the Trip, if You or Your party's conduct or behaviour is disruptive in any way and/or affects your safety or that of the driver of the vehicle. Neither lelecabs nor PHO accept liability for any extra costs incurred by you/or your party as a result of the PHO doing so.</li>
              <li>Passengers are not permitted to take alcoholic drinks onto the vehicles for the purpose of consuming them during the Trip.</li>
              <li>PHO further reserves the right to refuse carriage to any person who is thought to be under the influence of alcohol or drugs.</li></ol></li>
              <li><strong>LIMITATIONS AND EXCLUSIONS</strong><ol>
              <li> PHO shall not undertake the carriage or delivery of:<ol>
              <li> money or securities, antiques, precious metals, furs, or jewellery or value, any goods or property (of whatsoever nature) of an intrinsic value of more than £50.</li>
              <li> any goods or property of a hazardous, dangerous, inflammable, explosive or noxious nature, or are illegal to possess under existing English Law, and/or</li>
              <li> any goods or property (of whatsoever nature) which may deteriorate in transit.</li></ol></li>
              <li> PHO shall have no liability whatsoever for loss or damage, however arising in relation to the clause 10.1 of this agreement.</li>
              <li> Without prejudice to the provisions of clause 10.1 PHO shall not in any event be liable directly or indirectly for:<ol>
              <li> consequential loss (whether for loss or profit or otherwise) and/or.</li>
              <li> loss, damage and/or breakage to any fragile items what so ever whether arising from the acts, omissions or negligence of PHO and/or its employees and/or agents or arising otherwise howsoever.</li></ol></li>
              <li> Without prejudice to the generality of clause 10.1 in particular PHO shall not be liable for any loss and/or damage arising directly or indirectly from:<ol>
              <li> breakdown, accident, adverse weather conditions.</li>
              <li> any act or omission on the part of the Customer.</li>
                        
              <li> any clause, act or circumstance beyond the control of PHO (including, without limitation, any strike, (official or not) lock-out or other form of industrial action or labour dispute, governmental regulations, legal restrictions, embargoes, fire, flood, Act of God, any consequence of riot, war, invasion, act of foreign enemy, hostilities (whether war be declared or not) civil war, acts of terrorism, rebellion, military or usurped power, confiscation, requisition or destruction of or damage to property by or upon the order of or in the name of any Government or public local authority, or other conditions amounting to force majeure).</li></ol></li>
              <li> the provision of clauses 10.1 and 11.1 apply to liability for loss or damage to goods or property and do not apply to liability for death or personal injury.</li></ol></li>
              <li><strong>DISPUTES</strong><ol>
              <li>If You have any complaint in respect of the service provided by PHO, You agree to first contact PHO directly (by phone or email) to resolve this with them within 24 hours of the Agreed Time.</li>
              <li>If you are subsequently unable to resolve your complaint with PHO, you agree to notify lelecabs thereof via the form on the Contact Us page or by email to info@lelecabs.com within 72 hours of the Agreed Time. In the event that You fail to do so, any claim You might otherwise have shall be invalidated.</li>
              <li>If lelecabs is then unable to resolve any claim You may have in respect of the service provided by PHO to the mutual satisfaction of You and PHO, lelecabs shall be entitled to submit such claim to the determination of an independent third party selected by lelecabs and You agree to be bound by the decision of such third party.</li>
              <li>Should You have a dispute with the PHO, lelecabs is not liable for any claims, demands and damages (actual and consequential) of any kind and nature arising out of or in any way connected with such disputes.</li></ol></li>
              <li><strong>PRIVACY POLICY</strong><ol>
              <li>lelecabs will maintain the confidentiality of Personal Data collected as part of making a Booking. This data will not be shared with third parties without Your consent. lelecabs may amend this Privacy Policy, and will make such changes public via the Website.</li></ol></li>
              <li><strong style="text-transform: uppercase;">Miscellaneous</strong><ol>
              <li>These Terms shall form the entire agreement between the parties and supersede any previous agreement and (save so far as expressly preserved hereby) representations oral or otherwise made by either of the parties. You warrant that You have not relied on any representation made by lelecabs in entering into this agreement.</li>
              <li>Failure by either party at any time to enforce any right claim or provision of these Terms or arising hereunder shall not be construed as a waiver of such right, claim or provision.</li>
              <li>All notices to lelecabs shall be given by You in writing to info@lelecabs.com.</li>
              <li>All notices to You shall be given in writing to the email address You have provided as part of the Details. All notices may be served by email and shall be deemed to have been given 1 hour after transmission thereof.</li>
              <li>These Terms and Your agreement with lelecabs shall be governed and construed in accordance with English Law. You irrevocably agree for the exclusive benefit of lelecabs to submit any dispute hereunder to the jurisdiction of the courts of England but nothing herein shall prevent lelecabs from taking proceedings against You in any other court of competent jurisdiction.</li>
                        </ol></li>
           </ol>`,
        },
      },
    },
  },
]

function Terms() {
  return <PageFromJson metaPage={metaPage} components={components} />
}

export default Terms
