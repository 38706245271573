import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { postApi } from '../../utils/restAPIUtils'
import AuthForm from './../auth/AuthForm'
import { isValidEmail } from './../../utils/textUtils'
import { languageData, getCurrentLanguage } from './../../languages/index'

const getData = () => {
  const lang = getCurrentLanguage()
  return {
    formOptions: [
      {
        component: 'title',
        title: languageData[lang]['drive.form.title'],
        id: 'title',
      },
      {
        component: 'subtitle',
        id: 'subtitle',
        title: languageData[lang]['drive.form.subtitle'],
        error: 'main',
      },
      {
        component: 'input',
        id: 'name',
        name: 'name',
        type: 'name',
        label: languageData[lang]['drive.form.name'],
        required: true,
        value: null,
        withBorder: true,
        width: '100%',
        margin: 20,
      },
      {
        component: 'input',
        id: 'email',
        name: 'email',
        type: 'email',
        errorText: 'Please insert a valid e-mail.',
        label: languageData[lang]['drive.form.email'],
        required: true,
        value: null,
        withBorder: true,
        width: '100%',
        margin: 20,
      },
      {
        component: 'button',
        id: 'action',
        type: 'secondary',
        label: languageData[lang]['drive.form.submit'],
        disabled: false,
      },
    ],
  }
}

export function DriverForm() {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [error, setError] = useState([])
  const [loading, setLoading] = useState(false)

  const router = useNavigate()
  const value = {
    name,
    email,
  }

  const submitForm = async (value, router, setError) => {
    setLoading(true)
    setError([])
    if (!isValidEmail(value.email)) {
      setError([`main.Please insert a valid e-mail.`])
      setLoading(false)
      return
    }
    try {
      await postApi('drivers')({
        Accept: 'application/json',
        'Content-type': 'application/json',
      })(value)((cb) => cb)
      router('/drive/confirm-registration', {
        state: { previousPath: window.location.pathname },
      })
    } catch (err) {
      setError(['main.Something went wrong'])
    }
    setLoading(false)
  }

  const handlers = {
    name: setName,
    email: setEmail,
    action: async () => submitForm(value, router, setError),
  }
  const driverData = getData()
  return (
    <AuthForm
      noTopBorder
      {...driverData}
      value={value}
      handler={(id, value) => handlers[id](value)}
      error={error}
      loading={loading}
    />
  )
}
