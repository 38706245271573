import * as S from './styled'
import { useEffect, useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { isCovered } from './../../utils/mapUtils'
import { Loader } from '@googlemaps/js-api-loader'

const FormSearchbarBoxLocation = ({ data }) => {
  const [value, setValue] = useState('')
  const [loadedMapsScript, setLoadedMapsScript] = useState(false)
  const [geoArea, setGeoArea] = useState()
  const [match, setMatch] = useState(false)
  const [error, setError] = useState(false)

  const { REACT_APP_GOOGLE_KEY } = process.env

  function parseErrorMessage(errorMessage, messageLinkText, messageLinkHref) {
    const segments = errorMessage.split('<br />')

    const linkIndex = errorMessage.indexOf(messageLinkText)

    if (linkIndex !== -1) {
      segments.forEach((segment, index) => {
        const wordsLink = messageLinkText.split(' ')
        const words = segment.split(' ')

        const wrappedWords = words.map((word, index) => {
          if (wordsLink.includes(word)) {
            return (
              <>
                {' '}
                <S.TextLink
                  key={word.slice(0, 3) + index}
                  href={messageLinkHref}
                >
                  {word}
                </S.TextLink>{' '}
              </>
            )
          }
          return <> {word} </>
        })

        segments[index] = wrappedWords
      })
    }

    const parsedErrorMessage = segments.map((segment, index) => (
      <div key={index}>
        <>{segment}</>
        {index < segments.length - 1 && <br />}
      </div>
    ))

    return parsedErrorMessage
  }

  useEffect(() => {
    if (value?.length === 0) {
      setGeoArea() // reset geo area
    }
    if (!geoArea) {
      setError(false)
      setMatch(false)
      return
    }
    if (isCovered(geoArea)) {
      setError(false)
      setMatch(true)
    } else {
      setError(true)
      setMatch(false)
    }
  }, [geoArea, value])

  useEffect(() => {
    const loader = new Loader({
      apiKey: REACT_APP_GOOGLE_KEY,
      version: 'weekly',
      libraries: ['places'],
      id: '__googleMapsScriptId',
    })

    loader.loadCallback((e) => {
      if (e) {
        console.log(e)
      } else {
        if (!loadedMapsScript) setLoadedMapsScript(true)
      }
    })
  }, [loadedMapsScript, REACT_APP_GOOGLE_KEY])

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value)
    setValue(results[0]['formatted_address'])
    setGeoArea(results)
  }

  const handleChange = (value) => {
    setValue(value)
  }

  return (
    <>
      <S.Searchbar error={error} match={match}>
        <label htmlFor={data.input.id}>
          <S.InputIcon size={24} />
        </label>
        {loadedMapsScript ? (
          <PlacesAutocomplete
            value={value}
            onChange={handleChange}
            onSelect={handleSelect}
            onKeyDown={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <>
                <S.Input
                  {...getInputProps({
                    className: 'location-search-input',
                  })}
                />

                <div
                  style={{
                    position: 'absolute',
                    zIndex: 10,
                    top: '43px',
                    width: '100%',
                    left: '0',
                  }}
                >
                  {suggestions.map((suggestion, i) => {
                    const style = {
                      backgroundColor: suggestion.active ? '#ccc' : '#fff',
                      minHeight: 35,
                      borderLeft: '1px solid #ccc',
                      borderRight: '1px solid #ccc',
                      borderBottom: '1px solid #ccc',
                      borderTop: '1px solid #ccc',
                      padding: '5px 10px',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }
                    return (
                      <div
                        key={i}
                        {...getSuggestionItemProps(suggestion, { style })}
                      >
                        {suggestion.description}
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </PlacesAutocomplete>
        ) : null}
      </S.Searchbar>
      {error && (
        <>
          <S.ErrorMessage>{data.error.inputError}</S.ErrorMessage>
          <S.Message>
            {parseErrorMessage(
              data.error.errorMessage,
              data.error.messageLinkText,
              data.error.messageLinkHref,
            )}
          </S.Message>
        </>
      )}
      {match && (
        <>
          <S.MatchMessage>{data.match.inputMessage}</S.MatchMessage>
          <S.Message>
            {parseErrorMessage(
              data.match.messageFooter,
              data.match.messageLinkText,
              data.match.messageHref,
            )}
          </S.Message>
        </>
      )}
    </>
  )
}

export default FormSearchbarBoxLocation
