import { createContext, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../auth-context'
import { useEffectOnActualUpdate } from '../../hooks/useEffectOnActualUpdate'
import { getApi } from '../../utils/restAPIUtils'

export const OrganizationContext = createContext({
  organization: null,
  loadingOrganization: false,
})

export const OrganizationProvider = ({ children }) => {
  const { userAttributes, getIdToken } = useContext(AuthContext)

  const [currentOrganization, setCurrentOrganization] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffectOnActualUpdate(() => {
    const setOrganization = async () => {
      try {
        const token = await getIdToken()
        if (!token) {
          setCurrentOrganization(null)
          return
        }
        const organizations = await getApi('organizations')({
          Authorization: token,
        })()((cb) => cb)
        setCurrentOrganization(organizations[0] || null)
      } catch (err) {
        console.error({ err })
        setCurrentOrganization(null)
      }
    }

    setLoading(true)
    setOrganization().finally(() => {
      setLoading(false)
    })
  }, [userAttributes])

  return (
    <OrganizationContext.Provider
      value={{
        organization: currentOrganization,
        loadingOrganization: loading,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}
