import styled from 'styled-components'
import * as T from '../atomic/Typography'
import { MagnifyingGlass } from '@styled-icons/entypo/MagnifyingGlass'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 42px 32px 32px;
  @media (max-width: ${breakpoint.smallTablet}) {
    padding: 1.5em 15px 0.5em;
  }
`

export const Title = styled(T.Uh3)`
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: normal;
`
