import { imagesFiles } from '../../styles/images'
import * as S from './styled'

const BrandedWhyCircle = ({ data }) => (
  <S.Wrapper>
    <S.Title>{data.mainTitle}</S.Title>
    <S.CycleWrapper>
      {data.arrows.map((arrow, iter) => (
        <S.Arrow
          key={iter}
          src={imagesFiles[arrow.arrowIcon]}
          alt=""
          $start={arrow.position.columnStart}
          $end={arrow.position.columnEnd}
          $rowStart={arrow.position.rowStart}
          $justifyPosition={arrow.position.justifyPosition}
          $alignPosition={arrow.position.alignPosition}
        />
      ))}
      {data.elements.map((element, index) => (
        <S.Element
          key={index}
          $start={element.position.columnStart}
          $end={element.position.columnEnd}
        >
          <S.InnerElement>
            <S.Image src={imagesFiles[element.icon]} alt={element.icon} />
            <S.ElementTitle>{element.elementTitle}</S.ElementTitle>
            <S.ElementText>{element.elementText}</S.ElementText>
          </S.InnerElement>
        </S.Element>
      ))}
      <S.Logo
        src={imagesFiles[data.logo.logoImage]}
        alt={data.logo.logoImage}
        $start={data.logo.position.columnStart}
        $end={data.logo.position.columnEnd}
        $rowStart={data.logo.position.rowStart}
      />
    </S.CycleWrapper>
  </S.Wrapper>
)

export default BrandedWhyCircle
