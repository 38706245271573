const coveredRegions = [
  'AL',
  'AO',
  'AT',
  'BL',
  'BG',
  'BI',
  'BO',
  'BS',
  'CO',
  'CR',
  'CN',
  'FE',
  'FI',
  'FC',
  'GE',
  'GO',
  'GR',
  'IM',
  'SP',
  'LC',
  'LI',
  'LO',
  'LU',
  'MN',
  'MS',
  'MI',
  'MO',
  'MB',
  'NO',
  'PD',
  'PR',
  'PV',
  'PC',
  'PI',
  'PN',
  'PO',
  'RA',
  'RE',
  'RN',
  'RO',
  'SV',
  'SI',
  'SO',
  'BZ',
  'TN',
  'TV',
  'TS',
  'TO',
  'UD',
  'VA',
  'VE',
  'VB',
  'VC',
  'VI',
  'VR',
]

export default coveredRegions
