import React from 'react'

import { ErrorBoundary } from './../../components/compositions/Error'
import { Login } from './../../components/form-sign-in'
import { DefaultLayoutNew } from './../../components/utilities/DefaultLayoutNew'
import { OuterWrapper } from './../../components/super-mega-wrapper'

function LoginPage() {
  return (
    <DefaultLayoutNew pageType="viewer">
      <OuterWrapper>
        <ErrorBoundary>
          <Login />
        </ErrorBoundary>
      </OuterWrapper>
    </DefaultLayoutNew>
  )
}

export default LoginPage
