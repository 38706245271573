import UserPool from './../auth-context/UserPool'

const ShowWhenLoggedOut = ({ children }) => {
  const user = UserPool.getCurrentUser()

  if (user?.username) return null
  return children
}

export default ShowWhenLoggedOut
