import { Helmet } from 'react-helmet-async'

import coveredRegions from './../config/coverage'

/* ***************************************
 * Function to understand if a place is
 * covered by lelecabs. In order to expand the
 * service modify the coveredRegions array
 * @param { object } zone - array of google maps segments
 * @returns { boolean }
 * **************************************/
export const isCovered = (zone) =>
  coveredRegions.includes(
    zone
      ?.at(0)
      ?.address_components?.filter((segment) =>
        segment?.types?.includes('administrative_area_level_2'),
      )
      ?.at(0)?.short_name,
  )

export async function askGoogle(value) {
  const google = window.google
  ;<Helmet>
    <script
      async
      defer
      type="text/javascript"
      src="https://maps.googleapis.com/maps/api/js?key=%REACT_APP_API_KEY%&callback=initMap"
    ></script>
  </Helmet>
  if (!google) return
  const directionsService = new google.maps.DirectionsService()
  const start = value.from
  const end = value.to
  const request = {
    origin: start,
    destination: end,
    travelMode: 'DRIVING',
  }
  return directionsService.route(request, function (result, status) {})
}
