import { useState } from 'react'
import AuthForm from './../auth/AuthForm'

const data = {
  formOptions: [
    {
      component: 'input',
      id: 'fiscalCode',
      name: 'fiscalCode',
      type: 'fiscalCode',
      label: 'Codice Fiscale',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
      display: 'grid',
    },
    {
      component: 'input',
      id: 'dateOfBirth',
      name: 'dateOfBirth',
      type: 'dateOfBirth',
      label: 'Data di nascita',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'provinceOfBirth',
      name: 'provinceOfBirth',
      type: 'provinceOfBirth',
      label: 'Provincia di nascita',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'birthplace',
      name: 'birthplace',
      type: 'birthplace',
      label: 'Città di nascita',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'citizenship',
      name: 'citizenship',
      type: 'citizenship',
      label: 'Cittadinanza',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'provinceOfResidence',
      name: 'provinceOfResidence',
      type: 'provinceOfResidence',
      label: 'Provincia di resistenza',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'cityOfResidence',
      name: 'cityOfResidence',
      type: 'cityOfResidence',
      label: 'Città di residenza',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'zipcode',
      name: 'zipcode',
      type: 'zipcode',
      label: 'CAP',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'address',
      name: 'address',
      type: 'address',
      label: 'Indirizzo',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'streetNumber',
      name: 'streetNumber',
      type: 'streetNumber',
      label: 'Numero civico',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'mandatoryLabel',
      name: 'mandatoryLabel',
      margin: 40,
      id: 'mandatorylabel',
    },
    {
      component: 'button',
      id: 'action',
      type: 'secondary',
      label: 'Convalida',
      disabled: false,
    },
  ],
}

async function submitForm(value, success, error, loading, redirect) {
  // const {
  //     fiscalCode,
  //     dateOfBirth,
  //     provinceOfBirth,
  //     birthplace,
  //     citizenship,
  //     provinceOfResidence,
  //     cityOfResidence,
  //     zipcode,
  //     address,
  //     streetNumber,
  // } = value;
  //loading(true);
  //const result = await postApi("authentication")(/*no headers*/) ({
  //    email,
  //    password,
  //    strategy: "local",
  //})((cb) => cb);
  //if (result.accessToken) {
  //    saveState("token", result.accessToken);
  //    loading(false);
  //    success(true);
  //    if (redirect === "no") {
  //        Router.push("/");
  //    } else {
  //        Router.push(`/${redirect}`);
  //    }
  //} else {
  //    console.log("is this really an error?");
  //    loading(false);
  //    error(true);
  //}
}

const Form1 = ({ driver = false, redirect = 'no' }) => {
  const [fiscalCode, changeFiscalCode] = useState('')
  const [dateOfBirth, changeDateOfBirth] = useState('')
  const [provinceOfBirth, changeProvinceOfBirth] = useState('')
  const [birthplace, changeBirthplace] = useState('')
  const [citizenship, changeCitizenship] = useState('')
  const [provinceOfResidence, changeProvinceOfResidence] = useState('')
  const [cityOfResidence, changeCityOfResidence] = useState('')
  const [zipcode, changeZipcode] = useState('')
  const [address, changeAddress] = useState('')
  const [streetNumber, changeStreetNumber] = useState('')
  const [error, isFormInError] = useState([])
  const value = {
    fiscalCode,
    dateOfBirth,
    provinceOfBirth,
    birthplace,
    citizenship,
    provinceOfResidence,
    cityOfResidence,
    zipcode,
    address,
    streetNumber,
  }
  const handlers = {
    fiscalCode: changeFiscalCode,
    dateOfBirth: changeDateOfBirth,
    provinceOfBirth: changeProvinceOfBirth,
    birthplace: changeBirthplace,
    citizenship: changeCitizenship,
    provinceOfResidence: changeProvinceOfResidence,
    cityOfResidence: changeCityOfResidence,
    zipcode: changeZipcode,
    address: changeAddress,
    streetNumber: changeStreetNumber,
    action: () => submitForm(value, isFormInError),
  }
  return (
    <>
      <AuthForm
        noTopBorder
        {...data}
        value={value}
        handler={(id, value) => handlers[id](value)}
        error={error}
      />
    </>
  )
}

export default Form1
