import styled from 'styled-components'
import { DCol } from './Layout'
import { breakpoint, defaultTheme } from '../../styles/global.style'
import { ext } from './../../utils/utils'
import { Uh2 } from './../atomic/Typography'
import { Uh3 } from '../typography'
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { loadSessionState, loadState } from '../../utils/localStorage'

const img = require('./../../styles/Images/homebackground.jpg')

const AuthFormWrapper = styled.div`
  background-color: white;
  position: relative;
  max-width: ${(props) => (props.full ? 'unset' : '445px')};
  width: ${(props) => (props.full ? '100%' : 'unset')};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : '380px')};
  border-radius: ${(props) => (props.radius ? `${props.radius}px` : 'unset')};
  ${(props) =>
    props.height &&
    `
  min-height: ${props.height}px;
  `}
  margin: 0px 0px 10px;
  z-index: 10;
  border-top: ${(props) => (props.noTopBorder ? 'none' : '5px solid black')};
  padding: 60px 20px;
  @media (max-width: ${breakpoint.mobile}) {
    padding: 50px 10px;
    width: calc(100% - 20px);
    min-width: calc(100% - 50px);
  }
`

const NewAuthFormWrapper = styled.div`
  background-color: white;
  position: relative;
  max-width: ${(props) => (props.full ? 'unset' : '445px')};
  width: ${(props) => (props.full ? '100%' : 'unset')};
  min-width: 380px;
  ${(props) =>
    props.height &&
    `
  min-height: ${props.height}px;
  `}
  margin: 0px 0px 10px;
  z-index: 10;
  border-top: ${(props) => (props.noTopBorder ? 'none' : '5px solid black')};
  padding: 0px 20px;
  @media (max-width: ${breakpoint.mobile}) {
    padding: 0;
    width: calc(100% - 20px);
    min-width: calc(100% - 50px);
  }
`

const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0px;
  background-image: url('${(props) => props.src}');
  background-size: cover;
  background-position: center;
  position: absolute;
  @media (max-width: ${breakpoint.mobile}) {
    background-image: none;
  }
  @media (min-width: ${breakpoint.siteWidth}) {
    background-position: bottom center;
  }
`

const FormWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};
`

export const SimpleTitle = styled(Uh3)`
  width: 100%;
  margin-bottom: 20px;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
  border-bottom: 3px solid ${defaultTheme.primary};
`

export const Title = styled(ext(Uh2))`
  text-align: left;
  width: ${(props) => (props.width$ ? '100%' : '')};
  &::after {
    border: ${(props) => (props.noBorder ? 'none' : 'inherit')};
  }
`

const ArrowBackIcon = styled(ArrowBack)`
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
`

export const FormLikeLayout = ({
  children,
  withImg,
  noTopBorder,
  padding,
  alignItems,
  height,
  full,
  justifyContent,
  minWidth,
  radius,
}) => {
  return (
    <FormWrapper alignItems={alignItems} justifyContent={justifyContent}>
      {withImg && <ImgWrapper src={withImg ? img : ''} />}
      <AuthFormWrapper
        radius={radius}
        minWidth={minWidth}
        noTopBorder={noTopBorder}
        height={height}
        full={full}
      >
        <DCol padding={padding} vertical alignItems={alignItems || 'center'}>
          {children}
        </DCol>
      </AuthFormWrapper>
    </FormWrapper>
  )
}

export const NewLandingFormLikeLayout = ({
  children,
  withImg,
  noTopBorder,
  padding,
  alignItems,
  height,
  full,
}) => {
  return (
    <FormWrapper alignItems={alignItems}>
      {withImg && <ImgWrapper src={withImg ? img : ''} />}
      <NewAuthFormWrapper noTopBorder={noTopBorder} height={height} full={full}>
        <DCol padding={padding} vertical alignItems={alignItems || 'center'}>
          {children}
        </DCol>
      </NewAuthFormWrapper>
    </FormWrapper>
  )
}
export const TitleLayout = ({ label, width, handler, id, noBorder }) => {
  const navigate = useNavigate()
  const trip = loadSessionState('trip-pending')
  const goBack = () => {
    handler(id)
    navigate('/', { state: { trip } })
  }
  return (
    <TitleWrapper style={{ width: '100%' }}>
      {id === 'goBack' && <ArrowBackIcon size={34} onClick={goBack} />}
      <Title
        noBorder={noBorder}
        paddingBottom="18"
        extraSpace={10}
        width$={width}
      >
        {label}
      </Title>
    </TitleWrapper>
  )
}
