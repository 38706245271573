import BookTripBox from '../book-trip-box'
import { breakpoint } from '../../styles/global.style'
import * as S from './styled'
import * as T from '../atomic/Typography'

const TimelineBar = ({ data }) => {
  const mobileScreen = parseInt(breakpoint.mobile)
  return (
    <S.Wrapper>
      <S.Title>{data.title}</S.Title>
      <S.InnerWrapper>
        <S.TimelineWrapper>
          {data.stops.map((pin, y) => (
            <S.PinkMark key={y}>
              <S.Time>{pin.hour}</S.Time>
              <S.PinIconPsueudo $last={y === data.stops.length - 1}>
                <S.PinIcon />
              </S.PinIconPsueudo>
              <S.TextWrapper>
                <S.Destination>{pin.destination}</S.Destination>
                <S.Description>{pin.description}</S.Description>
                {pin.link && (
                  <S.LearnMore href={pin.link}>{pin.linkText}</S.LearnMore>
                )}
              </S.TextWrapper>
            </S.PinkMark>
          ))}
        </S.TimelineWrapper>
        <BookTripBox data={data} />
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

export default TimelineBar
