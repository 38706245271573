import React, { useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'

import { AuthContext } from './../auth-context'

import { loadState } from './../../utils/localStorage'
import { getCurrentLanguage } from './../../languages/index'

import * as S from './styled'
import AuthForm from './../auth/AuthForm'
import Logo from './../logo'

import { getData } from './data'

/* *************************
 * This function compares an Unix Epoch to now
 * and returns if it is less than a specified
 * amount
 * @param {number} time - Time to check
 * @param {number} threshold - in minutes (default 5 minutes)
 * @returns {boolean} if that function is smaller than the threshold
 * ************************/
const isRecent = (time, threshold = 5) => {
  if (typeof time !== 'number' || typeof threshold !== 'number') {
    return false
  }
  return new Date().valueOf() - time < threshold * 1000 * 60
}

export const Login = ({ onlyForm = false }) => {
  const { loginUser, userAttributes } = useContext(AuthContext)
  const tripPending = loadState('trip-pending')
  const sourcePath = loadState('sourcePath')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState([])
  const [loadingUserData, setLoadingUserData] = useState(false)
  const lang = getCurrentLanguage()

  const loginInfo = {
    email,
    password,
  }

  const { state } = useLocation()
  const fromRecovery = state?.fromRecovery || false

  let navigateTo = sourcePath
  if (
    isRecent(tripPending?.createdOn) &&
    (sourcePath === '/' || sourcePath === `/${lang.toLowerCase()}/`)
  ) {
    navigateTo = `${lang === 'EN' ? '' : `/${lang.toLowerCase()}`}/checkout`
  }

  const navigate = useNavigate()
  const handlers = {
    email: setEmail,
    password: setPassword,
    action: () =>
      loginUser(
        loginInfo,
        () =>
          navigate(navigateTo, {
            state: { previousPath: window.location.pathname },
          }),
        setError,
        setLoadingUserData,
      ),
    goToAccountVerify: () =>
      navigate('/account/register', {
        state: {
          emailToVerify: email,
          passwordToVerify: password,
          initialFormStage: 1,
        },
      }),
  }
  const loginFormOptions = getData()

  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // useEffect(() => {
  //   const script = document.createElement('script')
  //   script.src = 'https://accounts.google.com/gsi/client'
  //   script.async = true
  //   script.defer = true
  //   document.body.appendChild(script)

  //   return () => {
  //     // cleanup function to remove the script from the DOM
  //     document.body.removeChild(script)
  //   }
  // }, [])

  if (userAttributes !== null) return <Navigate to={'/'} />

  return (
    <S.SignInWrapper>
      {!onlyForm && (
        <>
          <S.SideImage />
          <S.LogoWrapper>
            <Logo colorFont="#FFFFFF" colorWheel="#000000" />
          </S.LogoWrapper>
        </>
      )}
      <S.AroundFormWrapper>
        <AuthForm
          noTopBorder
          formOptions={loginFormOptions}
          handler={(id, value) => handlers[id](value)}
          error={error}
          loading={loadingUserData}
        />
      </S.AroundFormWrapper>
    </S.SignInWrapper>
  )
}
