import React, { useState, useEffect, useContext } from 'react'
import { getApi } from './../../utils/restAPIUtils'
import AccountSidebar from '../account-sidebar'
import styled from 'styled-components'
import SingleTrip from '../single-trip'
import parseISO from 'date-fns/parseISO'
import { AuthContext } from '../auth-context'
import { Link, useLocation } from 'react-router-dom'
import { languageData, getCurrentLanguage } from './../../languages/index'
import * as T from './../atomic/Typography'
import Button from '../buttons'

import {
  Wrapper,
  List,
  TripListTitle,
  WarningMsg,
  MoreBtn,
  AccountWrapper,
  TripsWrapper,
  StyledCarMoving,
  MessageWrapper,
} from './styled'

import CarLoader from '../car-loader'

const lang = getCurrentLanguage()

const NoTripsBooked = ({ type }) => {
  const { search } = useLocation()
  switch (type) {
    case 'pending':
      return (
        <MessageWrapper>
          <T.P1>{languageData[lang]['trip.pending.no.trips.message']}</T.P1>
          <Link to={`/${search}`}>
            <Button
              type="secondary"
              label={languageData[lang]['trip.pending.no.trips.button.label']}
            />
          </Link>
        </MessageWrapper>
      )
    default:
    case 'past':
      return (
        <MessageWrapper>
          <T.P1>{languageData[lang]['trip.past.no.trips.message']}</T.P1>
        </MessageWrapper>
      )
  }
}

const TripList = ({
  past,
  label,
  list = [],
  loading = false,
  setTrips = () => null,
}) => {
  const [moreTrips, setMoreTrips] = useState(3)

  const handleClickMore = () => setMoreTrips((prev) => prev + 3)
  const currentDate = new Date().getTime()

  const filteredList = list
    .filter((trip) =>
      past
        ? currentDate > parseISO(trip?.when).getTime()
        : currentDate < parseISO(trip?.when).getTime(),
    )
    .sort((a, b) => {
      const one = parseISO(a?.when).getTime()
      const two = parseISO(b?.when).getTime()
      if (one < two) return 1
      else return -1
    })

  return (
    <Wrapper>
      <TripListTitle>{label}</TripListTitle>
      {loading ? (
        <CarLoader full />
      ) : filteredList.length === 0 ? (
        <>
          {past ? (
            <NoTripsBooked type="past" />
          ) : (
            <NoTripsBooked type="pending" />
          )}
        </>
      ) : (
        <List>
          {filteredList.slice(0, moreTrips).map((trip) => (
            <SingleTrip
              trip={trip}
              past={past}
              setTrips={setTrips}
              key={`trip${trip.id}`}
            />
          ))}
          {filteredList.length > moreTrips ? (
            <MoreBtn onClick={handleClickMore}>more</MoreBtn>
          ) : null}
        </List>
      )}
    </Wrapper>
  )
}

const whenToISO = (trip) => {
  return {
    ...trip,
    when: new Date(trip.when).toISOString(),
    whenReturn: trip.whenReturn
      ? new Date(trip.whenReturn).toISOString()
      : null,
  }
}

const TripListFunction = () => {
  const [trips, setTrips] = useState([])
  const [loading, setLoading] = useState(false)
  const { getIdToken } = useContext(AuthContext)

  useEffect(() => {
    const fetchTrips = async () => {
      setLoading(true)
      const token = (await getIdToken()) || ''
      const result = await getApi(`reservations`)({
        Authorization: token,
      })()((cb) => cb)
      if (result) {
        console.log(result?.items.filter((trip) => trip.cost === 8735))
        const paidTrips = result?.items
          ?.filter((trip) => trip?.paymentConfirmed)
          .map(whenToISO)
        setTrips(paidTrips)
      }
      setLoading(false)
    }
    fetchTrips()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <AccountWrapper>
      <AccountSidebar />
      <TripsWrapper>
        <TripList
          label={languageData[lang]['trip.pending.reservations.title']}
          loading={loading}
          list={trips}
          setTrips={setTrips}
        />
        <TripList
          label={languageData[lang]['trip.past.reservations.title']}
          loading={loading}
          list={trips}
          past
        />
      </TripsWrapper>
    </AccountWrapper>
  )
}

export default TripListFunction
