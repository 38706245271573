import ratingStar from './../../styles/Images/ratingStar.png'
import { getCurrentLanguage, languageData } from './../../languages/index'

export const getHomeData = () => {
  const lang = getCurrentLanguage()
  return {
    pageReviewsData: {
      reviews: [
        {
          reviewText: languageData[lang]['home.carousel.review.0'],
          author: 'Giorgia',
          stars: [
            {
              src: ratingStar,
            },
            {
              src: ratingStar,
            },
            {
              src: ratingStar,
            },
            {
              src: ratingStar,
            },
          ],
        },
        {
          reviewText: languageData[lang]['home.carousel.review.1'],
          author: 'Giorgia',
          stars: [
            {
              src: ratingStar,
            },
            {
              src: ratingStar,
            },
            {
              src: ratingStar,
            },
            {
              src: ratingStar,
            },
          ],
        },
        {
          reviewText: languageData[lang]['home.carousel.review.2'],
          author: 'Giorgia',
          stars: [
            {
              src: ratingStar,
            },
            {
              src: ratingStar,
            },
            {
              src: ratingStar,
            },
            {
              src: ratingStar,
            },
          ],
        },
      ],
    },
    rideFormData: {
      background: 'lele3Background',
      bannerText: languageData[lang]['home.hero.banner.new'],
    },
    imageBannerData: {
      imgSrc: 'coverageMap',
      centerText: true,
      titleText: languageData[lang]['home.image.title.0'],
      titleCentered: true,
    },
    ctaSimpleData: {
      imgSrc: 'ctaSimpleImage1',
      title: languageData[lang]['home.cta.two.col.title.0'],
      paragraphs: [languageData[lang]['home.cta.two.col.content.0']],
      buttonLabel: languageData[lang]['home.cta.two.col.button.0'],
      buttonHref: '/experiences',
    },
    pageCircleData: {
      logo: {
        logoImage: 'logoPng',
        position: {
          columnStart: '2',
          columnEnd: '3',
          rowStart: '3',
        },
      },
      mainTitle: languageData[lang]['home.branded.circle.main.title'],
      elements: [
        {
          icon: 'gridImageDrive',
          elementTitle:
            languageData[lang]['home.branded.circle.element.0.title'],
          elementText:
            languageData[lang]['home.branded.circle.element.0.content'],
          position: {
            columnStart: '2',
            columnEnd: '3',
          },
        },
        {
          icon: 'gridImageTask',
          elementTitle:
            languageData[lang]['home.branded.circle.element.1.title'],
          elementText:
            languageData[lang]['home.branded.circle.element.1.content'],
          position: {
            columnStart: '1',
            columnEnd: '2',
          },
        },
        {
          icon: 'gridImageSofa',
          elementTitle:
            languageData[lang]['home.branded.circle.element.2.title'],
          elementText:
            languageData[lang]['home.branded.circle.element.2.content'],
          position: {
            columnStart: '3',
            columnEnd: '4',
          },
        },
        {
          icon: 'gridImageClock',
          elementTitle:
            languageData[lang]['home.branded.circle.element.3.title'],
          elementText:
            languageData[lang]['home.branded.circle.element.3.content'],
          position: {
            columnStart: '1',
            columnEnd: '2',
          },
        },
        {
          icon: 'gridImageCheck',
          elementTitle:
            languageData[lang]['home.branded.circle.element.4.title'],
          elementText:
            languageData[lang]['home.branded.circle.element.4.content'],
          position: {
            columnStart: '3',
            columnEnd: '4',
          },
        },
        {
          icon: 'gridImageWallet',
          elementTitle:
            languageData[lang]['home.branded.circle.element.5.title'],
          elementText:
            languageData[lang]['home.branded.circle.element.5.content'],
          position: {
            columnStart: '2',
            columnEnd: '3',
          },
        },
      ],
      arrows: [
        {
          arrowIcon: 'arrow_4',
          position: {
            columnStart: '1',
            columnEnd: '2',
            rowStart: '1',
            justifyPosition: 'end',
          },
        },
        {
          arrowIcon: 'arrow_1',
          position: {
            columnStart: '3',
            columnEnd: '4',
            rowStart: '1',
            justifyPosition: 'start',
          },
        },
        {
          arrowIcon: 'arrow_3',
          position: {
            columnStart: '-4',
            columnEnd: '-3',
            rowStart: '3',
          },
        },
        {
          arrowIcon: 'arrow_2',
          position: {
            columnStart: '3',
            columnEnd: '4',
            rowStart: '3',
          },
        },
        {
          arrowIcon: 'arrow_6',
          position: {
            columnStart: '1',
            columnEnd: '2',
            rowStart: '5',
            justifyPosition: 'end',
            alignPosition: 'center',
          },
        },
        {
          arrowIcon: 'arrow_5',
          position: {
            columnStart: '3',
            columnEnd: '4',
            rowStart: '5',
            justifyPosition: 'baseline',
            alignPosition: 'center',
          },
        },
      ],
    },
    faqPageData: {
      sectionTitle: languageData[lang]['home.faq.footer.list.title'],
      longVersion: false,
      questions: [
        {
          questionTitle: languageData[lang]['home.faq.question.0'],
          questionAnswer: languageData[lang]['home.faq.answer.0'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.1'],
          questionAnswer: languageData[lang]['home.faq.answer.1'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.2'],
          questionAnswer: languageData[lang]['home.faq.answer.2'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.3'],
          questionAnswer: languageData[lang]['home.faq.answer.3'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.4'],
          questionAnswer: languageData[lang]['home.faq.answer.4'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.5'],
          questionAnswer: languageData[lang]['home.faq.answer.5'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.6'],
          questionAnswer: languageData[lang]['home.faq.answer.6'],
        },
      ],
      footer: {
        footerSubtitle: languageData[lang]['home.faq.footer.list.content'],
        linkWord: languageData[lang]['home.faq.footer.list.content.link'],
        linkHref: '/faq',
      },
    },
    ctaCardData: {
      title: languageData[lang]['home.card.call.center.title'],
      subtitle: languageData[lang]['home.card.call.center.subtitle'],
      bottomContent: {
        type: 'button',
        buttonLabel: languageData[lang]['home.card.call.center.button.0.label'],
        buttonLink: '/contact-us',
        secondaryButtonLabel:
          languageData[lang]['home.card.call.center.button.1.label'],
        secondaryButtonLink: '/contact-us',
      },
    },
  }
}

export const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: `At Lelecabs, our journey is fueled by passion. Our goal is to offer a straightforward and affordable transportation service which doesn't compromise on anything. `,
    },
  },
]
