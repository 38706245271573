import React from 'react'
import Logo from './../logo'
import { Menu, List, DRow, DCol, renderIcon, Hr } from './../atomic/Layout'
import { ext } from './../../utils/utils'
import styled from 'styled-components'
import { breakpoint } from '../../styles/global.style'
import { languageData, getCurrentLanguage } from './../../languages/index'

const lang = getCurrentLanguage()

const FooterDiv = styled.footer`
  background-color: ${(props) => props.theme.secondary};
  @media (max-width: ${breakpoint.tablet}) {
    padding: 25px 0px;
    ${(props) => props.hideFooterTablet && 'display: none;'}
  }
`

const FooterBottomLine = styled(ext(DRow))`
  padding: 100px 35px;
  align-items: baseline;
  @media (max-width: ${breakpoint.medium}) {
    align-items: center;
    height: 90px;
    flex-direction: column;
  }
  @media (max-width: ${breakpoint.bigtablet}) {
    padding-bottom: 100px;
  }
  @media (max-width: ${breakpoint.tablet}) {
    padding-top: 20px;
    padding-bottom: 100px;
  }
`

const FooterListDisappear = styled(ext(List))`
  @media (max-width: ${breakpoint.bigtablet}) {
    > * {
      :not(:last-child) {
        display: none;
      }
    }
    li p {
      font-size: 12px;
    }
  }
`

const LinksColumn = styled(ext(DCol))`
  @media (max-width: 900px) {
    display: none;
  }
`

const FollowUsColumn = styled(ext(DCol))`
  @media (max-width: 900px) {
    justify-content: center;
  }
  @media (max-width: ${breakpoint.tablet}) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`

const FollowUsList = styled(ext(List))`
  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;
  }
`

const SmallScreensHelper = styled(ext(DRow))`
  position: relative;
  flex-direction: column;
  padding-top: 25px;
  @media (min-width: 901px) {
    display: none;
  }
`
const ChevronIcon = styled.span`
  position: absolute;
  right: 30%;
`

const MobileList = styled(ext(List))`
  z-index: 10;
  width: 100%;
  top: 0;
  background-color: black;
  margin-top: -20px;
  margin-bottom: 20px;
`

const FooterTitle = styled.h4`
  text-transform: uppercase;
  position: relative;
  @media (max-width: ${breakpoint.tablet}) {
    font-size: 26px;
    margin-bottom: 10px;
  }
`

const FooterTitleWithIcon = styled(ext(FooterTitle))`
  margin-bottom: 40px;
`

const FooterChevronIcon = styled(ext(ChevronIcon))`
  top: -11px;
  right: -40px;
`

const DRowHR = styled(ext(DRow))`
  display: none;
  @media (max-width: ${breakpoint.tablet}) {
    display: block;
  }
`

const MenuTitle = styled.h4`
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 10px;
`

export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.setLinksOpen = this.setLinksOpen.bind(this)
  }
  state = {
    linksOpen: false,
  }

  setLinksOpen() {
    this.setState({ linksOpen: !this.state.linksOpen })
  }
  render() {
    const lang = getCurrentLanguage()
    const {
      menuC1 = [
        {
          id: 'vision',
          type: 'link',
          label: languageData[lang]['footer.company.link.0'],
        },
        {
          id: 'aboutus',
          type: 'text',
          label: languageData[lang]['footer.company.link.1'],
        },
      ],
      menuC2 = [
        {
          id: 'drive',
          type: 'link',
          label: languageData[lang]['footer.driver.link.0'],
        },
        {
          id: 'insurance',
          type: 'text',
          label: languageData[lang]['footer.driver.link.1'],
        },
        {
          id: 'car',
          type: 'text',
          label: languageData[lang]['footer.driver.link.2'],
        },
      ],
      menuC3 = [
        {
          id: 'coverage',
          type: 'text',
          label: languageData[lang]['footer.rider.link.0'],
        },
        // {id: 'hire', type: 'text', label: 'Hire'},
        {
          id: 'safety',
          type: 'text',
          label: languageData[lang]['footer.rider.link.1'],
        },
      ],
      legalMenu = [
        {
          id: 'terms',
          type: 'text',
          label: languageData[lang]['footer.terms.title'],
        },
        {
          id: 'privacy',
          type: 'text',
          label: languageData[lang]['footer.privacy.title'],
        },
        {
          id: '#',
          type: 'text',
          label: languageData[lang]['footer.license.title'],
        },
      ],
      hideFooterTablet,
      stopPropogation,
    } = this.props
    return (
      <FooterDiv hideFooterTablet={hideFooterTablet}>
        <SmallScreensHelper invertColor justifyContent="center">
          <div>
            <FooterTitleWithIcon onClick={() => this.setLinksOpen()}>
              {languageData[lang]['footer.mobile.quick.link']}{' '}
              <FooterChevronIcon>
                {this.state.linksOpen
                  ? renderIcon('chevronUp', 40)
                  : renderIcon('chevronDown', 40)}
              </FooterChevronIcon>
            </FooterTitleWithIcon>
          </div>
          {this.state.linksOpen && (
            <MobileList flex="1" vertical>
              <Menu
                vertical
                data={menuC1
                  .concat(menuC2)
                  .concat(menuC3)
                  .concat(legalMenu.slice(0, -1))}
                activeItem="test"
                stopPropogation={stopPropogation}
                padding={10}
              />
            </MobileList>
          )}
        </SmallScreensHelper>
        <DRowHR invertColor display="block">
          <Hr borderSize="2" margin="5" />
        </DRowHR>
        <DRow invertColor alignItems="flex-start">
          <LinksColumn
            padding={35}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <List flex="1" vertical alignItems="flex-start">
              <li>
                <MenuTitle>
                  {languageData[lang]['footer.company.title']}
                </MenuTitle>
              </li>
              <Menu
                vertical
                data={menuC1}
                activeItem="test"
                stopPropogation={stopPropogation}
              />
            </List>
            <List flex="1" vertical alignItems="flex-start">
              <li>
                <MenuTitle>
                  {languageData[lang]['footer.driver.title']}
                </MenuTitle>
              </li>
              <Menu
                vertical
                data={menuC2}
                activeItem="test"
                stopPropogation={stopPropogation}
              />
            </List>
            <List flex="1" vertical alignItems="flex-start">
              <li>
                <MenuTitle>
                  {languageData[lang]['footer.rider.title']}
                </MenuTitle>
              </li>
              <Menu
                vertical
                data={menuC3}
                activeItem="test"
                stopPropogation={stopPropogation}
              />
            </List>
          </LinksColumn>
          <FollowUsColumn padding={35} justifyContent="flex-end">
            <FollowUsList vertical alignItems="flex-start">
              <li>
                <FooterTitle>
                  {languageData[lang]['footer.social.title']}
                </FooterTitle>
              </li>
              {/* <List alignItems='flex-end'> */}
              {/* <Menu data={socialMenu} activeItem='test' padding={10} showImages/> */}
              <li>socials coming soon...</li>
              {/* </List> */}
            </FollowUsList>
          </FollowUsColumn>
        </DRow>
        <DRow invertColor display="block">
          <Hr borderSize="2" margin="5" />
        </DRow>
        <FooterBottomLine height="50" invertColor>
          <a href="/" aria-label="home" target="_self">
            <Logo
              height={35}
              colorFont="#FFFFFF"
              colorWheel="#000000"
              mobileWidth={40}
            />
          </a>
          <FooterListDisappear>
            <Menu
              data={legalMenu}
              small
              activeItem="test"
              stopPropogation={stopPropogation}
            />
          </FooterListDisappear>
        </FooterBottomLine>
      </FooterDiv>
    )
  }
}
