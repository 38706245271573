import styled from 'styled-components'
import * as T from '../atomic/Typography'
import { MagnifyingGlass } from '@styled-icons/entypo/MagnifyingGlass'

export const Searchbar = styled.div`
  display: flex;
  position: relative;
  height: 43px;
  padding: 12px;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 4px;
  border: ${(props) =>
    props.error
      ? '1px solid #C51918'
      : props.match
      ? '1px solid #219653'
      : '1px solid #D4D9EB'};
  background: #f7f7f8;
`

export const Input = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  &:focus {
    outline: none;
  }
`

export const InputIcon = styled(MagnifyingGlass)`
  color: rgba(128, 138, 174, 1);
`

export const ErrorMessage = styled(T.P3)`
  color: #c51918;
  margin-top: -20px;
`

export const MatchMessage = styled(T.P3)`
  color: #219653;
  margin-top: -20px;
`

export const Message = styled(T.P2)``

export const TextLink = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: #3959bc;
  &:hover {
    text-decoration: none;
  }
`
