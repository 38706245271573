import * as S from './styled'
import * as T from '../atomic/Typography'
import Button from '../buttons'
import { imagesFiles } from '../../styles/images'
import { Link, useLocation } from 'react-router-dom'

const CustomServiceOptions = ({ data }) => {
  const { search } = useLocation()

  return (
    <S.Wrapper>
      <S.Title>{data.title}</S.Title>
      <S.Subtitle>{data.subtitle}</S.Subtitle>
      <S.Services>
        {data.services.map((service, i) => (
          <S.SingleService key={i} disabled={service.disabled}>
            <S.Img src={imagesFiles[service.img]} />
            <S.ServiceTitle>{service.title}</S.ServiceTitle>
            <T.P2>{service.paragraph}</T.P2>
            {!service.disabled && (
              <Link to={`${service.buttonHref}${search}`}>
                <Button type="white" label={service.buttonLabel} />
              </Link>
            )}
          </S.SingleService>
        ))}
      </S.Services>
    </S.Wrapper>
  )
}

export default CustomServiceOptions
