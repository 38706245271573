import styled from 'styled-components'
import * as T from '../atomic/Typography'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const SingleParagraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Title = styled(T.Uh3)`
  color: #020202;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  padding: 0;
  line-height: normal;
  @media (max-width: ${breakpoint.tablet}) {
    font-size: 1.5em;
  }
`
