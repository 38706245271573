import styled from 'styled-components'
import { breakpoint } from '../../styles/global.style'

export const websitePadding = '90px'
export const websitePaddingBigTablet = '60px'
export const websitePaddingTablet = '30px'
export const websitePaddingMobile = '0.3em'
const websitePaddingMobileHeader = '1em'

export const Wrapper = styled.div`
  /* position: relative; */
  width: 100%;
  background: ${(props) =>
    props.$backgroundColor ? `${$backgroundColor}` : 'unset'};
  ${({ $isHeader }) =>
    $isHeader
      ? `
        box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
        z-index: 10000;
      `
      : ''}
  > div,
  footer {
    max-width: 1900px;
    margin: auto;
    padding-left: ${websitePadding};
    padding-right: ${websitePadding};
  }
  header {
    padding-left: ${websitePadding};
    padding-right: ${websitePadding};
    max-width: 1900px;
  }
  @media (max-width: ${breakpoint.bigtablet}) {
    > div,
    header,
    footer {
      padding-left: ${websitePaddingBigTablet};
      padding-right: ${websitePaddingBigTablet};
    }
  }
  @media (max-width: ${breakpoint.tablet}) {
    > div,
    header,
    footer {
      padding-left: ${websitePaddingTablet};
      padding-right: ${websitePaddingTablet};
    }
  }
  @media (max-width: ${breakpoint.mobile}) {
    > div,
    footer {
      padding-left: ${websitePaddingMobile};
      padding-right: ${websitePaddingMobile};
    }
    header {
      padding-left: ${websitePaddingMobileHeader};
      padding-right: ${websitePaddingMobileHeader};
    }
  }
`
