import { getCurrentLanguage, languageData } from './../../languages/index'

export const pageData = () => {
  const lang = getCurrentLanguage()
  return {
    formData: {
      title: languageData[lang]['page.contact.form.main.title'],
      subtitle: languageData[lang]['page.contact.form.main.subtitle'],
      form: {
        inputs: [
          {
            type: 'text',
            placeholder:
              languageData[lang]['page.contact.form.input.0.placeholder'],
            label: languageData[lang]['page.contact.form.input.0.label'],
            id: 'name',
          },
          {
            type: 'email',
            placeholder:
              languageData[lang]['page.contact.form.input.1.placeholder'],
            label: languageData[lang]['page.contact.form.input.1.label'],
            id: 'email',
          },
        ],
        textArea: {
          placeholder:
            languageData[lang]['page.contact.form.input.2.placeholder'],
          label: languageData[lang]['page.contact.form.input.2.label'],
          id: 'message',
        },
      },
      button: {
        label: languageData[lang]['page.contact.form.button.label'],
      },
      successMessage:
        languageData[lang]['page.contact.callback.success.message'],
    },
    ctaCardData: {
      title: languageData[lang]['page.contact.cta.card.0.title'],
      subtitle: languageData[lang]['page.contact.cta.card.0.subtitle'],
      bottomContent: {
        type: 'text',
        textContent: languageData[lang]['page.contact.cta.card.0.button'],
      },
    },
    ctaCardCallbackData: {
      title: languageData[lang]['page.contact.cta.card.1.title'],
      subtitle: languageData[lang]['page.contact.cta.card.1.subtitle'],
      simple: true,
      bottomContent: {
        type: 'phone_form',
        buttonLabel: languageData[lang]['page.contact.cta.card.1.button'],
        input1Label: languageData[lang]['page.contact.callback.prefix.label'],
        input2Label: languageData[lang]['page.contact.callback.phone.label'],
        input2Placeholder:
          languageData[lang]['page.contact.callback.phone.placeholder'],
        successMessage:
          languageData[lang]['page.contact.callback.success.message'],
      },
    },
    ctaCardSimpleData: {
      simple: true,
      title: languageData[lang]['page.contact.cta.card.2.title'],
      subtitle: languageData[lang]['page.contact.cta.card.2.subtitle'],
      bottomContent: {
        type: 'simple',
      },
    },
    faqPageData: {
      sectionTitle: languageData[lang]['home.faq.footer.list.title'],
      longVersion: false,
      questions: [
        {
          questionTitle: languageData[lang]['home.faq.question.0'],
          questionAnswer: languageData[lang]['home.faq.answer.0'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.1'],
          questionAnswer: languageData[lang]['home.faq.answer.1'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.2'],
          questionAnswer: languageData[lang]['home.faq.answer.2'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.3'],
          questionAnswer: languageData[lang]['home.faq.answer.3'],
        },
      ],
      footer: {
        footerSubtitle: languageData[lang]['home.faq.footer.list.content'],
        linkWord: languageData[lang]['home.faq.footer.list.content.link'],
        linkHref: '/faq',
      },
    },
  }
}

export const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Contact - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: `At Lelecabs we are ready to answer all your questions in order to deliver a seamless and care-free journey. Check out the many ways you can get in touch with us.`,
    },
  },
]
