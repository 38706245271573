import styled from 'styled-components'
import * as T from './../atomic/Typography'
import { breakpoint } from '../../styles/global.style'
import { ChevronDown } from '@styled-icons/feather/ChevronDown'

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 30px;
  background-color: #f5f5f5;
  @media (max-width: ${breakpoint.smallTablet}) {
    padding: 2.5em 1.5em;
  }
`

export const Title = styled(T.Uh3)`
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
  @media (max-width: ${breakpoint.bigtablet}) {
    font-size: 1.5em;
  }
`

export const CycleWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 180px 1fr 160px 210px;
  justify-items: center;
  margin-top: 40px;
  @media (max-width: ${breakpoint.bigtablet}) {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
`

export const Element = styled.div`
  display: flex;
  grid-column-start: ${(props) => props.$start};
  grid-column-end: ${(props) => props.$end};
`

export const Image = styled.img`
  max-width: 128px;
  margin-bottom: 5px;
`

export const ElementTitle = styled(T.P1)`
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 24px;
`

export const ElementText = styled(T.P1)``

export const Arrow = styled.img`
  max-width: 101px;
  justify-self: ${(props) =>
    props.$justifyPosition ? props.$justifyPosition : 'center'};
  align-self: ${(props) =>
    props.alignPosition ? props.alignPosition : 'center'};
  grid-column-start: ${(props) => props.$start};
  grid-column-end: ${(props) => props.$end};
  grid-row-start: ${(props) => props.$rowStart};
  margin: 0 25px;
  @media (max-width: ${breakpoint.bigtablet}) {
    display: none;
  }
`

export const InnerElement = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  text-align: center;
  @media (max-width: ${breakpoint.bigtablet}) {
    max-width: unset;
  }
`

export const Logo = styled.img`
  max-width: 205.24px;
  grid-column-start: ${(props) => props.$start};
  grid-column-end: ${(props) => props.$end};
  grid-row-start: ${(props) => props.$rowStart};
  align-self: center;
  font-weight: 700;
  margin-bottom: 24px;
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

export const SingleQuestion = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0px;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  gap: 1em;
  cursor: pointer;
`

export const QuestionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 15px;
`

export const TextWrapper = styled(T.P1)`
  font-weight: 700;
  width: 100%;
`

export const TextAnswer = styled(T.P1)`
  width: 100%;
  padding: 10px 0 20px 0;
`

export const ArrowIconDown = styled(ChevronDown)`
  color: rgba(46, 46, 46, 1);
`

export const ArrowIconUp = styled(ChevronDown)`
  color: rgba(46, 46, 46, 1);
  transform: rotate(180deg);
`

export const FooterText = styled(T.P1)`
  color: #2e2e2e;
  a {
    color: #3959bc;
    text-decoration: none;
  }
`
