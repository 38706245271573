import { createGlobalStyle } from 'styled-components'

export const breakpoint = {
  siteWidth: '1900px',
  verylarge: '1650px',
  large: '1450px',
  medium: '1200px',
  bigtablet: '900px',
  tablet: '1048px', //768px changed to a highier breakpoint to cover ipad landscape
  smallTablet: '680px',
  mobile: '480px',
}

export const breakpointNum = {
  siteWidth: 1900,
  verylarge: 1650,
  large: 1400,
  medium: 1150,
  bigtablet: 900,
  tablet: 768,
  mobile: 480,
}

export const colors = {
  kfcRed: '#E3012B',
  kfcRedHover: '#BA0123',
  kfcBlack: '#000000',
  kfcBlackHover: '#2E2E2E',
  kfcGrey: '#F5F5F5',
  kfcGreyHover: '#E0E0E0',
  kfcLogo: '#E4002B',
  kfcLightGrey: '#efefef',
  kfcBorderGrey: '#d0d0d0',
  kfcPageBg: '#f5f5f5',
  white: '#FFFFFF',
  textGray: '#7A7A7A',
  newBlue: 'rgba(57, 89, 188, 1)',
}

export const defaultTheme = {
  primary: colors.newBlue,
  primaryHover: colors.kfcRedHover,
  secondary: colors.kfcBlack,
  secondaryHover: colors.kfcBlackHover,
  tertiary: colors.kfcGrey,
  tertiaryHover: colors.kfcGreyHover,
  fontColor: colors.kfcBlack,
  linkColor: colors.kfcBlack,
  logoRed: colors.kfcLogo,
  logoBlack: colors.kfcBlack,
  pageBorder: colors.kfcBorderGrey,
  pageBg: colors.kfcPageBg,
  error: colors.kfcRed,
}

export const darkTheme = {
  primary: colors.kfcBlack,
  primaryHover: colors.kfcBlackHover,
  secondary: colors.kfcRed,
  secondaryHover: colors.kfcRedHover,
  tertiary: colors.kfcGrey,
  tertiaryHover: colors.kfcGreyHover,
  fontColor: colors.kfcRed,
  // fontColor: colors.kfcBlack,
  logoRed: colors.kfcLogo,
  logoBlack: colors.kfcBlack,
}

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'RalewayRegular';
    font-display: swap;
    src: url('/Fonts/Raleway-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'RalewayLight';
    font-display: swap;
    src: url('/Fonts/Raleway-Light.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Helvetica';
    font-display: swap;
    src: url('/Fonts/Helvetica.ttf') format('truetype');
  }
  @font-face {
    font-family: 'HelveticaLight';
    font-display: swap;
    src: url('/Fonts/Helvetica-Light.ttf') format('truetype');
  }
  @font-face {
    font-family: 'RalewayBold';
    font-display: swap;
    src: url('/Fonts/Raleway-Bold.ttf') format('truetype');
  }
  * {
    box-sizing: border-box;
  }
  strong, b {
    font-family: 'RalewayBold';
  }
  html {
    height: -webkit-fill-available;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${(props) => props.theme.fontColor};
    font-family: RalewayRegular;
    margin: 0 auto;
    height: -webkit-fill-available;
  }
  input, select, textarea {
    font-family:inherit;
  }
  h1, h2, h3, h4{
    font-family: HelveticaLight;
    font-weight: 300;
    letter-spacing: 0.075em;
    margin: 0;
  }
  button {
    font-family: RalewayLight;
    font-weight: 300;
    letter-spacing: 0.075em;
    text-transform: uppercase;
  }
  h1 {
    font-size: 62px;
    line-height: 62px;
    @media(max-width: ${breakpoint.mobile}) {
      font-size: 40px;
      line-height: 40px;
    }
  }
  h2 {
    font-size: 40px;
    line-height: 40px;
    @media(max-width: ${breakpoint.mobile}) {
      font-size: 30px;
      line-height: 30px;
    }
  }
  h3 {
    font-size: 24px;
    line-height: 24px;
  }
  h4 {
    font-size: 18px;
    line-height: 18px;
  }
  p {
    font-family: HelveticaLight;
    font-weight: 300;
    margin: 0;
  }
  a {
    color: ${(props) => props.theme.linkColor};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  /* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

`
