import * as S from './styled'
import * as T from './../atomic/Typography'

const SimpleParagrpah = ({ data }) => {
  return (
    <S.Wrapper>
      {data.paragraphs.map((paragraph, i) => (
        <S.SingleParagraph key={i}>
          <S.Title>{paragraph.title}</S.Title>
          {paragraph.contents.map((content, y) => (
            <T.P1 key={y}>{content}</T.P1>
          ))}
        </S.SingleParagraph>
      ))}
    </S.Wrapper>
  )
}

export default SimpleParagrpah
