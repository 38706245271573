import React from 'react'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { PageFromJson } from './../components/utilities/MainContainer'
import CoreHtmlParser from './../components/blocks/CoreHtmlParser'
import { ext } from './../utils/utils'
import { breakpoint } from './../styles/global.style'
import Button from './../components/buttons'
import { languageData, getCurrentLanguage } from './../languages/index'

const lang = getCurrentLanguage()

const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Password Change Successful- Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: 'Password Changed Successfully',
    },
  },
]

const Wrapper = styled.div`
  position: relative;
  width: 45%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 100px 0px 50px;
  margin: auto;
  @media (max-width: ${breakpoint.mobile}) {
    width: 50%;
    gap: 15px;
  }
`

const Title = styled.h2`
  font-family: 'Helvetica';
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  weight: 700, bold;
`

const Paragraph = styled(ext(CoreHtmlParser))`
  padding-bottom: 4px;
`
const getData = () => {
  const lang = getCurrentLanguage()
  return {
    titleColor: '#34A853',
    title: languageData[lang]['password.change.success.title'],
    paragraphText: {
      content_background: 'white',
      text_colour: 'black',
      body: {
        content: `<p>
              ${languageData[lang]['password.change.success.content.0']}
              </p>
              `,
        format: 'html',
      },
    },
    buttonLabel: 'Home',
  }
}

const ChangePasswordSuccess = () => {
  const router = useNavigate()
  const location = useLocation()
  const isRouteValid =
    location?.state?.previousPath === '/account/password-change'
  if (!isRouteValid) return <Navigate to={'/'} />
  const renderProps = getData()

  return (
    <PageFromJson metaPage={metaPage}>
      <Wrapper>
        <Title style={{ color: renderProps.titleColor }}>
          {renderProps.title}
        </Title>
        <Paragraph data={renderProps.paragraphText} />
        <Button
          key="home"
          type="secondary"
          onClick={() => router('/')}
          label={renderProps.buttonLabel}
        />
      </Wrapper>
    </PageFromJson>
  )
}

export default ChangePasswordSuccess
