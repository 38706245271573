import styled from 'styled-components'
import { ChevronLeft } from '@styled-icons/bootstrap/ChevronLeft'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const InnerWrapper = styled.div`
  width: 100%;
  @media (max-width: ${breakpoint.bigtablet}) {
    max-width: 455px;
  }
`

export const BackNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 70px;
  margin-top: 44px;
  margin-bottom: 30px;
  cursor: pointer;
  @media (max-width: ${breakpoint.bigtablet}) {
    margin-left: 0px;
    padding: 0 20px;
  }
`

export const ChevronIcon = styled(ChevronLeft)``
