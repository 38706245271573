import { RowWrapper, Label, FromToCard } from './styled'
import * as T from './../atomic/Typography'

const TripCardsProcessing = ({ from, to }) => {
  return (
    <RowWrapper>
      <FromToCard>
        <Label> From: </Label>
        <Label>
          <T.P3>
            {' '}
            <T.BoldSpan>{from} </T.BoldSpan>
          </T.P3>
        </Label>
      </FromToCard>
      <FromToCard>
        <Label> To: </Label>
        <Label>
          <T.P3>
            {' '}
            <T.BoldSpan>{to} </T.BoldSpan>{' '}
          </T.P3>
        </Label>
      </FromToCard>
    </RowWrapper>
  )
}

export default TripCardsProcessing
