import * as S from './styled'

import { DefaultLayoutNew } from '../../components/utilities/DefaultLayoutNew'

import CtaCard from '../../components/cta-card'
import FormSearchbarBox from '../../components/form-searchbar-box'
import ImageWithTitle from '../../components/image-with-title'
import SimpleParagrpah from '../../components/simple_paragraphs'
import { metaPage, pageCoverageData } from './data'
import InjectMeta from '../../components/utilities/InjectMeta'
import { getCurrentLanguage } from '../../languages'
import PageLoadAnimation from '../../styles/animations'

const PageCoverage = () => {
  const pageData = pageCoverageData()
  const lang = getCurrentLanguage()
  return (
    <>
      <InjectMeta meta={metaPage} lang={lang} />
      <PageLoadAnimation>
        <DefaultLayoutNew pageType="viewer">
          <S.Wrapper>
            <FormSearchbarBox data={pageData.searchboxData} />
            <ImageWithTitle data={pageData.imageBannerData} />
            <SimpleParagrpah data={pageData.pageParapgraphsData} />
            <CtaCard data={pageData.ctaCardData} />
          </S.Wrapper>
        </DefaultLayoutNew>
      </PageLoadAnimation>
    </>
  )
}
export default PageCoverage
