import React from 'react'
import styled from 'styled-components'
import { breakpoint } from './../global.style'

export const OrderReceieved = ({ width, color }) => (
  <svg width={width} fill={color} x="0px" y="0px" viewBox="0 0 56.69 56.69">
    <g>
      <path
        d="M26.86,20.76c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2s2-0.9,2-2C28.85,21.65,27.96,20.76,26.86,20.76z M27.33,22.75
				c0,0.26-0.21,0.48-0.48,0.48s-0.48-0.21-0.48-0.48c0-0.26,0.21-0.48,0.48-0.48S27.33,22.49,27.33,22.75z"
      />
      <path
        d="M26.86,26.15c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2s2-0.9,2-2C28.85,27.05,27.96,26.15,26.86,26.15z M27.33,28.15
				c0,0.26-0.21,0.48-0.48,0.48s-0.48-0.21-0.48-0.48s0.21-0.48,0.48-0.48S27.33,27.89,27.33,28.15z"
      />
      <path
        d="M26.86,31.55c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2s2-0.9,2-2C28.85,32.44,27.96,31.55,26.86,31.55z M27.33,33.54
				c0,0.26-0.21,0.48-0.48,0.48s-0.48-0.21-0.48-0.48s0.21-0.48,0.48-0.48S27.33,33.28,27.33,33.54z"
      />
      <path
        d="M42.57,10.5H22.9c-0.79,0-1.44,0.65-1.44,1.44v26.41h-7.24l-0.25,0.01v0.01c-0.72,0.08-1.29,0.69-1.29,1.43
				v1.05c0,2.76,2.25,5.01,5.01,5.01H39c2.76,0,5.01-2.25,5.01-5.01V11.94C44.01,11.15,43.37,10.5,42.57,10.5z M33.8,38.36h-2.51
				l-8.31-0.01V12.02h19.52v28.83c0,1.93-1.57,3.49-3.49,3.49h-0.27c-1.92,0-3.49-1.57-3.49-3.49V39.8
				C35.24,39.01,34.59,38.36,33.8,38.36z M35.14,44.34H17.69c-1.93,0-3.49-1.57-3.49-3.49v-0.97h3.3c0.04,0,0.07,0,0.07-0.01l2.04,0
				c0.04,0,0.07,0.01,0.11,0.01h14v0.97C33.72,42.17,34.23,43.41,35.14,44.34z"
      />
      <path d="M40.39,18.2v-4.48H25.08v4.48l0.76,0L40.39,18.2z M38.87,15.24v1.44H26.6v-1.44H38.87z" />
      <rect x="30.05" y="32.78" width="9.58" height="1.52" />
      <rect x="30.05" y="27.39" width="9.58" height="1.52" />
      <rect x="30.05" y="21.99" width="9.58" height="1.52" />
    </g>
  </svg>
)

export const DeliveryTick = ({ width, color }) => (
  <svg
    width={width}
    fill={color}
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
  >
    <path
      d="M28.35,46.39c-9.98,0-18.09-8.12-18.09-18.09s8.12-18.09,18.09-18.09c9.98,0,18.09,8.12,18.09,18.09
	S38.32,46.39,28.35,46.39z M28.35,12.21c-8.87,0-16.09,7.22-16.09,16.09s7.22,16.09,16.09,16.09s16.09-7.22,16.09-16.09
	S37.22,12.21,28.35,12.21z"
    />
    <polygon points="24.32,36.32 18.7,30.47 20.14,29.09 24.34,33.45 36.56,21.02 37.98,22.42 " />
  </svg>
)

export const Tick = ({ width, color }) => (
  <svg fill={color || 'black'} width={width} viewBox="0 0 24 24" version="1.1">
    <g id="-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polyline
        id="Path-3"
        stroke={color}
        strokeWidth="4"
        points="2 11.5 9.43920966 19 22 4"
      ></polyline>
    </g>
  </svg>
)

const SocialSvg = styled.svg`
  @media (max-width: ${breakpoint.mobile}) {
    width: 35px;
  }
`

export const Account = ({ width, color }) => (
  <svg
    version="1.1"
    id="Layer_1"
    fill={color}
    width={width}
    x="0px"
    y="0px"
    viewBox="8 8 40 40"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M28.35,26.51c-4.46,0-8.09-3.63-8.09-8.09s3.63-8.09,8.09-8.09s8.09,3.63,8.09,8.09S32.82,26.51,28.35,26.51z
				M28.35,12.32c-3.36,0-6.09,2.73-6.09,6.09s2.73,6.09,6.09,6.09s6.09-2.73,6.09-6.09S31.71,12.32,28.35,12.32z"
      />
      <g>
        <path
          d="M45.3,45.37h-2c0-8.24-6.71-14.95-14.95-14.95s-14.95,6.71-14.95,14.95h-2c0-9.34,7.6-16.95,16.95-16.95
					S45.3,36.03,45.3,45.37z"
        />
      </g>
    </g>
  </svg>
)

export const BabyChanging = ({ width, color }) => (
  <svg
    width={width}
    fill={color}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <path
      d="M38.64,35.51l-2.72-2.72c1.7-0.16,3.04-1.6,3.04-3.3c0-4.79-2.5-8.73-6.5-10.33c0.69-0.91,1.12-2.02,1.12-3.25
			c0-3.04-2.45-5.48-5.48-5.48s-5.48,2.45-5.48,5.48c0,1.33,0.48,2.56,1.28,3.51c-3.73,1.7-5.96,5.48-5.96,10.12
			c0,1.76,1.33,3.14,3.04,3.3l-2.72,2.72c-1.28,1.28-1.28,3.41,0,4.69l5.22,5.22c0.53,0.53,1.22,0.8,1.86,0.8s1.38-0.27,1.86-0.8
			c0.48-0.48,0.8-1.17,0.8-1.86s-0.27-1.38-0.8-1.86l-0.64-0.64l0,0l-1.28-1.33c-0.27-0.27-0.27-0.75,0-1.06l1.86-1.86h2.56l1.86,1.86
			c0.16,0.16,0.21,0.32,0.21,0.53s-0.05,0.37-0.21,0.53l-1.28,1.28l-0.59,0.59c-1.01,1.01-1.01,2.72,0,3.78
			c0.53,0.53,1.22,0.8,1.86,0.8c0.69,0,1.38-0.27,1.86-0.8l5.22-5.22C39.92,38.92,39.92,36.84,38.64,35.51z M29.69,35.24h-2.56
			l-3.57-3.41c0.05-0.21,0.05-0.37,0.05-0.59v-0.64h9.53v0.64c0,0.21,0,0.43,0.05,0.59L29.69,35.24z M28.1,12.03
			c2.13,0,3.89,1.76,3.89,3.89s-1.76,3.89-3.89,3.89s-3.89-1.76-3.89-3.89S25.91,12.03,28.1,12.03z M19.47,29.49
			c0-4.26,2.18-7.67,5.75-8.95c0.85,0.53,1.81,0.8,2.88,0.8c1.17,0,2.24-0.37,3.09-0.96l0,0c3.73,1.17,6.18,4.69,6.18,9.05
			c0,0.96-0.8,1.7-1.7,1.7h-0.85c0,0,0,0,0-0.05v-5.11c0-0.43-0.37-0.8-0.8-0.8c-0.43,0-0.8,0.37-0.8,0.8v2.93h-9.53v-2.93
			c0-0.43-0.37-0.8-0.8-0.8c-0.43,0-0.8,0.43-0.8,0.91v5.11c0,0,0,0,0,0.05h-0.85C20.27,31.25,19.47,30.45,19.47,29.49z M24.1,37.59
			c-0.16,0.16-0.32,0.37-0.43,0.64l-0.53-0.53c-0.32-0.32-0.8-0.32-1.12,0c-0.32,0.32-0.32,0.8,0,1.12l2.08,2.08l1.92,1.92
			c0.21,0.21,0.32,0.48,0.32,0.75s-0.11,0.53-0.32,0.75c-0.43,0.43-1.12,0.43-1.49,0l-5.22-5.22c-0.69-0.69-0.69-1.76,0-2.45
			l3.46-3.46l2.88,2.82L24.1,37.59z M37.52,39.08l-5.22,5.22c-0.43,0.43-1.06,0.43-1.49,0c-0.21-0.21-0.32-0.48-0.32-0.75
			c0-0.27,0.11-0.53,0.32-0.75l0.59-0.59l1.28-1.28l1.97-1.97c0.32-0.32,0.32-0.8,0-1.12c-0.32-0.32-0.8-0.32-1.12,0l-0.43,0.43
			c-0.11-0.21-0.27-0.43-0.43-0.64l-1.54-1.54l2.88-2.82l3.46,3.46C38.16,37.32,38.16,38.44,37.52,39.08z"
    />
  </svg>
)

export const Basket = ({ width, color }) => (
  <svg
    version="1.1"
    id="Layer_1"
    fill={color}
    width={width}
    x="0px"
    y="0px"
    viewBox="7 7 40 40"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M48.79,22.74h-2.66h-4.58l-2.02-9.4c-0.4-1.88-2.38-3.24-4.7-3.24H21.51c-2.32,0-4.3,1.36-4.7,3.24l-2.02,9.4
				h-4.58H7.55v2h2.99l2.78,16.66c0.49,2.92,2.99,5.04,5.96,5.04h17.79c2.96,0,5.47-2.12,5.96-5.04l2.78-16.66h2.99V22.74z
				M18.76,13.76c0.2-0.95,1.38-1.66,2.75-1.66h13.33c1.36,0,2.54,0.71,2.75,1.66l1.93,8.98H16.83L18.76,13.76z M41.05,41.07
				c-0.33,1.96-2,3.37-3.98,3.37H19.28c-1.98,0-3.66-1.42-3.98-3.37l-2.72-16.33h1.79h27.62h1.79L41.05,41.07z"
      />
      <rect x="24.69" y="30.77" width="2" height="8.08" />
      <rect x="19.72" y="30.77" width="2" height="8.08" />
      <rect x="29.65" y="30.77" width="2" height="8.08" />
      <rect x="34.62" y="30.77" width="2" height="8.08" />
    </g>
  </svg>
)

export const Cross = ({ width }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={width}
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <polygon
      points="42.13,15.89 40.72,14.48 28.56,26.63 16.41,14.48 14.99,15.89 27.15,28.05 14.99,40.2 16.41,41.62 28.56,29.46 
			40.72,41.62 42.13,40.2 29.98,28.05 "
    />
  </svg>
)

export const Collect = ({ width, color }) => (
  <svg
    width={width}
    fill={color}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <path
      d="M40.96,19.79c-0.02-0.49-0.42-0.88-0.92-0.88h-4.86v-2.33c0-2.06-0.92-3.99-2.52-5.3c-1.6-1.31-3.7-1.81-5.75-1.39
			c-3.13,0.64-5.4,3.57-5.4,6.95v2.07h-4.86c-0.49,0-0.89,0.38-0.92,0.88L14.5,45.65c-0.01,0.25,0.08,0.49,0.25,0.68
			c0.17,0.18,0.42,0.29,0.67,0.29h25.85c0.25,0,0.48-0.1,0.67-0.29c0.17-0.18,0.26-0.42,0.25-0.68L40.96,19.79z M33.34,18.91h-9.99
			v-2.33c0-1.51,0.67-2.92,1.85-3.87c1.17-0.95,2.7-1.32,4.2-1.01c2.28,0.47,3.94,2.62,3.94,5.1V18.91z M23.35,22.4v-1.64h9.99v1.64
			h1.84v-1.64h3.98l1.14,24.01H16.39l1.14-24.01h3.98v1.64H23.35z"
    />
  </svg>
)

//qr code
export const ColonelClub = ({ width, color }) => (
  <svg
    fill={color}
    width={width}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M25.42,30.76L25.42,30.76 M26.13,30.76h-1.11H15v11.12h11.12v-9.95V30.76z M24.72,40.48h-8.31v-8.31h8.31V40.48z
				M24.72,40.48"
      />
      <path d="M42.32,29v-1.41H29.29V14.62h-1.41v12.97H15V29h12.89v12.89h1.41V29H42.32 M42.32,29" />
      <path d="M19.31,38.54h3.41c0,0,0,0,0,0v-4.32H18.4v3.42v0.9H19.31z M19.81,35.62h1.51v1.51h-1.51V35.62z M19.81,35.62" />
      <path
        d="M41.61,30.76L41.61,30.76 M42.32,30.76h-1.11H31.19v11.12h11.12v-9.95V30.76z M40.91,40.48H32.6v-8.31h8.31V40.48z
				M40.91,40.48"
      />
      <path d="M35.51,38.54h3.41c0,0,0,0,0,0v-4.32h-4.32v3.42v0.9H35.51z M36,35.62h1.51v1.51H36V35.62z M36,35.62" />
      <path
        d="M25.42,14.62L25.42,14.62 M26.13,14.62h-1.11H15v11.12h11.12v-9.95V14.62z M24.72,24.34h-8.31v-8.31h8.31V24.34z
				M24.72,24.34"
      />
      <path d="M19.31,22.4h3.41c0,0,0,0,0,0v-4.32H18.4v3.42v0.9H19.31z M19.81,19.48h1.51v1.51h-1.51V19.48z M19.81,19.48" />
      <path
        d="M41.61,14.62L41.61,14.62 M42.32,14.62h-1.11H31.19v11.12h11.12v-9.95V14.62z M40.91,24.34H32.6v-8.31h8.31V24.34z
				M40.91,24.34"
      />
      <path d="M35.51,22.4h3.41c0,0,0,0,0,0v-4.32h-4.32v3.42v0.9H35.51z M36,19.48h1.51v1.51H36V19.48z M36,19.48" />
      <path d="M10.67,10.38v0.91v4.14h1.41v-3.65h3.52v-1.41h-4.05H10.67z" />
      <path d="M45.94,10.45h-0.91h-4.14v1.41h3.65v3.52h1.41v-4.05V10.45z" />
      <path d="M45.94,46.19v-0.91v-4.14h-1.41v3.65h-3.52v1.41h4.05H45.94z" />
      <path d="M10.67,46.12h0.91h4.14v-1.41h-3.65V41.2h-1.41v4.05V46.12z" />
    </g>
  </svg>
)

export const DeliveryBike = ({ width, color }) => (
  <svg
    version="1.1"
    width={width}
    fill={color}
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <path
      d="M51.87,30.02c-3.28-2.81-8.11-2.79-10.16-2.64v-8.9c0-1.89-1.54-3.43-3.43-3.43h-4.15
		c-0.47,0-0.85,0.38-0.85,0.85s0.38,0.85,0.85,0.85h4.15c0.95,0,1.73,0.78,1.73,1.73l0,9.86c0,0.03,0.06,3.44-2.34,5.91
		c-1.71,1.75-4.26,2.64-7.58,2.64h-5.24h-1.99V24.31h5.13c0.47,0,0.85-0.38,0.85-0.85s-0.38-0.85-0.85-0.85h-9.92V11.35
		c0-0.47-0.38-0.85-0.85-0.85H2.52c-0.47,0-0.85,0.38-0.85,0.85v12.11c0,0.47,0.38,0.85,0.85,0.85h3.93h7.89
		c-7.35,2.8-10.05,9.43-10.39,13.37c-0.02,0.24,0.06,0.47,0.22,0.65C4.34,38.5,4.56,38.6,4.8,38.6h3.68c-0.19,0.58-0.3,1.19-0.3,1.81
		c0,3.19,2.6,5.79,5.79,5.79c3.19,0,5.79-2.6,5.79-5.79c0-0.61-0.11-1.22-0.3-1.81h5.4h5.24h10.04c-0.19,0.58-0.3,1.19-0.3,1.81
		c0,3.19,2.6,5.79,5.79,5.79c3.19,0,5.79-2.6,5.79-5.79c0-0.62-0.11-1.22-0.3-1.81h3.08c0.47,0,0.85-0.38,0.85-0.85
		C55.02,34.42,53.96,31.82,51.87,30.02z M3.37,22.61V12.2h13v10.41H6.45H3.37z M18.05,40.41c0,2.25-1.83,4.09-4.09,4.09
		c-2.25,0-4.09-1.83-4.09-4.09c0-0.63,0.15-1.25,0.43-1.81h7.32C17.9,39.16,18.05,39.78,18.05,40.41z M21.16,36.9H5.78
		c0.55-2.94,3.23-11.8,15.37-12.2V36.9z M49.7,40.41c0,2.25-1.83,4.09-4.09,4.09c-2.25,0-4.09-1.83-4.09-4.09
		c0-0.63,0.15-1.25,0.43-1.81h7.32C49.54,39.16,49.7,39.78,49.7,40.41z M37.04,36.9c0.69-0.42,1.31-0.9,1.86-1.47
		c2.16-2.23,2.66-5.01,2.77-6.34c1.71-0.14,6.22-0.24,9.09,2.22c1.52,1.31,2.37,3.18,2.53,5.59H37.04z"
    />
  </svg>
)

export const Disability = ({ width, color }) => (
  <svg
    fill={color}
    width={width}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <path
      d="M42.32,35.67l-2.7,1.56l-5.25-8.26h-7.88v-4.79h7.87v-2.05h-7.87v-4.52c1.58-0.45,2.74-1.9,2.74-3.62
			c0-2.07-1.69-3.76-3.76-3.76c-2.07,0-3.76,1.69-3.76,3.76c0,1.72,1.16,3.17,2.74,3.62v4.56c-6.2,0.52-11.08,5.73-11.08,12.06
			c0,6.68,5.43,12.11,12.11,12.11c5.64,0,10.39-3.88,11.73-9.1l1.75,2.75l4.41-2.54L42.32,35.67z M23.75,13.99
			c0-0.94,0.77-1.71,1.71-1.71c0.94,0,1.71,0.77,1.71,1.71c0,0.94-0.77,1.71-1.71,1.71C24.51,15.7,23.75,14.93,23.75,13.99
			L23.75,13.99z M25.46,44.29c-5.54,0-10.05-4.51-10.05-10.05c0-5.2,3.96-9.49,9.03-10v6.79h8.81l2.27,3.57
			C35.32,39.97,30.88,44.29,25.46,44.29L25.46,44.29z M25.46,44.29"
    />
  </svg>
)

export const Drive = ({ width, color }) => (
  <svg
    fill={color}
    width={width}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <path
      d="M53.35,30.21c-0.01-0.04-0.02-0.09-0.03-0.12c-0.22-0.8-0.48-1.4-0.8-1.84c-1.86-2.61-6.31-3.8-9.73-4.33
		c-1.05-0.67-9.35-5.94-13.21-6.45c-2.7-0.36-7.61-0.45-11.93-0.23c-1.85,0.1-3.56,0.56-5.09,1.39c-1.34,0.72-2.55,1.61-3.62,2.63
		c-7.8,7.51-6.05,13.09-5.97,13.32c0.01,0.03,0.02,0.06,0.04,0.09c0.29,0.61,1.32,1.08,3.13,1.44c0.67,1.81,2.41,3.03,4.34,3.03
		c1.68,0,3.23-0.91,4.05-2.38c4.06-0.02,17.24-0.02,24.82-0.03c0.82,1.48,2.37,2.4,4.06,2.4c1.69,0,3.24-0.92,4.06-2.41l5.78,0
		c0.4,0,0.72-0.32,0.73-0.72C53.97,35.88,54,32.66,53.35,30.21z M48.97,27.22c1.07,0.55,1.86,1.18,2.36,1.88
		c0.08,0.11,0.16,0.25,0.24,0.42c-0.63-0.12-1.55-0.38-1.98-0.9C49.27,28.24,49.07,27.77,48.97,27.22z M43.4,37.69
		c-1.76,0-3.18-1.43-3.18-3.18s1.43-3.18,3.18-3.18s3.18,1.43,3.18,3.18S45.16,37.69,43.4,37.69z M10.47,31.32
		c1.75,0,3.18,1.43,3.18,3.18s-1.43,3.18-3.18,3.18c-1.76,0-3.18-1.43-3.18-3.18S8.72,31.32,10.47,31.32z M5.33,28.26
		c0.32-0.66,0.71-1.33,1.16-2c0.18,0,0.5,0,0.87,0.01C6.96,26.86,6.19,27.87,5.33,28.26z M43.4,29.87c-2.56,0-4.64,2.08-4.64,4.64
		c0,0.26,0.02,0.52,0.07,0.78c-12.45,0-20.46,0.01-23.79,0.02c0.05-0.27,0.07-0.54,0.07-0.8c0-2.56-2.08-4.64-4.64-4.64
		c-2.56,0-4.64,2.08-4.64,4.64c0,0.02,0,0.05,0,0.07c-1.07-0.23-1.41-0.42-1.51-0.53c-0.09-0.35-0.39-1.88,0.36-4.18c0,0,0,0,0,0
		c2.54,0,4.35-3.51,4.54-3.91c0.1-0.21,0.1-0.45-0.01-0.66c-0.11-0.21-0.31-0.35-0.54-0.38c-0.28-0.04-0.66-0.07-1.11-0.09
		c0.79-0.97,1.62-1.84,2.51-2.64c2.43-2.18,4.34-2.96,5.19-3.23c-2.09,1.94-3.36,4.05-3.42,4.15c-0.12,0.21-0.14,0.47-0.03,0.69
		c0.1,0.22,0.31,0.37,0.55,0.41c2.22,0.32,28.95,1.11,30.08,1.15c1.93,0.3,3.62,0.71,5.01,1.22c0,0.6,0.1,1.91,1.01,2.99
		c0.9,1.07,2.59,1.4,3.59,1.51c0.35,1.61,0.43,3.5,0.45,4.22l-4.53,0c0.04-0.26,0.07-0.52,0.07-0.78
		C48.04,31.95,45.96,29.87,43.4,29.87z M20.38,18.6c-0.25,1.32-0.64,3.48-0.79,4.52c-2.69-0.11-4.65-0.2-5.82-0.27
		c0.78-1.08,2.26-2.89,4.13-4.16C18.71,18.65,19.54,18.62,20.38,18.6z M21.05,23.18c0.15-0.96,0.5-2.95,0.81-4.6
		c3.01-0.02,5.82,0.1,7.52,0.33c2.54,0.34,7.48,3.11,10.46,4.9C35.32,23.67,27.32,23.41,21.05,23.18z"
    />
  </svg>
)

export const Express = ({ width, color }) => (
  <svg
    fill={color}
    width={width}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <polygon points="19.88,16.98 18.65,15.56 13.99,19.66 15.23,21.08 	" />
    <path
      d="M29.11,15.68v-0.91h1.83c1.28,0,2.33-1.04,2.33-2.33c0-1.28-1.04-2.32-2.33-2.32h-5.53c-1.28,0-2.32,1.04-2.32,2.32
			c0,1.28,1.04,2.33,2.32,2.33h1.83v0.91c-8.16,0.49-14.52,7.22-14.52,15.43c0,8.53,6.94,15.46,15.46,15.46s15.46-6.94,15.46-15.46
			C43.64,22.9,37.28,16.17,29.11,15.68z M30.94,12.88h-5.53c-0.24,0-0.44-0.2-0.44-0.44c0-0.24,0.2-0.44,0.44-0.44h5.53
			c0.24,0,0.44,0.2,0.44,0.44C31.38,12.69,31.18,12.88,30.94,12.88z M41.75,31.11c0,7.49-6.09,13.58-13.58,13.58
			c-7.49,0-13.58-6.09-13.58-13.58c0-7.49,6.09-13.58,13.58-13.58C35.66,17.53,41.75,23.62,41.75,31.11z"
    />
    <path
      d="M35.46,22.48l-7.97,7.98c-0.18,0.18-0.28,0.41-0.28,0.67c0,0.25,0.1,0.49,0.28,0.66c0.18,0.18,0.41,0.28,0.66,0.28
			c0,0,0,0,0,0c0.25,0,0.49-0.1,0.66-0.28l7.97-7.98c0.37-0.37,0.37-0.96,0-1.33C36.43,22.11,35.83,22.11,35.46,22.48z"
    />
  </svg>
)

export const Facebook = ({ width, color = '#fff' }) => (
  <SocialSvg
    version="1.1"
    fill={color}
    width={width}
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="10 10 37 37"
    xmlSpace="preserve"
  >
    <path
      d="M28.35,46.44c-9.98,0-18.09-8.12-18.09-18.09s8.12-18.09,18.09-18.09s18.09,8.12,18.09,18.09
			S38.32,46.44,28.35,46.44z M28.35,12.25c-8.87,0-16.09,7.22-16.09,16.09s7.22,16.09,16.09,16.09s16.09-7.22,16.09-16.09
			S37.22,12.25,28.35,12.25z"
    />
    <g>
      <path
        d="M26.32,23.67c0,0.38,0,2.1,0,2.1h-1.54v2.57h1.54v7.64h3.17v-7.64h2.12c0,0,0.2-1.23,0.3-2.58c-0.28,0-2.41,0-2.41,0
				s0-1.5,0-1.76c0-0.26,0.35-0.62,0.69-0.62c0.34,0,1.06,0,1.72,0c0-0.35,0-1.56,0-2.68c-0.89,0-1.9,0-2.35,0
				C26.24,20.71,26.32,23.28,26.32,23.67L26.32,23.67z M26.32,23.67"
      />
    </g>
  </SocialSvg>
)

export const FoodCourt = ({ width, color }) => (
  <svg
    width={width}
    fill={color}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <g>
      <path d="M11.25,19.83l17.18-7.49l17.18,7.49l0.74-1.69l-17.92-7.82l-17.92,7.82L11.25,19.83z" />
      <path
        d="M27.84,24.12c0-3.97-2.22-7.08-5.04-7.08c-2.83,0-5.05,3.11-5.05,7.08c0,3.49,1.72,6.32,4.06,6.95v15.25h1.98V31.06
				C26.13,30.43,27.84,27.61,27.84,24.12z M22.8,29.22c-1.66,0-3.07-2.34-3.07-5.1s1.4-5.1,3.07-5.1s3.07,2.34,3.07,5.1
				S24.46,29.22,22.8,29.22z"
      />
      <path
        d="M36.93,18.02v6.09c0,2.77-1.4,5.1-3.07,5.1c-1.66,0-3.07-2.34-3.07-5.1v-6.09h-1.98v6.09c0,3.49,1.72,6.32,4.06,6.95v15.25
				h1.98V31.06c2.34-0.63,4.06-3.45,4.06-6.95v-6.09H36.93z"
      />
      <rect x="31.55" y="18.02" width="1.98" height="6.75" />
      <rect x="34.27" y="18.02" width="1.98" height="6.75" />
    </g>
  </svg>
)

export const Halal = ({ width, color }) => (
  <svg
    width={width}
    fill={color}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M25.19,25.39c0.04,0,0.07-0.02,0.09-0.05c0.02-0.03,0.02-0.07,0.01-0.1l-0.7-1.73c-0.01-0.03-0.04-0.06-0.07-0.06
				c-0.03-0.01-0.07,0-0.09,0.02c-0.11,0.08-0.23,0.16-0.36,0.24c-0.28,0.18-0.56,0.36-0.75,0.59c-0.03,0.03-0.03,0.08-0.01,0.12
				c0.59,1.19,0.36,3.05,0.18,4.54c-0.04,0.35-0.08,0.68-0.11,0.97c-0.09,1.06-0.97,1.53-1.79,1.53c-0.59,0-1.11-0.25-1.35-0.66
				c-0.26-0.44-0.2-1.02,0.17-1.68c0.02-0.04,0.02-0.08-0.01-0.12c-0.02-0.03-0.06-0.05-0.1-0.05c-0.25,0-1.29,1.51-1.34,2.64
				c-0.03,0.61,0.21,1.36,1.51,1.49c0.12,0.01,0.24,0.02,0.36,0.02c1.26,0,3.32-0.61,3.48-2.25c0.02-0.24,0.04-0.63,0.05-1.08
				C24.41,28.36,24.5,25.43,25.19,25.39L25.19,25.39z M25.19,25.39"
      />
      <path
        d="M37.64,30.11c-0.58-0.07-1.09-0.31-1.52-0.71c-0.17-0.15-0.34-0.31-0.51-0.47c-0.48-0.44-0.97-0.9-1.51-1.25
				c-0.12-0.08-0.26-0.12-0.41-0.12c-0.75,0-1.54,0.97-1.62,1.55c-0.04,0.28,0.08,0.48,0.31,0.54c0.04,0.01,0.09,0,0.12-0.04
				c0.24-0.33,0.47-0.48,0.7-0.48c0.33,0,0.67,0.3,1.03,0.62c0.15,0.13,0.31,0.27,0.47,0.4c-0.56,0.07-1.09,0.1-1.55,0.1
				c-0.87,0-1.92-0.13-1.97-0.75c-0.1-1.14,0.1-2.35,0.3-3.52c0.1-0.62,0.21-1.26,0.27-1.87c0-0.04-0.02-0.08-0.05-0.1
				c-0.04-0.02-0.08-0.02-0.11,0c-1.1,0.67-1.48,1.67-1.89,2.73c-0.27,0.72-0.56,1.45-1.08,2.16c-0.3-2.12-1.11-3.73-2.46-4.92
				c-0.03-0.03-0.07-0.03-0.11-0.02c-0.04,0.01-0.06,0.04-0.07,0.08c-0.04,0.19-0.09,0.39-0.15,0.58c-0.26,0.96-0.51,1.87,0.63,2.56
				c0.03,0.02,0.07,0.02,0.11,0c0.03-0.02,0.06-0.05,0.06-0.09l0.01-0.13c0.65,0.94,1.01,1.79,1.18,2.77c-0.3,0.26-0.74,0.5-1.17,0.72
				c-0.54,0.28-1.1,0.58-1.39,0.94c-0.03,0.04-0.03,0.1,0,0.14c0.41,0.46,0.85,0.69,1.32,0.69c1.62,0,3.07-2.75,3.73-4.23
				c0,0.02-0.01,0.04-0.01,0.06c-0.25,1.6-0.51,3.25,1.46,3.77c0.01,0,0.02,0,0.02,0c0.18,0.01,0.37,0.01,0.58,0.01h0
				c0.79,0,4.76-0.08,5.35-1.6c0.01-0.03,0.01-0.07-0.01-0.09C37.7,30.13,37.67,30.11,37.64,30.11L37.64,30.11z M37.64,30.11"
      />
    </g>
    <path
      d="M28.35,47.09l-5.51-5.51h-7.8v-7.8l-5.52-5.51l5.52-5.51v-7.8h7.8l5.51-5.51l5.51,5.51h7.8v7.8l5.51,5.51l-5.51,5.51v7.8
			h-7.8L28.35,47.09z M16.73,39.88h6.8l4.81,4.81l4.81-4.81h6.8v-6.8l4.81-4.81l-4.81-4.81v-6.8h-6.8l-4.81-4.81l-4.81,4.81h-6.8v6.8
			l-4.81,4.81l4.81,4.81V39.88z"
    />
  </svg>
)

export const Hamburger = ({ width }) => (
  <svg
    version="1.1"
    id="Layer_1"
    width={width}
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <rect x="10.17" y="42.23" width="36.08" height="4" />
    <rect x="10.17" y="10.09" width="36.08" height="4" />
    <rect x="10.17" y="26.16" width="36.08" height="4" />
  </svg>
)

export const Instagram = ({ width, color = '#fff' }) => (
  <SocialSvg
    width={width}
    fill={color}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="10 10 37 37"
    xmlSpace="preserve"
  >
    <path
      d="M28.35,46.44c-9.98,0-18.09-8.12-18.09-18.09s8.12-18.09,18.09-18.09c9.98,0,18.09,8.12,18.09,18.09
			S38.32,46.44,28.35,46.44z M28.35,12.25c-8.87,0-16.09,7.22-16.09,16.09s7.22,16.09,16.09,16.09c8.87,0,16.09-7.22,16.09-16.09
			S37.22,12.25,28.35,12.25z"
    />
    <g>
      <path
        d="M34.4,20.77H22.29c-0.83,0-1.51,0.68-1.51,1.51V34.4c0,0.83,0.68,1.51,1.51,1.51H34.4c0.83,0,1.51-0.68,1.51-1.51V22.29
				C35.92,21.46,35.24,20.77,34.4,20.77L34.4,20.77z M28.35,25.32c1.67,0,3.03,1.36,3.03,3.03s-1.36,3.03-3.03,3.03
				c-1.67,0-3.03-1.36-3.03-3.03S26.68,25.32,28.35,25.32L28.35,25.32z M22.67,34.4c-0.23,0-0.38-0.15-0.38-0.38v-6.44h1.59
				c-0.08,0.23-0.08,0.53-0.08,0.76c0,2.5,2.04,4.54,4.54,4.54s4.54-2.04,4.54-4.54c0-0.23,0-0.53-0.08-0.76h1.59v6.44
				c0,0.23-0.15,0.38-0.38,0.38H22.67z M34.4,24.18c0,0.23-0.15,0.38-0.38,0.38h-1.51c-0.23,0-0.38-0.15-0.38-0.38v-1.51
				c0-0.23,0.15-0.38,0.38-0.38h1.51c0.23,0,0.38,0.15,0.38,0.38V24.18z M34.4,24.18"
      />
    </g>
  </SocialSvg>
)

export const MagnifyingGlass = ({ width }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={width}
    viewBox="13 13 31 31"
    xmlSpace="preserve"
  >
    <path
      d="M41.27,40.06l-6.54-6.45c1.66-1.93,2.67-4.42,2.68-7.15v0c0.03-6.1-4.92-11.09-11.02-11.12c-0.02,0-0.04,0-0.05,0
	c-2.94,0-5.7,1.14-7.79,3.21c-2.1,2.08-3.27,4.85-3.28,7.81c-0.03,6.1,4.92,11.09,11.02,11.12c0.02,0,0.04,0,0.05,0
	c2.63,0,5.05-0.93,6.95-2.47l6.57,6.48L41.27,40.06z M26.3,35.47c-5-0.02-9.05-4.11-9.03-9.11c0.01-2.42,0.97-4.7,2.69-6.4
	c1.71-1.69,3.97-2.63,6.38-2.63c0.01,0,0.03,0,0.04,0c5,0.02,9.05,4.11,9.03,9.11c-0.02,4.99-4.09,9.03-9.07,9.03
	C26.32,35.47,26.31,35.47,26.3,35.47z"
    />
  </svg>
)

export const Snapchat = ({ width, color = '#fff' }) => (
  <SocialSvg
    width={width}
    fill={color}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="10 10 37 37"
    xmlSpace="preserve"
  >
    <path
      d="M28.36,46.26c-9.98,0-18.09-8.12-18.09-18.09s8.12-18.09,18.09-18.09c9.98,0,18.09,8.12,18.09,18.09
			S38.33,46.26,28.36,46.26z M28.36,12.08c-8.87,0-16.09,7.22-16.09,16.09s7.22,16.09,16.09,16.09c8.87,0,16.09-7.22,16.09-16.09
			S37.23,12.08,28.36,12.08z"
    />
    <g>
      <g>
        <path
          d="M36.26,32.03c-2.09-0.34-3.03-2.45-3.14-2.69c0,0-0.01-0.02-0.01-0.03c-0.1-0.21-0.13-0.38-0.08-0.51
					c0.11-0.26,0.57-0.41,0.87-0.5c0.08-0.03,0.16-0.05,0.22-0.08c0.6-0.24,0.9-0.54,0.9-0.9c-0.01-0.29-0.23-0.55-0.56-0.67
					c-0.11-0.05-0.25-0.07-0.38-0.07c-0.09,0-0.23,0.01-0.36,0.07c-0.25,0.12-0.48,0.18-0.65,0.19c-0.08,0-0.13-0.02-0.18-0.03
					c0.01-0.09,0.01-0.18,0.02-0.28l0-0.05c0.07-1.12,0.16-2.52-0.22-3.36c-1.11-2.48-3.46-2.68-4.15-2.68l-0.32,0
					c-0.69,0-3.04,0.19-4.14,2.67c-0.38,0.84-0.29,2.24-0.22,3.36c0.01,0.11,0.01,0.22,0.02,0.32c-0.05,0.02-0.12,0.03-0.21,0.03
					c-0.2,0-0.44-0.06-0.71-0.19c-0.39-0.18-1.11,0.06-1.21,0.57c-0.05,0.28,0.06,0.67,0.88,1c0.06,0.03,0.15,0.05,0.24,0.08
					c0.28,0.09,0.74,0.23,0.85,0.49c0.05,0.13,0.03,0.3-0.09,0.53c-0.04,0.1-1.02,2.33-3.19,2.69c-0.23,0.04-0.39,0.24-0.37,0.46
					c0,0.06,0.02,0.13,0.05,0.19c0.18,0.43,0.88,0.72,2.18,0.93c0.03,0.07,0.06,0.23,0.08,0.31c0.03,0.13,0.06,0.26,0.1,0.39
					c0.04,0.14,0.16,0.37,0.5,0.37c0.12,0,0.25-0.03,0.39-0.05c0.21-0.04,0.49-0.1,0.85-0.1c0.2,0,0.4,0.02,0.61,0.05
					c0.39,0.06,0.73,0.31,1.13,0.59c0.59,0.41,1.12,0.73,2.18,0.73c0.03,0,0.06,0,0.08,0c0.04,0,0.08,0,0.12,0
					c0.93,0,1.75-0.25,2.44-0.73c0.38-0.27,0.74-0.52,1.13-0.59c0.21-0.03,0.41-0.05,0.61-0.05c0.34,0,0.61,0.04,0.85,0.09
					c0.16,0.03,0.29,0.05,0.41,0.05c0.24,0,0.41-0.13,0.48-0.36c0.04-0.13,0.07-0.26,0.1-0.39c0.01-0.07,0.05-0.23,0.08-0.31
					c1.29-0.21,1.95-0.49,2.13-0.91c0.03-0.06,0.04-0.13,0.05-0.2C36.64,32.26,36.48,32.06,36.26,32.03L36.26,32.03z M36.26,32.03"
        />
      </g>
    </g>
  </SocialSvg>
)

export const Twitter = ({ width, color = '#fff' }) => (
  <SocialSvg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={width}
    fill={color}
    viewBox="10 10 37 37"
    xmlSpace="preserve"
  >
    <path
      d="M28.33,46.44c-9.98,0-18.09-8.12-18.09-18.09s8.12-18.09,18.09-18.09s18.09,8.12,18.09,18.09
			S38.31,46.44,28.33,46.44z M28.33,12.25c-8.87,0-16.09,7.22-16.09,16.09s7.22,16.09,16.09,16.09s16.09-7.22,16.09-16.09
			S37.2,12.25,28.33,12.25z"
    />
    <g>
      <path
        d="M36.71,23.15c-0.62,0.27-1.28,0.46-1.97,0.54c0.71-0.43,1.25-1.1,1.51-1.9c-0.67,0.39-1.4,0.68-2.18,0.83
				c-0.63-0.67-1.52-1.09-2.51-1.09c-1.9,0-3.44,1.54-3.44,3.44c0,0.27,0.03,0.53,0.09,0.78c-2.86-0.14-5.39-1.51-7.09-3.59
				c-0.3,0.51-0.46,1.1-0.46,1.73c0,1.19,0.61,2.25,1.53,2.86c-0.56-0.02-1.09-0.17-1.56-0.43v0.04c0,1.67,1.19,3.06,2.76,3.37
				c-0.29,0.08-0.59,0.12-0.91,0.12c-0.22,0-0.44-0.02-0.65-0.06c0.44,1.37,1.71,2.36,3.21,2.39c-1.18,0.92-2.66,1.47-4.27,1.47
				c-0.28,0-0.55-0.02-0.82-0.05c1.52,0.98,3.33,1.55,5.27,1.55c6.33,0,9.78-5.24,9.78-9.78l-0.01-0.45
				C35.67,24.44,36.25,23.84,36.71,23.15L36.71,23.15z M36.71,23.15"
      />
    </g>
  </SocialSvg>
)

export const Youtube = ({ width, color = '#fff' }) => (
  <SocialSvg
    width={width}
    fill={color}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="10 10 37 37"
    xmlSpace="preserve"
  >
    <path
      d="M28.36,46.44c-9.98,0-18.09-8.12-18.09-18.09s8.12-18.09,18.09-18.09s18.09,8.12,18.09,18.09
		S38.34,46.44,28.36,46.44z M28.36,12.25c-8.87,0-16.09,7.22-16.09,16.09s7.22,16.09,16.09,16.09s16.09-7.22,16.09-16.09
		S37.23,12.25,28.36,12.25z"
    />
    <g>
      <path
        d="M35.55,23.98c-0.43-0.76-0.89-0.9-1.83-0.95c-0.94-0.06-3.31-0.09-5.36-0.09c-2.06,0-4.42,0.03-5.36,0.09
				c-0.94,0.05-1.4,0.19-1.84,0.95c-0.44,0.76-0.67,2.06-0.67,4.36c0,0,0,0,0,0c0,0,0,0,0,0v0c0,2.29,0.23,3.6,0.67,4.35
				c0.43,0.76,0.89,0.9,1.83,0.96c0.94,0.06,3.31,0.09,5.37,0.09c2.05,0,4.42-0.03,5.36-0.09c0.94-0.06,1.41-0.2,1.83-0.96
				c0.44-0.75,0.67-2.07,0.67-4.35c0,0,0,0,0,0c0,0,0,0,0,0C36.22,26.05,36,24.74,35.55,23.98L35.55,23.98z M26.39,31.3v-5.9
				l4.91,2.95L26.39,31.3z M26.39,31.3"
      />
    </g>
  </SocialSvg>
)

export const Wifi = ({ width, color }) => (
  <svg
    width={width}
    fill={color}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M28.35,37.96c-2.41,0-4.37,1.96-4.37,4.37s1.96,4.37,4.37,4.37c2.41,0,4.37-1.96,4.37-4.37S30.76,37.96,28.35,37.96z
				M30.58,42.32c0,1.23-1,2.23-2.23,2.23s-2.23-1-2.23-2.23s1-2.23,2.23-2.23S30.58,41.1,30.58,42.32z"
      />
      <path
        d="M28.35,28.28c-3.75,0-7.28,1.46-9.93,4.11l-0.27,0.27l1.52,1.51l0.27-0.27c2.24-2.25,5.23-3.49,8.42-3.49
				s6.18,1.24,8.42,3.49l0.27,0.27l1.52-1.51l-0.27-0.27C35.63,29.74,32.1,28.28,28.35,28.28z"
      />
      <path
        d="M28.35,19.06c-6.23,0-12.07,2.42-16.46,6.81l-0.27,0.27l1.51,1.51l0.27-0.27c8.24-8.24,21.65-8.24,29.89,0l0.27,0.27
				l1.51-1.51l-0.27-0.27C40.42,21.47,34.57,19.06,28.35,19.06z"
      />
      <path
        d="M51.34,19.34c-12.68-12.67-33.3-12.67-45.98,0L5.09,19.6l1.52,1.51l0.26-0.27c11.84-11.84,31.11-11.84,42.95,0l0.27,0.27
				l1.52-1.51L51.34,19.34z"
      />
    </g>
  </svg>
)
