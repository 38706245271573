import { isEqual } from 'lodash'
import { usePrevious } from './usePrevious'
import { useEffect } from 'react'

export const useEffectOnActualUpdate = (callbackFn, dependencyList) => {
  const prevDependencyList = usePrevious(dependencyList)

  useEffect(() => {
    if (isEqual(prevDependencyList, dependencyList)) return
    callbackFn()
    //NO EXHAUSTIVE DEPS HERE
  }, [dependencyList])
}
