import { Link, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { zapierApi, zapierEventTypes } from '../../utils/restAPIUtils'
import { countryCodes } from '../../utils/countryCodes'
import Button from '../buttons'
import * as S from './styled'
import * as T from './../atomic/Typography'

const CtaCard = ({ data }) => {
  const [callbackSuccess, setCallbackSuccess] = useState(false)
  const [value, setValue] = useState({
    prefix: 'IT',
    number: '',
  })

  const { search } = useLocation()

  const bottomWrapper = (data) => {
    switch (data.bottomContent.type) {
      case 'button':
        return (
          <S.ButtonWrapper>
            <Link to={`${data.bottomContent.buttonLink}${search}`}>
              <Button type="secondary" label={data.bottomContent.buttonLabel} />
            </Link>
            {data.bottomContent.secondaryButtonLabel && (
              <Link to={`${data.bottomContent.secondaryButtonLink}${search}`}>
                <Button
                  type="white"
                  label={data.bottomContent.secondaryButtonLabel}
                />
              </Link>
            )}
          </S.ButtonWrapper>
        )
      case 'text':
        return (
          <T.Uh3>
            <T.BoldSpan>{data.bottomContent.textContent}</T.BoldSpan>
          </T.Uh3>
        )
      case 'phone_form':
        const successTimeout = () => {
          setCallbackSuccess(true)
          setValue((prevState) => ({ ...prevState, number: '' }))
          setTimeout(() => {
            setCallbackSuccess(false)
          }, 5000)
        }
        const handleCallback = async () => {
          try {
            const phoneNum = countryCodes[value.prefix]['primary'].concat(
              value.number,
            )
            await zapierApi('get')(zapierEventTypes.callMeBack)()({
              phoneNum,
              url: window.location.href,
            })((cb) => cb)
            successTimeout()
          } catch (error) {
            console.error('Error sending webhook:', error)
          }
        }
        return (
          <>
            <S.InputWrapper>
              <S.SingleInput>
                <label htmlFor="tel">{data.bottomContent.input1Label}</label>
                <S.ReactFlags
                  selected={value['prefix']}
                  onSelect={(code) =>
                    setValue((prevState) => ({ ...prevState, prefix: code }))
                  }
                  searchable={true}
                  fullWidth={false}
                  customLabels={countryCodes}
                />
              </S.SingleInput>
              <S.SingleInput full>
                <label htmlFor="tel">{data.bottomContent.input2Label}</label>
                <S.Input
                  value={value['number']}
                  onChange={(e) =>
                    setValue((prevState) => ({
                      ...prevState,
                      number: e.target.value,
                    }))
                  }
                  id="tel"
                  placeholder={data.bottomContent.input2Placeholder}
                  type="tel"
                />
              </S.SingleInput>
            </S.InputWrapper>
            {!callbackSuccess ? (
              <Button
                onClick={handleCallback}
                type="secondary"
                label={data.bottomContent.buttonLabel}
              />
            ) : (
              <S.IconWrapper>
                <S.CallIcon size={20} />{' '}
                <p>{data.bottomContent.successMessage}</p>
              </S.IconWrapper>
            )}
          </>
        )
      case 'simple':
        return <></>
    }
  }
  return (
    <S.Wrapper>
      <S.MessageWrapper simple={data.simple} centerContent={data.centerContent}>
        <S.Title>{data.title}</S.Title>
        <T.P1>{data.subtitle}</T.P1>
        {bottomWrapper(data)}
      </S.MessageWrapper>
    </S.Wrapper>
  )
}

export default CtaCard
