import React, { useState, useContext } from 'react'
import { AuthContext } from '../auth-context'
import { useLocation, useNavigate } from 'react-router-dom'
import { getCurrentLanguage, languageData } from './../../languages/index'
import { saveState } from './../../utils/localStorage'
import AuthForm from './../auth/AuthForm'
import { askGoogle } from '../../utils/mapUtils'

const searchParams = new URLSearchParams(window.location.search)
const isEditing = searchParams.get('edit') === 'true'
const lang = getCurrentLanguage()

const getCoords = (data) => ({
  lng: data?.lng(),
  lat: data?.lat(),
})

const progressToNextStep = async (value, error, router, userAttributes) => {
  error([])
  try {
    const result = await askGoogle(value)
    const currentDate = new Date().getTime()

    if (result.status === 'OK') {
      const reservation = {
        when: value.when.toLocaleString('sv'), // something like -> "2023-05-21 08:00:00"
        originCoords: getCoords(
          result?.routes?.at(0)?.legs?.at(0)?.start_location,
        ),
        destinationCoords: getCoords(
          result?.routes?.at(0)?.legs?.at(0)?.end_location,
        ),
        duration: result?.routes?.at(0)?.legs?.at(0)?.duration?.value,
        distance: result?.routes?.at(0)?.legs?.at(0)?.distance?.value,
        origin: result?.request?.origin?.query,
        destination: result?.request?.destination?.query,
        triptype: value.triptype,
        direction: value.dropdown,
        originalPrice: value.price,
        updatedPrice: isEditing ? value.price : null,
        flightCode: value.flightCode ? value.flightCode : null,
        whenReturn:
          value.dropdown === 'Return' && value.whenReturn
            ? value.whenReturn.toLocaleString('sv')
            : null,
        returnFlightCode:
          value.dropdown === 'Return' && value.flightReturnCode
            ? value.flightReturnCode
            : null,
        ...(isEditing
          ? { updatedOn: currentDate }
          : { createdOn: currentDate, updatedOn: null }),
        coverage: value.coverage,
      }

      saveState('trip-pending', reservation)
      saveState('sourcePath', location.pathname)
      if (!userAttributes) {
        router('account/login', {
          state: { previousPath: window.location.pathname },
        })
      } else if (isEditing) {
        router('checkout?edit=true', {
          state: { previousPath: window.location.pathname },
        })
      } else {
        router(`checkout`, {
          state: { previousPath: window.location.pathname },
        })
      }
    } else {
      console.log('API CALL ERROR, status not OK')
    }
  } catch (err) {
    error([`main.${err.message.split(':').pop()}`])
    console.log(err, error)
  }
}

export function RideForm() {
  const router = useNavigate()
  const { userAttributes } = useContext(AuthContext)
  const lang = getCurrentLanguage()

  const { state = {} } = useLocation()
  const { trip = [] } = state || []

  const [from, setFrom] = useState(trip.origin ?? '')
  const [to, setTo] = useState(trip.destination ?? '')
  const [when, setWhen] = useState()
  const [whenReturn, setWhenReturn] = useState()
  const [duration, setDuration] = useState(trip.duration ?? 0)
  const [distance, setDistance] = useState(trip.distance ?? 0)
  const [triptype, setTripType] = useState(trip.triptype ?? 'lele')
  const [error, setError] = useState([])
  const [directionDropdown, setDirectionDropdown] = useState(
    trip.direction ?? languageData[lang]['home.hero.form.one.direction'],
  )
  const [flightCode, setFlightCode] = useState(trip.flightCode || '')
  const [flightReturnCode, setFlightReturnCode] = useState(
    trip.flightReturnCode || '',
  )
  const [price, setPrice] = useState(null)
  const [coverage, setCoverage] = useState(false)
  const dropdown =
    directionDropdown === languageData[lang]['home.hero.form.return.direction']
      ? 'Return'
      : 'One-way'

  const currentValue = {
    from,
    to,
    when,
    triptype,
    duration,
    distance,
    dropdown,
    flightCode,
    flightReturnCode,
    whenReturn,
    price,
    coverage,
  }

  const getData = () => {
    return {
      formOptions: [
        {
          component: 'title',
          title: languageData[lang]['home.hero.form.pickup'],
          id: 'title',
          error: 'main',
          full: true,
        },
        {
          component: 'direction',
          id: 'direction',
          name: 'direction',
        },
        {
          id: 'from',
          component: 'geo',
          name: 'from',
          type: 'geo',
          label: languageData[lang]['home.hero.form.from'],
          required: true,
          value: null,
          withBorder: true,
          width: '100%',
          margin: 20,
        },
        {
          id: 'to',
          component: 'geo',
          name: 'from',
          type: 'geo',
          label: languageData[lang]['home.hero.form.to'],
          required: true,
          value: null,
          withBorder: true,
          width: '100%',
          margin: 20,
        },
        {
          id: 'when',
          component: 'date',
          name: 'when',
          type: 'date',
          required: true,
          value: null,
          withBorder: true,
          width: '100%',
          margin: 20,
        },
        {
          id: 'directionDropdown',
          component: 'return-trip-dropdown',
          name: 'directionDropdown',
          flightHandler: 'flightCode',
          flightReturnHandler: 'flightReturnCode',
          returnDateHandler: 'whenReturn',
          outwardDateValue: when,
        },
        {
          id: 'coverage',
          component: 'coverage',
          margin: 30,
          label: languageData[lang]['home.hero.form.coverage'],
          priceHandler: 'coverage',
          hidden: false,
        },
        {
          id: 'triptype',
          component: 'tripselect',
          margin: 30,
          label: languageData[lang]['home.hero.form.service'],
          priceHandler: 'price',
        },
        {
          id: 'action',
          key: 'next',
          component: 'button',
          type: 'secondary',
          label: languageData[lang]['home.hero.form.submit'],
          disableFactor:
            directionDropdown !==
            languageData[lang]['home.hero.form.return.direction']
              ? ['from', 'to', 'when', 'triptype']
              : ['from', 'to', 'when', 'triptype', 'whenReturn'],
          disabled: false,
        },
      ],
    }
  }
  const handlers = {
    from: setFrom,
    to: setTo,
    when: setWhen,
    triptype: setTripType,
    direction: setDirectionDropdown,
    flightCode: setFlightCode,
    flightReturnCode: setFlightReturnCode,
    whenReturn: setWhenReturn,
    price: setPrice,
    action: () =>
      progressToNextStep(currentValue, setError, router, userAttributes),
    coverage: setCoverage,
  }

  const handleChange = async (id, value) => {
    const newState = { ...currentValue, [id]: value }
    if (newState['from'] && newState['to'] && (id === 'from' || id === 'to')) {
      const tripData = await askGoogle(newState)
      const newDuration = tripData?.routes?.at(0)?.legs?.at(0)?.duration?.value
      const newDistance = tripData?.routes?.at(0)?.legs?.at(0)?.distance?.value
      setDistance(newDistance)
      setDuration(newDuration)
    }
    if (
      (id === 'from' || id === 'to') &&
      (!newState['from'] || !newState['to'])
    ) {
      setDistance(0)
      setDuration(0)
    }
    return handlers[id](value)
  }

  return (
    <AuthForm
      noTopBorder
      {...getData()}
      withImg
      value={currentValue}
      handler={handleChange}
      error={error}
    />
  )
}
