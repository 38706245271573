import styled from 'styled-components'
import { ChevronDown } from '@styled-icons/feather/ChevronDown'
import { breakpoint } from '../../styles/global.style'
import * as T from '../atomic/Typography'

export const Wrapper = styled.footer`
  display: flex;
  padding: 64px 92px;
  flex-direction: column;
  gap: 24px;
  background: #000;
  color: white;
  @media (max-width: ${breakpoint.bigtablet}) {
    padding: 40px 24px;
  }
`

export const DesktopFooter = styled.div``

export const UpperWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  border-bottom: 2px solid #c5cac9;
  padding-bottom: 24px;
  margin-bottom: 24px;
`

export const LogoWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`

export const LogoWrapperMobile = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  border-bottom: 2px solid #c5cac9;
  padding-bottom: 32px;
  align-items: center;
`

export const Logo = styled.img``

export const SocialMedia = styled.div`
  display: flex;
  gap: 12px;
  @media (max-width: ${breakpoint.bigtablet}) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`

export const LinksMenu = styled.div`
  display: flex;
  gap: 50px;
  @media (max-width: ${breakpoint.bigtablet}) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

export const LinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  a {
    color: white;
    text-decoration: none;
  }
`

export const MobileFooterMenu = styled.div`
  display: flex;
  gap: 10px;
  border-bottom: ${(props) => (props.open ? 'unset' : '2px solid #C5CAC9')};
  padding-bottom: 32px;
  justify-content: center;
  align-items: center;
`

export const SmallScreenFoooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const ArrowIconUp = styled(ChevronDown)``

export const ArrowIconDown = styled(ChevronDown)`
  transform: rotate(180deg);
`

export const Copyright = styled(T.P2)`
  color: #aeaeae;
  @media (max-width: ${breakpoint.bigtablet}) {
    padding-bottom: 32px;
    text-align: center;
  }
`
