import React, { Component, useState } from 'react'
import { renderIcon } from '../atomic/Layout'
import {
  PrimaryBtn,
  SecondaryBtn,
  TertiaryBtn,
  WhiteBtn,
  FacebookBtn,
  FBIcon,
  Loading,
  IconPadding,
  Icon,
  TripBtn,
} from './styled'

const TextWithIcon = ({
  icon,
  iconPosition = 'right',
  label,
  color = 'white',
}) => (
  <>
    {icon && iconPosition === 'left' && (
      <Icon>{renderIcon(icon, 25, color)}</Icon>
    )}
    {label}
    {icon && iconPosition === 'right' && (
      <Icon>{renderIcon(icon, 25, color)}</Icon>
    )}
  </>
)

const Button = ({
  type = 'primary',
  label,
  loading,
  timer,
  disabled,
  hidden,
  full,
  borderRadius,
  ...otherProps
}) => {
  const [disable, setDisable] = useState(false)
  const timerHandler = () => {
    otherProps.onClick()
    if (timer) {
      setDisable(true)
      setTimeout(() => {
        setDisable(false)
      }, timer)
    }
  }
  switch (type) {
    case 'primary':
      return (
        <PrimaryBtn {...otherProps} aria-label={label}>
          <TextWithIcon label={label} />
        </PrimaryBtn>
      )
    case 'secondary':
      return (
        <SecondaryBtn
          {...otherProps}
          aria-label={label}
          disabled={timer ? disable : disabled}
          onClick={timerHandler}
          full={full}
          borderRadius={borderRadius}
          hidden={hidden}
        >
          {loading && (
            <>
              <Loading />
              <IconPadding />
            </>
          )}
          <TextWithIcon
            label={label}
            icon={otherProps.icon}
            iconPosition={otherProps.iconPosition}
          />
        </SecondaryBtn>
      )
    case 'tertiary':
      return (
        <TertiaryBtn {...otherProps} aria-label={label}>
          <TextWithIcon label={label} />
        </TertiaryBtn>
      )
    case 'white':
      return (
        <WhiteBtn
          {...otherProps}
          aria-label={label}
          full={full}
          disabled={timer ? disable : disabled}
        >
          {loading && (
            <>
              <Loading />
              <IconPadding />
            </>
          )}
          <TextWithIcon label={label} />
        </WhiteBtn>
      )
    case 'trip':
      return (
        <TripBtn
          {...otherProps}
          aria-label={label}
          disabled={timer ? disable : disabled}
          onClick={timerHandler}
          borderRadius={borderRadius}
          hidden={hidden}
        >
          <TextWithIcon
            label={label}
            icon={otherProps.icon}
            iconPosition={otherProps.iconPosition}
            color={otherProps.color}
          />
        </TripBtn>
      )
    case 'facebook':
      return (
        <FacebookBtn {...otherProps} aria-label={label}>
          <FBIcon>{renderIcon('facebookSquare')}</FBIcon>
          {label}
        </FacebookBtn>
      )
    default:
      return (
        <PrimaryBtn {...otherProps} aria-label={label}>
          <TextWithIcon label={label} />
        </PrimaryBtn>
      )
  }
}

export default Button
