import styled from 'styled-components'
import * as T from './../atomic/Typography'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  padding: 48px 32px;
  @media (max-width: ${breakpoint.tablet}) {
    padding: 15px;
  }
`

export const MessageWrapper = styled.div`
  color: green;
`

export const Title = styled(T.Uh3)`
  font-size: 32px;
  line-height: normal;
  font-weight: 700;
`

export const Subtitle = styled(T.P2)`
  margin-bottom: 40px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 630px;
  margin-top: 48px;
  margin-bottom: 24px;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Input = styled.input`
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #d4d9eb;
  background: #f7f7f8;
`

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const TextArea = styled.textarea`
  padding: 12px;
  border-radius: 4px;
  max-width: 630px;
  border: 1px solid #d4d9eb;
  background: #f7f7f8;
  min-height: 150px;
`
