import * as S from './styled'
import * as T from './../atomic/Typography'
import { Link } from 'react-router-dom'
import { Login } from '../form-sign-in'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../auth-context'
import Button from '../buttons'

const ModalAccount = ({ handler, id, modalValue, guestHandler }) => {
  const [login, setLogin] = useState(false)
  const { userAttributes } = useContext(AuthContext)

  const closeModal = () => {
    handler(id, false)
  }

  const continueAsGuest = () => {
    handler(guestHandler)
  }

  useEffect(() => {
    if (userAttributes !== null) {
      closeModal()
    }
  }, [userAttributes])

  return (
    <>
      <S.ModalComponent
        open={modalValue}
        footer={null}
        onCancel={closeModal}
        closable
        centered
        destroyOnClose={true}
        maskClosable={true}
        width={479}
        login={login}
      >
        <S.Wrapper>
          {!login ? (
            <>
              <S.Title paddingBottom="14">Choose Your Path</S.Title>
              <T.P1>{`Please let us know if you already have an account with us or if you'd like to book your trip as a guest. `}</T.P1>
              <T.P1>
                Making an account offers benefits like easy access to booking
                history and exclusive deals.
              </T.P1>
              <S.ButtonWrapper>
                <Button
                  full
                  type="secondary"
                  label="Login"
                  onClick={() => setLogin(true)}
                />
                <Button
                  full
                  type="white"
                  label="Continue as a guest"
                  onClick={continueAsGuest}
                />
              </S.ButtonWrapper>
            </>
          ) : (
            <S.GuestModal>
              <Login onlyForm />
              <Link onClick={continueAsGuest}>
                <T.P2>Continue as a guest</T.P2>
              </Link>
            </S.GuestModal>
          )}
        </S.Wrapper>
      </S.ModalComponent>
    </>
  )
}

export default ModalAccount
