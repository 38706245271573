import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { Clock } from '@styled-icons/fa-solid'
import { Play } from '@styled-icons/icomoon/Play'
import {
  ChevronDown,
  ChevronRight,
  ChevronUp,
  ChevronLeft,
  Navigation,
  Plus,
} from '@styled-icons/boxicons-regular'
import { ReceiptLong } from '@styled-icons/material-rounded/ReceiptLong'
import { Buildings } from '@styled-icons/boxicons-solid/Buildings'
import { FacebookSquare } from '@styled-icons/boxicons-logos'
import {
  Account,
  Basket,
  BabyChanging,
  Collect,
  Cross,
  DeliveryBike,
  DeliveryTick,
  OrderReceieved,
  Disability,
  Drive,
  Express,
  Facebook,
  FoodCourt,
  Halal,
  Hamburger,
  Instagram,
  MagnifyingGlass,
  Snapchat,
  Twitter,
  Youtube,
  Wifi,
  ColonelClub,
  Tick,
} from '../../styles/SVGs/Icons'
import { P3 } from '../typography'
import { defaultTheme, colors, breakpoint } from '../../styles/global.style'
import { ext } from '../../utils/utils'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  margin: 0 auto;
  max-width: ${breakpoint.siteWidth};
  background-color: ${(props) => (props.grey ? defaultTheme.pageBg : 'white')};
  padding-top: ${(props) => props.paddingTop || '0'}px;
  @media (max-width: ${breakpoint.bigtablet}) {
    padding-top: ${(props) =>
      props.paddingTopTablet || props.paddingTop || '0'}px;
  }
  @media (max-width: ${breakpoint.mobile}) {
    padding-top: ${(props) =>
      props.paddingTopMobile ? props.paddingTopMobile : '0'}px;
  }
`

export const AuthFormWrapper = styled.div`
  background-color: white;
  position: relative;
  max-width: 440px;
  min-width: 380px;
  margin: 0px 0px 10px;
  z-index: 10;
  border-top: ${(props) => (props.noTopBorder ? 'none' : '5px solid black')};
  padding: 60px 20px;
  @media (max-width: ${breakpoint.mobile}) {
    padding: 50px 10px;
    width: calc(100% - 20px);
    min-width: calc(100% - 50px);
  }
`

export const DRow = styled.div`
  ${(props) =>
    props.background
      ? `
    background-image: url(${props.background});
    background-position: center;
    background-size: cover;
    padding: 100px ${props.alignment === 'left' ? '2%' : '15%'} !important; 
  `
      : ''}
  display: ${(props) => props.display || 'flex'};
  position: relative;
  flex-shrink: 0;
  flex: 1;
  min-height: ${(props) => (props.height ? `${props.height}px` : '')};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-between'};
  max-width: ${breakpoint.siteWidth};
  padding: 0px ${(props) => (props.full ? '0px' : '35px')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  margin: ${(props) => (props.spaced ? '0px 0px 30px' : 'auto')};
  background-color: ${(props) =>
    props.invertColor
      ? props.theme.secondary
      : props.grey
      ? props.theme.tertiary
      : 'white'};
  color: ${(props) => (props.invertColor ? 'white' : props.theme.secondary)};
  ${(props) =>
    props.mobileHeight
      ? `
    @media(max-width: ${breakpoint.mobile}) {
      min-height: ${props.mobileHeight}px;
    }
  `
      : ''}
  @media(max-width: ${breakpoint.tablet}) {
    padding: 0px ${(props) => (props.full ? '0px' : '15px')};
  }
  @media (max-width: ${breakpoint.mobile}) {
    padding: ${(props) => (props.full ? '0px' : '55px 15px')};
  }
`

export const DCol = styled.div`
  ${(props) =>
    props.background
      ? `
    padding: 100px !important;
    background-color: white;
    max-width: ${props.maxWidth};
  `
      : ''}
  display: flex;
  flex: 1;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-around'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  padding: ${(props) => props.padding || '0'}px 0px;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
`

export const List = styled.ul`
  display: flex;
  list-style: none;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  padding-left: 0px;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-around'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  flex: ${(props) => (props.flex ? props.flex : '0 1 auto')};
  margin: 0px;
  gap: 10px;
`

export const Hr = styled.hr`
  margin-top: ${(props) => props.margin || '15'}px;
  margin-bottom: ${(props) => props.margin || '15'}px;
  border: none;
  border-top: ${(props) => props.borderSize || '3'}px solid
    ${(props) => props.borderColor || colors.kfcLightGrey};
`

export const ListItem = styled.li`
  padding: 4px ${(props) => (props.vertical ? '0' : props.padding || '10')}px;
  padding-bottom: 10px;
  border-bottom: ${(props) => (props.selected ? '2px solid #3959BC' : 'unset')};
  @media (max-width: ${breakpoint.tablet}) {
    width: ${(props) => (props.fullWidth ? 'calc(100vw - 20px)' : 'initial')};
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-around;
    align-items: flex-start;
  }
  @media (max-width: ${breakpoint.bigtablet}) {
    padding: ${(props) => props.padding || '0'};
  }
  @media (max-width: ${breakpoint.mobile}) {
    align-items: start;
    margin: 0 auto;
  }
`
const MenuTitle = styled.p`
  color: #3959bc;
  font-weight: 900;
  line-height: 19px;
  @media (max-width: ${breakpoint.tablet}) {
    padding: 0px 40px 15px 40px;
  }
`

const MenuLink = styled.h4`
  cursor: pointer;
  color: ${(props) =>
    props.active && !props.underline
      ? props.theme.primary
      : props.color || props.theme.kfcBlack};
  border-bottom: ${(props) =>
    props.underline && props.active
      ? `2px solid ${props.theme.primary}`
      : 'none'};
  white-space: nowrap;
  text-transform: capitalize;
  &:hover {
    opacity: 0.5;
  }
  @media (max-width: ${breakpoint.bigtablet}) {
    padding: 0 40px;
  }
  @media (max-width: ${breakpoint.mobile}) {
    padding: 7px 40px;
    font-weight: ${(props) => (props.bolded ? '700' : 'none')};
  }
`

export const NeutralLink2 = styled(Link)`
  color: inherit;
  text-decoration: inherit;
  &:hover {
    text-decoration: inherit;
  }
`

export const renderIcon = (
  name,
  size = 25,
  color,
  small,
  showImages,
  imageData,
) =>
  !!showImages ? (
    <NeutralLink2
      rel="noreferrer noopener"
      href={imageData.url}
      target={imageData.target}
    >
      <img src={imageData.image} width={`${imageData.iconSize}px`} alt={name} />
    </NeutralLink2>
  ) : (
    <>
      {name === 'babyChanging' && (
        <BabyChanging width={small ? `18px` : `${size}px`} color={color} />
      )}
      {name === 'chevronDown' && <ChevronDown size={small ? 18 : size} />}
      {name === 'chevronUp' && <ChevronUp size={small ? 18 : size} />}
      {name === 'chevronLeft' && <ChevronLeft size={small ? 18 : size} />}
      {name === 'chevronRight' && <ChevronRight size={small ? 18 : size} />}
      {name === 'clock' && <Clock size={small ? 18 : size} />}
      {name === 'collect' && (
        <Collect width={small ? `18px` : `${size}px`} color={color} />
      )}
      {name === 'colonelClub' && (
        <ColonelClub width={small ? `18px` : `${size}px`} color={color} />
      )}
      {name === 'deliveryBike' && (
        <DeliveryBike width={small ? '18px' : `${size}px`} color={color} />
      )}
      {name === 'deliveryTick' && (
        <DeliveryTick width={small ? '18px' : `${size}px`} color={color} />
      )}
      {name === 'orderReceieved' && (
        <OrderReceieved width={small ? '18px' : `${size}px`} color={color} />
      )}
      {name === 'disability' && (
        <Disability width={small ? '18px' : `${size}px`} color={color} />
      )}
      {name === 'drive' && (
        <Drive width={small ? '18px' : `${size}px`} color={color} />
      )}
      {name === 'express' && (
        <Express width={small ? '18px' : `${size}px`} color={color} />
      )}
      {name === 'facebook' && <Facebook width={small ? `18px` : `${size}px`} />}
      {name === 'facebookSquare' && <FacebookSquare size={32} />}
      {name === 'foodCourt' && (
        <FoodCourt width={small ? `18px` : `${size}px`} color={color} />
      )}
      {name === 'halal' && (
        <Halal width={small ? `18px` : `${size}px`} color={color} />
      )}
      {name === 'instagram' && (
        <Instagram width={small ? `18px` : `${size}px`} />
      )}
      {name === 'menu' && <Hamburger width={small ? `18px` : `${size}px`} />}
      {name === 'navigation' && <Navigation size={small ? 18 : size} />}
      {name === 'search' && (
        <MagnifyingGlass width={small ? `18px` : `${size}px`} />
      )}
      {name === 'shopping' && (
        <Basket color={color} width={small ? `18px` : `${size}px`} />
      )}
      {name === 'snapchat' && <Snapchat width={small ? `18px` : `${size}px`} />}
      {name === 'times' && <Cross width={small ? `18px` : `${size}px`} />}
      {name === 'twitter' && <Twitter width={small ? `18px` : `${size}px`} />}
      {name === 'user' && (
        <Account color={color} width={small ? `18px` : `${size}px`} />
      )}
      {name === 'youtube' && <Youtube width={small ? `18px` : `${size}px`} />}
      {name === 'wifi' && (
        <Wifi width={small ? `18px` : `${size}px`} color={color} />
      )}
      {name === 'tick' && (
        <Tick width={small ? `18px` : `${size}px`} color={color} />
      )}
      {name === 'plus' && (
        <Plus width={small ? `18px` : `${size}px`} color={color} />
      )}
      {name === 'videoplay' && (
        <Play width={small ? `18px` : `${size}px`} color={color} />
      )}
      {name === 'company' && (
        <Buildings width={small ? `18px` : `${size}px`} color={color} />
      )}
      {name === 'receiptLong' && (
        <ReceiptLong width={small ? '18px' : `${size}px`} color={color} />
      )}
    </>
  )

const renderText = (
  id,
  label,
  small,
  active,
  underline,
  target,
  stopPropogation,
  bolded,
) =>
  id ? (
    <NeutralLink2
      rel="noreferrer noopener"
      target={target ? target : '_self'}
      onClick={stopPropogation}
      to={`/${id}`}
      key={id}
    >
      {small ? (
        <P3 key={id}>{label}</P3>
      ) : (
        <MenuLink
          bolded={bolded}
          active={active}
          key={id}
          underline={underline}
        >
          {label}
        </MenuLink>
      )}
    </NeutralLink2>
  ) : (
    <>
      {small ? (
        <P3 key={id}>{label}</P3>
      ) : (
        <MenuLink
          bolded={bolded}
          active={active}
          key={id}
          underline={underline}
        >
          {label}
        </MenuLink>
      )}
    </>
  )

const renderTextAction = (id, label, handler, underline) => (
  <MenuLink key={id} underline={underline} onClick={() => handler()}>
    {label}
  </MenuLink>
)

const MenuItem = ({
  item,
  active,
  small,
  underline,
  color,
  target,
  showImages,
  stopPropogation,
  padding,
  bolded,
}) => {
  if (item.type === 'icon')
    return renderIcon(item.icon, item.iconSize, color, small, showImages, item)
  else if (item.handler)
    return renderTextAction(item.id, item.label, item.handler, underline)
  else
    return renderText(
      item.id,
      item.label,
      small,
      active,
      underline,
      target,
      stopPropogation,
      bolded,
    )
}

const MenuDropdown = ({ props, setOpenDropdown, openDropdown }) => {
  const dropdownMenu = useRef(null)
  useEffect(() => {
    const handleClick = (e) => {
      if (
        dropdownMenu.current &&
        !dropdownMenu.current.contains(e.target) &&
        !dropdownMenu.current.parentElement?.contains(e.target)
      ) {
        setOpenDropdown(false)
      }
    }

    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [openDropdown])

  const { search } = useLocation()

  return (
    <SubmenuDropdown ref={dropdownMenu}>
      {props.map((page, i) => (
        <Link
          to={`${page.id}${search}`}
          style={{ textDecoration: 'none' }}
          key={i}
        >
          <SubPageWrapper>
            <h4>
              <span className="label">{page.label}</span>
              <span>
                <ChevronRight size={20} />
              </span>
            </h4>
          </SubPageWrapper>
        </Link>
      ))}
    </SubmenuDropdown>
  )
}

const SubmenuDropdown = styled.div`
  background: white;
  width: 200px;
  padding: 12px;
  height: max-content;
  position: absolute;
  top: 30px;
  border: 1px solid rgba(228, 0, 43, 1);
  border-radius: 10px;
`

const SubPageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover .label {
    opacity: 0.5;
  }
`

export const Menu = ({
  data = [],
  activeItem = '',
  small = false,
  vertical = false,
  noPadding = false,
  underline,
  padding = 30,
  bolded,
  color,
  showImages,
  stopPropogation,
}) => {
  const [dropdownActive, setDropdownActive] = useState(false)
  const handleDropDownOn = () => setDropdownActive((prevState) => !prevState)
  return (
    <>
      {data.map((item, iter) => (
        <ListItem
          first={iter === 0}
          last={iter + 1 === data.length}
          vertical={vertical || noPadding}
          padding={padding}
          key={item.id}
          selected={window.location.pathname.includes(item.id)}
        >
          {item.type === 'dropdown' ? (
            <div style={{ position: 'relative' }}>
              <MenuLink onClick={handleDropDownOn}>{item.label}</MenuLink>
              {dropdownActive && (
                <MenuDropdown
                  props={item.subpages}
                  setOpenDropdown={setDropdownActive}
                  openDropdown={dropdownActive}
                />
              )}
            </div>
          ) : (
            <MenuItem
              stopPropogation={stopPropogation}
              showImages={showImages}
              small={small}
              item={item}
              active={activeItem === item.id}
              underline={underline}
              color={color}
              target={item.target}
              bolded={bolded}
            />
          )}
        </ListItem>
      ))}
    </>
  )
}

export const MobileMenu = ({
  data = [],
  small = false,
  vertical = false,
  noPadding = false,
  padding = 20,
}) => (
  <>
    {data.map((item, iter) => (
      <ListItem
        first={iter === 0}
        last={iter + 1 === data.length}
        vertical={vertical || noPadding}
        padding={padding}
        key={item.id}
        fullWidth
      >
        {item.label && <MenuTitle>{item.label}</MenuTitle>}
        {item.data.map((el) => (
          <MenuItem key={el.id} small={small} item={el} target={item.target} />
        ))}
        <Line color={'#A3C5ED'} />
      </ListItem>
    ))}
  </>
)

/**
 * ULList basic unordered list
 */

export const ULList = styled.ul`
  background-color: #fff;
  padding: 0;
  list-style: none;
  flex-direction: 'column';
  margin: 0px;
`

const UlListItem = styled.li`
  text-align: left;
`

const TextLink = styled.span`
  cursor: pointer;
  display: block;
  padding: ${(props) => (props.padding ? props.padding : '0')}px;
  &:hover {
    color: ${defaultTheme.primary};
  }
`

const NormalText = styled(ext(P3))`
  padding: ${(props) => (props.padding ? props.padding : '0')}px;
`

export const Line = styled.hr`
  width: 100%;
  border: 0.5px solid ${(props) => (props.color ? props.color : '#a00925')};
  @media (max-width: ${breakpoint.bigtablet}) {
    margin-top: 10px;
  }
`

export const ULListItems = ({ data = [], padding = '', onClick }) => (
  <>
    {data.map((item) => (
      <UlListItem key={item.id}>
        {onClick ? (
          <TextLink padding={padding} onClick={() => onClick(item)}>
            {item.label}
          </TextLink>
        ) : (
          <NormalText padding={padding}>{item.label}</NormalText>
        )}
      </UlListItem>
    ))}
  </>
)

/**
 * ULList end
 */

const SVGWrapper = styled.svg`
  margin-bottom: ${(props) => props.extraSpace || '0'}px;
  @media (max-width: ${breakpoint.tablet}) {
    margin-bottom: ${(props) => props.extraSpaceMobile || '0'}px;
  }
`

const Rect = styled.rect`
  fill: ${(props) => props.color || props.theme.primary};
  ${(props) => (props.selectable ? 'cursor: pointer;' : '')}
`

export const Dots = ({
  num = 3,
  width = 150,
  red = false,
  extraSpace = 0,
  extraSpaceMobile,
  activeItem,
  activeColor,
  passiveColor,
  onActiveClick,
}) => (
  <SVGWrapper
    viewport="0 0 100 30"
    width={width}
    height="20"
    extraSpace={extraSpace}
    extraSpaceMobile={extraSpaceMobile}
  >
    {[...Array(num)].map((item, iter) => (
      <Rect
        onClick={onActiveClick ? () => onActiveClick(iter) : () => {}}
        color={activeItem === iter ? activeColor : passiveColor}
        key={`rect${iter}`}
        width="15"
        height="10"
        x={`${iter * 30}`}
        y="0"
        selectable={onActiveClick ? true : false}
      />
    ))}
  </SVGWrapper>
)
