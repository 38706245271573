import React from 'react'
import { DefaultLayout } from '../components/utilities/DefaultLayout'
import { ErrorBoundary } from '../components/compositions/Error'
import { ChangePassword } from '../components/change-password-form'
import { DefaultLayoutNew } from '../components/utilities/DefaultLayoutNew'

function PasswordChange() {
  return (
    <DefaultLayoutNew pageType="viewer">
      <main>
        <ErrorBoundary>
          <ChangePassword />
        </ErrorBoundary>
      </main>
    </DefaultLayoutNew>
  )
}

export default PasswordChange
