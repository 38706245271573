import React from 'react'
import {
  StyledSpinner,
  Line,
  ProcessingWrapper,
  ProcessingLabel,
  Return,
  DateWrapper,
  DateLabel,
} from './styled'
import { FormLikeLayout, TitleLayout } from '../atomic/FormLikeLayout'
import { BoldSpan, P2 } from '../atomic/Typography'
import { languageData, getCurrentLanguage } from './../../languages/index'
import TripCardsProcessing from '../trip-cards-processing'
import { getDate, getHour } from '../../utils/rideUtils'
import { breakpoint } from '../../styles/global.style'

const lang = getCurrentLanguage()

export const Spinner = ({ size }) => (
  <StyledSpinner viewBox={`0 0 ${size} ${size}`} size={size}>
    <circle
      className="path"
      cx={`${size / 2}`}
      cy={`${size / 2}`}
      r={`${size * 0.4}`}
      fill="none"
      strokeWidth="4"
    />
  </StyledSpinner>
)

const dateToString = (date, locale) => {
  const myDate = new Date(date) // we are storing in milliseconds
  return myDate.toLocaleDateString(locale ?? 'default', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

const ProcessingRide = ({ reservation }) => {
  const { when, origin, destination, direction, whenReturn } = reservation
  const lang = getCurrentLanguage()
  const tabletScreen = parseInt(breakpoint.smallTablet)
  return (
    <FormLikeLayout
      justifyContent={window.innerWidth > tabletScreen ? 'start' : 'center'}
      radius={16}
      minWidth={600}
      noTopBorder
      alignItems={'start'}
      height={465}
    >
      <TitleLayout
        label={languageData[lang]['checkout.confirmation.reservation.title']}
      />
      <ProcessingWrapper>
        <ProcessingLabel>
          {languageData[lang]['checkout.processing.trip.label']}
        </ProcessingLabel>
        <Spinner size={40} />
      </ProcessingWrapper>
      {when && (
        <DateWrapper>
          <DateLabel>
            <BoldSpan>{getDate(when, lang)}</BoldSpan>
          </DateLabel>
          <BoldSpan>{getHour(when, lang)}</BoldSpan>
        </DateWrapper>
      )}
      <TripCardsProcessing from={origin} to={destination} />
      <Line />

      {direction === 'Return' && (
        <Return>
          <DateWrapper>
            <DateLabel>
              <BoldSpan>{getDate(whenReturn, lang)}</BoldSpan>
            </DateLabel>
            <BoldSpan>{getHour(whenReturn, lang)}</BoldSpan>
          </DateWrapper>
          <TripCardsProcessing from={destination} to={origin} />{' '}
        </Return>
      )}
    </FormLikeLayout>
  )
}

export default ProcessingRide
