import styled from 'styled-components'
import { breakpoint } from '../../styles/global.style'

export const SignInWrapper = styled.div`
  display: flex;
  position: relative;
`

export const SideImage = styled.div`
  flex: 1;
  max-width: 50%;
  width: 100%;
  background-image: url('/images/taxiSignBackground.png');
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.6);
  height: 800px;
  @media (max-width: ${breakpoint.medium}) {
    display: none;
  }
`

export const AroundFormWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LogoWrapper = styled.div`
  position: absolute;
  bottom: 2%;
  left: 3%;
`
