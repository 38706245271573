import { useRef } from 'react'
import BrandedWhyCircle from '../../components/branded-why-circle'
import CtaTwoColSimple from '../../components/cta-two-col-simple'
import FormDriveUs from '../../components/form-drive-with-us'
import MultiColMarketing from '../../components/multi-col-marketing'
import { DefaultLayoutNew } from '../../components/utilities/DefaultLayoutNew'
import { metaPage, pageData } from './data'
import { Wrapper } from './styled'
import { getCurrentLanguage } from '../../languages'
import InjectMeta from '../../components/utilities/InjectMeta'
import PageLoadAnimation from '../../styles/animations'

const PageForDrivers = () => {
  const formRef = useRef(null)

  const scrollIntoView = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const data = pageData()
  const lang = getCurrentLanguage()
  return (
    <>
      <InjectMeta meta={metaPage} lang={lang} />
      <PageLoadAnimation>
        <DefaultLayoutNew pageType="viewer">
          <Wrapper>
            <CtaTwoColSimple
              data={data.ctaSimpleHeroData}
              scrollIntoView={scrollIntoView}
            />
            <BrandedWhyCircle data={data.pageCircleData} />
            <FormDriveUs data={data.formDriveData} formRef={formRef} />
            <MultiColMarketing data={data.multiCtaData} />
          </Wrapper>
        </DefaultLayoutNew>
      </PageLoadAnimation>
    </>
  )
}

export default PageForDrivers
