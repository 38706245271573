import styled from 'styled-components'
import { breakpoint } from './../../styles/global.style'
import { Carousel as TheCarousel } from 'react-responsive-carousel'
import { ext } from '../../utils/utils'

export const Indicator = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

export const ChildrenWrap = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
`

export const ChildrenPos = styled.div`
  position: relative;
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
  flex: auto;
  @media (max-width: ${breakpoint.tablet}) {
    justify-content: center;
    align-items: center;
  }
`

export const CarouselWrapper = styled(ext(TheCarousel))`
  width: 100%;
`

export const MainImg = styled.img`
  position: absolute;
  opacity: 0;
`

export const OpacityBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, ${(props) => props.opacity || 0});
`

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;
`

export const VideoIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const IconStyle = styled.div`
  z-index: 50;
  color: white;
  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
`
