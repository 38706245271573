import { Link, useLocation } from 'react-router-dom'
import Button from '../buttons'
import * as S from './styled'

const BookTripBox = ({ data }) => {
  const { search } = useLocation()
  return (
    <S.Wrapper>
      <S.ButtonBox>
        <Link to={`/contact-us${search}`}>
          <Button full type="primary" label={data.buttonLabel} />
        </Link>
      </S.ButtonBox>
    </S.Wrapper>
  )
}

export default BookTripBox
