import { addMinutes, getMinutes } from 'date-fns'
import { addHours } from 'date-fns'
import { addDays, getHours, setHours, setMinutes } from 'date-fns'

export const handleWeightUpdate = (value, iter, oldvalue, update, error) => {
  if (value > 35) error('we only provide chairs up to 35kg')
  else error('')
  update(oldvalue.map((item, i) => (i === iter ? value : item)))
}

/**
 * This function filters proposed dates in the date-picker in such a way that
 * according to the actual time
 * - if actual time is less than 12 a.m. (i.e. morning),
 *   then make available dates starting from 8.00 am of the next day
 * - if actual time is less than 12 a.m. (i.e. afternoon),
 *   then make available dates starting from 8.00 am of the day after the next day
 * @param {Date} time - the time to be checked
 * @returns boolean
 */
export const filterSelectableDates = (time) => {
  const currentDate = new Date()
  const selectedDate = new Date(time)

  return getHours(currentDate) < 12
    ? selectedDate.getTime() >=
        setHours(setMinutes(addDays(new Date(), 1), 59), 7).getTime()
    : selectedDate.getTime() >=
        setHours(setMinutes(addDays(new Date(), 2), 59), 7).getTime()
}

/**
 * Returns the minimum acceptable Date according to the actual time:
 * - if actual time is less than 12 a.m. return the day after
 * - if actual time is more than 12 a.m. return the day after the next day
 * @returns Date
 */
export const getMinDate = () => {
  const currentDate = new Date()
  return getHours(currentDate) < 12
    ? addDays(currentDate, 1)
    : addDays(currentDate, 2)
}

/**
 * Returns the minimum acceptable time (i.e., 8 a.m.)
 * with the logic presented in getMinDate
 * @returns Date
 */
export const getMinAcceptableTime = () =>
  setHours(setMinutes(getMinDate(), 0), 8)

export const getMinDateReturnTrip = (outwardTripDate) =>
  addHours(outwardTripDate, 1)

export const getMinAcceptableTimeReturnTrip = (outwardTripDate) =>
  getMinDateReturnTrip(outwardTripDate)

export const filterSelectableDatesReturnTrip = (outwardTripDate) => (time) => {
  const selectedDate = new Date(time)
  return selectedDate < addHours(outwardTripDate, 1)
}
