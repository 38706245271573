import * as S from './styled'
import Button from './../buttons'
import { useState } from 'react'
import { zapierApi, zapierEventTypes } from '../../utils/restAPIUtils'

const defaultFormValue = {
  name: '',
  email: '',
  message: '',
}

const FormContactUs = ({ data }) => {
  const [formValue, setFormValue] = useState(defaultFormValue)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const onSubmit = async () => {
    try {
      setSuccess(false)
      setError(false)
      setLoading(true)
      await zapierApi('get')(zapierEventTypes.contactUs)()({
        ...formValue,
        url: window.location.href,
      })((cb) => cb)
      setLoading(false)
      setSuccess(true)
      setFormValue(defaultFormValue)
    } catch (error) {
      setError(true)
      setLoading(false)
      console.error('Error sending webhook:', error)
    }
  }
  return (
    <S.Wrapper>
      <S.Title>{data.title}</S.Title>
      <S.Subtitle>{data.subtitle}</S.Subtitle>
      <S.Form>
        {data.form.inputs.map((input, i) => (
          <S.InputWrapper key={i}>
            <label htmlFor={input.id}>{input.label}</label>
            <S.Input
              value={formValue[input.id]}
              onChange={(e) =>
                setFormValue((prevState) => ({
                  ...prevState,
                  [input.id]: e.target.value,
                }))
              }
              id={input.id}
              type={input.type}
              placeholder={input.placeholder}
            />
          </S.InputWrapper>
        ))}
        <S.TextAreaWrapper>
          <label>{data.form.textArea.label}</label>
          <S.TextArea
            placeholder={data.form.textArea.placeholder}
            value={formValue[data.form.textArea.id]}
            onChange={(e) =>
              setFormValue((prevState) => ({
                ...prevState,
                [data.form.textArea.id]: e.target.value,
              }))
            }
          />
        </S.TextAreaWrapper>
      </S.Form>
      <Button
        type="secondary"
        label={data.button.label}
        onClick={onSubmit}
        disabled={loading}
      />
      <br />
      {success ? (
        <S.MessageWrapper>
          <div>{data.successMessage}</div>
        </S.MessageWrapper>
      ) : null}
    </S.Wrapper>
  )
}

export default FormContactUs
