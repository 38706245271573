import React, { useContext } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { AuthContext } from './../components/auth-context'
import { OrganizationProvider } from './../components/organization-context'

import { pages } from './data'

import languages, { populatePathWithLang } from './../languages'

const Router = () => {
  const { loadingAttributes, cognitoSession } = useContext(AuthContext)
  return (
    <BrowserRouter>
      <OrganizationProvider>
        <Routes>
          {languages.map((lang, langIdx) =>
            pages.map((page, index) =>
              !page.protected || cognitoSession || loadingAttributes ? (
                <Route
                  key={`${langIdx}${index}`}
                  path={populatePathWithLang(lang.route, page.link)}
                  element={<page.component lang={lang.value} />}
                />
              ) : (
                <Route
                  path="*"
                  element={
                    <Navigate to={`${lang.route}/account/login`} key={index} />
                  }
                />
              ),
            ),
          )}
        </Routes>
      </OrganizationProvider>
    </BrowserRouter>
  )
}
export default Router
