import BrandedWhyCircle from '../../components/branded-why-circle'
import CtaTwoColSimple from '../../components/cta-two-col-simple'
import { DefaultLayoutNew } from '../../components/utilities/DefaultLayoutNew'
import { metaPage, pageData } from './data'
import { Wrapper } from './styled'
import CtaCard from '../../components/cta-card'
import { getCurrentLanguage } from '../../languages'
import InjectMeta from '../../components/utilities/InjectMeta'
import PageLoadAnimation from '../../styles/animations'

const PageExperiences = () => {
  const data = pageData()
  const lang = getCurrentLanguage()
  return (
    <>
      <InjectMeta meta={metaPage} lang={lang} />
      <PageLoadAnimation>
        <DefaultLayoutNew pageType="viewer">
          <Wrapper>
            <CtaTwoColSimple data={data.ctaSimpleHeroData} />
            <CtaTwoColSimple data={data.ctaSimpleGetKnowData} />
            <BrandedWhyCircle data={data.pageCircleData} />
            <CtaTwoColSimple data={data.ctaSimpleTourData} />
            <CtaCard data={data.ctaCardData} />
          </Wrapper>
        </DefaultLayoutNew>
      </PageLoadAnimation>
    </>
  )
}
export default PageExperiences
