import styled from 'styled-components'
import { breakpoint } from '../../styles/global.style'
import * as T from './../atomic/Typography'

export const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  padding: 40px 24px;
  border: 1px solid #c4c4c4;
  width: 100%;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
  margin: 43px 0px 0px;
  box-sizing: border-box;
  @media (max-width: ${breakpoint.bigtablet}) {
    flex-wrap: wrap;
  }
`

export const Title = styled(T.Uh3)`
  font-weight: 800;
`

export const LeftImage = styled.img`
  max-width: 411px;
  width: 100%;
`

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const ParagraphWrapper = styled.div``

export const Button = styled.button`
  padding: 19px 36px;
  max-width: 197px;
  width: 100%;
  height: 59px;
  background: linear-gradient(270deg, #3959bc -10.65%, #2e417a 101.17%);
  border-radius: 10px;
  border: none;
  color: white;
  cursor: pointer;
`
