import { getCurrentLanguage, languageData } from './../../languages'

export const footerData = () => {
  const lang = getCurrentLanguage()
  return {
    menuLinks: [
      [
        {
          id: '',
          type: 'link',
          label: languageData[lang]['footer.new.link.0'],
          href: '/operation-coverage',
        },
        {
          id: 'who-we-are',
          type: 'text',
          label: languageData[lang]['footer.new.link.1'],
          href: '/who-we-are',
        },
        {
          id: 'whyLelecabs',
          type: 'text',
          label: languageData[lang]['footer.new.link.2'],
          href: '/why-lelecabs',
        },
        {
          id: 'experiences',
          type: 'text',
          label: languageData[lang]['footer.new.link.3'],
          href: '/experiences',
        },
      ],
      [
        {
          id: 'drive',
          type: 'link',
          label: languageData[lang]['footer.new.link.4'],
          href: '/for-drivers',
        },
        {
          id: 'contactUs',
          type: 'text',
          label: languageData[lang]['footer.new.link.5'],
          href: '/contact-us',
        },
        {
          id: 'faq',
          type: 'text',
          label: languageData[lang]['footer.new.link.6'],
          href: '/faq',
        },
      ],
      [
        {
          id: 'Terms',
          type: 'text',
          label: languageData[lang]['footer.new.link.7'],
          href: '/terms',
        },
        {
          id: 'privacy',
          type: 'text',
          label: languageData[lang]['footer.new.link.8'],
          href: '/privacy',
        },
      ],
    ],
    media: {
      logoSrc: 'logoSvg',
      logoAlt: 'Lelecabs Logo',
      logoHref: '/',
      instagramImgSrc: 'mdi_instagram',
      instagramHref: '',
      twitterImgSrc: 'mdi_twitter',
      twitterHref: '',
    },
    mobileQuickLinks: languageData[lang]['footer.mobile.quick.link'],
    copyrightText: languageData[lang]['footer.license.title'],
  }
}
