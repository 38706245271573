import styled from 'styled-components'
import { P1 } from './../atomic/Typography'
import { ext } from './../../utils/utils'
import { breakpoint } from './../../styles/global.style'

export const PWrapper = styled.div`
  max-width: 66%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${breakpoint.tablet}) {
    margin: 10px 0px;
  }
`

export const Paragraph = styled(ext(P1))`
  ${(props) =>
    props.hideMobile
      ? `@media(max-width: ${breakpoint.mobile}) {
      display: none;
    }`
      : ''}
`
