import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  max-width: 460px;
  justify-content: center;
`

export const ButtonBox = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  background: #ffffff;
  border: 1px solid #efefef;
  border-top: 9px solid #3959bc;
  button {
    background: linear-gradient(270deg, #3959bc -10.65%, #2e417a 101.17%);
    width: 100%;
    max-width: unset;
  }
  a {
    width: 100%;
  }
`
