import * as S from './styled'

import { TemporaryBanner } from '../../pages'
import RideFormNew from './../ride-form-new'
import { OuterWrapper } from './../super-mega-wrapper'

import { imagesFiles } from './../../styles/images'

const HeroRideForm = ({ data }) => (
  <OuterWrapper>
    <TemporaryBanner>{data.bannerText}</TemporaryBanner>
    <S.Wrapper background={imagesFiles[data.background]}>
      <RideFormNew />
    </S.Wrapper>
  </OuterWrapper>
)

export default HeroRideForm
