import styled from 'styled-components'
import * as T from './../atomic/Typography'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  padding: 32px 92px;
  background-color: ${(props) =>
    props.transparentBackground ? 'unset' : '#F5F5F5'};
  @media (max-width: ${breakpoint.smallTablet}) {
    padding: 2.5em 1.5em;
  }
`

export const Title = styled(T.Uh3)`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
  line-height: 35px;
  letter-spacing: 0.48px;
`

export const ColTitle = styled(T.Uh3)`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
  margin: 0;
  letter-spacing: 0.48px;
  @media (max-width: ${breakpoint.verylarge}) {
    font-size: 25px;
    line-height: 30px;
  }
  @media (max-width: ${breakpoint.medium}) {
    font-size: 1.8em;
  }
`

export const ColumnsWrapper = styled.div`
  display: flex;
  gap: 40px;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: ${breakpoint.medium}) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (min-width: ${breakpoint.verylarge}) {
    justify-content: space-evenly;
  }
`

export const SingleColumn = styled.div`
  max-width: 608px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Image = styled.img`
  margin-bottom: 8px;
  max-width: 100%;
`
