import BrandedWhyCircle from '../../components/branded-why-circle'
import CtaCard from '../../components/cta-card'
import CtaTwoColSimple from '../../components/cta-two-col-simple'
import CustomServiceOptions from '../../components/custom-service-options'
import MultiColMarketing from '../../components/multi-col-marketing'
import ShowWhenLoggedOut from './../../components/show-when-logged-out'
import { DefaultLayoutNew } from '../../components/utilities/DefaultLayoutNew'
import { metaPage, pageData } from './data'
import { Wrapper } from './styled'
import { getCurrentLanguage } from '../../languages'
import InjectMeta from '../../components/utilities/InjectMeta'
import PageLoadAnimation from '../../styles/animations'

const PageWhyLelecabs = () => {
  const data = pageData()
  const lang = getCurrentLanguage()
  return (
    <>
      <InjectMeta meta={metaPage} lang={lang} />
      <PageLoadAnimation>
        <DefaultLayoutNew pageType="viewer">
          <Wrapper>
            <CtaCard data={data.ctaCardData} />
            <CtaTwoColSimple data={data.ctaSimpleHeroData} />
            <BrandedWhyCircle data={data.pageCircleData} />
            <CustomServiceOptions data={data.customServiceData} />
            <MultiColMarketing data={data.multiCtaThreeData} />
            <ShowWhenLoggedOut>
              <CtaCard data={data.ctaCardSignData} />
            </ShowWhenLoggedOut>
          </Wrapper>
        </DefaultLayoutNew>
      </PageLoadAnimation>
    </>
  )
}

export default PageWhyLelecabs
