import { languageData, getCurrentLanguage } from './../../languages/index'

export const getData = () => {
  const lang = getCurrentLanguage()
  return [
    {
      component: 'title',
      title: languageData[lang]['login.title'],
      id: 'title',
    },
    {
      component: 'simpleSubtitle',
      id: 'subtitle',
      title: languageData[lang]['login.subittle'],
      marginBottom: '30',
    },
    // {
    //   component: 'googleButton',
    //   id: 'googleButton',
    // },
    {
      component: 'input',
      id: 'email',
      name: 'email',
      type: 'email',
      errorText: 'Invalid email',
      label: languageData[lang]['register.form.3'],
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'password',
      name: 'password',
      type: 'password',
      label: languageData[lang]['register.form.4'],
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'linkunderline',
      href: '/account/reset',
      label: languageData[lang]['login.forgotten-password'],
      margin: 20,
    },
    {
      component: 'errorSubtitle',
      id: 'goToAccountVerify',
      label: '',
      error: 'errorBox',
      buttonText: languageData[lang]['login.complete.verification.button'],
      errorSubtitle1:
        languageData[lang]['login.verification.nonCompleted.subtitle.1'],
      errorSubtitle2:
        languageData[lang]['login.verification.nonCompleted.subtitle.2'],
    },
    {
      component: 'button',
      id: 'action',
      full: true,
      type: 'secondary',
      label: languageData[lang]['login.button'],
    },
    {
      component: 'textwithLinkunderline',
      data: [
        {
          type: 'text',
          label: languageData[lang]['login.register-link.register.text'],
        },
        {
          type: 'linkunderline',
          href: '/account/register',
          label: languageData[lang]['login.register-link.register.link'],
        },
      ],
      id: 'linkwithtext',
    },
  ]
}
