import React from 'react'
import { PageFromJson } from '../components/utilities/MainContainer'
import { languageData, getCurrentLanguage } from './../languages/index'

const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Email Confirmation',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'confirmation',
      content: 'Email Confirmation.',
    },
  },
]

const getData = () => {
  const lang = getCurrentLanguage()
  return [
    {
      id: 'confirm-email',
      data: {
        message: [
          languageData[lang]['register.confirmation.email.content.0'],
          languageData[lang]['register.confirmation.email.content.1'],
        ],
        button: {
          label: languageData[lang]['register.confirmation.email.button'],
          type: 'secondary',
        },
      },
    },
  ]
}

function EmailConfirmation() {
  const components = getData()
  return <PageFromJson metaPage={metaPage} components={components} />
}

export default EmailConfirmation
