import { colors } from '../../styles/global.style'
import styled from 'styled-components'

export const StyledAvatar = styled.div`
  border: 0.5px solid ${colors.kfcBlack};
  box-sizing: border-box;
  border-radius: 50%;
  font-size: 12px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
