import styled from 'styled-components'
import * as T from '../atomic/Typography'
import { MagnifyingGlass } from '@styled-icons/entypo/MagnifyingGlass'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Title = styled(T.Uh3)`
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: normal;
`

export const Searchbar = styled.div`
  display: flex;
  height: 43px;
  padding: 12px;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 4px;
  border: ${(props) =>
    props.error
      ? '1px solid #C51918'
      : props.match
      ? '1px solid #219653'
      : '1px solid #D4D9EB'};
  background: #f7f7f8;
`

export const Input = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  &:focus {
    outline: none;
  }
`

export const InputIcon = styled(MagnifyingGlass)`
  color: rgba(128, 138, 174, 1);
`

export const Match = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #219653;
  background: #fcfcfc;
  padding: 12px;
  border-radius: 4px;
  margin-top: -20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 18px 0px;
`

export const MatchTitle = styled(T.P1)`
  margin-bottom: 14px;
  font-weight: 700;
`
