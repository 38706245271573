import ReactGA from 'react-ga4'
import { useEffect } from 'react'

import { ThemeProvider } from 'styled-components'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import Router from './routing/router'
import { zapierApi, zapierEventTypes } from './utils/restAPIUtils'
import { GlobalStyle, defaultTheme } from './styles/global.style'

import { Auth } from './components/auth-context'

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING)
}

const App = () => {
  useEffect(() => {
    const onerror = (event, source, lineno, colno, error) => {
      if (process.env.NODE_ENV === 'production') {
        zapierApi('get')(zapierEventTypes.onWindowError)()({
          message: `event: ${event}
          source: ${source}, at ${lineno}:${colno}
          ERROR: ${error}`,
          url: window.location.href,
        })((cb) => cb)
      }
    }
    window.onerror = onerror
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <Auth>
          <Router />
        </Auth>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
