import styled from 'styled-components'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  background-image: url('${(props) => props.background}');
  padding: 40px 106px 51px;
  min-height: 800px;
  background-size: cover;
  @media (max-width: ${breakpoint.large}) {
    padding: 40px 45px 51px;
    background-position: center;
  }
  @media (max-width: ${breakpoint.bigtablet6}) {
    background-position: 63%;
  }
  @media (max-width: ${breakpoint.smallTablet}) {
    padding: 0;
    background-image: none;
    min-height: auto;
  }
  @media (min-width: ${breakpoint.large}) {
    background-size: cover;
    background-position: center center;
  }
`
