import * as S from './styled'

const ColumnsNoticeBox = ({ data }) => {
  return (
    <S.Wrapper>
      <S.Title>{data.title}</S.Title>
      <S.ColumnsWrapper>
        {data.columns.map((column, i) => (
          <S.Column key={i}>
            <S.ColumnTitle>{column.title}</S.ColumnTitle>
            <S.UnorderedList>
              {column.lists.map((list, y) => (
                <S.ListItem key={y}>{list}</S.ListItem>
              ))}
            </S.UnorderedList>
          </S.Column>
        ))}
      </S.ColumnsWrapper>
    </S.Wrapper>
  )
}

export default ColumnsNoticeBox
