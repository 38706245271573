import styled from 'styled-components'
import { Modal } from 'antd'
import * as T from './../atomic/Typography'

export const Wrapper = styled.div``
export const Title = styled(T.UH2asH1)`
  margin-bottom: 15px;
  padding-bottom: 20px;
`

export const Span = styled.span`
  font-weight: 800;
`

export const ParagraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 43px 0 0 0;
`

export const Input = styled.input``

export const Label = styled.label`
  font-size: 16px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
  justify-content: space-between;
  margin-top: 43px;
  button {
    padding: 0 10px;
    font-size: 14px;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 25px;
`

export const NameInput = styled.input`
  background: #f5f5f5;
  border-radius: 4px;
  border: 1px solid rgba(212, 217, 235, 1);
  padding: 12px;
  &:focus {
    outline: none;
  }
`

export const ModalComponent = styled(Modal).attrs((props) => ({
  className: props.className,
}))`
  & .ant-modal-content {
    border-radius: 20px;
    padding: 0px;
  }
  & .ant-modal-body {
    padding: 64px 40px;
  }
  & .anticon svg {
    display: none;
  }
`
