import * as S from './styled'
import * as T from './../atomic/Typography'
import { Link, useLocation } from 'react-router-dom'
// import Carousel, { Modal, ModalGateway } from 'react-images';
import { useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import InformationBox from '../information-box'
import { breakpoint } from '../../styles/global.style'

const ItineraryItem = ({ data }) => {
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const mobileScreen = parseInt(breakpoint.mobile)
  const { search } = useLocation()

  return (
    <S.Wrapper>
      <S.HeaderNavigation>
        <S.ItinerarySection href={data.navigation.itinerarySectionLink}>
          {data.navigation.itinerarySection}
        </S.ItinerarySection>
        {window.innerWidth > mobileScreen && <S.ChevronIconRight />}
        {window.innerWidth > mobileScreen && (
          <S.PageName>{data.title}</S.PageName>
        )}
        <S.Stops>
          {data.navigation.previousStepLink && (
            <Link to={`${data.navigation.previousStepLink}${search}`}>
              <S.ChevronIconLeft />
            </Link>
          )}
          {window.innerWidth > mobileScreen &&
            data.navigation.previousStepLink && (
              <Link to={`${data.navigation.previousStepLink}${search}`}>
                <S.StopsTextPrevious>
                  {data.navigation.previousStopsText}
                </S.StopsTextPrevious>
              </Link>
            )}
          {window.innerWidth > mobileScreen && data.navigation.nextStepLink && (
            <Link to={`${data.navigation.nextStepLink}${search}`}>
              <S.StopsTextNext>{data.navigation.nextStopsText}</S.StopsTextNext>
            </Link>
          )}

          {data.navigation.nextStepLink && (
            <Link to={`${data.navigation.nextStepLink}${search}`}>
              <S.ChevronIconRight />
            </Link>
          )}
        </S.Stops>
      </S.HeaderNavigation>
      <S.GalleryWrapper>
        <S.OpenGallery
          onClick={() => setViewerIsOpen(true)}
        >{`${data.photoAlbumText} (${data.photos.length})`}</S.OpenGallery>
        <S.GalleryComponentH>
          {data.photos.map((photo, k) => (
            <S.GridImage
              src={photo.src}
              rowStart={photo.rowStart}
              rowEnd={photo.rowEnd}
              columnEnd={photo.columnEnd}
              columnStart={photo.columnStart}
              secondaryImage={k > 0}
            />
          ))}
        </S.GalleryComponentH>
        <>
          {viewerIsOpen ? (
            <S.ModalComponent
              visible={viewerIsOpen}
              footer={null}
              closable={false}
              centered
              onCancel={() => setViewerIsOpen(false)}
              destroyOnClose={true}
              maskClosable={true}
              // onCancel={setOpen}
              width="60%"
              maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
            >
              <Carousel>
                {data.photosGalleryFull.map((full, b) => (
                  <div>
                    <img src={full.src} key={b} alt="dwa" />
                  </div>
                ))}
              </Carousel>
            </S.ModalComponent>
          ) : null}
        </>
      </S.GalleryWrapper>
      <S.DescriptionWrapper>
        <S.Title>{data.title}</S.Title>
        {data.paragraphs.map((text, i) => (
          <T.P1 key={i}>{text}</T.P1>
        ))}
        <S.ExperienceWrapper>
          <S.ExperienceTitle>{data.experienceTitle}</S.ExperienceTitle>
          {data.rows.map((row, y) => (
            <S.Row key={y}>
              <S.RowTitle>{row.title}</S.RowTitle>
              <S.RowParagrpah>{row.paragraph}</S.RowParagrpah>
            </S.Row>
          ))}
        </S.ExperienceWrapper>
      </S.DescriptionWrapper>
      {data.infoBox && <InformationBox data={data.infoBox.data} />}
    </S.Wrapper>
  )
}

export default ItineraryItem
