import * as S from './styled'
import * as T from '../atomic/Typography'
import Button from './../buttons'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { postApi } from '../../utils/restAPIUtils'
import { isValidEmail } from '../../utils/textUtils'

const FormDriveUs = ({ data, formRef }) => {
  const [formValue, setFormValue] = useState({
    name: '',
    email: '',
  })
  const router = useNavigate()
  const [error, setError] = useState([])
  const [loading, setLoading] = useState(false)

  const submitForm = async (value, router, setError) => {
    setError([])
    setLoading(true)
    if (!isValidEmail(value.email)) {
      setError([`main.Please insert a valid e-mail.`])
      setLoading(false)
      return
    }
    try {
      await postApi('drivers')({
        Accept: 'application/json',
        'Content-type': 'application/json',
      })(value)((cb) => cb)
      router('/drive/confirm-registration', {
        state: { previousPath: window.location.pathname },
      })
    } catch (err) {
      setError(['main.Something went wrong'])
    }
    setLoading(false)
  }
  return (
    <S.Wrapper ref={formRef}>
      <S.LeftWrapper>
        <S.Title>{data.title}</S.Title>
        <S.Steps>
          {data.steps.map((step, i) => (
            <S.SingleStep key={i}>
              <S.StepText>{step.stepNum}</S.StepText>
              <S.StepTitle>{step.title}</S.StepTitle>
              <T.P2>{step.subtitle}</T.P2>
            </S.SingleStep>
          ))}
        </S.Steps>
      </S.LeftWrapper>
      <S.RightForm>
        <S.FormTitle>{data.form.title}</S.FormTitle>
        <S.Subtitle>{data.subtitle}</S.Subtitle>
        <S.Form>
          {data.form.inputs.map((input, i) => (
            <S.InputWrapper key={i}>
              <label htmlFor={input.id}>{input.label}</label>
              <S.Input
                value={formValue[input.id]}
                onChange={(e) =>
                  setFormValue((prevState) => ({
                    ...prevState,
                    [input.id]: e.target.value,
                  }))
                }
                id={input.id}
                type={input.type}
                placeholder={input.placeholder}
              />
            </S.InputWrapper>
          ))}
        </S.Form>
        <Button
          type="secondary"
          label={data.form.button.label}
          full
          onClick={async () => submitForm(formValue, router, setError)}
          loading={loading}
        />
      </S.RightForm>
    </S.Wrapper>
  )
}

export default FormDriveUs
