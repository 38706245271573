import styled from 'styled-components'
import * as T from './../atomic/Typography'
import { breakpoint, defaultTheme } from '../../styles/global.style'
import { Modal } from 'antd'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  a {
    color: #2f80ed;
    text-decoration: none;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 15px;
`

export const Title = styled(T.Uh2)`
  text-align: left;
  /* border-bottom: 3px solid ${defaultTheme.primary}; */
`

export const GuestModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalComponent = styled(Modal).attrs((props) => ({
  className: props.className,
}))`
  & .ant-modal-content {
    border-radius: 20px;
    padding: 0px;
  }
  & .ant-modal-body {
    padding: ${(props) => (props.login ? '0 0 40px 0' : '60px 40px')};
  }
`
