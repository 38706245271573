import { RowWrapper, FromToCard, Circle, TimeLineWrapper } from './styled'
import * as T from './../atomic/Typography'

const TripCards = ({ from, to, flightCode }) => {
  return (
    <RowWrapper>
      <TimeLineWrapper>
        <FromToCard>
          <Circle />
          <p> {from} </p>
        </FromToCard>
        <FromToCard>
          <Circle last />
          <p> {to} </p>
        </FromToCard>
      </TimeLineWrapper>
      {flightCode && (
        <T.P2>
          <T.BoldSpan>Flight Code:</T.BoldSpan> {flightCode}
        </T.P2>
      )}
    </RowWrapper>
  )
}

export default TripCards
