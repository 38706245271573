import styled, { keyframes } from 'styled-components'
import { CarSide } from '@styled-icons/fa-solid'

export const AbsoluteWrapper = styled.div`
  position: ${(props) => (props.full ? 'absolute' : 'fixed')};
  width: ${(props) => (props.full ? '100vw' : '100%')};
  height: ${(props) => (props.full ? '100vh' : '100%')};
  background-color: #ddd;
  z-index: 100000;
  top: 0;
  left: ${(props) => (props.full ? '0' : 'unset')};
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
`

const startandmove = keyframes`
  0% {
    transform: translate(-120px);
    opacity: 0.1;
  }
  15% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(120px);
  }
`

export const CarMoving = styled(CarSide)`
  animation: ${startandmove} 1s ease-in-out infinite;
`
