import React, { Component, useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { colors, breakpoint, breakpointNum } from './../../styles/global.style'

// const getLocalImg = (url) => require(url);

const CommonSq = (props) => `
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 900px;
  min-width: 320px;
  position: relative;
  padding: ${props.padding || '0px'};
  height: ${props.height || '600px'};
  background-color: ${
    props.bgColor ? colors[props.bgColor] : props.transparent ? '' : 'white'
  };
  color: ${props.color ? colors[props.color] : props.theme.secondary};
  justify-content: ${props.justifyContent || 'center'};
  align-items: ${props.alignItems || 'center'};
  margin: 0px ${props.right ? '0px' : '15px'} ${
    props.spaced ? '30px' : '0px'
  } ${props.left ? '0px' : '15px'};
  @media(max-width: ${breakpoint.large}) {
    height: ${props.height || '550px'};
  }
  @media(max-width: ${breakpoint.medium}) {
    height: ${props.height || '475px'};
  }
  @media(max-width: ${breakpoint.bigtablet}) {
    height: ${props.height || '425px'};
    align-items: center;
  }
  @media(max-width: ${breakpoint.tablet}) {
    min-height: ${props.height || '500px'};
    min-width: 100%; 
    margin: 0px 0px 30px;
  }
  @media(max-width: ${breakpoint.mobile}) {
    min-height: ${props.height || '250px'};
    padding-top: 55px;
    padding-bottom: 55px;
  }
`
const CommonCar = (props) => `
  min-height: 483px;
  max-height: 725px;
  height: ${`${props.height || props.minHeight}px`};
  background-size: cover;
  background-position: center;
  @media(max-width: ${breakpoint.large}) {
    height: ${`${getCustomH(
      props.ar * breakpointNum.large,
      props.minHeight,
    )}px`};
    max-height: 550px;
  }
  @media(max-width: 1366px) {
    max-height: 550px;
  }
  @media(max-width: 1280px) {
    max-height: 550px;
  }
`

const SqBlurImg = styled.div`
  background-image: ${(props) =>
    props.blurried ? `url(${props.blurried})` : 'none'};
  filter: grayscale(1);
  ${(props) => CommonSq(props)}
`

const SqRealImg = styled.div`
  background-image: ${(props) =>
    props.higresimg ? `url(${props.higresimg})` : 'none'};
  ${(props) => CommonSq(props)}
  transition: background-image 15s ease-in-out;
`

const getCustomH = (computed, min) => (computed > min ? computed : min)

const CarRealImg = styled.div`
  background-image: url('${(props) => props.blurried}');
  ${(props) => CommonCar(props)}
  @media(max-width: ${breakpoint.tablet}) {
    height: ${(props) =>
      `${getCustomH(props.ar * breakpointNum.tablet, props.minHeight)}px`};
    background-image: url('${(props) => props.mobileBlurried || props.src}');
  }
`

const CarBlurImg = styled.div`
  background-image: url('${(props) => props.blurried}');
  ${(props) => CommonCar(props)}
  @media(max-width: ${breakpoint.tablet}) {
    height: ${(props) =>
      `${getCustomH(props.ar * breakpointNum.tablet, props.minHeight)}px`};
    background-image: url('${(props) =>
      props.mobileBlurried || props.blurried}');
  }
`

export const LazyBgSqImg = (props) => {
  const [state, setState] = useState({
    loaded: false,
    initLoad: false,
  })
  const { timeout, blurried, higresimg } = props

  useEffect(() => {
    const timer = setTimeout(() => setState({ initLoad: true }), timeout || 50)
    return () => clearTimeout(timer)
  }, [timeout])

  return (
    <>
      {/* first the blurried image */}
      {!state.initLoad && <SqBlurImg src={blurried} {...props} />}
      {state.initLoad && (
        <SqRealImg
          onLoad={() => setState({ loaded: true })}
          src={higresimg}
          {...props}
        />
      )}
    </>
  )
}

export class LazyBgCarouselImg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      initLoad: false,
    }
  }
  componentDidMount() {
    // loading only on client size
    setTimeout(
      () => this.setState({ initLoad: true }),
      this.props.timeout || 50,
    )
  }

  render() {
    return (
      <>
        {/* first the blurried image */}
        {!this.state.initLoad && (
          <CarBlurImg src={this.props.blurried} {...this.props} />
        )}
        {this.state.initLoad && (
          <CarRealImg
            onLoad={() => this.setState({ loaded: true })}
            src={this.props.higresimg}
            {...this.props}
          />
        )}
      </>
    )
  }
}
