import React, { useEffect, useState } from 'react'
import { Spinner } from '../processing-ride'
import TooltipElement from '../tooltip'
import { Loader } from '@googlemaps/js-api-loader'
import { LuggageCart } from '@styled-icons/fa-solid'
import { EventSeat } from '@styled-icons/material'
import accounting from 'accounting-js'
import { addDays } from 'date-fns/fp'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'

import { DateTimePicker } from '@mui/x-date-pickers'
import './react-datepicker.css'

import { Note, P2 } from '../typography'
import { services } from './data'
import * as S from './styled'

import { useLocation } from 'react-router-dom'
import { renderIcon } from '../atomic/Layout'
import { getCurrentLanguage, languageData } from './../../languages/index'
import {
  filterSelectableDates,
  filterSelectableDatesReturnTrip,
  getMinAcceptableTime,
  getMinAcceptableTimeReturnTrip,
  getMinDate,
  getMinDateReturnTrip,
  handleWeightUpdate,
} from './helpers'
import { breakpoint } from '../../styles/global.style'

const searchParams = new URLSearchParams(window.location.search)
const isEditing = searchParams.get('edit') === 'true'
const { REACT_APP_GOOGLE_KEY } = process.env

export const Checkbox = ({
  name,
  value,
  label,
  hasError,
  errorText,
  onChange,
  margin,
  width,
}) => (
  <S.CheckboxWrap margin={margin} width={width}>
    <S.LabelWrapperCheckbox hasError={hasError}>
      <S.StyledCheckbox
        type="checkbox"
        hasError={hasError}
        name={name}
        value={value}
        checked={value}
        onChange={onChange}
      />
      {label}
    </S.LabelWrapperCheckbox>
    {hasError && errorText && <S.FormError>{errorText}</S.FormError>}
  </S.CheckboxWrap>
)

const Label = ({ label, required, hasError, readOnly, optional }) => {
  const inputData = getData()
  return (
    <S.LabelFlexWrapper>
      <S.LabelText readOnly={readOnly} hasError={hasError}>
        {label}
        {required && <S.Required>*</S.Required>}
      </S.LabelText>
      {optional && (
        <S.OptionalLabelText>
          {inputData.data.tripOption.optional}
        </S.OptionalLabelText>
      )}
    </S.LabelFlexWrapper>
  )
}

export const Input = ({
  name,
  errorText,
  hasError = false,
  settings = {},
  width,
  value,
  onChange,
  onIconClick,
  withBorder,
  label,
  required,
  margin,
  withSelect,
  type,
  readOnly,
  tabIndex,
  optional,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <>
      <S.LabelWrapper
        hasError={hasError}
        withSelect={withSelect}
        width={width}
        exceptMobile={true}
        margin={margin}
      >
        {label && (
          <Label
            readOnly={readOnly}
            hasError={hasError}
            label={label}
            required={required}
            optional={optional}
          />
        )}
        {name === 'phone_number' ? (
          <S.StyledPhoneInput
            international
            defaultCountry="IT"
            placeholder="Enter phone number"
            value={value}
            onChange={(e) => onChange({ target: { value: e ?? '' } })}
            hasError={hasError}
            withBorder={withBorder}
          />
        ) : type === 'password' ? (
          <S.PasswordInputWrapper>
            <S.StyledInput
              tabIndex={tabIndex}
              readOnly={readOnly}
              type={!showPassword ? type : 'text'}
              hasError={hasError}
              name={name}
              // withBorder={withBorder}
              hasIcon={settings.icon}
              width={width}
              placeholder={settings.mobilePlaceholder || settings.placeholder}
              value={value}
              onChange={onChange}
              min={settings.min}
              max={settings.max}
            />
            <div onClick={() => setShowPassword((prevState) => !prevState)}>
              {showPassword ? (
                <S.EyeIcon size={25} />
              ) : (
                <S.EyeIconOff size={25} />
              )}
            </div>
          </S.PasswordInputWrapper>
        ) : (
          <S.StyledInput
            tabIndex={tabIndex}
            readOnly={readOnly}
            type={type}
            hasError={hasError}
            name={name}
            withBorder={withBorder}
            hasIcon={settings.icon}
            width={width}
            placeholder={settings.mobilePlaceholder || settings.placeholder}
            value={value}
            onChange={onChange}
            min={settings.min}
            max={settings.max}
          />
        )}

        {settings.icon && (
          <S.InputIcon onClick={onIconClick}>
            {renderIcon(settings.icon, 24)}
          </S.InputIcon>
        )}
        {errorText && hasError && (
          <S.FormError margin={margin}>{errorText}</S.FormError>
        )}
      </S.LabelWrapper>
      <S.LabelWrapper
        hasError={hasError}
        withSelect={withSelect}
        width={width}
        mobileOnly={true}
        margin={margin}
      >
        {label && (
          <Label
            readOnly={readOnly}
            hasError={hasError}
            label={label}
            required={required}
          />
        )}
        {name === 'phone_number' ? (
          <S.StyledPhoneInput
            international
            defaultCountry="IT"
            placeholder="Enter phone number"
            value={value}
            onChange={(e) => onChange({ target: { value: e ?? '' } })}
            hasError={hasError}
            withBorder={withBorder}
          />
        ) : (
          <S.StyledInput
            tabIndex={tabIndex}
            readOnly={readOnly}
            type={type}
            hasError={hasError}
            name={name}
            withBorder={withBorder}
            hasIcon={settings.icon}
            width={width}
            placeholder={settings.mobilePlaceholder || settings.placeholder}
            value={value}
            onChange={onChange}
            min={settings.min}
            max={settings.max}
          />
        )}
        {settings.icon && (
          <S.InputIcon onClick={onIconClick}>
            {renderIcon(settings.icon, 24)}
          </S.InputIcon>
        )}
        {errorText && hasError && (
          <S.FormError margin={margin}>{errorText}</S.FormError>
        )}
      </S.LabelWrapper>
    </>
  )
}

export const DateHourPicker = ({ margin, value, handler, id }) => {
  const formData = getData()
  const { state = {} } = useLocation()
  const { trip = {} } = state || {}

  useEffect(() => {
    if (isEditing && trip.when && trip.originTimeZone) {
      const originTZEditing = new Date(
        new Date(trip.when).toLocaleString('sv', {
          timeZone: trip.originTimeZone,
        }),
      )
      handler(id, originTZEditing)
      return
    }
    if (isEditing && trip.when) {
      const valueToDate = new Date(trip.when)
      handler(id, valueToDate)
      return
    }
  }, [])

  return (
    <S.DateWrapper margin={margin} selected={value}>
      <S.LabelWrapper>
        <Label label={formData.data.datePicker.label} />
        <DateTimePicker
          value={value}
          format="dd/MM/yyyy HH:mm"
          onChange={(date) =>
            filterSelectableDates(date)
              ? handler(id, date)
              : handler(id, getMinAcceptableTime())
          }
          onKeyDown={(e) => e.preventDefault()}
          ampm={false}
          slotProps={{
            textField: {
              readOnly: true,
            },
          }}
          minDate={getMinDate()}
          maxDate={addDays(90, Date.now())}
          closeOnSelect
        />
      </S.LabelWrapper>
    </S.DateWrapper>
  )
}

export const DirectionSelect = ({ value, id, handler }) => {
  const inputData = getData()
  const lang = getCurrentLanguage()

  const translatedValue =
    value === 'Return'
      ? languageData[lang]['home.hero.form.return.direction']
      : languageData[lang]['home.hero.form.one.direction']

  return (
    <S.DirectionSelect>
      {inputData.data.tripOption.options.map((option, i) => (
        <S.DirectionTab
          selected={translatedValue === option}
          key={i}
          onClick={() => {
            handler(id, option)
          }}
        >
          {option}
        </S.DirectionTab>
      ))}
    </S.DirectionSelect>
  )
}

export const DropdownReturnTripSelect = ({
  returnFlightCodeId,
  dateId,
  dropdownId,
  flightCodeId,
  value,
  handler,
  returnDateValue,
  outwardDateValue,
  dateHourPickerConfig,
}) => {
  const inputData = getData()
  const { state = {} } = useLocation()
  const { trip = {} } = state || {}
  const outwardDate = new Date(outwardDateValue ?? 0)
  const lang = getCurrentLanguage()
  const mobileScreen = parseInt(breakpoint.mobile)

  const FlightCodeLabel = ({ label }) => (
    <S.FlightCodeLabel>
      {label}
      <TooltipElement
        id={'flight-code-tooltip'}
        content="For timely updates and<br />smoother travel, ensuring<br />accurate arrival<br />predictions for a hassle-<br />free airport experience."
        place="right"
        tooltipStyle={{ backgroundColor: '#3959BC', zIndex: 100 }}
        anchor={<S.InfoSVG size={16} />}
      />
    </S.FlightCodeLabel>
  )

  useEffect(() => {
    if (!outwardDateValue || !returnDateValue) return
    const outwardDate = new Date(outwardDateValue)
    const returnDate = new Date(returnDateValue)
    const isValidReturnTime = returnDate - outwardDate > 1_000 * 60 * 60 // one hour at least
    if (!isValidReturnTime)
      handler(dateId, getMinAcceptableTimeReturnTrip(outwardDate))
  }, [outwardDateValue])

  useEffect(() => {
    if (trip.flightCode) {
      handler(flightCodeId, trip.flightCode)
    }
    if (trip.returnFlightCode) {
      handler(returnFlightCodeId, trip.returnFlightCode)
    }
  }, [])

  useEffect(() => {
    if (isEditing && trip.whenReturn && trip.destinationTimeZone) {
      const destinationTZEditing = new Date(
        new Date(trip.whenReturn).toLocaleString('sv', {
          timeZone: trip.destinationTimeZone,
        }),
      )
      handler(dateId, new Date(destinationTZEditing))
    }

    if (isEditing && trip.whenReturn) {
      handler(dateId, new Date(trip.whenReturn)),
        handler(dropdownId, trip.direction)
    }
  }, [])
  return (
    <S.DropdownParentWrapper>
      <S.DropdownWrapper
        flexDirection={
          value.dropdown === 'Return' && window.innerWidth > mobileScreen
            ? 'row'
            : 'column'
        }
        gap={
          value.dropdown === 'Return' && window.innerWidth > mobileScreen
            ? '8px'
            : '16px'
        }
      >
        <DateHourPicker {...dateHourPickerConfig} />
        <Input
          withBorder
          width={'100%'}
          label={
            <FlightCodeLabel label={inputData.data.tripOption.flightNumber} />
          }
          optional
          value={value.flightCode}
          onChange={(e) => handler(flightCodeId, e.target.value)}
        />
      </S.DropdownWrapper>
      {value.dropdown === 'Return' && (
        <S.FlexWrapper
          flexDirection={window.innerWidth > mobileScreen ? 'row' : 'column'}
          gap={window.innerWidth > mobileScreen ? '8px' : '16px'}
        >
          <S.LabelWrapper minWidth="50%">
            <Label label={languageData[lang]['home.form.return.date']} />
            <DateTimePicker
              format="dd/MM/yyyy HH:mm"
              value={returnDateValue}
              onChange={(date) => handler(dateId, date)}
              disabled={!outwardDateValue}
              onKeyDown={(e) => e.preventDefault()}
              ampm={false}
              minDate={getMinDateReturnTrip(outwardDate)}
              maxDate={addDays(60, Date.now())}
              shouldDisableTime={filterSelectableDatesReturnTrip(outwardDate)}
              closeOnSelect
            />
          </S.LabelWrapper>
          <Input
            width="100%"
            withBorder
            label={
              <FlightCodeLabel
                label={inputData.data.tripOption.flightNumberReturn}
              />
            }
            optional
            value={value.flightReturnCode}
            onChange={(e) => handler(returnFlightCodeId, e.target.value)}
          />
        </S.FlexWrapper>
      )}
    </S.DropdownParentWrapper>
  )
}

export const Peoples = ({ margin, adults, childs, infants, handler, id }) => (
  <>
    <S.PeopleWrapper margin={10}>
      <S.ColH4>Adult</S.ColH4>
      <S.ColH4>
        Child
        <br />
        (2-12)
      </S.ColH4>
      <S.ColH4>
        Infant
        <br />
        {'(\u003C 2)'}
      </S.ColH4>
    </S.PeopleWrapper>
    <S.PeopleWrapper margin={10}>
      <S.ColG>
        <S.RoundButton
          disabled={adults === 1}
          onClick={() => handler(`${id}adults`, adults - 1)}
        >
          -
        </S.RoundButton>
        <S.PeopleNum>{adults}</S.PeopleNum>
        <S.RoundButton
          disabled={adults + childs + infants === 8}
          onClick={() => handler(`${id}adults`, adults + 1)}
        >
          +
        </S.RoundButton>
      </S.ColG>
      <S.ColG>
        <S.RoundButton
          disabled={childs === 0}
          onClick={() => handler(`${id}childs`, childs - 1)}
        >
          -
        </S.RoundButton>
        <S.PeopleNum>{childs}</S.PeopleNum>
        <S.RoundButton
          disabled={adults + childs + infants === 8}
          onClick={() => handler(`${id}childs`, childs + 1)}
        >
          +
        </S.RoundButton>
      </S.ColG>
      <S.ColG>
        <S.RoundButton
          disabled={infants === 0}
          onClick={() => handler(`${id}infants`, infants - 1)}
        >
          -
        </S.RoundButton>
        <S.PeopleNum>{infants}</S.PeopleNum>
        <S.RoundButton
          disabled={adults + childs + infants === 8}
          onClick={() => handler(`${id}infants`, infants + 1)}
        >
          +
        </S.RoundButton>
      </S.ColG>
    </S.PeopleWrapper>
    <S.PeopleWrapper margin={margin}>
      <S.ColG>
        {Array(adults)
          .fill(1)
          .map((num, iter) => (
            <S.StyledMan key={`adults${iter}`} size={40} />
          ))}
      </S.ColG>
      <S.ColG>
        {Array(childs)
          .fill(1)
          .map((num, iter) => (
            <S.StyledMan top={10} key={`childs${iter}`} size={30} />
          ))}
      </S.ColG>
      <S.ColG>
        {Array(infants)
          .fill(1)
          .map((num, iter) => (
            <S.StyledMan top={20} key={`infants${iter}`} size={20} />
          ))}
      </S.ColG>
    </S.PeopleWrapper>
  </>
)

const getData = () => {
  const lang = getCurrentLanguage()
  return {
    id: 'formData',
    data: {
      placeholder: {
        address: languageData[lang]['home.hero.form.from.placeholder'],
      },
      datePicker: {
        label: languageData[lang]['home.hero.form.title'],
      },
      tripOption: {
        label: 'Trip',
        options: [
          languageData[lang]['home.hero.form.one.direction'],
          languageData[lang]['home.hero.form.return.direction'],
        ],
        flightNumber: languageData[lang]['home.hero.form.flight.code.label'],
        flightNumberReturn:
          languageData[lang]['home.hero.form.flight.code.return.label'],
        optional: languageData[lang]['home.hero.form.optional.label'],
        returnTime: languageData[lang]['home.hero.form.return.time.label'],
      },
    },
  }
}

export const GeoInput = (props) => {
  const { state = {} } = useLocation()
  const { trip = {} } = state || {}

  const [address, setAddress] = useState({
    to: trip.destination ? trip.destination : '',
    from: trip.origin ? trip.origin : '',
  })
  const [, setCoordinates] = useState({
    lat: null,
    lng: null,
  })
  const [loadedMapsScript, setLoadedMapsScript] = useState(false)

  useEffect(() => {
    const loader = new Loader({
      apiKey: REACT_APP_GOOGLE_KEY,
      version: 'weekly',
      libraries: ['places'],
      id: '__googleMapsScriptId',
    })

    loader.loadCallback((e) => {
      if (e) {
        console.log(e)
      } else {
        if (!loadedMapsScript) setLoadedMapsScript(true)
      }
    })
  }, [loadedMapsScript, REACT_APP_GOOGLE_KEY])

  useEffect(() => {
    if (isEditing && trip) props.handler(props.id, address[props.id])

    if (!loadedMapsScript) {
      const loader = new Loader({
        apiKey: REACT_APP_GOOGLE_KEY,
        version: 'weekly',
        libraries: ['places'],
        id: '__googleMapsScriptId',
      })

      loader.loadCallback((e) => {
        if (e) {
          console.log(e)
        } else {
          if (!loadedMapsScript) setLoadedMapsScript(true)
        }
      })
    }
  }, [loadedMapsScript])

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value)
    const latLng = await getLatLng(results[0])
    setAddress((prevAddress) => ({
      ...prevAddress,
      [props.id]: value,
    }))
    setCoordinates(latLng)
    props.handler(props.id, value)
    props.handler(props.zoneCoverage, (prevState) => ({
      ...prevState,
      [props.id]: results,
    }))
  }

  const handleChange = (value) => {
    if (isEditing && props.id === 'from') return
    if (!value) props.handler(props.id, value)
    setAddress((prevAddress) => ({
      ...prevAddress,
      [props.id]: value,
    }))
  }

  const inputData = getData()
  return loadedMapsScript ? (
    <>
      <PlacesAutocomplete
        value={address[props.id]}
        onChange={handleChange}
        onSelect={handleSelect}
        onKeyDown={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <S.LabelWrapper width={props.width} margin={props.margin}>
              {props.label && (
                <Label
                  readOnly={props.readOnly}
                  hasError={props.hasError}
                  label={props.label}
                />
              )}
              <S.StyledInput
                {...getInputProps({
                  placeholder: inputData.data.placeholder.address,
                })}
                withBorder={props.withBorder}
                width={props.width}
                disabled={isEditing && props.id === 'from'}
                routeError={props.routeError}
              />

              <div style={{ position: 'absolute', zIndex: 10 }}>
                {suggestions.map((suggestion, i) => {
                  const style = {
                    backgroundColor: suggestion.active ? '#ccc' : '#fff',
                    minHeight: 35,
                    borderLeft: '1px solid #ccc',
                    borderRight: '1px solid #ccc',
                    borderBottom: '1px solid #ccc',
                    padding: '5px 10px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }
                  return (
                    <div
                      key={i}
                      {...getSuggestionItemProps(suggestion, { style })}
                    >
                      {suggestion.description}
                    </div>
                  )
                })}
              </div>
            </S.LabelWrapper>
          </>
        )}
      </PlacesAutocomplete>
    </>
  ) : null
}

export const TripTypeBanner = ({ tripType }) => {
  const service = services.find((s) => s.id === tripType)
  if (!service) return null
  const vehicleIcons = {
    car: <S.Car size={30} />,
    van: <S.Van size={30} />,
    seat: <EventSeat size={30} />,
  }

  return (
    <S.TripSelectWrapper
      margin={service.margin}
      selected={false}
      disabled={false}
    >
      <S.ServiceWrapper>
        {vehicleIcons[service.icon]}
        <S.Service>{service.label}</S.Service>
      </S.ServiceWrapper>
      <S.Allowances>
        <span>
          {service.people} <S.StyledMan size={18} />
        </span>
        <span>
          {service.bags} <LuggageCart size={18} />
        </span>
      </S.Allowances>
    </S.TripSelectWrapper>
  )
}

export const TripSelect = ({
  from,
  to,
  quotes,
  label,
  value = {},
  handler,
  id,
  margin,
}) => {
  const { state = {} } = useLocation()
  const { trip = {} } = state || {}
  const [selectedService, setSelectedService] = useState({
    id: trip.triptype ?? 'lele',
  })

  useEffect(() => {
    if (trip) return
    handler(id, trip.triptype)
  }, [])

  const isFromAndToFilled = from && to
  const disabledTripTypes = ['s-lele']

  return (
    <S.FieldWrapper>
      <S.TitleLabel>{label}</S.TitleLabel>
      {services.map((service) => (
        <S.TripSelectWrapper
          key={service.id}
          className="buttonService"
          margin={service.margin || margin}
          selected={value === service.id}
          disabled={service.disabled}
          onClick={() => {
            handler(id, service.id)
            setSelectedService(service)
          }}
        >
          <S.ServiceTypeWrapper>
            {service.icon === 'car' && <S.Car size={30} />}
            {service.icon === 'van' && <S.Van size={30} />}
            {service.icon === 'seat' && <EventSeat size={30} />}
            <S.Service>{service.label}</S.Service>
          </S.ServiceTypeWrapper>
          {service.icon !== 'seat' && (
            <S.Allowances column>
              <span>
                <S.StyledMan size={18} /> {service.people}
              </span>
              <span>
                <LuggageCart size={18} /> {service.bags}
              </span>
            </S.Allowances>
          )}
          <S.Cost>
            {!isFromAndToFilled || disabledTripTypes.includes(service.id)
              ? '--'
              : null}
            {isFromAndToFilled &&
            !quotes?.[service.id] &&
            !disabledTripTypes.includes(service.id) ? (
              <Spinner size={20} />
            ) : null}
            {isFromAndToFilled && quotes?.[service.id]
              ? `${accounting.formatMoney(quotes[service.id], {
                  symbol: '€ ',
                  precision: 2,
                })}`
              : null}
          </S.Cost>
        </S.TripSelectWrapper>
      ))}
    </S.FieldWrapper>
  )
}

export const ChildrenWeight = ({
  label,
  sublabel,
  value = [],
  handler,
  id,
  margin,
}) => {
  const [kids, updateKids] = useState(false)
  const [error, updateError] = useState('')
  const [weights, updateweights] = useState([0])

  return (
    <S.FieldWrapper margin={margin}>
      <Checkbox label={label} value={kids} onChange={() => updateKids(!kids)} />
      {kids && (
        <>
          <Note>{sublabel}</Note>
          <S.WeigthsWrapper>
            {weights.map((weight, iter) => (
              <S.WeightBox key={`weight${iter}`}>
                <S.StyledInput
                  width="45px"
                  withBorder
                  onChange={(e) =>
                    handleWeightUpdate(
                      e.target.value,
                      iter,
                      weights,
                      updateweights,
                      updateError,
                    )
                  }
                  onBlur={() => {
                    if (
                      iter === weights.length - 1 &&
                      weight &&
                      weight > 0 &&
                      weight < 36
                    ) {
                      updateweights([...weights, 0])
                      handler(id, weights)
                    }
                  }}
                  value={weight}
                />{' '}
                Kg
              </S.WeightBox>
            ))}
          </S.WeigthsWrapper>
          {error && (
            <P2 color="#E3011C" center>
              !! {error} !!
            </P2>
          )}
        </>
      )}
    </S.FieldWrapper>
  )
}
