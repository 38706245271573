import styled from 'styled-components'
import * as T from '../atomic/Typography'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  display: flex;
  padding: 40px 35px;
  flex-direction: column;
  gap: 24px;
  background: #f5f5f5;
`

export const Title = styled(T.Uh3)`
  font-size: 40px;
  line-height: normal;
  font-weight: 700;
  @media (max-width: ${breakpoint.bigtablet}) {
    text-align: center;
  }
  @media (max-width: ${breakpoint.tablet}) {
    font-size: 2em;
  }
`

export const Subtitle = styled(T.P1)`
  margin-bottom: 40px;
  @media (max-width: ${breakpoint.medium}) {
    text-align: center;
  }
`

export const Services = styled.div`
  display: flex;
  align-items: baseline;
  gap: 24px;
  justify-content: center;
  justify-content: space-between;
  @media (max-width: ${breakpoint.medium}) {
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
  @media (min-width: ${breakpoint.siteWidth}) {
    justify-content: space-around;
  }
`

export const SingleService = styled.div`
  max-width: 480px;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  justify-items: flex-start;
  /* flex-direction: column; */
  gap: 24px;
  max-height: 525px;
  align-items: ${(props) => (props.disabled ? 'center' : 'end')};
  color: ${(props) => (props.disabled ? '#b9bab8' : 'unset')};
  a {
    text-decoration: none;
  }
  @media (max-width: ${breakpoint.medium}) {
    align-items: center;
    justify-items: center;
  }
`

export const Img = styled.img`
  max-width: ${(props) => (props.disabled ? '80%' : '100%')};
  @media (max-width: ${breakpoint.large}) {
    max-width: 340px;
  }
  @media (max-width: ${breakpoint.medium}) {
    max-width: auto;
  }
`

export const ServiceTitle = styled(T.Uh3)`
  color: ${(props) => (props.disabled ? '#AEAEAE' : '#000')};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
