import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import passwordStrength from 'zxcvbn'
import {
  registerUser,
  resendOnlyConfirmationEmail,
  verifyUserWithCode,
} from '../../utils/accountUtils'
import { zapierApi, zapierEventTypes } from '../../utils/restAPIUtils'
import Logo from '../logo'
import { getCurrentLanguage, languageData } from './../../languages/index'
import AuthForm from './../auth/AuthForm'
import * as S from './styled'
import { AuthContext } from '../auth-context'

const getData = () => {
  const lang = getCurrentLanguage()
  return [
    {
      component: 'title',
      title: languageData[lang]['register.title'],
      id: 'title',
    },
    {
      component: 'simpleSubtitle',
      id: 'subtitle',
      title: languageData[lang]['register.subtitle'],
      error: 'main',
    },
    {
      component: 'textwithLinkunderline',
      margin: 20,
      data: [
        {
          type: 'text',
          label: languageData[lang]['register.member.login.text'],
        },
        { type: 'linkunderline', href: '/account/login', label: 'Login' },
      ],
      id: 'linkwithtext',
    },
    {
      component: 'input',
      id: 'name',
      name: 'name',
      type: 'name',
      label: languageData[lang]['register.form.0'],
      errorText: 'Please insert a valid first name',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'family_name',
      name: 'family_name',
      type: 'family_name',
      label: languageData[lang]['register.form.1'],
      errorText: 'Please insert a valid family name',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'phone_number',
      name: 'phone_number',
      type: 'phone_number',
      label: languageData[lang]['register.form.2'],
      errorText: 'Please insert a valid phone number',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'email',
      name: 'email',
      type: 'email',
      errorText: 'Please insert a valid email',
      label: languageData[lang]['register.form.3'],
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'password',
      name: 'password',
      type: 'password',
      errorText:
        'Your password must be at least 8 characters long, contain at least one number, one special character and have a mixture of uppercase and lowercase letters.',
      label: languageData[lang]['register.form.4'],
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'password-strength-bar',
      id: 'password-strength-bar',
      name: 'password-strength-bar',
      margin: 20,
    },
    {
      component: 'input',
      id: 'confirmPassword',
      name: 'confirm_password',
      label: languageData[lang]['register.form.5'],
      type: 'password',
      errorText: 'Passwords do not match.',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'mandatoryLabel',
      name: 'mandatoryLabel',
      margin: 20,
      id: 'mandatorylabel',
    },
    {
      component: 'terms',
      id: 'terms',
      name: 'terms',
      type: 'checkbox',
      errorText: 'Please accept the terms error',
      margin: 40,
    },
    {
      component: 'button',
      id: 'register',
      type: 'secondary',
      label: languageData[lang]['register.button'],
      disabled: false,
    },
  ]
}

const getDataEmailSent = () => {
  const lang = getCurrentLanguage()
  return [
    {
      component: 'title',
      title: languageData[lang]['register.confirmation.form.1.title'],
      id: 'title',
    },
    {
      component: 'simpleSubtitle',
      id: 'subtitle',
      title: languageData[lang]['register.confirmation.form.1.subtitle.0'],
    },
    {
      component: 'simpleSubtitle',
      id: 'subtitle',
      title: languageData[lang]['forgotten.password.expire.message'],
      boldWord: languageData[lang]['forgotten.password.expire.message.bold'],
      marginTop: '15',
      marginBottom: '15',
    },
    {
      component: 'input',
      id: 'email',
      name: 'email',
      label: languageData[lang]['register.form.3'],
      type: 'text',
      disabled: true,
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'verificationCodeInput',
      name: 'Verification code',
      label: languageData[lang]['register.confirmation.form.1.input.0'],
      type: 'text',
      errorText:
        languageData[lang]['register.confirmation.form.1.input.0.error'],
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'button',
      id: 'submitVerificationCode',
      type: 'secondary',
      label: languageData[lang]['register.confirmation.form.1.button.0'],
      disabled: false,
    },
    {
      component: 'simpleSubtitle',
      id: 'subtitle',
      marginTop: '20',
      title: languageData[lang]['register.confirmation.form.1.subtitle.1'],
    },
    {
      component: 'button',
      id: 'resubmitConfirmation',
      type: 'secondary',
      label: languageData[lang]['register.confirmation.form.1.button.1'],
      marginTop: '24',
      intervalTimer: 60000,
    },
  ]
}

const getDataEmailResent = () => {
  const lang = getCurrentLanguage()
  return [
    {
      component: 'title',
      title: languageData[lang]['register.confirmation.form.2.title'],
      id: 'title',
    },
    {
      component: 'simpleSubtitle',
      id: 'subtitle',
      title: languageData[lang]['register.confirmation.form.2.subtitle.0'],
    },
    {
      component: 'button',
      id: 'registrationConfirmed',
      type: 'white',
      label: languageData[lang]['register.confirmation.form.2.button'],
      marginTop: '24',
      full: true,
    },
  ]
}

export function SignUp() {
  const { loginUser } = useContext(AuthContext)

  const location = useLocation()
  const {
    emailToVerify = '',
    passwordToVerify = '',
    initialFormStage = 0,
  } = location?.state ?? {}
  const { userAttributes } = useContext(AuthContext)

  const [email, setEmail] = useState(emailToVerify)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordScore, setPasswordScore] = useState(0)
  const [name, setName] = useState('')
  const [family_name, setFamilyName] = useState('')
  const [phone_number, setPhone] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [terms, setTerms] = useState(false)
  const [error, setError] = useState([])
  const [loadingUserData, setLoadingUserData] = useState(false)

  const router = useNavigate()

  const value = {
    name,
    family_name,
    phone_number,
    email,
    password,
    confirmPassword,
    terms,
  }

  useEffect(() => {
    if (password === '') setPasswordScore(0)
    else {
      const pw = passwordStrength(password).score
      setPasswordScore(pw + 1)
    }
  }, [password])

  const formStages = [getData(), getDataEmailSent(), getDataEmailResent()]

  const [currentStage, setCurrentStage] = useState(initialFormStage)
  const formState = formStages[currentStage]

  const registerNewUser = async () => {
    try {
      const user = await registerUser(value, setError)
      const {
        email,
        name,
        family_name: surname,
        phone_number: phoneNumber,
      } = value
      setCurrentStage(1)
      zapierApi('get')(zapierEventTypes.newSignUp)()({
        email,
        name,
        surname,
        phoneNumber,
        url: window.location.href,
      })((cb) => cb)
      // do something with the user data
    } catch (error) {
      // handle error
    }
  }

  const userVerifyWithCode = async () => {
    try {
      await verifyUserWithCode(email, verificationCode)
      setCurrentStage(2)
    } catch (error) {
      // handle error
      setError(['verificationCodeInput.' + error.message])
      console.error(error)
    }
  }

  const resubmitConfirmation = async () => {
    const emailAddress = localStorage.getItem('emailRegister')
    resendOnlyConfirmationEmail(emailAddress)
  }

  const loginAndRedirectToHome = async () => {
    // if we are coming from the normal signup flow, then password is the state and is not empty string
    // if we are coming from the only verification flow, then the password is passed within
    // in the state of react router component with the name of 'passwordToVerify'
    const passwordToSend = password || passwordToVerify
    const userInfo = { email, password: passwordToSend }
    return await loginUser(
      userInfo,
      () => router('/'),
      setError,
      setLoadingUserData,
    )
  }

  const handlers = {
    name: setName,
    family_name: setFamilyName,
    phone_number: setPhone,
    email: setEmail,
    password: setPassword,
    confirmPassword: setConfirmPassword,
    passwordScore: setPasswordScore,
    terms: setTerms,
    action: () => registerUser(value, setError, router),
    register: registerNewUser,
    verificationCodeInput: setVerificationCode,
    submitVerificationCode: userVerifyWithCode,
    resubmitConfirmation: resubmitConfirmation,
    registrationConfirmed: loginAndRedirectToHome,
  }

  // const registerData = getData()
  localStorage.setItem('emailRegister', value.email)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStage])

  if (userAttributes !== null) return <Navigate to={'/'} />

  return (
    <S.SignInWrapper>
      <S.SideImage />
      <S.LogoWrapper>
        <Logo colorFont="#FFFFFF" colorWheel="#000000" />
      </S.LogoWrapper>
      <S.AroundFormWrapper>
        <AuthForm
          noTopBorder
          // {...registerData}
          value={value}
          handler={(id, value) => handlers[id](value)}
          error={error}
          formOptions={formState}
          score={passwordScore}
          loading={loadingUserData}
        />
      </S.AroundFormWrapper>
    </S.SignInWrapper>
  )
}
