import styled from 'styled-components'
import { P3 } from '../atomic/Typography'

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  align-items: flex-start;
`
export const FromToCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-width: 144px;
  max-width: 144px;
  padding-right: ${({ padding }) => (padding ? `${padding}px` : '0')};
`

export const Label = styled(P3)`
  line-height: 12px;
`
