import styled, { keyframes } from 'styled-components'
import { ext } from './../../utils/utils'
import { breakpoint } from '../../styles/global.style'
import { Spinner10 } from '@styled-icons/icomoon'

const BaseBtn = styled.button`
  position: relative;
  font-size: 18px;
  margin: ${(props) =>
    `${props.marginVertical || '0px'} ${props.marginHorizontal || '0px'}`};
  padding: ${(props) => props.padding || '0px 40px'};
  /* letter-spacing: 3px; */
  height: ${(props) => (props.height ? props.height : '45')}px;
  min-width: ${(props) => (props.stretch ? '200' : '90')}px;
  max-width: ${(props) => (props.full ? 'none' : '300px')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')}px;
  width: ${(props) =>
    props.full ? '100%' : props.fixedWidth ? props.fixedWidth + 'px' : 'auto'};
  border: none;
  line-height: ${(props) => (props.icon ? '1.8em' : '1em')};
  &:hover {
    cursor: pointer;
  }
  &:disabled,
  &[disabled],
  &:disabled:hover,
  &[disabled]:hover {
    opacity: 0.3;
  }
  @media (max-width: ${breakpoint.tablet}) {
    font-size: 20px;
    padding: 0px 20px;
  }
  @media (max-width: ${breakpoint.mobile}) {
    font-size: 16px;
    padding: 0px 20px;
  }
`

export const PrimaryBtn = styled(ext(BaseBtn))`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.tertiary};
  &:hover {
    background-color: ${(props) => props.theme.primaryHover};
  }
  &:disabled,
  &[disabled],
  &:disabled:hover,
  &[disabled]:hover {
    cursor: default;
    opacity: ${(props) => (props.disableColor ? '1' : '0.75')};
    background-color: ${(props) => props.disableColor || ''};
  }
`

export const SecondaryBtn = styled(ext(BaseBtn))`
  background-color: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.tertiary};
  border-radius: 4px;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  gap: 4px;
  justify-content: center;
  :hover:not(:disabled) {
    background-color: ${(props) => props.theme.secondaryHover};
  }
  &:disabled,
  &[disabled],
  &:disabled:hover,
  &[disabled]:hover {
    opacity: ${(props) => (props.disableColor ? '1' : '0.3')};
    background-color: ${(props) => props.disableColor || ''};
  }
`

export const TertiaryBtn = styled(ext(BaseBtn))`
  background-color: transparent;
  color: ${(props) => props.theme.secondary};
  border: 2px solid ${(props) => props.theme.secondary};
  &:hover {
    background-color: ${(props) => props.theme.tertiaryHover};
  }
`

export const WhiteBtn = styled(ext(BaseBtn))`
  color: black;
  background-color: transparent;
  border: 3px solid black;
  display: flex;
  border-radius: 4px;
  align-items: center;
  gap: 4px;
  justify-content: center;

  &:hover:not(:disabled) {
    cursor: pointer;
    background-color: rgb(0, 0, 0, 0.2);
  }
  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
`

export const TripBtn = styled(ext(BaseBtn))`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: 'RalewayBold';
  line-height: 21px;
  letter-spacing: 0.24px;
  font-size: 16px;
  background-color: #e0e0e0;
  color: #2e2e2e;
  border-radius: 8px;
  text-transform: none;
  &:hover:not(:disabled) {
    cursor: pointer;
    background-color: #aeaeae;
  }
  @media (max-width: ${breakpoint.medium}) {
    width: 100%;
  }
  @media (max-width: ${breakpoint.bigtablet}) {
    width: ${(props) =>
      props.full
        ? '100%'
        : props.fixedWidth
        ? props.fixedWidth + 'px'
        : 'auto'};
  }
  @media (max-width: ${breakpoint.smallTablet}) {
    width: 100%;
  }
`

export const Icon = styled.span`
  top: -2px;
  position: relative;
`

export const FacebookBtn = styled(ext(BaseBtn))`
  border-radius: 3px;
  background-color: #3b5898;
  padding: 9px 12px;
  color: white;
  font-family: RobotoRegular;
  text-transform: none;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: bold;
  height: auto;
`

export const FBIcon = styled.span`
  margin-right: 10px;
`

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Loading = styled(Spinner10)`
  position: ${(props) => (props.inline ? 'static' : 'absolute')};
  left: 10px;
  width: 18px;
  animation: ${rotate} 1.5s linear infinite;
`

export const IconPadding = styled.div`
  display: inline;
  padding-right: 1%;
`
