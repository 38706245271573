import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import AuthForm from './../auth/AuthForm'
import { CognitoUser } from 'amazon-cognito-identity-js'
import UserPool from './../auth-context/UserPool.js'

import { languageData, getCurrentLanguage } from './../../languages/index'

import * as S from './styled'
import * as T from './../atomic/Typography'

export const PasswordReset = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState()
  const [verificationCode, setVerificationCode] = useState('')
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname]) //renders page from top

  const getDataEnterEmailForm = () => {
    const lang = getCurrentLanguage()
    return [
      {
        component: 'title',
        title: languageData[lang]['forgotten.password-title'],
        id: 'title',
      },
      {
        component: 'subtitle',
        id: 'subtitle',
        title: languageData[lang]['forgotten.password-subtitle'],
      },
      {
        component: 'input',
        id: 'email',
        name: 'email',
        type: 'email',
        errorText: 'Invalid email',
        label: languageData[lang]['drive.form.email'],
        value: 'email',
        withBorder: true,
        width: '100%',
        margin: 20,
      },
      {
        component: 'subtitle',
        id: 'errorBox',
        title: '',
        error: 'errorBox',
      },
      {
        component: 'button',
        id: 'sendEmail',
        type: 'secondary',
        label: languageData[lang]['forgotten.password-button'],
        full: true,
      },
      {
        component: 'simpleSubtitle',
        id: 'subtitle',
        marginTop: '30',
        title: languageData[lang]['forgotten.password.expire.message'],
        boldWord: languageData[lang]['forgotten.password.expire.message.bold'],
      },
    ]
  }

  const getDataEmailSent = () => {
    const lang = getCurrentLanguage()
    return [
      {
        component: 'title',
        title: languageData[lang]['forgotten.password-title.1'],
        id: 'title',
      },
      {
        component: 'simpleSubtitle',
        id: 'subtitle',
        title: `${languageData[lang]['forgotten.password.verification.message.0']} ${email}. ${languageData[lang]['forgotten.password.verification.message.1']}`,
      },
      {
        component: 'simpleSubtitle',
        id: 'subtitle',
        title: languageData[lang]['forgotten.password.check.spam'],
        marginTop: '15',
        marginBottom: '40',
      },
      {
        component: 'input',
        id: 'verificationCode',
        name: 'verificationCode',
        type: 'input',
        errorText: languageData[lang]['forgotten.password.verification.error'],
        label: languageData[lang]['forgotten.password.verification.code'],
        value: '',
        withBorder: true,
        width: '100%',
        margin: 20,
      },
      {
        component: 'button',
        id: 'goToStepNumber2',
        type: 'secondary',
        disabled: !verificationCode,
        label: languageData[lang]['forgotten.password-button.next'],
        marginTop: '24',
        full: true,
      },
      {
        component: 'simpleSubtitle',
        id: 'subtitle',
        marginTop: '20',
        title: languageData[lang]['forgotten.password.email.not.found'],
      },
      {
        component: 'button',
        id: 'resubmitConfirmation',
        type: 'white',
        label: languageData[lang]['forgotten.password-button'],
        marginTop: '24',
        full: true,
        margin: 20,
      },
      {
        component: 'linkunderline',
        label: languageData[lang]['forgotten.password.change.email.link'],
        href: 'reset',
        id: 'changeEmailLink',
        marginTop: 24,
        textAlign: 'center',
      },
    ]
  }

  const getDataChangePasswordForm = () => {
    const lang = getCurrentLanguage()
    return [
      {
        component: 'title',
        title: languageData[lang]['forgotten.password-title.2'],
        id: 'title',
      },
      {
        component: 'simpleSubtitle',
        id: 'subtitle',
        title: languageData[lang]['forgotten.password.almost.set.text'],
        marginBottom: '30',
      },
      {
        component: 'input',
        id: 'verificationCode',
        name: 'verificationCode',
        type: 'input',
        errorText: languageData[lang]['forgotten.password.verification.error'],
        label: languageData[lang]['forgotten.password.verification.code'],
        value: verificationCode,
        withBorder: true,
        width: '100%',
        margin: 20,
      },
      {
        component: 'inputPasswordRequirement',
        id: 'password',
        name: 'newPassword',
        type: 'password',
        clickEvent: 'checkList',
        label: languageData[lang]['forgotten.password.new.password'],
        errorText:
          languageData[lang]['forgotten.password.error.new.password.not.safe'],
        withBorder: true,
        width: '100%',
        margin: 20,
      },
      {
        component: 'input',
        id: 'confirmPassword',
        name: 'confirm password',
        label: languageData[lang]['register.form.5'],
        type: 'password',
        errorText:
          languageData[lang]['forgotten.password.error.not.matching.passwords'],
        required: true,
        value: null,
        withBorder: true,
        width: '100%',
        margin: 20,
      },
      {
        component: 'subtitle',
        id: 'errorBox',
        title: '',
        error: 'errorBox',
      },
      {
        component: 'button',
        id: 'changePassword',
        type: 'secondary',
        label: languageData[lang]['forgotten.password-button.reset'],
        full: true,
      },
    ]
  }

  const getDataPasswordChanged = () => {
    const lang = getCurrentLanguage()
    return [
      {
        component: 'title',
        title: languageData[lang]['forgotten.password.success.title'],
        id: 'title',
      },
      {
        component: 'subtitle',
        id: 'subtitle',
        title: languageData[lang]['forgotten.password.success.subtitle'],
      },
      {
        component: 'subtitle',
        id: 'errorBox',
        title: '',
        error: 'errorBox',
      },
      {
        component: 'button',
        id: 'goToLogin',
        type: 'secondary',
        label: languageData[lang]['login.title'],
      },
    ]
  }

  const [checkList, setCheckList] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordCheckStatus, setPasswordCheckStatus] = useState({
    'long-password': false,
    'upper-lower-case': false,
    'letters-numbers': false,
    'special-characters': false,
    'lower-case': false,
    number: false,
  })

  const getPasswordStatus = (value) => {
    const valueLength = value.split('').length
    const valueLowerCaseRegex = /(.*[a-z].*[A-Z].*)|((.*[A-Z].*[a-z].*))/
    const valueSpecialCharsRegex = /[$&+,:;=?@#|'<>.^*()%!-]/
    const valueLowercaseLetterRegex = /^(?=.*[a-z])/
    const valueNumberRegex = /[0-9]/
    return {
      'long-password': valueLength >= 8,
      'upper-lower-case': valueLowerCaseRegex.test(value),
      'special-characters': valueSpecialCharsRegex.test(value),
      'lower-case': valueLowercaseLetterRegex.test(value),
      number: valueNumberRegex.test(value),
    }
  }

  const formStages = [
    getDataEnterEmailForm(),
    getDataEmailSent(),
    getDataChangePasswordForm(),
    getDataPasswordChanged(),
  ]

  const [formState, setFormState] = useState(formStages[0])
  const navigate = useNavigate()

  const user = new CognitoUser({
    Username: email,
    Pool: UserPool,
  })
  const lang = getCurrentLanguage()

  const submitEmail = async () => {
    setError([])
    await user.forgotPassword(
      {
        onSuccess: (_) => setFormState(formStages[1]),
        onFailure: (err) => setError(['errorBox.' + err.message]),
      },
      { locale: lang },
    )
  }

  const goToNewPasswordInput = () => {
    setError([])
    // verification code must be only numbers and of length 6
    if (verificationCode.length === 6 && /^[0-9]*$/.test(verificationCode)) {
      setFormState(formStages[2])
    } else {
      setError(['verificationCode.'])
    }
    return
  }

  const resubmitConfirmation = async () => {
    setError([])
    await user.forgotPassword(
      {
        onSuccess: (_) => setFormState(formStages[1]),
        onFailure: (err) => setError(['errorBox.' + err.message]),
      },
      { locale: lang },
    )
  }

  const changePassword = async () => {
    setError([])

    // the two passwords must match
    if (password !== confirmPassword) {
      setError(['confirmPassword'])
      return
    }
    // verification code must be only numbers and of length 6
    if (verificationCode.length !== 6 || !/^[0-9]*$/.test(verificationCode)) {
      setError(['verificationCode.'])
      return
    }
    // if some value is falsy, then password is not strong enough
    if (Object.values(passwordCheckStatus).some((val) => !val)) {
      setError(['password.'])
      return
    }

    await user.confirmPassword(
      verificationCode,
      password,
      {
        onSuccess: () => setFormState(formStages[3]),
        onFailure: (err) => setError(['errorBox.' + err.message]),
      },
      { locale: lang },
    )
  }

  const goToLogin = () => {
    setError([])
    navigate('/account/login', { state: { fromRecovery: true } })
  }

  useEffect(() => {
    const passwordStatusObj = getPasswordStatus(password)
    setPasswordCheckStatus(passwordStatusObj)
    if (password) setCheckList(true)
  }, [password])

  const handlers = {
    email: setEmail,
    password: setPassword,
    verificationCode: setVerificationCode,
    sendEmail: submitEmail,
    changePassword: changePassword,
    goToLogin: goToLogin,
    resubmitConfirmation: resubmitConfirmation,
    goToStepNumber2: goToNewPasswordInput,
    confirmPassword: setConfirmPassword,
  }

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.BackNavigation
          onClick={() => {
            navigate('/account/login', { state: { fromRecovery: true } })
          }}
        >
          <S.ChevronIcon size={15} />
          <T.P1>Back to Login</T.P1>
        </S.BackNavigation>
        <AuthForm
          noTopBorder
          formOptions={formState}
          value={[]}
          handler={(id, value) => handlers[id](value)}
          error={error}
          passwordCheckStatus={passwordCheckStatus}
          checkList={checkList}
          // clickEvent={handlers}
        />
      </S.InnerWrapper>
    </S.Wrapper>
  )
}
