import React, { useState } from 'react'
import { colors, breakpointNum } from './../../styles/global.style'
import { DRow, renderIcon } from './../atomic/Layout'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Dots } from './../atomic/Layout'
import propPath from 'crocks/Maybe/propPath'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import { LazyBgCarouselImg } from '../blocks/LazyImages'
import {
  Indicator,
  ChildrenWrap,
  ChildrenPos,
  CarouselWrapper,
  MainImg,
  OpacityBox,
  VideoWrapper,
  VideoIconWrapper,
  IconStyle,
} from './styled'

const getWidth = (slide) => propPath(['meta', 'width'], slide).option('')
const getHeight = (slide) => propPath(['meta', 'height'], slide).option('')
const getTitle = (slide) => propPath(['meta', 'title'], slide).option('')
const getAlt = (slide) => propPath(['meta', 'alt'], slide).option('')

const getWidthLength = (length) => (length - 1) * 30 + 15

const Carousel = ({ full, data, children, spaced }) => {
  const [currentSlide, updateCurrentSlide] = useState(0)
  const [coverVideo, setCoverVideo] = useState(true)
  return (
    <>
      <DRow
        spaced={spaced}
        full={full}
        alignItems="center"
        displayContent="center"
      >
        <CarouselWrapper
          interval={3500}
          autoPlay={false}
          infiniteLoop={true}
          showArrows={true}
          onChange={updateCurrentSlide}
          onClickItem={() => {}}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          selectedItem={currentSlide}
          swipeable={data && data.length === 1 ? false : true}
        >
          {data &&
            data.map((slide, num) => {
              const width = getWidth(slide)
              const height = getHeight(slide)
              const title = getTitle(slide)
              const alt = getAlt(slide)
              return (
                <LazyBgCarouselImg
                  blurried={slide.src}
                  mobileBlurried={slide.mobileSrc}
                  selected
                  key={slide.key}
                  height={height}
                  ar={height / width}
                  minHeight={550}
                  opacity={slide.opacity}
                >
                  <OpacityBox opacity={slide.opacity} />
                  <MainImg
                    alt={alt}
                    width={width}
                    maxHeight={height}
                    title={title}
                    src={slide.src}
                  />
                  {slide.type === 'video' ? (
                    <VideoIconWrapper>
                      {coverVideo ? (
                        <IconStyle
                          onClick={() => {
                            setCoverVideo(false)
                            const fullscreenme =
                              window.innerWidth < breakpointNum.tablet
                            setTimeout(
                              () =>
                                fullscreenme
                                  ? screenfull.request(
                                      document.getElementsByTagName(
                                        'iframe',
                                      )[0],
                                    )
                                  : {},
                              1000,
                            )
                          }}
                        >
                          {renderIcon('videoplay', 60)}
                        </IconStyle>
                      ) : (
                        <VideoWrapper>
                          <ReactPlayer
                            url={slide.videoSrc}
                            controls
                            playing
                            muted
                            width="100%"
                            height="100%"
                            onEnded={() => setCoverVideo('true')}
                          />
                        </VideoWrapper>
                      )}
                    </VideoIconWrapper>
                  ) : (
                    <ChildrenWrap>
                      <ChildrenPos justifyContent={slide.justifyContent}>
                        {React.Children.map(children, (child, i) =>
                          i === num ? child : null,
                        )}
                      </ChildrenPos>
                    </ChildrenWrap>
                  )}
                  {data.length > 1 && (
                    <Indicator>
                      <Dots
                        num={data.length}
                        width={getWidthLength(data.length)}
                        active
                        activeItem={num}
                        activeColor={colors.kfcRed}
                        onActiveClick={(num) =>
                          num !== currentSlide ? updateCurrentSlide(num) : null
                        }
                        passiveColor={colors.kfcBlackHover}
                      />
                    </Indicator>
                  )}
                </LazyBgCarouselImg>
              )
            })}
        </CarouselWrapper>
      </DRow>
    </>
  )
}

export default Carousel
