import axios from 'axios'

const MyAxios =
  (base) =>
  (method) =>
  (url) =>
  (headers = {}) =>
  (body) =>
  async (cb) =>
    axios({
      method,
      headers: { ...headers },
      url: `/${url}`,
      baseURL: base,
      ...(method === 'get' ? { params: body } : { data: body }),
    })
      .then((res) => cb(res.data))
      .catch((err) => {
        if (err.response) {
          // there is a response
          return err.response
        } else if (err.request) {
          return {
            status: 500,
            data: {
              message:
                'We are having problems contacting the server, please try again later',
            },
          }
        } else {
          return {
            status: 500,
            data: {
              message:
                'We are having problems contacting the server, please try again later',
            },
          }
        }
      })

// Connected to Reservation API
const Api = MyAxios(
  process.env.REACT_APP_API_ADDRESS || 'http://localhost:3030',
)
export const getApi = Api('get')
export const postApi = Api('post')
export const patchApi = Api('patch')
export const deleteApi = Api('delete')

export const zapierApi = MyAxios(process.env.REACT_APP_ZAPIER_API)
export const zapierEventTypes = {
  callMeBack: '3shgkzv',
  contactUs: '3shgin1',
  guestReservationPaid: '38h1o6h',
  onWindowError: '38tkkyp',
  newSignUp: '38syu04',
}

axios.defaults.timeout = 30000
