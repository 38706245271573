import React from 'react'
import { UhDyn } from './../atomic/Typography'
import { DRow, DCol } from './../atomic/Layout'
import CoreHtmlParser from './../blocks/CoreHtmlParser'
import { PWrapper, Paragraph } from './styled'

const PageHeader = ({
  data,
  height = 250,
  mobileHeight = 150,
  spaced,
  noUnderline,
}) => {
  return (
    <DRow
      spaced={spaced}
      grey={data.background_colour === 'kfc_grey'}
      full
      height={height}
      mobileHeight={mobileHeight}
      justifyContent="space-between"
    >
      <DCol
        vertical
        justifyContent="space-between"
        alignItems="center"
        padding={15}
      >
        <PWrapper>
          {noUnderline ? (
            <h1>{data.heading}</h1>
          ) : (
            <UhDyn
              paddingBottom="7px"
              label={data.heading}
              extraSpace={5}
              type="h1"
            />
          )}
        </PWrapper>
        {data.body && (
          <PWrapper>
            <Paragraph hideMobile={data.hideMobile}>
              <CoreHtmlParser data={data} />
            </Paragraph>
          </PWrapper>
        )}
      </DCol>
    </DRow>
  )
}

export default PageHeader
