import AccountHome from '../pages/account-home'
import ConfirmPage from '../pages/account/confirm'
import LoginPage from '../pages/account/login'
import PasswordResetPage from '../pages/account/passwordReset'
import RegisterPage from '../pages/account/register'
import Settings from '../pages/account-settings'
import Privacy from '../pages/privacy'
import Terms from '../pages/terms'
import ReservationResponse from '../pages/checkout/reservation/payment-response'
import ReservationCancel from '../pages/checkout/reservation/cancel'
import DriverConfirm from '../pages/drivers-confirm'
import PasswordChange from '../pages/change-password'
import ChangePasswordSuccess from '../pages/change-password-success'
import Itinerary from '../pages/itineraries'
import LakeMisurina from '../pages/dolomites-itinerary/lake-misurina'
import Cortina from '../pages/dolomites-itinerary/cortina'
import Faloria from '../pages/dolomites-itinerary/faloria'
import EmailConfirmation from '../pages/email-confirmation'
import AccountChanged from './../pages/account/account-changed/index'
import PageExperiences from './../pages/page-experiences/index'
import PageHowItWorks from './../pages/page-how-it-works/index'
import PageContactUs from './../pages/page-contact-us/index'
import PageCoverage from './../pages/page-coverage/index'
import PageWhoAreWe from './../pages/page-who-are-we/index'
import PageForDrivers from './../pages/page-for-drivers/index'
import PageWhyLelecabs from './../pages/page-why-lelecabs/index'
import PageHome from '../pages/page-home'
import PageFaq from '../pages/page-faq'
import CheckoutNew from '../pages/page-checkout'
import CheckoutGuests from '../pages/page-checkout-guests'
import ReservationResponseGuest from '../pages/page-checkout-response-guest'

export const pages = [
  {
    link: '/',
    component: PageHome,
  },
  {
    link: 'privacy',
    component: Privacy,
  },
  {
    link: 'drive/confirm-registration',
    component: DriverConfirm,
  },
  {
    link: 'terms',
    component: Terms,
  },
  {
    link: 'checkout',
    component: CheckoutNew,
    protected: true,
  },
  {
    link: 'checkout/guests',
    component: CheckoutGuests,
    protected: false,
  },
  {
    link: 'checkout/response',
    component: ReservationResponse,
    protected: true,
  },
  {
    link: 'checkout/response/guest',
    component: ReservationResponseGuest,
    protected: false,
  },
  {
    link: 'checkout/cancel',
    component: ReservationCancel,
    protected: true,
  },
  {
    link: 'account/register',
    component: RegisterPage,
    protected: false,
  },
  {
    link: 'account/login',
    component: LoginPage,
    protected: false,
  },
  {
    link: 'account/confirm',
    component: ConfirmPage,
    protected: false,
  },
  {
    link: 'account/confirm/email',
    component: EmailConfirmation,
    protected: false,
  },
  {
    link: 'account/reset',
    component: PasswordResetPage,
    protected: false,
  },
  {
    link: 'account/password-change',
    component: PasswordChange,
    protected: true,
  },
  {
    link: 'account/change-password-success',
    component: ChangePasswordSuccess,
    protected: true,
  },
  {
    link: 'account/account-changed',
    component: AccountChanged,
    protected: true,
  },
  {
    link: 'account',
    component: AccountHome,
    protected: true,
  },
  {
    link: 'account/settings',
    component: Settings,
    protected: true,
  },
  // itineraries section
  {
    link: '/itineraries/dolomites',
    component: Itinerary,
  },
  {
    link: 'itineraries/dolomites/misurina',
    component: LakeMisurina,
  },
  {
    link: 'itineraries/dolomites/cortina',
    component: Cortina,
  },
  {
    link: 'itineraries/dolomites/faloria',
    component: Faloria,
  },
  {
    link: '/experiences',
    component: PageExperiences,
  },
  {
    link: '/how-it-works',
    component: PageHowItWorks,
  },
  {
    link: '/contact-us',
    component: PageContactUs,
  },
  {
    link: '/operation-coverage',
    component: PageCoverage,
  },
  {
    link: '/who-we-are',
    component: PageWhoAreWe,
  },
  {
    link: '/for-drivers',
    component: PageForDrivers,
  },
  {
    link: '/why-lelecabs',
    component: PageWhyLelecabs,
  },
  {
    link: '/faq',
    component: PageFaq,
  },
]
