import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Payment } from '@styled-icons/material/Payment'
import { Suitcase } from '@styled-icons/entypo/Suitcase'
import { Taxi } from '@styled-icons/boxicons-solid/Taxi'
import { ListUl } from '@styled-icons/boxicons-regular/ListUl'
import { AccountBox } from '@styled-icons/material/AccountBox'
import { Ticket } from '@styled-icons/entypo/Ticket'
import { UserCircle } from '@styled-icons/boxicons-regular/UserCircle'

const IconUser = styled(UserCircle)`
  width: 40px;
  margin-right: 25px;
  color: black;
`

const IconPayment = styled(Payment)`
  width: 25px;
  color: rgba(238, 148, 9, 0.62);
  margin-right: 25px;
`

const IconTrips = styled(Suitcase)`
  width: 25px;
  color: rgba(238, 148, 9, 0.62);
  margin-right: 25px;
`

const IconCars = styled(Taxi)`
  width: 25px;
  color: rgba(238, 148, 9, 0.62);
  margin-right: 25px;
`

const IconRequest = styled(ListUl)`
  width: 25px;
  color: rgba(238, 148, 9, 0.62);
  margin-right: 25px;
`

const IconAccount = styled(AccountBox)`
  width: 25px;
  color: rgba(238, 148, 9, 0.62);
  margin-right: 25px;
`

const IconSub = styled(Ticket)`
  width: 25px;
  color: rgba(238, 148, 9, 0.62);
  margin-right: 25px;
`

const Aside = styled.div`
  height: 100%;
  position: relative;
`

const MenuContainer = styled.div`
  font-style: arial;
  border-right: 1px solid black;
  font-size: 25px;
  display: block;
  line-height: 80px;
  padding-left: 30px;
  width: 250px;
  height: 100%;
`

const MenuItem = styled(Link)`
  text-decoration: none;
  color: black;
`

const DriverMenu = [
  {
    label: 'Driver',
    id: 'user',
    Icon: <IconUser />,
    href: '#',
  },
  {
    label: 'Account',
    id: 'account',
    Icon: <IconAccount />,
    href: '#',
  },
  {
    label: 'Cars',
    id: 'cars',
    Icon: <IconCars />,
    href: '#',
  },
  {
    label: 'Payments',
    id: 'paymnts',
    Icon: <IconPayment />,
    href: '#',
  },
  {
    label: 'Trips',
    id: 'Trips',
    Icon: <IconTrips />,
    href: '#',
  },
  {
    label: 'Subscription',
    id: 'Subscription',
    Icon: <IconSub />,
    href: '#',
  },
  {
    label: 'Request',
    id: 'Request',
    Icon: <IconRequest />,
    href: '#',
  },
]

const Menu = ({ type }) => (
  <Aside>
    <MenuContainer>
      {DriverMenu.map((item, iter) => {
        return (
          <MenuItem href={item.href}>
            {item.Icon}
            {item.label} <br />{' '}
          </MenuItem>
        )
      })}
    </MenuContainer>
  </Aside>
)

export default Menu
