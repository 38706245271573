import styled from 'styled-components'
import { Carousel } from 'react-responsive-carousel'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SingleReview = styled.div`
  max-width: 609px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
  @media (max-width: ${breakpoint.smallTablet}) {
    max-width: 400px;
  }
  @media (max-width: ${breakpoint.mobile}) {
    max-width: 230px;
  }
`

export const Stars = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const CarouselComponent = styled(Carousel).attrs((props) => ({
  className: props.className,
}))`
  .control-dots {
    margin-top: 100px;
  }
  .carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carousel-status {
    display: none;
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: none;
    color: black;
  }
  .carousel .control-next.control-arrow:before {
    border-left: 8px solid rgba(46, 46, 46, 1);
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid rgba(46, 46, 46, 1);
  }
  .carousel.carousel-slider {
    padding: 55px 0px;
  }
`
