import React from 'react'
import styled from 'styled-components'
import { DefaultLayout } from './../components/utilities/DefaultLayout'
import InjectMeta from './../components/utilities/InjectMeta'
import { RideForm } from './../components/ride-form'
import { breakpoint } from './../styles/global.style'
import CTASideBySide from './../components/compositions/CTASideBySide'
import english from './../languages/english'
import { languageData, getCurrentLanguage } from './../languages/index'

const lang = getCurrentLanguage()

export const TemporaryBanner = styled.h3`
  text-transform: uppercase;
  background-color: black;
  text-align: center;
  padding: 15px;
  color: white;
  @media (max-width: ${breakpoint.bigtablet}) {
    font-size: 16px;
  }
`
const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Become a driver or get a ride - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content:
        'Become a driver and make money giving rides, or get a Lelecabs ride for the next days.',
    },
  },
]

const getData = () => {
  const lang = getCurrentLanguage()
  return {
    spaced: true,
    children: [
      {
        heading: languageData[lang]['home.cta.0.title'],
        content_background: 'transparent-white',
        text_colour: 'black',
        body: {
          content: `<p>${languageData[lang]['home.cta.0.content.0']}</p><p>${languageData[lang]['home.cta.0.content.1']}</p>`,
          format: 'html',
        },
        button_text: languageData[lang]['home.cta.0.submit'],
        button_style: 'secondary',
        button_link: {
          uri: '/coverage',
        },
        button_link_target: 'same_tab',
        image: {
          original: {
            url: '/images/homeCTA-left.jpg',
          },
          low_res: {
            url: '/images/homeCTA-left_low.jpg',
          },
        },
      },
      {
        heading: languageData[lang]['home.cta.1.title'],
        content_background: 'black',
        text_colour: 'white',
        body: {
          content: `<p>${languageData[lang]['home.cta.1.content.0']}</p><p>${languageData[lang]['home.cta.1.content.1']}</p>`,
          format: 'html',
        },
        button_text: languageData[lang]['home.cta.1.submit'],
        button_style: 'primary',
        button_link: {
          uri: '/safety',
        },
        button_link_target: 'same_tab',
        image: {
          original: {
            url: '/images/homeCTA-right.jpg',
          },
          low_res: {
            url: '/images/homeCTA-right_low.jpg',
          },
        },
      },
    ],
  }
}

function HomePage({ isLogged, lang, ...props }) {
  const ctaHomeData = getData()
  return (
    <>
      <InjectMeta meta={metaPage} lang={lang} />
      <DefaultLayout pageType="viewer" lang={lang}>
        <div>
          <TemporaryBanner>
            {languageData[lang]['home.hero.banner']}
          </TemporaryBanner>
          <RideForm />
          <CTASideBySide
            first={false}
            key="cta-home"
            data={ctaHomeData}
            spaced
            marginTop={45}
          />
        </div>
      </DefaultLayout>
    </>
  )
}

export default HomePage
