import * as S from './styled'

const Avatar = ({ name = '', onClick = () => null }) => {
  const firstLetter = !!name
    ? name
        .split(' ')
        .map((str) => str[0])
        .join('')
        .toUpperCase()
    : ''

  return <S.StyledAvatar onClick={onClick}>{firstLetter}</S.StyledAvatar>
}

export default Avatar
