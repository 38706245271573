import Ajv from 'ajv'
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import validator from 'validator'
import UserPool from './../components/auth-context/UserPool'
import { getCurrentLanguage, languageData } from '../languages'
import { isValidEmail, isValidFamilyName, isValidFirstName } from './textUtils'

const userPool = UserPool

const validateSchema = (userData) => {
  const ajv = new Ajv()
  const schema = {
    type: 'object',
    properties: {
      name: { type: 'string' },
      family_name: { type: 'string' },
      phone_number: { type: 'string' },
      email: { type: 'string' },
      password: { type: 'string' },
      confirmPassword: { type: 'string' },
    },
    required: [
      'name',
      'family_name',
      'phone_number',
      'email',
      'password',
      'confirmPassword',
    ],
    additionalProperties: false,
  }
  const isValid = ajv.validate(schema, userData)
  if (!isValid) console.log(ajv.errors)
  return isValid
}

const validateUserData = (userData, error) => {
  const my_obj = [
    { checkFunction: isValidEmail, errorName: 'email.', data: userData.email },
    {
      checkFunction: isValidFirstName,
      errorName: 'name.',
      data: userData.name,
    },
    {
      checkFunction: isValidFamilyName,
      errorName: 'family_name.',
      data: userData.family_name,
    },
    {
      checkFunction: isPossiblePhoneNumber,
      errorName: 'phone_number.',
      data: userData.phone_number,
    },
    {
      checkFunction: validator.isStrongPassword,
      errorName: 'password.',
      data: userData.password,
    },
  ]
  my_obj.map((obj) => {
    if (obj.checkFunction(obj.data)) return (obj.isValid = true)
    else return (obj.isValid = false)
  })
  const filteredObj = my_obj
    .filter((element) => element['isValid'] === false)
    .map((err) => err.errorName)
  if (userData.password !== userData.confirmPassword)
    filteredObj.push('confirmPassword.')
  error(filteredObj)
  return filteredObj.length === 0
}

export async function registerUser(value, error, router) {
  const lang = getCurrentLanguage()
  const { terms, ...userInfo } = value
  error([])

  if (!terms) {
    error([`main.${languageData[lang]['register.error.terms']}`])
    return Promise.reject(`main.${languageData[lang]['register.error.terms']}`)
  }

  const attributesList = [
    {
      Name: 'phone_number',
      Value: `${userInfo.phone_number}`,
    },
    {
      Name: 'family_name',
      Value: userInfo.family_name,
    },
    {
      Name: 'name',
      Value: userInfo.name,
    },
    {
      Name: 'locale',
      Value: getCurrentLanguage(),
    },
  ]

  const isValid = validateUserData(userInfo, error)
  const isSchemaValid = validateSchema(userInfo)
  if (isValid && isSchemaValid) {
    return new Promise((resolve, reject) => {
      UserPool.signUp(
        userInfo.email,
        userInfo.password,
        attributesList,
        null,
        (err, data) => {
          if (err) {
            console.log(err.code)
            if (err.code === 'UsernameExistsException') {
              error([
                'main.' + languageData[lang]['register.error.existing.account'],
              ])
              reject(
                `main.${languageData[lang]['register.error.existing.account']}`,
              )
            } else if (err.code === 'InvalidPasswordException') {
              error([
                'main.' +
                  languageData[lang]['register.error.password.strength'],
              ])
              reject(
                `main.${languageData[lang]['register.error.password.strength']}`,
              )
            } else {
              error(['main.' + err.message])
              reject(`main.${err.message}`)
            }
          } else {
            resolve(data.user)
          }
        },
        { locale: lang },
      )
    })
  } else {
    return Promise.reject('Invalid user data')
  }
}

export const verifyUserWithCode = async (email, verificationCode) => {
  const lang = getCurrentLanguage()
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  })

  return new Promise((resolve, reject) => {
    user.confirmRegistration(
      verificationCode,
      false,
      (err, result) => {
        if (err) {
          reject(err)
          return
        }
        resolve(result)
      },
      { locale: lang },
    )
  })
}

export async function resendConfirmationEmail(email, router) {
  const lang = getCurrentLanguage()
  const userData = {
    Username: email,
    Pool: UserPool,
  }

  const cognitoUser = new CognitoUser(userData)
  cognitoUser.resendConfirmationCode(
    (err, result) => {
      if (err) {
        console.error('Error resending confirmation code:', err)
        // Handle the error here, such as showing an error message to the user
        return
      }
      // Redirect the user to the confirmation page
      router('/account/confirm', {
        state: {
          email: email,
          previousPath: window.location.pathname,
        },
      })
    },
    { locale: lang },
  )
}

export async function resendOnlyConfirmationEmail(email) {
  const lang = getCurrentLanguage()
  const userData = {
    Username: email,
    Pool: UserPool,
  }

  const cognitoUser = new CognitoUser(userData)
  cognitoUser.resendConfirmationCode(
    (err, result) => {
      if (err) {
        console.error('Error resending confirmation code:', err)
        // Handle the error here, such as showing an error message to the user
        return
      }
      console.log('Confirmation code resent successfully')
    },
    { locale: lang },
  )
}

export function changePassword(
  username,
  password,
  newpassword,
  newpasswordcheck,
  setError,
  router,
) {
  const lang = getCurrentLanguage()

  if (newpassword !== newpasswordcheck) {
    setError(['errorBox.The two passwords do not match, please try again!'])
    return
  }
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  })
  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)

  cognitoUser.authenticateUser(
    authenticationDetails,
    {
      onSuccess: function (result) {
        cognitoUser.changePassword(password, newpassword, (err, result) => {
          if (err) {
            setError(['errorBox.' + err.message])
          } else {
            router('/account/change-password-success', {
              state: { previousPath: window.location.pathname },
            })
          }
        })
      },
      onFailure: function (err) {
        setError(['errorBox.' + err.message])
      },
    },
    { locale: lang },
  )
}

export const updateAttributeAsPromise = (user, attribute, value) =>
  new Promise((resolve, reject) =>
    user.updateAttributes([{ Name: attribute, Value: value }], (err, data) => {
      if (err || !data) {
        reject(err)
        console.error(err)
      } else resolve(data)
    }),
  )
