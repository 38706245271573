import { languageData, getCurrentLanguage } from './../../languages/index'

export const pageWhoData = () => {
  const lang = getCurrentLanguage()
  return {
    ctaCardData: {
      title: languageData[lang]['page.who.we.are.cta.0.title'],
      subtitle: languageData[lang]['page.who.we.are.cta.0.subtitle'],
      bottomContent: {
        type: 'button',
        buttonLabel: languageData[lang]['page.who.we.are.cta.0.button'],
        buttonLink: '/account/register',
      },
    },
    multiCtaData: {
      title: languageData[lang]['page.who.we.are.multi.cta.main.title'],
      columns: [
        {
          imageSrc: 'colVision1',
          title: languageData[lang]['page.who.we.are.multi.cta.column.0.title'],
          paragraph:
            languageData[lang]['page.who.we.are.multi.cta.column.0.parapgraph'],
        },
        {
          imageSrc: 'colVision2',
          title: languageData[lang]['page.who.we.are.multi.cta.column.1.title'],
          paragraph:
            languageData[lang]['page.who.we.are.multi.cta.column.1.parapgraph'],
        },
      ],
    },
    multiCtaThreeData: {
      title: languageData[lang]['page.who.we.are.multi.three.cta.main.title'],
      transparentBackground: true,
      columns: [
        {
          imageSrc: 'ctaSimpleImage4',
          title:
            languageData[lang][
              'page.who.we.are.multi.three.cta.column.0.title'
            ],
          paragraph:
            languageData[lang][
              'page.who.we.are.multi.three.cta.column.0.parapgraph'
            ],
        },
        {
          imageSrc: 'ctaSimpleImage5',
          title:
            languageData[lang][
              'page.who.we.are.multi.three.cta.column.1.title'
            ],
          paragraph:
            languageData[lang][
              'page.who.we.are.multi.three.cta.column.1.parapgraph'
            ],
        },
        {
          imageSrc: 'ctaSimpleImage6',
          title:
            languageData[lang][
              'page.who.we.are.multi.three.cta.column.2.title'
            ],
          paragraph:
            languageData[lang][
              'page.who.we.are.multi.three.cta.column.2.parapgraph'
            ],
        },
      ],
    },
    ctaSimpleHeroData: {
      imgSrc: 'whoWeAreHero',
      asHero: true,
      title: languageData[lang]['page.who.we.are.cta.hero.title'],
      paragraphs: [
        languageData[lang]['page.who.we.are.cta.hero.parapgraph.0'],
        languageData[lang]['page.who.we.are.cta.hero.parapgraph.1'],
        languageData[lang]['page.who.we.are.cta.hero.parapgraph.2'],
      ],
    },
  }
}

export const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Who we are - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: `At Lelecabs we are passionate and dedicated to creating a more equitable and affordable experience for everyone. Find out more about who we are and what we provide. `,
    },
  },
]
