import styled from 'styled-components'

import { breakpoint } from '../../styles/global.style'
import { ext } from '../../utils/utils'

import { DRow, List } from '../atomic/Layout'
import { globalConstants } from '../super-mega-wrapper/global_constant'

export const StyledHeader = styled.header`
  position: fixed;
  width: 100%;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
  max-width: ${breakpoint.siteWidth};
  top: 0;
  z-index: 900;
  background-color: white;
  @media (max-width: ${breakpoint.mobile}) {
    top: ${(props) => (props.AppDownload ? '70px' : 0)};
  }
  @media (min-width: ${breakpoint.siteWidth}) {
    margin: auto;
    position: sticky;
    margin-top: -${globalConstants.HEADER_HEIGHT};
  }
`

export const ResponsiveRow = styled(ext(DRow))`
  height: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
  align-items: center;
  padding: 0;
  @media (max-width: ${breakpoint.bigtablet}) {
    flex-direction: ${(props) => (props.open ? 'column' : 'row')};
    display: flex;
    justify-content: space-between;
    height: ${(props) => (props.open ? 'calc(100vh - 30px)' : '50px')};
    padding-bottom: ${(props) => (props.open ? '30px' : '0px')};
    padding-top: 10px;
  }
  @media (max-width: ${breakpoint.tablet}) {
    width: calc(100%);
  }
`

export const HideListForMobile = styled(ext(List))`
  width: fit-content;
  margin-left: auto;
  display: flex;
  justify-content: ${({ last }) => (last ? 'flex-end' : 'center')};
  @media (max-width: 900px) {
    display: none;
  }
`

export const ShowOnlyInMobile = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${(props) => (props.menu ? '2px solid black' : 'none')};
  width: 100%;
  @media (min-width: ${breakpoint.bigtablet}) {
    display: none;
  }
`

export const MobileHamburgher = styled.button`
  background-color: white;
  border: none;
  cursor: pointer;
  top: 10px;
  @media (min-width: ${breakpoint.bigtablet}) {
    display: none;
  }
`

export const MobileMenuWrapper = styled.div`
  @media (max-width: ${breakpoint.tablet}) {
    overflow: hidden scroll;
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const MobileWrapper = styled.div`
  width: ${(props) => props.width || ''};
  @media (max-width: ${breakpoint.tablet}) {
    flex: ${(props) => props.grow || 0} 1 auto;
    width: ${(props) => props.width || ''};
    display: flex;
    padding-top: ${(props) => props.padding || ''};
  }
`

export const GrowList = styled(ext(List))`
  width: ${(props) => (props.fullWidth ? '100%' : 'initial')};
  display: flex;
  flex: 1 1 auto;
  padding: 30px ${(props) => (props.noPadding ? '0' : props.padding || '0')}px;
  > li {
    flex: 1;
  }
  @media (max-width: ${breakpoint.mobile}) {
    align-items: ${(props) =>
      props.alignItems ? props.alignItems : 'flex-start'};
    justify-content: ${(props) =>
      props.justifyContent ? props.justifyContent : 'center'};
    padding: 10px 0 0 0;
  }
`

export const MeAccount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  :hover {
    color: #777;
    cursor: pointer;
  }
`

export const AccountBoxWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 80px;
  border: 1px solid #aaa;
  width: 240px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10000;
  background-color: white;
`

export const UserBox = styled.div`
  width: 100%;
  display: flex;
  background-color: #a00a25;
  color: #fff;
  justify-content: space-between;
  align-items: center;
`

export const UserChildWrapper = styled.div`
  padding: 15px 30px;
  flex: 1;
  max-width: ${(props) => (props.icon ? '50px' : '80%')};
  text-align: left;
`

export const UserName = styled.p`
  font-weight: 700;
  line-height: 21px;
`

export const UserAccount = styled.p`
  color: #fdcfd7;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`

export const LogOutStyled = styled.h4`
  cursor: pointer;
  text-transform: none;
`

export const MobileLanguageWrapper = styled.li`
  @media (min-width: ${breakpoint.bigtablet}) {
    display: none;
  }
  list-style: none;
  top: 15px;
`

export const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 16px 0;
  @media (min-width: ${breakpoint.bigtablet}) {
    border-bottom: none;
  }
`
