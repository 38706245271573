import { getCurrentLanguage, languageData } from './../../languages/index'

export const pageCoverageData = () => {
  const lang = getCurrentLanguage()
  return {
    imageBannerData: {
      imgSrc: 'coverageMap',
      centerText: true,
      titleText: languageData[lang]['page.coverage.image.banner.title'],
      titleCentered: false,
    },
    searchboxData: {
      title: languageData[lang]['page.coverage.searchbox.title'],
      subtitle: languageData[lang]['page.coverage.searchbox.subtitle'],
      input: {
        inputType: 'location',
        id: 'searchbox',
        type: 'text',
        placeholder:
          languageData[lang]['page.coverage.searchbox.input.placeholder'],
      },
      error: {
        inputError: languageData[lang]['page.coverage.searchbox.input.error'],
        errorMessage:
          languageData[lang]['page.coverage.searchbox.error.message'],
        messageLinkText:
          languageData[lang]['page.coverage.searchbox.error.message.keyword'],
        messageLinkHref: '/contact-us',
      },
      match: {
        inputMessage: `You're in our covered zone, and we're ready to assist you.`,
        messageFooter: `We're excited to offer our services in your area! Book now to enjoy our convenient services.`,
        messageLinkText: 'Book now',
        messageHref: '/',
      },
    },
    pageParapgraphsData: {
      paragraphs: [
        {
          title: languageData[lang]['page.coverage.transfers.content.title.0'],
          contents: [
            languageData[lang]['page.coverage.transfers.content.paragraph.0'],
          ],
        },
        {
          title: languageData[lang]['page.coverage.transfers.content.title.1'],
          contents: [
            languageData[lang]['page.coverage.transfers.content.paragraph.1'],
          ],
        },
        {
          title: languageData[lang]['page.coverage.transfers.content.title.2'],
          contents: [
            'Bergamo Orio al Serio Il Caravaggio',
            'Bologna Guglielmo Marconi (BLQ)',
            'Milano Malpensa (MXP)',
            'Milano Linate (LIN)',
            'Treviso Angelo Canova (TSF)',
            'Venice Marco Polo (VCE)',
            'Verona Valerio Catullo (VRN)',
            'Florence Amerigo Vespucci (FLR)',
          ],
        },
      ],
    },
    ctaCardData: {
      title: 'Have a Unique Destination in Mind?',
      subtitle: `We're all about flexibility. If you have a unique destination in mind that's not currently covered, or if you need a custom trip, just reach out. We're here to make it happen. Your input matters to us, so don't hesitate to get in touch.`,
      bottomContent: {
        type: 'button',
        buttonLabel: 'Get in touch',
        buttonLink: '/contact-us',
      },
    },
  }
}

export const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Coverage - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: `Lelecabs' service coverage connects airports in Northern Italy to your desired destination accross the country. `,
    },
  },
]
