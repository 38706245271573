export const services = [
  {
    id: 'lele',
    margin: 3,
    icon: 'car',
    label: 'Lele',
    bags: '2x',
    people: '4x',
    title:
      'Classic taxi reservation. You will not share the taxi with anyone. Maximum 4 people and 2 big bags',
  },
  {
    id: 'x-lele',
    margin: 3,
    icon: 'van',
    label: 'X-Lele',
    bags: '6x',
    people: '8x',
    title:
      'Reserve a Van. You will not share the taxi with anyone. Maximum 8 people and 6 big bags',
  },
  {
    id: 's-lele',
    icon: 'seat',
    label: 'S-Lele',
    bags: 'nx',
    people: 'nx',
    title: 'Shared journey. Coming soon...',
    disabled: true,
  },
]

export const fakeSelectSettings = {
  value: 'ALL',
  options: [
    { value: 'ALL', label: 'All restaurant types' },
    { value: 'Drive', label: 'Drive In' },
  ],
}
