import { useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { saveState } from './../../utils/localStorage'
import languages, {
  DefaultFlag,
  Flag,
  getCurrentLanguage,
  getPathWithoutLang,
} from './../../languages'

import { ChevronDown } from '@styled-icons/boxicons-solid/ChevronDown'
import { ChevronUp } from '@styled-icons/boxicons-solid/ChevronUp'
import * as S from './styled'
import { useClickOutside } from '../../hooks/useClickOutside'
import { updateAttributeAsPromise } from '../../utils/accountUtils'
import UserPool from './../auth-context/UserPool'

const getFlag = (key) => {
  let TheFlag = Flag[key]
  if (!TheFlag) TheFlag = DefaultFlag
  return <TheFlag width="20px" />
}

const LanguagePicker = () => {
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)
  const [language, setLanguage] = useState(getCurrentLanguage())
  const langPickerRef = useRef(null)
  const location = useLocation()

  const user = UserPool.getCurrentUser()

  useClickOutside(langPickerRef, () => {
    if (menuOpen) {
      setMenuOpen(false)
    }
  })

  const handleSetLanguage = (lang) => {
    if (language !== lang) {
      saveState('lang', lang)
      setLanguage(lang)
      const currentPath = getPathWithoutLang()
      let newPath = `${lang === 'EN' ? '' : lang.toLowerCase()}${currentPath}`
      if (newPath && newPath[0] !== '/') newPath = '/' + newPath
      navigate(newPath, { state: { ...location?.state } })
    }

    if (user) {
      user.getSession((err, session) => {
        if (err || !session.isValid()) {
          console.log('User needs to re-authenticate')
        } else {
          const attribute = 'locale'
          const value = lang

          updateAttributeAsPromise(user, attribute, value)
            .then(() => {})
            .catch((error) => {
              console.error('Error updating locale attribute:', error)
            })
        }
      })
    } else {
      console.log('User is not authenticated')
    }
  }

  return (
    <S.Wrapper ref={langPickerRef} onClick={() => setMenuOpen(!menuOpen)}>
      {language ? getFlag(language) : null}
      {language}
      <S.ArrowWrapper>
        {menuOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </S.ArrowWrapper>
      {menuOpen ? (
        <S.Menu>
          {languages.map((lang) => (
            <S.LanguageBoxWrapper
              key={lang.value}
              activeLang={lang.value === language}
              onClick={() => handleSetLanguage(lang.value)}
            >
              <S.LanguageBox key={lang.value}>
                {lang.value ? getFlag(lang.value) : null}
                &nbsp; &nbsp;
                {lang.longLabel}
              </S.LanguageBox>
              {lang.value === language && <S.CheckMarkIcon size={20} />}
            </S.LanguageBoxWrapper>
          ))}
        </S.Menu>
      ) : null}
    </S.Wrapper>
  )
}

export default LanguagePicker
