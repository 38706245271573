import { Tooltip } from 'react-tooltip'

const TooltipElement = ({ id, content, place, anchor, tooltipStyle }) => {
  return (
    <>
      <div
        data-tooltip-id={id}
        data-tooltip-html={content}
        data-tooltip-place={place}
      >
        {anchor}
      </div>
      <Tooltip style={tooltipStyle} id={id} />
    </>
  )
}

export default TooltipElement
