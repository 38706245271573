import lele3Background from './Images/FInal-hero_banner 1.png'
import coverageMap from './Images/coverageMap.png'
import ctaSimpleImage1 from './Images/ctaSimple1.png'
import gridImageDrive from './Images/gridImageDrive.png'
import gridImageWallet from './Images/gridImageWallet.png'
import gridImageTask from './Images/gridImageTask.png'
import gridImageCheck from './Images/gridImageCheck.png'
import gridImageSofa from './Images/gridImageSofa.png'
import gridImageClock from './Images/gridImageClock.png'
import arrow_6 from './Images/arrow_6.png'
import arrow_1 from './Images/arrow_1.png'
import arrow_2 from './Images/arrow_2.png'
import arrow_3 from './Images/arrow_3.png'
import arrow_4 from './Images/arrow_4.png'
import arrow_5 from './Images/arrow_5.png'
import logoPng from './Images/logoPng.png'
import colVision1 from './Images/colVision1.png'
import colVision2 from './Images/colVision2.png'
import colSteps1 from './Images/Frame26085847.png'
import colSteps2 from './Images/Frame 26085848.png'
import colSteps3 from './Images/Frame 26085849.png'
import logoSvg from './Images/LeleCabs.svg'
import mdi_twitter from './Images/mdi_twitter.png'
import mdi_instagram from './Images/mdi_instagram.png'
import leleService from './Images/leleService.png'
import xleleService from './Images/xleleService.png'
import sleleService from './Images/sleleService.png'
import whoWeAreHero from './Images/Group-58.png'
import forDriversHero from './Images/Group_59.png'
import ctaSimpleImage4 from './Images/tim-foster-TW-rxLXqJUsx-unsplash.png'
import ctaSimpleImage5 from './Images/tim-foster-TW-rxLXqJUs-unsplash 1.png'
import ctaSimpleImage6 from './Images/andrei-seritan-UVjkrNQwt-E-unsplash 1.png'
import experiencesHero from './Images/Group 53.png'
import ctaSimpleImage7 from './Images/volodymyr-proskurovskyi1.png'
import gridImageExperience1 from './Images/Frame 26085868.png'
import gridImageExperience2 from './Images/Frame 26085869.png'
import gridImageExperience3 from './Images/Frame 26085870.png'
import gridImageExperience4 from './Images/Frame 26085871.png'
import gridImageExperience5 from './Images/Frame 26085872.png'
import ctaSimpleImage8 from './Images/ctaSimpleImage8.png'
import driverCTAleft from './Images/driverCTA-left 1.png'
import driverCTAright from './Images/driverCTA-right 1.png'
import gridImage80 from './Images/Frame 260858680.png'
import gridImage81 from './Images/Frame 260858681.png'
import gridImage82 from './Images/Frame 260858682.png'
import gridImage83 from './Images/Frame 260858683.png'
import gridImage84 from './Images/Frame 260858684.png'

export const imagesFiles = {
  arrow_1,
  arrow_2,
  arrow_3,
  arrow_4,
  arrow_5,
  arrow_6,
  coverageMap,
  ctaSimpleImage1,
  gridImageDrive,
  gridImageWallet,
  gridImageTask,
  gridImageCheck,
  gridImageSofa,
  gridImageClock,
  lele3Background,
  logoPng,
  logoSvg,
  mdi_twitter,
  mdi_instagram,
  colVision1,
  colVision2,
  colSteps1,
  colSteps2,
  colSteps3,
  leleService,
  xleleService,
  sleleService,
  whoWeAreHero,
  ctaSimpleImage4,
  ctaSimpleImage5,
  ctaSimpleImage6,
  forDriversHero,
  experiencesHero,
  ctaSimpleImage7,
  gridImageExperience1,
  gridImageExperience2,
  gridImageExperience3,
  gridImageExperience4,
  gridImageExperience5,
  ctaSimpleImage8,
  driverCTAleft,
  driverCTAright,
  gridImage80,
  gridImage81,
  gridImage82,
  gridImage83,
  gridImage84,
}
