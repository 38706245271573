import React from 'react'
import { PageFromJson } from '../components/utilities/MainContainer'
import { languageData, getCurrentLanguage } from './../languages/index'

const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Itineraries',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: 'Itineraries.',
    },
  },
]

const getData = () => {
  const lang = getCurrentLanguage()
  return [
    {
      id: 'carousel',
      data: {
        spaced: true,
        children: [
          {
            transparent: true,
            heading: languageData[lang]['dolomites.tour.hero.title'],
            content_background: 'transparent-black',
            text_colour: 'white',
            body: {
              content: `<p>${languageData[lang]['dolomites.tour.hero.content.0']}</p>
            <p>${languageData[lang]['dolomites.tour.hero.content.1']}</p>`,
              format: 'html',
            },
            type: 'empty-block',
            justifyContent: 'center',
            alignItems: 'center',
            position: 1,
          },
        ],
        carousel: [
          {
            position: 1,
            key: 'slide1',
            src: '/images/dolomites-hero.png',
            mobileSrc: '/images/dolomites-hero.png',
            meta: {
              width: 1900,
              height: 850,
              title: 'dolomites',
              alt: 'dolomites image',
            },
          },
        ],
      },
    },
    {
      id: 'timeline',
      data: {
        title: languageData[lang]['itineraries.dolomites.main.title'],
        buttonLabel:
          languageData[lang]['itineraries.dolomites.contact.box.button'],
        stops: [
          {
            hour: '8:00 ',
            destination:
              languageData[lang]['itineraries.dolomites.stop.0.title'],
            description:
              languageData[lang]['itineraries.dolomites.stop.0.subtitle'],
          },
          {
            hour: '9:30 ',
            destination:
              languageData[lang]['itineraries.dolomites.stop.1.title'],
            description: '',
          },
          {
            hour: '10:30 ',
            destination:
              languageData[lang]['itineraries.dolomites.stop.2.title'],
            description:
              languageData[lang]['itineraries.dolomites.stop.2.subtitle'],
            link: '/itineraries/dolomites/misurina',
            linkText: languageData[lang]['itineraries.dolomites.learn.more'],
          },
          {
            hour: '12:00 ',
            destination:
              languageData[lang]['itineraries.dolomites.stop.3.title'],
            description:
              languageData[lang]['itineraries.dolomites.stop.3.subtitle'],
            link: '/itineraries/dolomites/faloria',
            linkText: languageData[lang]['itineraries.dolomites.learn.more'],
          },
          {
            hour: '13:30',
            destination:
              languageData[lang]['itineraries.dolomites.stop.4.title'],
            description:
              languageData[lang]['itineraries.dolomites.stop.4.subtitle'],
          },
          {
            hour: languageData[lang]['itineraries.dolomites.stop.5.time'],
            destination:
              languageData[lang]['itineraries.dolomites.stop.5.title'],
            description:
              languageData[lang]['itineraries.dolomites.stop.5.subtitle'],
            link: '/itineraries/dolomites/cortina',
            linkText: languageData[lang]['itineraries.dolomites.learn.more'],
          },
          {
            hour: '16:00',
            destination:
              languageData[lang]['itineraries.dolomites.stop.6.title'],
            description: '',
          },
        ],
      },
    },
    {
      id: 'column-notice-box',
      data: {
        title: languageData[lang]['itinerary.dolomites.information.title'],
        columns: [
          {
            title:
              languageData[lang][
                'itinerary.dolomites.information.column.0.title'
              ],
            lists: [
              languageData[lang][
                'itinerary.dolomites.information.column.0.content.0'
              ],
              languageData[lang][
                'itinerary.dolomites.information.column.0.content.1'
              ],
            ],
          },
          {
            title:
              languageData[lang][
                'itinerary.dolomites.information.column.1.title'
              ],
            lists: [
              languageData[lang][
                'itinerary.dolomites.information.column.1.content.0'
              ],
              languageData[lang][
                'itinerary.dolomites.information.column.1.content.1'
              ],
            ],
          },
          {
            title:
              languageData[lang][
                'itinerary.dolomites.information.column.2.title'
              ],
            lists: [
              languageData[lang][
                'itinerary.dolomites.information.column.2.content.0'
              ],
              languageData[lang][
                'itinerary.dolomites.information.column.2.content.1'
              ],
              languageData[lang][
                'itinerary.dolomites.information.column.2.content.2'
              ],
            ],
          },
        ],
      },
    },
  ]
}

function ItineraryDolomites() {
  const components = getData()
  return <PageFromJson metaPage={metaPage} components={components} />
}

export default ItineraryDolomites
