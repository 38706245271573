import styled from 'styled-components'
import * as T from './../atomic/Typography'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  background-image: url('${(props) => props.background}');
  height: 600px;
  /* background-image: url('./../../styles/Images/coverageMap.png'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: 80px;
  text-align: ${(props) => (props.centerText ? 'center' : 'left')};
  min-height: 480px;
  @media (max-width: ${breakpoint.medium}) {
    height: 400px;
  }
  @media (max-width: ${breakpoint.smallTablet}) {
    min-height: 265px;
    height: unset;
    background-size: cover;
  }
  @media (max-width: ${breakpoint.mobile}) {
    background-position: -178px center;
  }
  @media (min-width: ${breakpoint.large}) {
    background-size: cover;
    background-position: center center;
  }
`

export const Title = styled(T.Uh3)`
  font-size: 32px;
  font-weight: 700;
  margin-top: -40px;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  @media (max-width: ${breakpoint.smallTablet}) {
    font-size: 1.5em;
  }
  @media (max-width: ${breakpoint.mobile}) {
    font-size: 1.3em;
  }
`
