import React from 'react'
import { PageFromJson } from '../../components/utilities/MainContainer'

const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'My Trips - Account Dashboard - Lelecabs.com',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content:
        'Check your upcoming and past trips, save an invoice or report an incident!',
    },
  },
]

const components = [
  // {
  //   id: 'page_intro',
  //   data: {
  //     noUnderline: true,
  //     children: {
  //       heading: 'Trips'
  //     }
  //   }
  // },
  { id: 'tripList' },
]

function AccountHome() {
  return (
    <PageFromJson pageType="user" metaPage={metaPage} components={components} />
  )
}

export default AccountHome
