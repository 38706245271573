import React from 'react'
import { PageFromJson } from './../../components/utilities/MainContainer'

import { languageData, getCurrentLanguage } from './../../languages/index'

const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Lake Misurina',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content:
        'Lelecabs offers a unique range of personalised experiences accross Italy. Find out more about our services.',
    },
  },
]

const getData = () => {
  const lang = getCurrentLanguage()
  return [
    {
      id: 'itineraryDescription',
      data: {
        navigation: {
          itinerarySection: languageData[lang]['itinerary.dolomites.section'],
          itinerarySectionLink: '/itineraries/dolomites',
          previousStopsText:
            languageData[lang]['itinerary.section.previous.stops.text'],
          nextStopsText:
            languageData[lang]['itinerary.section.next.stops.text'],
          nextStepLink: '/itineraries/dolomites/cortina',
          previousStepLink: '/itineraries/dolomites/misurina',
        },
        title: languageData[lang]['itinerary.faloria.section.title'],
        paragraphs: [
          languageData[lang]['itinerary.faloria.section.subtitle.0'],
          languageData[lang]['itinerary.faloria.section.subtitle.1'],
        ],
        experienceTitle:
          languageData[lang]['itinerary.faloria.section.experience.title'],
        rows: [
          {
            title:
              languageData[lang][
                'itinerary.faloria.section.experience.row.0.title'
              ],
            paragraph:
              languageData[lang][
                'itinerary.faloria.section.experience.row.0.content.0'
              ],
          },
          {
            title:
              languageData[lang][
                'itinerary.faloria.section.experience.row.1.title'
              ],
            paragraph:
              languageData[lang][
                'itinerary.faloria.section.experience.row.1.content.0'
              ],
          },
          {
            title:
              languageData[lang][
                'itinerary.faloria.section.experience.row.2.title'
              ],
            paragraph:
              languageData[lang][
                'itinerary.faloria.section.experience.row.2.content.0'
              ],
          },
        ],
        photoAlbumText: languageData[lang]['itinerary.section.album.text'],
        photos: [
          {
            src: '/images/faloria1.png',
            rowEnd: '3',
            rowStart: '1',
          },
          {
            src: '/images/faloria2.png',
            columnEnd: '4',
            rowStart: '-2',
          },
          {
            src: '/images/faloria3.png',
            rowStart: '1',
            columnEnd: '4',
            columnStart: '2',
          },
          {
            src: '/images/faloria4.png',
            rowEnd: '-1',
            rowStart: '-2',
            columnEnd: '3',
          },
        ],
        photosGalleryFull: [
          {
            src: '/images/faloria1.png',
          },
          {
            src: '/images/faloria2full.jpg',
          },
          {
            src: '/images/faloria3full.JPG',
          },
          {
            src: '/images/faloria4full.jpg',
          },
        ],
        infoBox: {
          data: {
            title:
              languageData[lang]['itinerary.faloria.section.info.box.title'],
            photo: '/images/faloriaMenu.png',
            paragraphs: [
              languageData[lang][
                'itinerary.faloria.section.info.box.content.0'
              ],
              languageData[lang][
                'itinerary.faloria.section.info.box.content.1'
              ],
            ],
            button: {
              label:
                languageData[lang][
                  'itinerary.faloria.section.info.box.button.text'
                ],
              href: 'https://faloriacristallo.it/rifugio-faloria/',
            },
          },
        },
      },
    },
  ]
}

function Faloria() {
  const components = getData()
  return <PageFromJson metaPage={metaPage} components={components} />
}

export default Faloria
