import MultiColMarketing from '../../components/multi-col-marketing'
import { Wrapper } from './styled'
import CtaCard from './../../components/cta-card'
import { metaPage, pageWhoData } from './data'
import CtaTwoColSimple from '../../components/cta-two-col-simple'
import { DefaultLayoutNew } from '../../components/utilities/DefaultLayoutNew'
import ShowWhenLoggedOut from './../../components/show-when-logged-out'
import { getCurrentLanguage } from '../../languages'
import InjectMeta from '../../components/utilities/InjectMeta'
import PageLoadAnimation from '../../styles/animations'

const PageWhoAreWe = () => {
  const data = pageWhoData()
  const lang = getCurrentLanguage()
  return (
    <>
      <InjectMeta meta={metaPage} lang={lang} />
      <PageLoadAnimation>
        <DefaultLayoutNew pageType="viewer">
          <Wrapper>
            <CtaTwoColSimple data={data.ctaSimpleHeroData} />
            <MultiColMarketing data={data.multiCtaData} />
            <MultiColMarketing data={data.multiCtaThreeData} />
            <ShowWhenLoggedOut>
              <CtaCard data={data.ctaCardData} />
            </ShowWhenLoggedOut>
          </Wrapper>
        </DefaultLayoutNew>
      </PageLoadAnimation>
    </>
  )
}

export default PageWhoAreWe
