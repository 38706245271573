import { CircleFill } from '@styled-icons/bootstrap/CircleFill'
import { breakpoint } from '../../styles/global.style'
import styled, { css } from 'styled-components'
import { SimCardDownload } from '@styled-icons/material-rounded/SimCardDownload'
import { Info } from '@styled-icons/evaicons-solid/Info'
import * as T from './../atomic/Typography'

const arrowIconSrc = {
  notMobile: '/images/single-trip/trip-destination.svg',
  mobile: '/images/single-trip/trip-destination-mobile.svg',
}

const tripArrowIconSrc = '/images/single-trip/trip-destination-new.svg'

const buttonType = {
  primary: {
    bg: '#4e4e4e',
    hover: '#777676',
  },
  secondary: {
    bg: 'none',
    hover: 'none',
  },
  danger: {
    bg: '#ab0000',
    hover: '#8e0404',
  },
}

export const Container = styled.div`
  width: 100%;
  max-width: 850px;
`

export const CardParentWrapper = styled.div`
  border-radius: ${(props) => (!props.past ? '12px' : '0')};
  box-shadow: ${(props) =>
    !props.past ? '0px 2px 8px 0px rgba(0, 0, 0, 0.25)' : 'none'};
`

export const TripDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const DetailIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`

export const CardWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  border-radius: 0px 0px 12px 12px;
  box-sizing: border-box;
  border-radius: ${(props) => (props.past ? '12px' : '0px 0px 12px 12px')};
  box-shadow: ${(props) =>
    props.past ? '0px 2px 8px 0px rgba(0, 0, 0, 0.25)' : 'none'};
  @media (max-width: ${breakpoint.mobile}) {
    padding: 30px 20px;
  }
`

export const Header = styled.div`
  display: ${(props) => (props.return ? 'row-reverse' : 'flex')};
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
  transform: ${(props) => (props.return ? 'scaleX(-1)' : '')};
  p {
    transform: ${(props) => (props.return ? 'scaleX(-1)' : '')};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  transform: ${(props) => (props.return ? 'scaleX(-1)' : '')};
  p {
    transform: ${(props) => (props.return ? 'scaleX(-1)' : '')};
  }
  @media (max-width: ${breakpoint.mobile}) {
    gap: 20px;
  }
`

export const Cost = styled.div`
  font-family: 'RalewayBold';
  font-size: 18px;
  position: relative;
  line-height: 18px;
  color: #484848;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

export const Divider = styled.div`
  height: 1px;
  background: #efefef;
`

export const H3 = styled.h3`
  text-transform: none;
  font-family: 'RalewayBold';
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.4px;
  /* margin-bottom: ${(props) => (props.return ? '25px' : '')}; */
  transform: ${(props) => (props.return ? 'scaleX(-1)' : '')};
  color: #484848;
`

export const H4 = styled.h4`
  font-family: 'RalewayBold';
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`

export const Footer = styled.div`
  width: 100%;
  max-width: 700px;
  padding-top: 20px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${breakpoint.mobile}) {
    flex-wrap: wrap;
    gap: 20px;
  }
`

export const ButtonRow = styled.div`
  display: flex;
  gap: 12px;
  @media (max-width: ${breakpoint.medium}) {
    justify-content: space-between;
    width: 100%;
    > button,
    > div {
      flex: 1 1 50%;
      max-width: 50%;
    }
  }
  @media (max-width: ${breakpoint.bigtablet}) {
    width: auto;
    button,
    > div {
      flex: 0 0 auto;
      max-width: none;
    }
  }
  @media (max-width: ${breakpoint.smallTablet}) {
    justify-content: space-between;
    width: 100%;
    > button,
    > div {
      flex: 1 1 50%;
      max-width: 50%;
    }
  }
  @media (max-width: ${breakpoint.mobile}) {
    flex-direction: column;
    > button,
    > div {
      flex: 0 0 auto;
      width: 100%;
      max-width: 100%;
    }
  }
`

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  flex-wrap: wrap;
  @media (max-width: ${breakpoint.medium}) {
    width: 100%;
    > button,
    > div {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  @media (max-width: ${breakpoint.bigtablet}) {
    width: auto;
    > button,
    > div {
      flex: 0 0 auto;
      max-width: none;
    }
  }
  @media (max-width: ${breakpoint.smallTablet}) {
    width: 100%;
    > button,
    > div {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
`

export const Col = styled.div`
  max-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: 18px;
  gap: 10px;
  @media (max-width: ${breakpoint.mobile}) {
    min-width: 40%;
    justify-content: flex-start;
  }
`

const bar = css`
  width: 100%;
  max-width: 580px;
  padding: 20px;
  @media (max-width: ${breakpoint.mobile}) {
    padding-left: 90px;
    padding-right: 60px;
    width: 100%;
    margin: 0;
  }
`

export const TripLocationsWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`

export const DestinationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const From = styled.div`
  ${bar};
  box-sizing: border-box;
  border: 1px solid #484848;
`

export const To = styled.div`
  ${bar};
  border: 1px solid ${(props) => (props.past ? '#34A853' : '#F9A000')};
  margin-top: 40px;
`

export const ArrowIcon = styled.div`
  background: url(${arrowIconSrc.notMobile}) no-repeat;
  background-size: contain;
  width: 100%;
  max-width: 60px;
  height: 60px;
  @media (max-width: ${breakpoint.mobile}) {
    background-image: url(${arrowIconSrc.mobile});
    position: absolute;
    height: 100px;
    top: -70%;
    left: 10%;
  }
`

export const TripArrowIcon = styled.div`
  height: 52px;
  padding: 4px 0px;
  background: url(${tripArrowIconSrc}) no-repeat;
  background-size: contain;
  width: 100%;
  max-width: 13px;
  @media (max-width: ${breakpoint.mobile}) {
    padding: 0px;
  }
`

export const FooterIcon = styled.img`
  max-width: 30px;
  height: auto;
`

export const Circle = styled(CircleFill)`
  color: ${(props) => (props.return === 'Return' ? '#FF9900' : '#FCE0A4')};
  width: 28px;
  height: auto;
`

export const EditedLabel = styled(H4)`
  margin-left: auto;
  @media (max-width: ${(props) => (props.edit ? '440px' : '350px')}) {
    font-size: 16px;
  }
`

export const ToWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  position: relative;
`

export const Wrapper = styled.div`
  height: 56px;
  background: ${(props) =>
    props.return === 'Return'
      ? 'linear-gradient(90deg, #FFE7BB 0%, #EAB7EB 100%);'
      : props.edit
      ? 'linear-gradient(90deg, #FFE7BB 0%, #B7DEEB 100%);'
      : 'linear-gradient(93deg, #F2C084 1.75%, #FCE5A1 98.19%);'};
  display: flex;
  border-radius: 12px 12px 0px 0px;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  @media (max-width: ${breakpoint.mobile}) {
    padding: 0 16px;
  }
  @media (max-width: 440px) {
    padding: 8px;
    height: auto;
  }
`

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: ${(props) => (props.edit ? '440px' : '350px')}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const DownloadReceipt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoint.medium}) {
    width: 100%;
  }
  @media (max-width: ${breakpoint.bigtablet}) {
    width: 142px;
  }
  @media (max-width: ${breakpoint.smallTablet}) {
    max-width: 100%;
  }
`

export const MoreDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const DetailsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const DetailsListRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`

export const ReceiptIcon = styled(SimCardDownload)`
  cursor: pointer;
`

export const PriceInfo = styled(Info)`
  color: #3959bc;
  cursor: pointer;
  margin-left: auto;
  position: relative;
`

export const PriceBreakdownWrapper = styled.div`
  display: flex;
  position: absolute;
  z-index: 3;
  right: -17px;
  top: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  width: 261px;
  background: #ffffff;
  border-radius: 8px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25));
  margin-top: 15px;
  &::after {
    content: '';
    position: absolute;
    top: -8px;
    right: 10px;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
  }
  @media (max-width: ${breakpoint.mobile}) {
    max-width: 170px;
  }
  @media (max-width: ${breakpoint.mobile}) {
    max-width: 170px;
  }
`

export const BreakdownTitle = styled(T.P2)``

export const DetailedBreakdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 14px 4px 14px;
  width: 100%;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 15px;
  p {
    color: #7a7a7a;
  }
`
