import styled from 'styled-components'
import { P3 } from '../atomic/Typography'

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FromToCard = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const Label = styled(P3)`
  line-height: 12px;
`

export const TimeLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  position: relative;
  align-items: start;
  justify-content: space-between;
  &::after {
    content: '';
    position: absolute;
    top: 14px;
    left: 6px;
    width: 4px;
    height: calc(100% - 28px);
    background: black;
  }
`

export const Circle = styled.div`
  width: 15px;
  height: 15px;
  border: 3px solid black;
  border-radius: 50%;
  flex-shrink: 0;
  align-self: ${(props) => (props.last ? 'end' : 'start')};
`
