import { getCurrentLanguage, languageData } from './../../languages/index'

export const pageData = () => {
  const lang = getCurrentLanguage()
  return {
    ctaCardData: {
      simple: true,
      title: languageData[lang]['page.why.lelecabs.cta.card.0.title'],
      subtitle: languageData[lang]['page.why.lelecabs.cta.card.0.subtitle'],
      bottomContent: {
        type: 'button',
        buttonLabel:
          languageData[lang]['page.why.lelecabs.cta.card.0.button.label'],
        buttonLink: '/',
      },
    },
    ctaSimpleHeroData: {
      imgSrc: 'ctaSimpleImage7',
      title: languageData[lang]['page.why.lelecabs.cta.hero.title'],
      paragraphs: [
        languageData[lang]['page.why.lelecabs.cta.hero.paragraph.0'],
      ],
      buttonLabel: languageData[lang]['page.why.lelecabs.cta.hero.button'],
      buttonHref: '/who-we-are',
    },
    pageCircleData: {
      logo: {
        logoImage: 'logoPng',
        position: {
          columnStart: '2',
          columnEnd: '3',
          rowStart: '3',
        },
      },
      mainTitle: languageData[lang]['home.branded.circle.main.title'],
      elements: [
        {
          icon: 'gridImageDrive',
          elementTitle:
            languageData[lang]['home.branded.circle.element.0.title'],
          elementText:
            languageData[lang]['home.branded.circle.element.0.content'],
          position: {
            columnStart: '2',
            columnEnd: '3',
          },
        },
        {
          icon: 'gridImageTask',
          elementTitle:
            languageData[lang]['home.branded.circle.element.1.title'],
          elementText:
            languageData[lang]['home.branded.circle.element.1.content'],
          position: {
            columnStart: '1',
            columnEnd: '2',
          },
        },
        {
          icon: 'gridImageSofa',
          elementTitle:
            languageData[lang]['home.branded.circle.element.2.title'],
          elementText:
            languageData[lang]['home.branded.circle.element.2.content'],
          position: {
            columnStart: '3',
            columnEnd: '4',
          },
        },
        {
          icon: 'gridImageClock',
          elementTitle:
            languageData[lang]['home.branded.circle.element.3.title'],
          elementText:
            languageData[lang]['home.branded.circle.element.3.content'],
          position: {
            columnStart: '1',
            columnEnd: '2',
          },
        },
        {
          icon: 'gridImageCheck',
          elementTitle:
            languageData[lang]['home.branded.circle.element.4.title'],
          elementText:
            languageData[lang]['home.branded.circle.element.4.content'],
          position: {
            columnStart: '3',
            columnEnd: '4',
          },
        },
        {
          icon: 'gridImageWallet',
          elementTitle:
            languageData[lang]['home.branded.circle.element.5.title'],
          elementText:
            languageData[lang]['home.branded.circle.element.5.content'],
          position: {
            columnStart: '2',
            columnEnd: '3',
          },
        },
      ],
      arrows: [
        {
          arrowIcon: 'arrow_4',
          position: {
            columnStart: '1',
            columnEnd: '2',
            rowStart: '1',
            justifyPosition: 'end',
          },
        },
        {
          arrowIcon: 'arrow_1',
          position: {
            columnStart: '3',
            columnEnd: '4',
            rowStart: '1',
            justifyPosition: 'start',
          },
        },
        {
          arrowIcon: 'arrow_3',
          position: {
            columnStart: '-4',
            columnEnd: '-3',
            rowStart: '3',
          },
        },
        {
          arrowIcon: 'arrow_2',
          position: {
            columnStart: '3',
            columnEnd: '4',
            rowStart: '3',
          },
        },
        {
          arrowIcon: 'arrow_6',
          position: {
            columnStart: '1',
            columnEnd: '2',
            rowStart: '5',
            justifyPosition: 'end',
            alignPosition: 'center',
          },
        },
        {
          arrowIcon: 'arrow_5',
          position: {
            columnStart: '3',
            columnEnd: '4',
            rowStart: '5',
            justifyPosition: 'baseline',
            alignPosition: 'center',
          },
        },
      ],
    },
    customServiceData: {
      title: languageData[lang]['Most popular ride options'],
      subtitle: languageData[lang]['page.why.lelecabs.cta.card.0.subtitle'],
      services: [
        {
          img: 'leleService',
          title: languageData[lang]['page.why.lelecabs.custom.service.0.title'],
          paragraph:
            languageData[lang]['page.why.lelecabs.custom.service.0.paragraph'],
          buttonLabel:
            languageData[lang]['page.why.lelecabs.custom.service.0.button'],
          buttonHref: '/',
        },
        {
          img: 'xleleService',
          title: languageData[lang]['page.why.lelecabs.custom.service.1.title'],
          paragraph:
            languageData[lang]['page.why.lelecabs.custom.service.1.paragraph'],
          buttonLabel:
            languageData[lang]['page.why.lelecabs.custom.service.1.button'],
          buttonHref: '/',
        },
        {
          img: 'sleleService',
          title: languageData[lang]['page.why.lelecabs.custom.service.2.title'],
          paragraph:
            languageData[lang]['page.why.lelecabs.custom.service.2.paragraph'],
          buttonLabel: '',
          buttonHref: '',
          disabled: true,
        },
      ],
    },
    multiCtaThreeData: {
      title: languageData[lang]['page.why.lelecabs.multi.three.main.title'],
      transparentBackground: true,
      columns: [
        {
          imageSrc: 'ctaSimpleImage4',
          title:
            languageData[lang]['page.why.lelecabs.multi.three.column.0.title'],
          paragraph:
            languageData[lang][
              'page.why.lelecabs.multi.three.column.0.paragraph'
            ],
        },
        {
          imageSrc: 'ctaSimpleImage5',
          title:
            languageData[lang]['page.why.lelecabs.multi.three.column.1.title'],
          paragraph:
            languageData[lang][
              'page.why.lelecabs.multi.three.column.1.paragraph'
            ],
        },
        {
          imageSrc: 'ctaSimpleImage6',
          title:
            languageData[lang]['page.why.lelecabs.multi.three.column.2.title'],
          paragraph:
            languageData[lang][
              'page.why.lelecabs.multi.three.column.2.paragraph'
            ],
        },
      ],
    },
    ctaCardSignData: {
      title: languageData[lang]['page.why.lelecabs.cta.card.1.title'],
      subtitle: languageData[lang]['page.why.lelecabs.cta.card.1.subtitle'],
      bottomContent: {
        type: 'button',
        buttonLabel:
          languageData[lang]['page.why.lelecabs.cta.card.1.button.label'],
        buttonLink: '/account/register',
      },
    },
  }
}

export const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Why Lelecabs - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: `Our mission is to provide safe, convenient, and affordable transportation solutions that doesn't compromise on any other aspects. Find out why you should choose Lelecabs for your next trip to Italy.`,
    },
  },
]
