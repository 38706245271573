import { languageData, getCurrentLanguage } from '../../languages'

export const getData = () => {
  const lang = getCurrentLanguage()

  return {
    loggedLogOut: [
      {
        id: 'logout',
        type: 'text',
        label: languageData[lang]['header.menu.logged.link.3'],
      },
    ],
    loggedMenuData: [
      {
        id: 'account',
        type: 'text',
        label: languageData[lang]['header.menu.logged.link.1'],
      },
      {
        id: 'account/settings',
        type: 'text',
        label: languageData[lang]['header.menu.logged.link.2'],
      },
    ],
    loggedText: languageData[lang]['header.menu.mobile.logged.auth.0'],
    mainMenuData: [
      {
        id: 'how-it-works',
        type: 'text',
        label: languageData[lang]['header.new.tab.0'],
      },
      {
        id: 'operation-coverage',
        type: 'text',
        label: languageData[lang]['header.tab.1'],
      },
      {
        id: 'experiences',
        type: 'text',
        label: languageData[lang]['header.new.tab.2'],
        subpages: [
          {
            id: 'itineraries/dolomites',
            label: languageData[lang]['header.menu.mobile.link.3.0'],
          },
        ],
      },
      // {id: 'hire', type: 'text', label: 'Hire'}
    ],
    mobileList: [
      {
        data: [
          {
            id: 'how-it-works',
            label: languageData[lang]['header.new.menu.mobile.logged.link.7.0'],
          },
          {
            id: 'why-lelecabs',
            label: languageData[lang]['header.new.menu.mobile.logged.link.3.0'],
          },
          {
            id: 'who-we-are',
            label: languageData[lang]['header.new.menu.mobile.logged.link.3.1'],
          },
          {
            id: 'operation-coverage',
            label: languageData[lang]['header.menu.mobile.logged.link.1.0'],
          },
          {
            id: 'experiences',
            label: languageData[lang]['header.new.menu.mobile.link.3.1'],
          },
          {
            id: 'for-drivers',
            label: languageData[lang]['header.new.menu.mobile.logged.link.2.0'],
          },
        ],
      },
      {
        data: [
          {
            id: 'contact-us',
            label: languageData[lang]['header.new.menu.mobile.logged.link.5.0'],
          },
          {
            id: 'faq',
            label: languageData[lang]['header.new.menu.mobile.logged.link.6.0'],
          },
        ],
      },
      {
        data: [
          {
            id: 'terms',
            label: languageData[lang]['header.menu.mobile.logged.link.1.2'],
          },
          {
            id: 'privacy',
            label: languageData[lang]['header.menu.mobile.logged.link.1.3'],
          },
        ],
      },
    ],
    mobileListLogged: [
      {
        id: 'account',
        label: languageData[lang]['header.menu.mobile.logged.title.0'],
        data: [
          {
            id: 'account',
            label: languageData[lang]['header.menu.mobile.logged.link.0.0'],
          },
          {
            id: 'account/settings',
            label: languageData[lang]['header.menu.mobile.logged.link.0.2'],
          },
        ],
      },
      {
        data: [
          {
            id: 'how-it-works',
            label: languageData[lang]['header.new.menu.mobile.logged.link.7.0'],
          },
          {
            id: 'why-lelecabs',
            label: languageData[lang]['header.new.menu.mobile.logged.link.3.0'],
          },
          {
            id: 'who-we-are',
            label: languageData[lang]['header.new.menu.mobile.logged.link.3.1'],
          },
          {
            id: 'operation-coverage',
            label: languageData[lang]['header.menu.mobile.logged.link.1.0'],
          },
          {
            id: 'experiences',
            label: languageData[lang]['header.new.menu.mobile.link.3.1'],
          },
          {
            id: 'for-drivers',
            label: languageData[lang]['header.new.menu.mobile.logged.link.2.0'],
          },
        ],
      },
      {
        data: [
          {
            id: 'contact-us',
            label: languageData[lang]['header.new.menu.mobile.logged.link.5.0'],
          },
          {
            id: 'faq',
            label: languageData[lang]['header.new.menu.mobile.logged.link.6.0'],
          },
        ],
      },
      {
        data: [
          {
            id: 'terms',
            label: languageData[lang]['header.menu.mobile.logged.link.1.2'],
          },
          {
            id: 'privacy',
            label: languageData[lang]['header.menu.mobile.logged.link.1.3'],
          },
        ],
      },
    ],
    mobileLoginMenuData: [
      {
        id: 'account/login',
        type: 'text',
        label: languageData[lang]['header.menu.login'],
      },
    ],
    mobileLogoutMenuData: [
      {
        id: 'account/logout',
        type: 'text',
        label: languageData[lang]['header.menu.logout'],
      },
    ],
  }
}
