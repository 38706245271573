import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import React from 'react'
import styled from 'styled-components'
import { ArrowToBottom } from '@styled-icons/boxicons-regular'
import AccountSidebar from '../account-sidebar'

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 0px 10px 45px;
`

const List = styled.ul`
  padding-inline-start: 0;
  margin-block-start: 0.3em;
`
const ListItem = styled.li`
  list-style: none;
  border: 1px solid #ccc;
  padding: 10px 5px;
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ToIcon = styled(ArrowToBottom)`
  width: 20px;
`

const columnBase = `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
  flex: 1;
`
const TripDestination = styled.div`
  ${columnBase}
  max-width: 45%;
  justify-content: space-between;
`

const DateCol = styled.div`
  ${columnBase}
  max-width: 30%;
  justify-content: center;
`

const CostCol = styled.div`
  ${columnBase}
  max-width: 25%;
  justify-content: center;
`

const CostNumber = styled.h3`
  width: 100%;
  text-align: right;
`

const TripListTitle = styled.h3`
  margin-bottom: 20px;
`

const showDate = (date = '') => {
  const dateinmil = parseISO(date).getTime()
  const newDate = new Date(dateinmil)
  return format(newDate, 'dd-MM-yyyy p')
}

const WarningMsg = styled.p`
  padding: 10px 0px 20px;
  text-align: center;
  color: orange;
`

const AccountWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  background: #f5f5f5;
`

const CardList = ({ label, list = [], past }) => {
  const filteredList = list.filter((trip) =>
    past
      ? new Date().getTime() > parseISO(trip.date).getTime()
      : new Date().getTime() < parseISO(trip.date).getTime(),
  )
  return (
    <Wrapper>
      <TripListTitle>{label}</TripListTitle>
      {filteredList.length === 0 ? (
        <WarningMsg>{`...you don't have any method of payment registered yet !!`}</WarningMsg>
      ) : (
        <List>
          {filteredList.map((trip) => (
            <ListItem>
              <TripDestination>
                <p>{trip.from}</p>
                <p>
                  <ToIcon />
                </p>
                <p>{trip.to}</p>
              </TripDestination>
              <DateCol>{trip.date ? showDate(trip.date) : ''}</DateCol>
              <CostCol>
                <CostNumber>{`${trip.cost}€`}</CostNumber>
              </CostCol>
            </ListItem>
          ))}
        </List>
      )}
    </Wrapper>
  )
}
const PaymentsRegisteredCardList = () => {
  //   const [trips, updateTrips] = useState([{from: 'first trip'}]);

  //   useEffect(() => {
  //     const fetchTrips = async (token) => {
  //       const result = await getApi('reservations')
  //         ({ 'Authorization': 'Bearer ' + token })
  //         (/* no body */)
  //         (cb => cb);
  //       if (result.total) updateTrips(result.data)
  //     }
  //     const token = loadState('token');
  //     fetchTrips(token);
  //   }, []);
  return (
    <AccountWrapper>
      <AccountSidebar />
      <CardList label="Payments Registered" list={[]} />
    </AccountWrapper>
  )
}
export default PaymentsRegisteredCardList
