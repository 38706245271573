import { Wrapper } from './styled'
import * as S from './styled'
import { useState } from 'react'

const FaqList = ({ data }) => {
  const [questionIndex, setQuestionIndex] = useState(-1)
  const selectQuestion = (i) => {
    if (questionIndex !== i) setQuestionIndex(i)
    else setQuestionIndex(-1)
  }

  const parseLink = (text, linkWord, href) => {
    const pattern = new RegExp(linkWord, 'gi')

    const modifiedText = text.replace(pattern, (match) => {
      return `<a href="${href}" key="${match}">${match}</a>`
    })

    return <span dangerouslySetInnerHTML={{ __html: modifiedText }} />
  }

  return (
    <S.Wrapper>
      <S.Title>{data.sectionTitle}</S.Title>
      <S.ListWrapper>
        {data.questions.map((question, i) => (
          <S.SingleQuestion key={i} onClick={() => selectQuestion(i)}>
            <S.QuestionWrapper>
              <S.TextWrapper>{question.questionTitle}</S.TextWrapper>
              {i === questionIndex ? (
                <S.ArrowIconUp size={40} />
              ) : (
                <S.ArrowIconDown size={40} />
              )}
            </S.QuestionWrapper>
            {questionIndex === i && (
              <S.TextAnswer>{question.questionAnswer}</S.TextAnswer>
            )}
          </S.SingleQuestion>
        ))}
      </S.ListWrapper>
      {!data.longVersion && (
        <S.FooterText>
          {parseLink(
            data.footer.footerSubtitle,
            data.footer.linkWord,
            data.footer.linkHref,
          )}
        </S.FooterText>
      )}
    </S.Wrapper>
  )
}
export default FaqList
