import styled from 'styled-components'
import { P3, P2 } from './../atomic/Typography'

export const ReserveTrip = styled(P2)`
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 40px;
`

export const SmallBold = styled(P2)`
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Vat = styled(P3)`
  color: #7a7a7a;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ExtraFees = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const DateLabel = styled(P2)``

export const Box = styled.div`
  box-sizing: border-box;
  border: 0.5px solid #ccc;
  width: 100%;
  padding: 0;
`
export const InfoWrapper = styled.div`
  box-sizing: inherit;
  padding: 15px 19px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
`

export const InfoGraphicWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
`

export const DateWrapper = styled.div`
  box-sizing: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 32px;
`

export const PriceWrapper = styled.div`
  box-sizing: inherit;
  flex-direction: column;
  gap: 15px;
  align-items: start;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 19px;
`
