import styled from 'styled-components'
import { breakpoint } from '../../styles/global.style'
import * as T from './../atomic/Typography'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  padding: 43px 35px 136px;
  @media (min-width: ${breakpoint.verylarge}) {
  }
  @media (max-width: ${breakpoint.tablet}) {
    padding: 23px 15px 56px 15px;
  }
  @media (max-width: ${breakpoint.mobile}) {
    padding: 15px 15px 56px 15px;
  }

  button {
  }
`

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`
