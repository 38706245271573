import React, {
  useState,
  createContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react'

import UserPool from './UserPool'

import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'

export const AuthContext = createContext({
  cognitoSession: null,
  userAttributes: null,
  loadingAttributes: false,
  loginUser: () => {},
  logout: () => {},
  getCurrentUserAttributes: async () => null,
  getCurrentSession: async () => null,
  getIdToken: async () => null,
})

const defaultUserStatus = {
  cognitoSession: null,
  userAttributes: null,
  loadingAttributes: false,
}

export const Auth = ({ children }) => {
  const [userStatus, setUserStatus] = useState({
    ...defaultUserStatus,
    loadingAttributes: true,
  })

  const updateUserStatus = useCallback(async (updateLoading = false) => {
    return new Promise((resolve, reject) => {
      if (updateLoading)
        setUserStatus((status) => ({ ...status, loadingAttributes: true }))
      const user = UserPool.getCurrentUser()
      if (!user) {
        setUserStatus(defaultUserStatus)
        resolve(defaultUserStatus)
        return
      }
      user.getSession((sessionError, session) => {
        if (sessionError) {
          setUserStatus(defaultUserStatus)
          reject(sessionError)
          return
        }
        user.getUserAttributes((attrError, attributes) => {
          if (attrError) {
            setUserStatus(defaultUserStatus)
            reject(attrError)
            return
          }
          const parsedObject = attributes.reduce(
            (acc, cur) => ({
              ...acc,
              [cur.Name]: cur.Value,
            }),
            {},
          )
          const newUserStatus = {
            cognitoSession: session,
            userAttributes: parsedObject,
            loadingAttributes: false,
          }
          setUserStatus(newUserStatus)
          resolve(newUserStatus)
        })
      })
    })
  }, [])

  const loginUser = useCallback(
    async (loginFormData, onSuccess, onError, setLoadingUserData) => {
      const user = new CognitoUser({
        Username: loginFormData.email,
        Pool: UserPool,
      })
      const authDetails = new AuthenticationDetails({
        Username: loginFormData.email,
        Password: loginFormData.password,
      })

      await user.authenticateUser(authDetails, {
        onSuccess: () => {
          //we have to perform update User Status before onSuccess because onSuccess
          //could include redirect (and it usually does)
          setLoadingUserData(true),
            updateUserStatus(true).then(() => {
              onSuccess?.()
            })
        },
        onFailure: (err) => {
          onError?.(['errorBox.' + err.message]), setLoadingUserData(false)
        },
      })
    },
    [updateUserStatus],
  )

  const logout = useCallback(async () => {
    const user = UserPool.getCurrentUser()
    if (!user) return
    user.signOut()
    await updateUserStatus(true)
  }, [updateUserStatus])

  const getIdToken = useCallback(
    () =>
      updateUserStatus(false)
        .then((status) => status.cognitoSession)
        .then((session) => session?.idToken?.jwtToken || null),
    [updateUserStatus],
  )

  useEffect(() => {
    updateUserStatus(true)
  }, [updateUserStatus])

  const contextValues = useMemo(
    () => ({
      loginUser,
      logout,
      getCurrentUserAttributes: () =>
        updateUserStatus(true).then((s) => s.userAttributes),
      getCurrentSession: () =>
        updateUserStatus(true).then((s) => s.cognitoSession),
      getIdToken,
      userAttributes: userStatus.userAttributes,
      cognitoSession: userStatus.cognitoSession,
      loadingAttributes: userStatus.loadingAttributes,
    }),
    [userStatus, updateUserStatus, getIdToken, loginUser, logout],
  )

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  )
}
