import { Link, useLocation } from 'react-router-dom'
import React from 'react'
import styled from 'styled-components'
import {
  FormLikeLayout,
  NewLandingFormLikeLayout,
  SimpleTitle,
  TitleLayout,
} from '../atomic/FormLikeLayout'
import { getCurrentLanguage, languageData } from '../../languages/index'
import { ext } from '../../utils/utils'
import { LinkUnderline, P2, P3 } from '../atomic/Typography'
import Button from '../buttons'
import {
  Checkbox,
  ChildrenWeight,
  DirectionSelect,
  DropdownReturnTripSelect,
  GeoInput,
  Input,
  Peoples,
  // Price,
  TripSelect,
} from '../forms'
import {
  AttentionIcon,
  CheckListBox,
  ConfirmationErrorMessage,
  Divider,
  FalseRequirement,
  IconItemWrapper,
  StyledListItem,
  TrueRequirement,
  ButtonsWrapper,
} from '../forms/styled'
import PasswordStr from './passwordStr'
import ModalAccount from '../modal-select-account'
import PassengerInfoForm from './../form-passenger-info'
import CancellationCoverage from '../cancellation-coverage'

const lang = getCurrentLanguage()

const RegisterP = styled(ext(P3))`
  margin-top: 20px;
  width: 100%;
`

const Red = styled.span`
  color: ${(props) => props.theme.error};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0')}px;
`

const RouteErrorMessage = styled.div`
  text-align: center;
  margin-bottom: 24px;
`

const TermsLabel = ({ termsLabel }) => {
  const { search } = useLocation()
  return (
    <>
      {termsLabel.first}{' '}
      <Link to={`/terms${search}`} target="_blank">
        {termsLabel.termsLink}
      </Link>{' '}
      {termsLabel.second}{' '}
      <Link to={`/privacy${search}`} target="_blank">
        {termsLabel.privacyLink}
      </Link>
    </>
  )
}

const generateInlineError = (error = [], key = '', id = '') => {
  return error
    .filter((err) => err.split('.')[0] === id)
    .map((err) => (
      <P2
        key={key + '_err' + Math.floor(Math.random() * 10)}
        marginBottom={15}
        color="#E3011C"
        center
      >
        {pureErrorMsg(err)}
      </P2>
    ))
}

const generateErroredInput = (error = [], key = '', id) =>
  error
    .filter((err) => err.indexOf(`'${id}'`) > -1)
    .map((err) => err.split(`'${id}'`)[1])
    .map((err) => (
      <P2
        key={key + '_err' + Math.floor(Math.random() * 10)}
        marginBottom={15}
        color="#E3011C"
        center
      >
        {' '}
        {err}{' '}
      </P2>
    ))

const withErroredBorder = (error = [], id = '') =>
  error.filter((err) => err.indexOf(`'${id}'`) > -1).reduce(() => true, false)

const pureErrorMsg = (err = '') => err.split('.').slice(1)

const isInputError = (error = [], id = '') =>
  error.filter((err) => err.split('.').at(0) === id).find((_) => true)

const Wrap = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')}px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')}px;
`

const ButtonErrorWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`
const PassWrap = styled.div`
  width: 63%;
  text-align: left;
  margin: auto;
  padding: 0;
  font-size: 12px;
`

const generateFormLine = (
  line,
  value,
  handler,
  loading,
  error = [],
  redirect = 'no',
  score,
  passwordCheckStatus,
  checkList,
  routeError,
) => {
  switch (line.component) {
    case 'button':
      const factorsOK = line.disableFactor
        ? line.disableFactor.reduce((acc, item) => {
            let newAcc = acc
            if (
              value[item] !== undefined &&
              value[item] !== '' &&
              !!value[item]
            ) {
              newAcc = acc + 1
            }
            return newAcc
          }, 0)
        : 0

      const disabled = line.disableFactor
        ? factorsOK < line.disableFactor.length
        : false
      return (
        <Button
          key={line.id + Math.floor(Math.random() * 10)}
          type={line.type}
          onClick={() => handler(line.id)}
          label={line.label}
          loading={loading}
          disabled={loading || disabled || routeError}
          timer={line.intervalTimer}
          marginTop={line.marginTop}
          full={line.full}
          className={line.className}
        />
      )
    case 'routerError':
      return (
        <RouteErrorMessage key={line.id}>
          {routeError && (
            <Red>
              <p>{line.label}</p>
            </Red>
          )}
        </RouteErrorMessage>
      )
    case 'accountModal':
      return (
        <ModalAccount
          handler={handler}
          id={line.id}
          key={line.id}
          modalValue={line.modalValue}
          guestHandler={line.guestHandler}
        />
      )
    case 'multiple-buttons':
      return (
        <ButtonsWrapper key={line.id + Math.floor(Math.random() * 10)}>
          {line.buttons.map((button, i) => (
            <Button
              key={button.id + i}
              type={button.type}
              onClick={() => handler(button.id)}
              label={button.label}
              loading={loading}
              marginTop={button.marginTop}
              full={button.full}
            />
          ))}
        </ButtonsWrapper>
      )
    case 'passenger-info':
      return <PassengerInfoForm handler={handler} value={value} />
    case 'children':
      return (
        <ChildrenWeight
          label={line.label}
          sublabel={line.sublabel}
          value={value.children}
          key={line.id + Math.floor(Math.random() * 10)}
          handler={handler}
          id={line.id}
          margin={line.margin}
        />
      )
    /* case 'date':
      return (
        <DateHourPicker
          key={line.id}
          name={line.name}
          type={line.type}
          errorText={line.errorText}
          hasError={line.hasError}
          label={line.label}
          id={line.id}
          value={value[line.id]}
          handler={handler}
          required={line.required}
          withBorder={line.withBorder}
          width={line.width}
          margin={line.margin}
        />
      ) */
    case 'return-trip-dropdown':
      return (
        <DropdownReturnTripSelect
          value={value}
          handler={handler}
          outwardDateValue={line.outwardDateValue}
          returnDateValue={value[line.returnDateHandler]}
          dateId={line.returnDateHandler}
          dropdownId={line.id}
          flightCodeId={line.flightHandler}
          returnFlightCodeId={line.flightReturnHandler}
          key={line.id + line.name}
          dateHourPickerConfig={{
            ...line.dateHourPickerConfig,
            handler,
            value: value[line.dateHourPickerConfig.id],
          }}
        />
      )
    case 'errorSubtitle':
      return (
        <ButtonErrorWrap key={line.id + Math.floor(Math.random() * 10)}>
          <P2 marginBottom={15}>{line.title}</P2>
          {error.length > 0
            ? generateInlineError(error, line.id, line.error)
            : null}
          {error.length > 0 && error[0].includes('not confirmed') && (
            <>
              <ConfirmationErrorMessage>
                <p>
                  {line.errorSubtitle1}
                  <br />
                  <br />
                  {line.errorSubtitle2}
                </p>
                <AttentionIcon />
              </ConfirmationErrorMessage>
              <Button
                key={line.id + line.title}
                type="white"
                full
                onClick={() => handler(line.id)}
                label={line.buttonText}
              />
            </>
          )}
        </ButtonErrorWrap>
      )
    case 'geo':
      return (
        <GeoInput
          key={line.id + line.label}
          id={line.id}
          name={line.name}
          type={line.type}
          errorText={line.errorText}
          hasError={line.hasError}
          label={line.label}
          value={value[line.id]}
          handler={handler}
          routeError={routeError}
          zoneCoverage={line.zoneCoverage}
          required={line.required}
          withBorder={line.withBorder}
          width={line.width}
          margin={line.margin}
        />
      )
    case 'input': {
      return (
        <Wrap key={line.id + line.name}>
          <Input
            name={line.name}
            type={line.type}
            readOnly={line.disabled}
            hasError={isInputError(error, line.id)}
            errorText={line.errorText}
            errorBorder={
              error.length > 0
                ? withErroredBorder(error, line.id, line.error)
                : null
            }
            label={line.label}
            value={value[line.id]}
            onChange={(e) => handler(line.id, e.target.value)}
            required={line.required}
            withBorder={line.withBorder}
            width={line.width}
            margin={line.margin}
          />
          {error.length > 0
            ? generateErroredInput(error, line.id, line.id)
            : null}
        </Wrap>
      )
    }
    case 'inputPasswordRequirement':
      const checkListData = {
        listItems: [
          {
            id: 'long-password',
            text: 'minimum 8 characters',
          },
          {
            id: 'upper-lower-case',
            text: 'one uppercase letter',
          },
          {
            id: 'lower-case',
            text: 'one lowercase letter',
          },
          {
            id: 'special-characters',
            text: 'one special character',
          },
          {
            id: 'number',
            text: 'one number',
          },
        ],
      }
      return (
        <React.Fragment key={line.id}>
          <Input
            name={line.name}
            type={line.type}
            hasError={isInputError(error, line.id)}
            errorText={line.errorText}
            errorBorder={
              error.length > 0
                ? withErroredBorder(error, line.id, line.error)
                : null
            }
            label={line.label}
            value={value[line.id]}
            onChange={(e) => handler(line.id, e.target.value)}
            required={line.required}
            withBorder={line.withBorder}
            width={line.width}
            margin={line.margin}
          />
          {checkList ? (
            <CheckListBox>
              {checkListData.listItems.map((item, index) => (
                <IconItemWrapper key={index}>
                  {passwordCheckStatus[item.id] ? (
                    <TrueRequirement />
                  ) : (
                    <FalseRequirement />
                  )}
                  <StyledListItem isChecked={passwordCheckStatus[item.id]}>
                    {item.text}
                  </StyledListItem>
                </IconItemWrapper>
              ))}
            </CheckListBox>
          ) : null}
        </React.Fragment>
      )
    case 'linkunderline':
      return (
        <LinkUnderline
          key={line.id + Math.floor(Math.random() * 10)}
          width="100%"
          marginBottom={line.margin}
          align={line.textAlign}
          href={line.href}
          marginTop={line.marginTop}
        >
          {line.label}
        </LinkUnderline>
      )
    case 'direction':
      return (
        <DirectionSelect
          key={line.id}
          value={value.dropdown}
          id={line.id}
          handler={handler}
        />
      )
    case 'mandatoryLabel':
      return (
        <React.Fragment key={line.id + Math.floor(Math.random() * 10)}>
          <P3 marginBottom={line.margin}>
            <Red>*</Red> {languageData[lang]['register.footnote.text']}
          </P3>
          {error.length > 0 && (
            <Red marginBottom={50}>{error[0].split('.').slice(1)}</Red>
          )}
        </React.Fragment>
      )
    case 'password-strength-bar':
      return (
        <PassWrap key={line.id + Math.floor(Math.random() * 10)}>
          {score >= 1 && <PasswordStr score={score} />}
        </PassWrap>
      )
    case 'people':
      return (
        <Peoples
          key={line.id + Math.floor(Math.random() * 10)}
          margin={line.margin}
          adults={value[`${line.id}adults`] || 1}
          childs={value[`${line.id}childs`] || 0}
          infants={value[`${line.id}infants`] || 0}
          id={line.id}
          handler={handler}
        />
      )
    // case 'price':
    //   return (
    //     <Price
    //       distance={value.distance}
    //       duration={value.duration}
    //       id={line.id}
    //       key={line.key}
    //       handler={handler}
    //       active={value.price || 'car'}
    //       margin={line.margin}
    //     />
    //   )
    case 'simpleSubtitle':
      const boldText = (fullString, boldWord) => {
        const words = fullString.split(' ')
        const boldWords = boldWord.split(' ')
        return (
          <P2 key={line.id + Math.floor(Math.random() * 10)}>
            {words.map((word, index) => {
              if (boldWords.includes(word)) {
                return <b key={index}>{word} </b>
              } else {
                return <span key={index}>{word} </span>
              }
            })}
          </P2>
        )
      }
      return (
        <Wrap
          key={line.id + line.marginTop + line.marginBottom}
          marginTop={line.marginTop}
          marginBottom={line.marginBottom}
        >
          {line.boldWord ? (
            <div marginBottom={15}>{boldText(line.title, line.boldWord)}</div>
          ) : (
            <P2>{line.title}</P2>
          )}
        </Wrap>
      )
    case 'subtitle':
      return (
        <Wrap key={line.id + line.title}>
          <P2 marginBottom={15}>{line.title}</P2>
          {error.length > 0
            ? generateInlineError(error, line.id, line.error)
            : null}
        </Wrap>
      )
    case 'terms':
      return (
        <Wrap key={line.id}>
          <Checkbox
            key={line.id + Math.floor(Math.random() * 10)}
            name={line.name}
            label={
              <TermsLabel
                termsLabel={{
                  first: languageData[lang]['register.privacy-policy'],
                  termsLink: 'Terms and Conditions',
                  second: 'including the ',
                  privacyLink: 'Privacy Policy*',
                }}
              />
            }
            value={value[line.id]}
            onChange={() => handler(line.id, !value[line.id])}
            margin={line.margin}
          />
          {error.length > 0
            ? generateErroredInput(error, line.id, line.id)
            : null}
        </Wrap>
      )
    case 'textwithLinkunderline':
      return (
        <RegisterP key={line.id + Math.floor(Math.random() * 10)}>
          {line.data.map((text, iter) =>
            text.type === 'text' ? (
              <span
                key={`insideLineData-${iter}` + Math.floor(Math.random() * 10)}
              >
                {text.label}{' '}
              </span>
            ) : (
              <LinkUnderline
                key={`insideLineData-${iter}` + Math.floor(Math.random() * 10)}
                href={`${text.href}${
                  redirect === 'no' ? '' : `?redirect=${redirect}`
                }`}
              >
                {text.label}
              </LinkUnderline>
            ),
          )}
        </RegisterP>
      )
    case 'title':
      return (
        <TitleLayout
          label={line.title}
          key={line.id + Math.floor(Math.random() * 10)}
          width={line.full}
          handler={handler}
          id={line.id}
          noBorder={line.noBorder}
        />
      )
    case 'coverage':
      return line.hidden ? (
        <></>
      ) : (
        <CancellationCoverage
          id={line.id}
          key={line.id}
          handler={handler}
          value={value}
          label={line.label}
        />
      )
    case 'simpleTitle':
      return <SimpleTitle key={line.title}>{line.title}</SimpleTitle>
    case 'tripselect':
      return (
        <TripSelect
          label={line.label}
          value={value.triptype}
          key={line.id}
          handler={handler}
          id={line.id}
          margin={line.margin}
          from={value.from}
          quotes={value.quotes}
          to={value.to}
        />
      )
    case 'googleButton':
      return (
        <div key={line.id}>
          <div
            id="g_id_onload"
            data-client_id="1075855380202-csf9haaphua1j269ahh5jk4r5t8jb71u.apps.googleusercontent.com"
            data-login_uri="https://dev-lelecabs.auth.eu-west-1.amazoncognito.com/login?client_id=t2ns3m3qh3v55mngkn80c6a3v&response_type=code&scope=email+openid+phone&redirect_uri=http%3A%2F%2Flocalhost"
            data-auto_prompt="false"
          ></div>
          <div
            class="g_id_signin"
            data-type="standard"
            data-size="large"
            data-theme="outline"
            data-width="340"
            data-text="sign_in_with"
            data-shape="rectangular"
            data-logo_alignment="left"
          ></div>
          <Divider id={line.id}>
            <p> or</p>
          </Divider>
        </div>
      )
    default:
      return null
  }
}

const NewAuthForm = ({
  noTopBorder,
  padding,
  formOptions,
  value = {},
  handler,
  withImg,
  error,
  loading,
  redirect,
  score,
  passwordCheckStatus,
  checkList,
  full,
  routeError,
}) => {
  return (
    <NewLandingFormLikeLayout
      noTopBorder={noTopBorder}
      padding={padding}
      withImg={withImg}
      full={full}
    >
      {formOptions.map((line) =>
        generateFormLine(
          line,
          value,
          handler,
          loading,
          error,
          redirect,
          score,
          passwordCheckStatus,
          checkList,
          routeError,
        ),
      )}
    </NewLandingFormLikeLayout>
  )
}

export default NewAuthForm
