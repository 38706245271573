import * as S from './styled'
import * as T from './../atomic/Typography'
import Button from '../buttons'
import { resendConfirmationEmail } from './../../utils/accountUtils'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const ConfirmationEmail = ({ data }) => {
  const router = useNavigate()

  const emailAddress = localStorage.getItem('emailRegister')
  const handleResubmit = () => {
    resendConfirmationEmail(emailAddress, router)
  }

  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <S.Wrapper>
      <S.Message>
        {data.message.map((text, i) => (
          <T.P1 key={i}>{text}</T.P1>
        ))}
      </S.Message>
      <Button
        type={data.button.type}
        label={data.button.label}
        onClick={handleResubmit}
      />
    </S.Wrapper>
  )
}

export default ConfirmationEmail
