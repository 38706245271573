import styled from 'styled-components'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  background-image: url('${(props) => props.background}');
  padding: 40px 106px 51px;
  background-size: cover;
  background-position: center;
  @media (max-width: ${breakpoint.smallTablet}) {
    background-image: none;
    padding: 0;
  }
`
