import React from 'react'
import { LazyBgSqImg } from '../../components/blocks/LazyImages'
import { OpacityBox } from './styled'

class Square extends React.Component {
  render() {
    return (
      <LazyBgSqImg {...this.props} timeout={100}>
        {this.props.opacityProp && <OpacityBox />}
        {this.props.children}
      </LazyBgSqImg>
    )
  }
}

export default Square
