import React, { useEffect, useState, useContext } from 'react'
import useQuotes from '../../hooks/useQuotes'
import styled from 'styled-components'
import ProcessingRide from './../../components/processing-ride'
import RideConfirmation from './../../components/ride-confirmation'
import { loadState, removeState, saveState } from './../../utils/localStorage'
import { postApi } from './../../utils/restAPIUtils'
import { loadStripe } from '@stripe/stripe-js'
import { AuthContext } from './../../components/auth-context'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { getCurrentLanguage, languageData } from './../../languages/index'
import { DefaultLayoutNew } from './../../components/utilities/DefaultLayoutNew'
import { Wrapper } from './styled'
import { breakpoint } from '../../styles/global.style'
import { imagesFiles } from '../../styles/images'

const lang = getCurrentLanguage()

const TemporaryBanner = styled.h3`
  background-color: black;
  text-align: center;
  padding: 15px;
  color: white;
  @media (max-width: ${breakpoint.bigtablet}) {
    font-size: 16px;
  }
`

export const isTripExpired = (
  createdOn,
  threshold = 24 * 60 * 60 * 1000, // milliseconds in a day
) => new Date().getTime() > createdOn + threshold

const searchParams = new URLSearchParams(window.location.search)
const isEditing = searchParams.get('edit') === 'true'

const CheckoutNew = () => {
  const [stripe, setStripe] = useState(null)
  const [trip, setTrip] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState([])
  const [sessionId, setSessionId] = useState()
  const { getIdToken } = useContext(AuthContext)
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API)
  const location = useLocation()
  const router = useNavigate()
  const lang = getCurrentLanguage()

  const theCompleteTrip = loadState('trip-pending')
  const { origin, destination, coverage, direction } = theCompleteTrip

  const { quotes } = useQuotes({
    from: origin,
    to: destination,
    coverage,
    direction,
  })
  const { createdOn, ...theTrip } = theCompleteTrip || {}
  const { previousPath } = location?.state || { previousPath: '/' }

  const isRouteValid =
    previousPath === '/' ||
    previousPath === `/${lang.toLowerCase()}/` ||
    previousPath === '/account/confirm' ||
    previousPath === '/account' ||
    previousPath === '/account/login' ||
    previousPath === `/${lang.toLowerCase()}/account/login` ||
    previousPath === `/${lang.toLowerCase()}/account` ||
    previousPath === '/home'

  useEffect(() => {
    const isTripValid = !isTripExpired(createdOn)
    if (!isTripValid) {
      removeState('trip-pending')
      removeState('tripPendingId')
      router('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!stripe && isRouteValid) {
      const defStripe = async () => setStripe(await stripePromise)
      defStripe()
    }
  }, [stripe, setStripe, stripePromise, isRouteValid])

  const originalTripId = loadState('originalTripId')
  const originalTripCreatedOn = loadState('originalTripCreatedOn')

  useEffect(() => {
    const postTrip = async (tripBody) => {
      setTrip(theTrip)
      setLoading(true)
      setError([])
      try {
        const token = (await getIdToken()) || ''
        const headers = {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: token,
        }
        const url = 'v1/reservations'

        const result = await postApi(url)(headers)(tripBody)((cb) => cb)

        if (result.id && result.session.id) {
          setLoading(false)
          saveState('trip-pending', theTrip)
          saveState('tripPendingId', result.id)
          setTrip({ ...theTrip, reservation: result.id })
          setSessionId(result.session.id)
        }
      } catch (err) {
        setError(['main.Something went wrong'])
      }
    }
    if (isRouteValid && !isEditing) {
      if (theTrip) postTrip(theTrip)
    } else if (isRouteValid && isEditing) {
      if (theTrip && originalTripId)
        postTrip({
          reservation: theTrip,
          originalTripId: originalTripId,
          originalTripCreatedOn,
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isRouteValid) return <Navigate to={'/'} />

  const handleSubmit = async () => {
    if (sessionId) {
      await stripe.redirectToCheckout({
        sessionId: sessionId,
      })
    }
  }

  if (!stripe) return null
  if (!trip) return null
  return (
    <DefaultLayoutNew pageType="user" redirect="checkout">
      <TemporaryBanner>
        {languageData[lang]['home.hero.banner.new']}
      </TemporaryBanner>
      <Wrapper background={imagesFiles['lele3Background']}>
        {loading ? (
          <ProcessingRide reservation={theTrip} />
        ) : (
          <RideConfirmation
            quotes={quotes}
            reservation={theTrip}
            onCancel={() => {
              removeState('trip-pending')
              removeState('tripPendingId')
              router('/')
            }}
            onSubmit={handleSubmit}
            sessionId={sessionId}
          />
        )}
      </Wrapper>
    </DefaultLayoutNew>
  )
}

export default CheckoutNew
