import styled from 'styled-components'

export const CancellationCoverage = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 16px;
  align-items: center;
  label {
    cursor: pointer;
  }
  width: 100%;
`

export const CheckboxCoverage = styled.input`
  height: 17px;
  width: 15px;
  cursor: pointer;
`
