import styled from 'styled-components'
import * as T from './../atomic/Typography'
import { ChevronRight } from '@styled-icons/bootstrap/ChevronRight'
import { ChevronLeft } from '@styled-icons/bootstrap/ChevronLeft'
import { breakpoint, colors } from '../../styles/global.style'
import { Modal } from 'antd'

export const Wrapper = styled.div`
  width: 100%;
  padding: 43px 35px 136px;
  box-sizing: border-box;
  @media (min-width: ${breakpoint.verylarge}) {
    max-width: 1600px;
    margin: auto;
  }
  @media (max-width: ${breakpoint.large}) {
    padding: 23px 35px 56px 35px;
  }

  @media (max-width: ${breakpoint.tablet}) {
    padding: 23px 15px 56px 15px;
  }
  @media (max-width: ${breakpoint.mobile}) {
    padding: 15px 15px 56px 15px;
  }
`

export const DescriptionWrapper = styled.div`
  max-width: 742px;
`

export const Title = styled(T.UH2asH1)`
  width: 100%;
  text-align: left;
  margin-bottom: 35px;
  margin-top: 51px;
`

export const ExperienceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 31px;
  margin-top: 80px;
`

export const Row = styled.div`
  display: flex;
  gap: 28px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #efefef;
  padding-bottom: 24px;
  @media (max-width: ${breakpoint.mobile}) {
    flex-direction: column;
  }
`

export const RowTitle = styled(T.P1)`
  font-weight: 700;
  min-width: 90px;
  @media (max-width: ${breakpoint.mobile}) {
    min-width: auto;
  }
`

export const RowParagrpah = styled(T.P1)``

export const ExperienceTitle = styled(T.Uh3)`
  font-weight: 700;
`

export const HeaderNavigation = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 56px;
  gap: 5px;
`

export const ChevronIconRight = styled(ChevronRight)`
  color: ${colors.textGray};
  cursor: pointer;
  width: 20px;
  height: 20px;
  &:hover {
    transform: scale(1.2);
  }
`

export const ChevronIconLeft = styled(ChevronLeft)`
  color: ${colors.textGray};
  cursor: pointer;
  width: 20px;
  height: 20px;
  &:hover {
    transform: scale(1.2);
  }
`

export const ItinerarySection = styled.a`
  color: ${colors.textGray};
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;
`

export const PageName = styled.a`
  font-weight: 700;
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;
`

export const Stops = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
  a {
    text-decoration: none;
  }
`

export const StopsTextNext = styled(T.P1)`
  color: ${colors.textGray};
  font-size: 18px;
  text-decoration: none;
`

export const StopsTextPrevious = styled(T.P1)`
  color: ${colors.textGray};
  margin-right: 15px;
  font-size: 18px;
  text-decoration: none;
`

export const GalleryWrapper = styled.div`
  flex-wrap: wrap;
  position: relative;
`

export const OpenGallery = styled(T.P1)`
  padding: 10px 24px;
  max-width: 230.57px;
  background: #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  color: ${colors.white};
  position: absolute;
  top: 20px;
  z-index: 1;
  left: 20px;
  cursor: pointer;
  @media (max-width: ${breakpoint.mobile}) {
    top: 5px;
    z-index: 1;
    left: 5px;
  }
`

export const GalleryComponentH = styled.div`
  display: grid;
  grid-template-rows: 0.1fr 0.1fr;
  grid-template-columns: 1fr 200px 200px;
  gap: 18px;
  @media (max-width: ${breakpoint.large}) {
    display: flex;
  }
`

export const GridImage = styled.img`
  grid-column-start: ${(props) => props.columnStart};
  grid-column-end: ${(props) => props.columnEnd};
  grid-row-end: ${(props) => props.rowEnd};
  grid-row-start: ${(props) => props.rowStart};
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: ${breakpoint.large}) {
    display: ${(props) => (props.secondaryImage ? 'none' : '')};
    /* max-width: ${(props) => (!props.secondaryImage ? '1200px' : '')}; */
  }
`

export const ModalComponent = styled(Modal).attrs((props) => ({
  className: props.className,
}))`
  & {
    .ant-modal-content {
      background-color: transparent;
      box-shadow: none;
    }
  }
`
