import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { saveState } from '../../utils/localStorage'

import { AuthContext } from '../auth-context'
import UserPool from '../auth-context/UserPool'

import Avatar from '../avatar'
import { Link } from 'react-router-dom'
import LanguagePicker from '../language-picker'
import Logo from '../logo'

import {
  Line,
  ListItem,
  Menu,
  MobileMenu,
  NeutralLink2,
  renderIcon,
} from '../atomic/Layout'

import * as S from './styled'

import { getData } from './data'
import { breakpoint } from '../../styles/global.style'

const handleLogoutInternal = (closeMenu, logout) => {
  closeMenu(false)
  logout()
}

const LelecabsLogo = () => (
  <Link to="/" aria-label="home" target="_self">
    <Logo height={35} />
  </Link>
)

const CentralMenu = ({ data, stopPropagation }) => (
  <S.HideListForMobile>
    <Menu
      data={data.mainMenuData}
      activeItem="test"
      stopPropagation={stopPropagation}
    />
  </S.HideListForMobile>
)

const RightMenu = ({
  accountBox,
  data,
  firstName,
  lastName,
  loadingAttributes,
  saveCurrentPath,
  setAccountBox,
}) => {
  if (loadingAttributes) return null
  return (
    <S.HideListForMobile last>
      <li>
        <LanguagePicker />
      </li>
      &nbsp; &nbsp;
      {firstName ? (
        <li>
          <S.MeAccount
            onClick={(e) => {
              e.stopPropagation()
              setAccountBox(!accountBox)
            }}
          >
            <Avatar name={`${firstName} ${lastName}`} />
            <p>{firstName}</p>
          </S.MeAccount>
        </li>
      ) : (
        <li>
          <h3>
            <NeutralLink2 to={'/account/login'} onClick={saveCurrentPath}>
              {data?.mobileLoginMenuData?.at(0)?.label}
            </NeutralLink2>
          </h3>
        </li>
      )}
    </S.HideListForMobile>
  )
}

const AccountBoxModal = ({
  accountBox,
  data,
  handleLogout,
  handleLogoutInternal,
  firstName,
  lastName,
  setAccountBox,
}) => {
  if (!accountBox) return null
  return (
    <S.AccountBoxWrapper onClick={(e) => e.stopPropagation()}>
      <S.UserBox>
        <S.UserChildWrapper>
          <S.UserName>{`${firstName} ${lastName}`}</S.UserName>
          <S.UserAccount>Rider Account</S.UserAccount>
        </S.UserChildWrapper>
      </S.UserBox>
      <S.MobileWrapper width="100%">
        <S.GrowList
          vertical
          justifyContent="streach"
          alignItems="flex-start"
          padding="30"
        >
          <Menu
            justifyContent="space-between"
            data={data.loggedMenuData}
            activeItem="test"
            vertical
          />
          <Line />
          <ListItem
            justifyContent="space-between"
            data={data.loggedLogOut}
            activeItem="test"
            vertical
          >
            <S.LogOutStyled
              onClick={() => handleLogoutInternal(setAccountBox, handleLogout)}
            >
              {data.loggedText}
            </S.LogOutStyled>
          </ListItem>
        </S.GrowList>
      </S.MobileWrapper>
    </S.AccountBoxWrapper>
  )
}

const HeaderNew = ({
  firstName,
  lastName,
  stopPropagation,
  handleLogout,
  accountBox,
  setAccountBox = () => null,
  lang = en,
}) => {
  const { loadingAttributes } = useContext(AuthContext)
  const [menu, setMenu] = useState(false)
  const user = UserPool.getCurrentUser()
  const data = getData()

  const location = useLocation()

  const saveCurrentPath = () => {
    saveState('sourcePath', location.pathname)
  }

  const tabletScreen = parseInt(breakpoint.bigtablet)
  return (
    <S.StyledHeader>
      <S.ResponsiveRow open={menu}>
        {window.innerWidth > tabletScreen && <LelecabsLogo />}
        {/*The following components will disappear in mobile*/}
        <CentralMenu data={data} stopPropagation={stopPropagation} />
        <RightMenu
          accountBox={accountBox}
          data={data}
          firstName={firstName}
          lang={lang}
          lastName={lastName}
          loadingAttributes={loadingAttributes}
          saveCurrentPath={saveCurrentPath}
          setAccountBox={setAccountBox}
        />
        <AccountBoxModal
          accountBox={accountBox}
          data={data}
          firstName={firstName}
          handleLogout={handleLogout}
          handleLogoutInternal={handleLogoutInternal}
          lastName={lastName}
          setAccountBox={setAccountBox}
        />
        {/*End of the components that disappear in mobile*/}
        <S.ShowOnlyInMobile menu={menu}>
          <S.LogoWrapper>
            <LelecabsLogo />
            <S.MobileLanguageWrapper>
              <LanguagePicker />
            </S.MobileLanguageWrapper>
          </S.LogoWrapper>
          {menu ? (
            <S.MobileHamburgher>
              <span onClick={() => setMenu(!menu)}>
                {renderIcon('times', 30)}
              </span>
            </S.MobileHamburgher>
          ) : (
            <S.MobileHamburgher>
              <span onClick={() => setMenu(!menu)}>
                {renderIcon('menu', 30)}
              </span>
            </S.MobileHamburgher>
          )}
        </S.ShowOnlyInMobile>
        {menu && (
          <S.MobileMenuWrapper>
            <S.MobileWrapper grow={1}>
              <S.GrowList
                vertical
                justifyContent="streach"
                alignItems="center"
                fullWidth
                noPadding
              >
                <S.MobileWrapper grow={1}>
                  <S.GrowList
                    vertical
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Menu
                      justifyContent="space-between"
                      vertical
                      bolded
                      data={
                        firstName
                          ? [
                              {
                                ...data.mobileLogoutMenuData[0],
                                handler: () => {
                                  handleLogoutInternal(
                                    setAccountBox,
                                    handleLogout,
                                  )
                                },
                              },
                            ]
                          : data.mobileLoginMenuData
                      }
                      activeItem="test"
                    />
                  </S.GrowList>
                </S.MobileWrapper>
                <Line color={'#A3C5ED'} />
                {!user ? (
                  <MobileMenu
                    justifyContent="space-between"
                    vertical
                    data={data.mobileList}
                  />
                ) : (
                  <MobileMenu
                    justifyContent="space-between"
                    vertical
                    data={!user ? data.mobileList : data.mobileListLogged}
                  />
                )}
              </S.GrowList>
            </S.MobileWrapper>
          </S.MobileMenuWrapper>
        )}
      </S.ResponsiveRow>
    </S.StyledHeader>
  )
}

export default HeaderNew
