import styled from 'styled-components'
import * as T from '../atomic/Typography'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  display: flex;
  padding-top: 48px;
  flex-direction: column;
  align-items: start;
  gap: 40px;
  @media (max-width: ${breakpoint.smallTablet}) {
    padding: 2.5em 1.5em;
  }

  @media (min-width: ${breakpoint.siteWidth}) {
    align-items: center;
  }
`

export const Title = styled(T.Uh3)`
  font-size: 32px;
  line-height: normal;
  font-weight: 700;
  margin-bottom: -32px;
`

export const Subtitle = styled(T.P2)`
  margin-bottom: 40px;
  text-align: center;
  @media (max-width: ${breakpoint.medium}) {
    text-align: left;
  }
`

export const GridElement = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  @media (max-width: ${breakpoint.bigtablet}) {
    flex-direction: column;
  }
  @media (min-width: ${breakpoint.large}) {
    max-width: 1256px;
  }
`

export const RowImage = styled.img`
  max-width: 100%;
`

export const TextContent = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;
  a {
    text-decoration: none;
  }
  @media (max-width: ${breakpoint.bigtablet}) {
    align-items: center;
    text-align: center;
  }
`

export const RowTitle = styled(T.Uh3)`
  font-weight: 700;
  margin: 0;
  padding: 0;
`

export const Step = styled(T.P2)`
  font-weight: 700;
  color: #7a7a7a;
`

export const RowParagraph = styled(T.P1)`
  @media (max-width: ${breakpoint.mobile}) {
    font-size: 1rem;
    line-height: 30px;
  }
`

export const LinkText = styled(T.P2)`
  color: #3959bc;
`
