import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import propPath from 'crocks/Maybe/propPath'
import Button from './../buttons'
import { DCol, Dots, DRow } from './../atomic/Layout'
import { P1, Uh1 } from './../atomic/Typography'
import styled from 'styled-components'

const BaseDots = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
`

const Wrapper = styled.div`
  margin: 200px 0px;
  max-width: 450px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 300px;
  text-align: center;
`

const ErrorWrapper = styled.div`
  margin: 0 auto;
  max-width: 450px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  text-align: center;
`

const defaultData = {
  heading: 'Ooops!',
  heading2: 'You were not expecting this!',
  body: {
    content:
      'sorry but the page you are looking for does not exist please check your URL and try again.',
    format: 'format_only_wysiwyg',
  },
}

const Error = ({ data = null }) => {
  const { search } = useLocation()
  const digestedData = propPath(
    ['mainContent', '0', 'data', 'children'],
    data,
  ).option(defaultData)
  return (
    <DRow grey>
      <DCol vertical>
        <BaseDots>
          <Dots width="75px" />
        </BaseDots>
        <Wrapper>
          <Uh1>{digestedData.heading}</Uh1>
          <h2>{digestedData.heading2}</h2>
          <P1 extraSpace={20}>{digestedData.body.content}</P1>
          <Link to={`/${search}`}>
            <Button label="Homepage" />
          </Link>
        </Wrapper>
      </DCol>
    </DRow>
  )
}

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('catched in component did catch')
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorWrapper>
          <h1>Something went wrong.</h1>
        </ErrorWrapper>
      )
    }

    return this.props.children
  }
}

export default Error
