import * as S from './styled'
import * as T from './../atomic/Typography'
import { imagesFiles } from '../../styles/images'

const MultiColMarketing = ({ data }) => (
  <S.Wrapper transparentBackground={data.transparentBackground}>
    {data.title && <S.Title>{data.title}</S.Title>}
    <S.ColumnsWrapper>
      {data.columns.map((column, i) => (
        <S.SingleColumn key={i}>
          <S.Image src={imagesFiles[column.imageSrc]} />
          <S.ColTitle>{column.title}</S.ColTitle>
          <T.P1>{column.paragraph}</T.P1>
        </S.SingleColumn>
      ))}
    </S.ColumnsWrapper>
  </S.Wrapper>
)

export default MultiColMarketing
