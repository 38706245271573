import { useContext, useEffect, useState } from 'react'
import { AuthContext } from './../components/auth-context'
import { postApi } from '../utils/restAPIUtils'

export const formatQuotes = (quotes) => {
  let formattedQuotes = {}
  Object.entries(quotes).forEach(([key, value]) => {
    formattedQuotes[key] = parseFloat((value / 100).toFixed(2))
  })
  return formattedQuotes
}
export const addParamsToQuotes = ({ quotes, coverage, direction }) => {
  const tripCoverage = 10
  const stripeMultiplier = 100
  const vatRate = 0.1
  if (coverage && coverage !== 'false') {
    quotes = Math.floor(
      quotes + tripCoverage * stripeMultiplier * (1 + vatRate),
    )
  }
  if (direction === 'Return') {
    quotes = quotes * 2
  }
  return quotes
}

export const useQuotes = ({ from, to, coverage = false, direction = null }) => {
  const [quotes, setQuotes] = useState(0)
  const [quoteParams, setQuoteParams] = useState({})
  const [duration, setDuration] = useState(0)
  const [distance, setDistance] = useState(0)
  const [originCoords, setOriginCoords] = useState(0)
  const [destinationCoords, setDestinationCoords] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [fromCache, setFromCache] = useState(false)

  const { getIdToken } = useContext(AuthContext)

  useEffect(() => {
    const getQuotes = async () => {
      setLoading(true)
      setError(false)
      const token = (await getIdToken()) || ''

      try {
        const response = await postApi('v1/reservations/quotes')({
          Authorization: token,
        })({
          from,
          to,
          coverage,
          direction,
        })((cb) => cb)
        setQuotes(response.quotes)
        setQuoteParams(response.quoteParams)
        setDuration(response.duration)
        setDistance(response.distance)
        setFromCache(response.fromCache)
        setOriginCoords(response.originCoords)
        setDestinationCoords(response.destinationCoords)
      } catch (err) {
        setError(true)
      }
    }
    if (from && to) getQuotes()

    if (!from || !to) setQuotes(0)
    setLoading(false)
  }, [from, to, coverage, direction])

  return {
    quotes,
    quoteParams,
    duration,
    distance,
    fromCache,
    originCoords,
    destinationCoords,
    loading,
    error,
  }
}

export default useQuotes
