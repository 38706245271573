import ReactGA from 'react-ga4'

export const sendPageViewEvent = (path, title = 'Page View') => {
  if (!process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING) {
    return
  }
  ReactGA.send({
    hitType: 'pageview',
    page: path,
    title: title,
  })
}

const useAnalyticsEventTracker = (category) => {
  // don't do anything if there is not google analyitcs
  if (!process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING) {
    return
  }
  const eventTracker = (action, label) => {
    ReactGA.event({ category, action, label })
  }
  return eventTracker
}

export default useAnalyticsEventTracker
