import * as S from './styled'
import Button from '../../../components/buttons'
import { PageFromJson } from '../../../components/utilities/MainContainer'
import { useNavigate } from 'react-router-dom'

const getData = () => {
  return {
    title: 'Account Type Change confirmed',
    buttonLabelBook: 'Start Booking',
    buttonLabelMembers: 'Invite Members',
    paragraph: `Congratulations! Your new travel organization has been successfully created. 
              You can now easily manage your team, book trips, track and the bookings. 
              If you have any questions or concerns, our support team is always available to help.`,
  }
}

const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Account Change Confirmation - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: 'Account change confirmation',
    },
  },
]

const AccountChanged = () => {
  const router = useNavigate()
  const data = getData()
  return (
    <PageFromJson metaPage={metaPage}>
      <S.Wrapper>
        <S.CheckIcon size={60} />
        <S.Title>{data.title}</S.Title>
        <S.Paragraph>{data.paragraph}</S.Paragraph>
        <S.ButtonWrapper>
          <Button
            full
            key="home"
            type="secondary"
            onClick={() => router('/')}
            label={data.buttonLabelBook}
          />
          <Button
            full
            key="members"
            type="white"
            onClick={() => router('/account/company-setup')}
            disabled
            label={data.buttonLabelMembers}
          />
        </S.ButtonWrapper>
      </S.Wrapper>
    </PageFromJson>
  )
}

export default AccountChanged
