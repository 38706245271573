import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { changePassword } from '../../utils/accountUtils'
import AuthForm from '../auth/AuthForm'
import { languageData, getCurrentLanguage } from './../../languages/index'

const getData = () => {
  const lang = getCurrentLanguage()
  return [
    {
      component: 'title',
      title: languageData[lang]['header.menu.logged.link.2.content.2.link.0'],
      id: 'title',
    },
    {
      component: 'subtitle',
      id: 'errorBox',
      title: '',
      error: 'errorBox',
    },
    {
      component: 'input',
      id: 'email',
      name: 'email',
      type: 'email',
      errorText: 'Please insert a valid e-mail.',
      label: languageData[lang]['password.update.form.0.label'],
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'oldPassword',
      name: 'old password',
      label: languageData[lang]['password.update.form.1.label'],
      type: 'password',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'newPassword',
      name: 'new password',
      label: languageData[lang]['password.update.form.2.label'],
      type: 'password',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'input',
      id: 'newPasswordCheck',
      name: 'New password',
      label: languageData[lang]['password.update.form.3.label'],
      type: 'password',
      required: true,
      value: null,
      withBorder: true,
      width: '100%',
      margin: 20,
    },
    {
      component: 'button',
      id: 'action',
      type: 'secondary',
      label: languageData[lang]['password.update.button.label'],
    },
  ]
}

export function ChangePassword() {
  const router = useNavigate()
  const [error, setError] = useState([])
  const [email, setEmail] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordCheck, setNewPasswordCheck] = useState('')
  const value = {
    email,
    oldPassword,
    newPassword,
    newPasswordCheck,
  }

  const handlers = {
    email: setEmail,
    oldPassword: setOldPassword,
    newPassword: setNewPassword,
    newPasswordCheck: setNewPasswordCheck,
    action: () => {
      changePassword(
        value.email,
        value.oldPassword,
        value.newPassword,
        value.newPasswordCheck,
        setError,
        router,
      )
    },
  }
  const passwordForm = getData()
  return (
    <AuthForm
      formOptions={passwordForm}
      value={value}
      handler={(id, value) => handlers[id](value)}
      error={error}
    />
  )
}
