import React, { useState } from 'react'
import * as S from './styled'
import { Link, useLocation } from 'react-router-dom'
import { imagesFiles } from '../../styles/images'
import { breakpoint } from '../../styles/global.style'
import { footerData } from './data'
import * as T from '../atomic/Typography'

export const FooterNew = () => {
  const [mobileMenu, setMobileMenu] = useState(false)
  const data = footerData()

  const openMenu = () => {
    setMobileMenu((prevState) => !prevState)
  }

  const tabletScreen = parseInt(breakpoint.bigtablet)
  const { search } = useLocation()

  return (
    <S.Wrapper>
      {window.innerWidth > tabletScreen ? (
        <S.DesktopFooter>
          <S.UpperWrapper>
            <S.LogoWrapper>
              <Link to={`${data.media.logoHref}${search}`}>
                <S.Logo
                  src={imagesFiles[data.media.logoSrc]}
                  alt={data.media.logoAlt}
                />
              </Link>
              {/*<S.SocialMedia>
                <Link>
                  <img
                    src={imagesFiles[data.media.twitterImgSrc]}
                    alt={imagesFiles[data.media.twitterImgSrc]}
                  />
                </Link>
                <Link>
                  <img
                    src={imagesFiles[data.media.instagramImgSrc]}
                    alt={imagesFiles[data.media.instagramImgSrc]}
                  />
                </Link>
              </S.SocialMedia>*/}
            </S.LogoWrapper>
            <S.LinksMenu>
              {data.menuLinks.map((column, i) => (
                <S.LinksColumn key={i}>
                  {column.map((link, y) => (
                    <Link key={`${i}-${y}`} to={`${link.href}${search}`}>
                      <T.P2>{link.label}</T.P2>
                    </Link>
                  ))}
                </S.LinksColumn>
              ))}
            </S.LinksMenu>
          </S.UpperWrapper>
          <S.Copyright>{data.copyrightText}</S.Copyright>
        </S.DesktopFooter>
      ) : (
        <S.SmallScreenFoooter>
          <S.LogoWrapperMobile>
            <Link to={`${data.media.logoHref}${search}`}>
              <S.Logo
                src={imagesFiles[data.media.logoSrc]}
                alt={data.media.logoAlt}
              />
            </Link>
            {/* <S.SocialMedia>
              <Link>
                <img
                  src={imagesFiles[data.media.twitterImgSrc]}
                  alt={imagesFiles[data.media.twitterImgSrc]}
                />
              </Link>
              <Link>
                <img
                  src={imagesFiles[data.media.instagramImgSrc]}
                  alt={imagesFiles[data.media.instagramImgSrc]}
                />
              </Link>
            </S.SocialMedia> */}
          </S.LogoWrapperMobile>
          <S.MobileFooterMenu onClick={openMenu} open={mobileMenu}>
            <p>{data.mobileQuickLinks}</p>
            {!mobileMenu ? (
              <S.ArrowIconUp size={24} />
            ) : (
              <S.ArrowIconDown size={24} />
            )}
          </S.MobileFooterMenu>
          {mobileMenu && (
            <S.LinksMenu>
              {data.menuLinks.map((column, i) => (
                <S.LinksColumn key={i}>
                  {column.map((link, y) => (
                    <Link key={`${i}-${y}`} to={`${link.href}${search}`}>
                      <p>{link.label}</p>
                    </Link>
                  ))}
                </S.LinksColumn>
              ))}
            </S.LinksMenu>
          )}
          <S.Copyright>{data.copyrightText}</S.Copyright>
        </S.SmallScreenFoooter>
      )}
    </S.Wrapper>
  )
}

export default FooterNew
