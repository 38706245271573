import FormSearchbarBoxFaq from './../form-searchbox-faq'
import * as S from './styled'
import * as T from './../atomic/Typography'
import FormSearchbarBoxLocation from '../form-searchbar-location'

const FormSearchbarBox = ({ data, faqData }) => {
  const searchbarType = (type) => {
    switch (type) {
      case 'location':
        return <FormSearchbarBoxLocation data={data} />
      case 'faq':
        return <FormSearchbarBoxFaq data={data} faqData={faqData} />
    }
  }

  return (
    <S.Wrapper>
      <S.Title>{data.title} </S.Title>
      <T.P2>{data.subtitle}</T.P2>
      {searchbarType(data.input.inputType)}
    </S.Wrapper>
  )
}

export default FormSearchbarBox
