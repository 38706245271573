import accounting from 'accounting-js'

export const getDate = (date, locale) => {
  const myDate = new Date(date)
  return myDate.toLocaleDateString(locale ?? 'default', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
}

export const getHour = (date, locale) =>
  new Date(date).toLocaleTimeString(locale ?? 'default', {
    hour: '2-digit',
    minute: '2-digit',
  })

export const formatPrice = (price) => {
  if (!price) return '-- €'
  return accounting.formatMoney(price, { symbol: '€ ', precision: 2 })
}

export const isMoreThanOneDay = (reservationDate) => {
  const reservationTime = new Date(reservationDate)
  const diffInMs = reservationTime.getTime() - new Date().getTime()
  const diffInHours = diffInMs / (1000 * 60 * 60) // convert milliseconds to hours
  return diffInHours
}
