import { languageData, getCurrentLanguage } from './../../languages/index'

export const pageData = () => {
  const lang = getCurrentLanguage()

  return {
    formDriveData: {
      title: languageData[lang]['page.for.drivers.signup.form.title'],
      steps: [
        {
          title:
            languageData[lang]['page.for.drivers.signup.form.step.0.title'],
          subtitle:
            languageData[lang]['page.for.drivers.signup.form.step.0.subtitle'],
          stepNum:
            languageData[lang]['page.for.drivers.signup.form.step.0.label'],
        },
        {
          title:
            languageData[lang]['page.for.drivers.signup.form.step.1.title'],
          subtitle:
            languageData[lang]['page.for.drivers.signup.form.step.1.subtitle'],
          stepNum:
            languageData[lang]['page.for.drivers.signup.form.step.1.label'],
        },
        {
          title:
            languageData[lang]['page.for.drivers.signup.form.step.2.title'],
          subtitle:
            languageData[lang]['page.for.drivers.signup.form.step.2.subtitle'],
          stepNum:
            languageData[lang]['page.for.drivers.signup.form.step.2.label'],
        },
      ],
      form: {
        title: languageData[lang]['page.for.drivers.signup.form.box.title'],
        subtitle:
          languageData[lang]['page.for.drivers.signup.form.box.subtitle'],
        inputs: [
          {
            type: 'text',
            placeholder:
              languageData[lang][
                'page.for.drivers.signup.form.box.input.0.placeholder'
              ],
            label:
              languageData[lang][
                'page.for.drivers.signup.form.box.input.0.label'
              ],
            id: 'name',
          },
          {
            type: 'email',
            placeholder:
              languageData[lang][
                'page.for.drivers.signup.form.box.input.1.placeholder'
              ],
            label:
              languageData[lang][
                'page.for.drivers.signup.form.box.input.1.label'
              ],
            id: 'email',
          },
        ],
        button: {
          label:
            languageData[lang]['page.for.drivers.signup.form.box.button.label'],
        },
      },
    },
    pageCircleData: {
      logo: {
        logoImage: 'logoPng',
        position: {
          columnStart: '2',
          columnEnd: '3',
          rowStart: '3',
        },
      },
      mainTitle: languageData[lang]['page.for.drivers.circle.section.title'],
      elements: [
        {
          icon: 'gridImage84',
          elementTitle:
            languageData[lang]['page.for.drivers.circle.icon.0.title'],
          elementText:
            languageData[lang]['page.for.drivers.circle.icon.0.subtitle'],
          position: {
            columnStart: '2',
            columnEnd: '3',
          },
        },
        {
          icon: 'gridImage83',
          elementTitle:
            languageData[lang]['page.for.drivers.circle.icon.1.title'],
          elementText:
            languageData[lang]['page.for.drivers.circle.icon.1.subtitle'],
          position: {
            columnStart: '1',
            columnEnd: '2',
          },
        },
        {
          icon: 'gridImage82',
          elementTitle:
            languageData[lang]['page.for.drivers.circle.icon.2.title'],
          elementText:
            languageData[lang]['page.for.drivers.circle.icon.2.subtitle'],
          position: {
            columnStart: '3',
            columnEnd: '4',
          },
        },
        {
          icon: 'gridImage81',
          elementTitle:
            languageData[lang]['page.for.drivers.circle.icon.3.title'],
          elementText:
            languageData[lang]['page.for.drivers.circle.icon.3.subtitle'],
          position: {
            columnStart: '1',
            columnEnd: '2',
          },
        },
        {
          icon: 'gridImage80',
          elementTitle:
            languageData[lang]['page.for.drivers.circle.icon.4.title'],
          elementText:
            languageData[lang]['page.for.drivers.circle.icon.4.subtitle'],
          position: {
            columnStart: '3',
            columnEnd: '4',
          },
        },
      ],
      arrows: [
        {
          arrowIcon: 'arrow_4',
          position: {
            columnStart: '1',
            columnEnd: '2',
            rowStart: '1',
            justifyPosition: 'end',
          },
        },
        {
          arrowIcon: 'arrow_1',
          position: {
            columnStart: '3',
            columnEnd: '4',
            rowStart: '1',
            justifyPosition: 'start',
          },
        },
        {
          arrowIcon: 'arrow_3',
          position: {
            columnStart: '-4',
            columnEnd: '-3',
            rowStart: '3',
          },
        },
        {
          arrowIcon: 'arrow_2',
          position: {
            columnStart: '3',
            columnEnd: '4',
            rowStart: '3',
          },
        },

        {
          arrowIcon: 'arrow_5',
          position: {
            columnStart: '2',
            columnEnd: '3',
            rowStart: '4',
            justifyPosition: 'center',
            alignPosition: 'end',
          },
        },
      ],
    },
    ctaSimpleHeroData: {
      imgSrc: 'forDriversHero',
      asHero: true,
      title: languageData[lang]['page.for.drivers.cta.hero.title'],
      paragraphs: [
        languageData[lang]['page.for.drivers.cta.hero.paragraph.0'],
        languageData[lang]['page.for.drivers.cta.hero.paragraph.1'],
      ],
      buttonLabel: languageData[lang]['page.for.drivers.cta.hero.button'],
    },
    multiCtaData: {
      transparentBackground: true,
      columns: [
        {
          imageSrc: 'driverCTAleft',
          title:
            languageData[lang]['page.for.drivers.cta.multi.column.0.title'],
          paragraph:
            languageData[lang]['page.for.drivers.cta.multi.column.0.subtitle'],
        },
        {
          imageSrc: 'driverCTAright',
          title:
            languageData[lang]['page.for.drivers.cta.multi.column.1.title'],
          paragraph:
            languageData[lang]['page.for.drivers.cta.multi.column.1.subtitle'],
        },
      ],
    },
  }
}

export const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Drivers - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: `Being a driver at Lelecabs is an essential part of who we are and what we represent. Find out more about how you could join our team on a quest to provide a world-class service. `,
    },
  },
]
