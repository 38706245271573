import { getCurrentLanguage, languageData } from './../../languages/index'

export const pageData = () => {
  const lang = getCurrentLanguage()
  return {
    ctaSimpleHeroData: {
      imgSrc: 'experiencesHero',
      asHero: true,
      title: languageData[lang]['page.experience.cta.hero.main.title'],
      paragraphs: [languageData[lang]['page.experience.cta.hero.paragraph.0']],
      buttonLabel: languageData[lang]['page.experience.cta.hero.button.label'],
      buttonHref: '/contact-us',
    },
    ctaSimpleGetKnowData: {
      imgSrc: 'ctaSimpleImage7',
      asHero: false,
      title: languageData[lang]['page.experience.cta.get.know.main.title'],
      paragraphs: [
        languageData[lang]['page.experience.cta.get.know.paragraph.0'],
      ],
      buttonLabel:
        languageData[lang]['page.experience.cta.get.know.button.label'],
      buttonHref: '/who-we-are',
    },
    pageCircleData: {
      logo: {
        logoImage: 'logoPng',
        position: {
          columnStart: '2',
          columnEnd: '3',
          rowStart: '3',
        },
      },
      mainTitle: languageData[lang]['page.experience.circle.section.title'],
      elements: [
        {
          icon: 'gridImageExperience1',
          elementTitle:
            languageData[lang]['page.experience.circle.icon.0.title'],
          elementText:
            languageData[lang]['page.experience.circle.icon.0.subtitle'],
          position: {
            columnStart: '2',
            columnEnd: '3',
          },
        },
        {
          icon: 'gridImageExperience2',
          elementTitle:
            languageData[lang]['page.experience.circle.icon.1.title'],
          elementText:
            languageData[lang]['page.experience.circle.icon.1.subtitle'],
          position: {
            columnStart: '1',
            columnEnd: '2',
          },
        },
        {
          icon: 'gridImageExperience3',
          elementTitle:
            languageData[lang]['page.experience.circle.icon.2.title'],
          elementText:
            languageData[lang]['page.experience.circle.icon.2.subtitle'],
          position: {
            columnStart: '3',
            columnEnd: '4',
          },
        },
        {
          icon: 'gridImageExperience4',
          elementTitle:
            languageData[lang]['page.experience.circle.icon.3.title'],
          elementText:
            languageData[lang]['page.experience.circle.icon.3.subtitle'],
          position: {
            columnStart: '1',
            columnEnd: '2',
          },
        },
        {
          icon: 'gridImageExperience5',
          elementTitle:
            languageData[lang]['page.experience.circle.icon.4.title'],
          elementText:
            languageData[lang]['page.experience.circle.icon.4.subtitle'],
          position: {
            columnStart: '3',
            columnEnd: '4',
          },
        },
      ],
      arrows: [
        {
          arrowIcon: 'arrow_4',
          position: {
            columnStart: '1',
            columnEnd: '2',
            rowStart: '1',
            justifyPosition: 'end',
          },
        },
        {
          arrowIcon: 'arrow_1',
          position: {
            columnStart: '3',
            columnEnd: '4',
            rowStart: '1',
            justifyPosition: 'start',
          },
        },
        {
          arrowIcon: 'arrow_3',
          position: {
            columnStart: '-4',
            columnEnd: '-3',
            rowStart: '3',
          },
        },
        {
          arrowIcon: 'arrow_2',
          position: {
            columnStart: '3',
            columnEnd: '4',
            rowStart: '3',
          },
        },

        {
          arrowIcon: 'arrow_5',
          position: {
            columnStart: '2',
            columnEnd: '3',
            rowStart: '4',
            justifyPosition: 'center',
            alignPosition: 'end',
          },
        },
      ],
    },
    ctaSimpleTourData: {
      imgSrc: 'ctaSimpleImage8',
      asHero: false,
      reversed: true,
      title: languageData[lang]['page.experience.cta.tour.main.title'],
      paragraphs: [
        languageData[lang]['page.experience.cta.tour.paragraph.0'],
        languageData[lang]['page.experience.cta.tour.paragraph.1'],
      ],
      buttonLabel: languageData[lang]['page.experience.cta.tour.button.label'],
      buttonHref: '/itineraries/dolomites',
    },
    ctaCardData: {
      title: languageData[lang]['page.experience.cta.card.0.title'],
      centerContent: true,
      simple: true,
      subtitle: languageData[lang]['page.experience.cta.card.0.subtitle'],
      bottomContent: {
        type: 'button',
        buttonLabel: languageData[lang]['page.experience.cta.card.0.button'],
        buttonLink: '/contact-us',
      },
    },
  }
}

export const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Experiences - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: `Lelecabs provides a wide range of additional experiences as part of your connection service which aim to make your trip in the North of Italy unforgettable. `,
    },
  },
]
