const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const NAME_REGEX = /^[\p{L} ,.'-]+$/u

export const isValidEmail = (email) => {
  return EMAIL_REGEX.test(email)
}

export const isValidFirstName = (text) => {
  return NAME_REGEX.test(text) && text && text.length >= 2
}

export const isValidFamilyName = (text) => {
  return NAME_REGEX.test(text) && text && text.length >= 2
}
