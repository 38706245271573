import { Link, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const activeStyle = css`
  border-left: 8px solid #e3012b;
  color: #5a5a5a;
  text-decoration: none;
  background: linear-gradient(
    90deg,
    rgba(227, 117, 137, 0.282) 0%,
    rgba(231, 163, 176, 0) 100%
  );
  & svg {
    color: #e3012b;
  }
  & span {
    background: url('/images/sidebar/my-trips-red.svg');
    background-size: cover;
  }
`

export const MenuItem = styled.h4`
  cursor: pointer;
  padding: 30px 60px;
  text-decoration: none;
  text-transform: none;
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 1.08;
  font-family: 'RalewayBold';
  &.active,
  &:hover {
    ${activeStyle}
  }
  &:not(.active):not(:hover) {
    border-left: 8px solid transparent;
  }
`

const SidebarMenu = ({ data }) => {
  const location = useLocation()
  return (
    <MenuWrapper>
      {data.map((item) => (
        <MenuItem
          key={item.id}
          as={Link}
          to={item.href}
          className={item.href === location.pathname ? 'active' : ''}
        >
          {item.icon}
          {item.label}
        </MenuItem>
      ))}
    </MenuWrapper>
  )
}

export default SidebarMenu
