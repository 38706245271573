import React from 'react'
import * as S from './styled'
import * as T from './../atomic/Typography'
import Button from './../buttons'
import { useState } from 'react'
import { showDate } from '../single-trip'
import { isMoreThanOneDay } from '../../utils/rideUtils'
import parse, { domToReact } from 'html-react-parser'

const getModalDate = (date) => (
  <S.Span>
    {`${showDate(date, 'date')} 
    at ${showDate(date, 'hour')}`}
  </S.Span>
)

const ModalCancel = ({
  data,
  isModalOpen,
  setIsModalOpen,
  handleDeleteTrip,
  date,
  loadingDelete,
  handleEditTrip,
  trip,
}) => {
  const [checkValue, setCheckValue] = useState(false)

  const handleModalAction = {
    cancel: () => setIsModalOpen(false),
    delete: handleDeleteTrip,
    edit: handleEditTrip,
  }

  return (
    <S.ModalComponent
      open={isModalOpen}
      footer={null}
      closable
      centered
      destroyOnClose={true}
      maskClosable={true}
      width={579}
    >
      <S.Wrapper>
        <S.Title full>{data.title}</S.Title>
        {data.mainSubtitle && (
          <T.P2>
            {data.mainSubtitle} {getModalDate(date)}
          </T.P2>
        )}
        <S.ParagraphsWrapper>
          {data.subtitles.map((text, i) => (
            <T.P2 key={i}>
              {parse(
                `${text.subtitle} ${
                  text.boldText ? `<span>${text.boldText}</span>` : ''
                }`,
                {
                  replace: ({ type, name, attribs, children }) => {
                    if (type === 'tag' && name === 'span') {
                      return <S.Span>{domToReact(children)}</S.Span>
                    }
                  },
                },
              )}
            </T.P2>
          ))}
        </S.ParagraphsWrapper>
        {data.checkbox && (
          <S.CheckBoxWrapper>
            <S.Input
              type="checkbox"
              id="checkbox"
              onChange={(e) => setCheckValue(e.target.checked)}
              value={checkValue}
            />
            <S.Label htmlFor="checkbox">{data.checkbox.checkboxLabel}</S.Label>
          </S.CheckBoxWrapper>
        )}
        {data.type === 'originalTrip' && isMoreThanOneDay(trip.when) < 24 && (
          <S.Error>{data.error}</S.Error>
        )}
        <S.ButtonWrapper>
          {!!data.buttonBlack && (
            <Button
              type="secondary"
              label={data.buttonBlack.label}
              full
              onClick={handleModalAction[data.buttonBlack.handler]}
            />
          )}
          {!!data.buttonWhite && (
            <Button
              type="white"
              label={data.buttonWhite.label}
              disabled={
                (data.checkbox && !checkValue) ||
                isMoreThanOneDay(trip.when) < 24
              }
              onClick={handleModalAction[data.buttonWhite.handler]}
            />
          )}
        </S.ButtonWrapper>
        {data.type === 'originalTrip' && loadingDelete ? (
          <S.Loading inline />
        ) : null}
      </S.Wrapper>
    </S.ModalComponent>
  )
}

export default ModalCancel
