import styled from 'styled-components'
import * as T from './../atomic/Typography'

export const ProcessingLabel = styled.h3`
  font-family: 'RalewayRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 23px;
`
export const ProcessingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 31px;
  padding-bottom: 40px;
`

export const Line = styled.div`
  height: 4px;
  width: 100%;
  background-color: black;
  margin: 23px 0 16px 0;
`

export const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  & .path {
    stroke: black;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

export const Return = styled.div`
  margin-top: 15px;
  width: 100%;
`

export const DateLabel = styled(T.P2)``

export const DateWrapper = styled.div`
  box-sizing: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`
