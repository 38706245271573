import { loadState } from './../utils/localStorage'

import english from './english'
import italian from './italian'
import german from './german'
import korean from './korean'

import {
  US,
  IT,
  DE,
  FR,
  ES,
  NL,
  PT,
  JP,
  CN,
  KR,
} from 'country-flag-icons/react/3x2'

const defaultLang = 'EN'

/***********************************
 * Get the current language reading
 * localStorage, fallback to default
 * ********************************/
export const getCurrentLanguage = () => loadState('lang') || defaultLang

/***********************************
 * Get the current path
 * ********************************/
export const getPath = () =>
  window.location.pathname.slice(1) + window.location.search

/***********************************
 * Get the current language reading
 * localStorage, fallback to default
 * ********************************/
export const populatePathWithLang = (lang, link) => {
  const langLowerCase = lang.toLowerCase()
  if (langLowerCase === 'en') return link
  if (link === '/' || link[0] === '/') return `${langLowerCase}${link}`
  else return `${langLowerCase}/${link}`
}

export const getPathWithoutLang = () =>
  window.location.pathname
    .split('/')
    .filter(
      (section) =>
        !languages
          .map((lang) => lang.value.toLowerCase() + '/')
          .includes(section + '/'),
    )
    .join('/')

const languages = [
  { value: 'EN', label: 'EN', longLabel: 'English', default: true, route: '' },
  { value: 'IT', label: 'IT', longLabel: 'Italiano', route: '/it' },
  { value: 'DE', label: 'DE', longLabel: 'Deutsch', route: '/de' },
  // { value: 'FR', label: 'FR', longLabel: 'Français', route: '/fr' },
  // { value: 'ES', label: 'ES', longLabel: 'Español', route: '/es' },
  // { value: 'NL', label: 'NL', longLabel: 'Dutch', route: '/nl' },
  // { value: 'PT', label: 'PT', longLabel: 'Portugais', route: '/pt' },
  // { value: 'JA', label: 'JA', longLabel: '日本語', route: '/ja' },
  // { value: 'ZH', label: 'ZH', longLabel: '中文', route: '/zh' },
  { value: 'KO', label: 'KO', longLabel: '한국어', route: '/ko' },
  //{ value: 'RU', label: 'RU', longLabel: 'русский', route: 'ru' }
]

export const Flag = {
  EN: US,
  IT,
  DE,
  FR,
  ES,
  NL,
  PT,
  JA: JP,
  ZH: CN,
  KO: KR,
}

export const DefaultFlag = Flag[defaultLang]

export const languageData = {
  EN: english,
  en: english,
  IT: italian,
  it: italian,
  DE: german,
  de: german,
  KO: korean,
  ko: korean,
}

export default languages
