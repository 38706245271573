import React from 'react'
import { AbsoluteWrapper, CarMoving } from './styled'

const CarLoader = ({ full }) => {
  return (
    <AbsoluteWrapper full={full}>
      <CarMoving size={40} />
    </AbsoluteWrapper>
  )
}

export default CarLoader
