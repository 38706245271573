import * as S from './styled'
import { countryCodes } from './../../utils/countryCodes'
import { getCurrentLanguage, languageData } from '../../languages'

const PassengerInfoForm = ({ handler, value }) => {
  const lang = getCurrentLanguage()
  return (
    <S.PassangerForm>
      <S.SingleInput>
        <label htmlFor="passangerName">
          {languageData[lang]['home.page.passenger.name.label']}
        </label>
        <S.Input
          id="passangerName"
          value={value['passangerName']}
          onChange={(e) => handler(e.target.id, e.target.value)}
        />
      </S.SingleInput>
      <S.PhoneInputWrapper>
        <S.SingleInput>
          <label htmlFor="prefix">
            {languageData[lang]['home.page.passenger.prefix.label']}
          </label>
          <S.ReactFlags
            id="phonePrefix"
            selected={value['phonePrefix']}
            onSelect={(code) => handler('phonePrefix', code)}
            searchable={true}
            fullWidth={false}
            customLabels={countryCodes}
          />
        </S.SingleInput>
        <S.SingleInput full>
          <label htmlFor="passangerPhone">
            {languageData[lang]['home.page.passenger.phone.label']}
          </label>
          <S.Input
            id="passangerPhone"
            value={value['passangerPhone']}
            onChange={(e) => handler(e.target.id, e.target.value)}
          />
        </S.SingleInput>
      </S.PhoneInputWrapper>
      <S.SingleInput>
        <label htmlFor="passangerEmail">
          {languageData[lang]['home.page.passenger.email.label']}
        </label>
        <S.Input
          id="passangerEmail"
          type="email"
          value={value['passangerEmail']}
          onChange={(e) => handler(e.target.id, e.target.value)}
        />
      </S.SingleInput>
    </S.PassangerForm>
  )
}

export default PassengerInfoForm
