import React from 'react'
import styled from 'styled-components'
import { PageFromJson } from './../../../components/utilities/MainContainer'

const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'Payment Error - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: 'Error during payment',
    },
  },
]

const AbsoluteWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const AbsoluteWrapperChild = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 400px;
`

const WrapperCompostion = ({ children }) => (
  <AbsoluteWrapper>
    <AbsoluteWrapperChild>{children}</AbsoluteWrapperChild>
  </AbsoluteWrapper>
)

const components = []

const ReservationCancel = () => {
  return (
    <PageFromJson metaPage={metaPage} components={components}>
      <WrapperCompostion>
        <h2>A problem has occurred</h2>
      </WrapperCompostion>
    </PageFromJson>
  )
}

export default ReservationCancel
