import CtaCard from '../../components/cta-card'
import FaqList from '../../components/faq-list'
import FormContactUs from '../../components/form-contact-us'
import { DefaultLayoutNew } from '../../components/utilities/DefaultLayoutNew'
import InjectMeta from '../../components/utilities/InjectMeta'
import { getCurrentLanguage } from '../../languages'
import PageLoadAnimation from '../../styles/animations'
import { metaPage, pageData } from './data'
import { Wrapper } from './styled'

const PageContactUs = () => {
  const data = pageData()
  const lang = getCurrentLanguage()
  return (
    <>
      <InjectMeta meta={metaPage} lang={lang} />
      <PageLoadAnimation>
        <DefaultLayoutNew pageType="viewer" footerType="new">
          <Wrapper>
            <CtaCard data={data.ctaCardData} />
            <CtaCard data={data.ctaCardCallbackData} />
            <FormContactUs data={data.formData} />
            <FaqList data={data.faqPageData} />
            <CtaCard data={data.ctaCardSimpleData} />
          </Wrapper>
        </DefaultLayoutNew>
      </PageLoadAnimation>
    </>
  )
}

export default PageContactUs
