import { Link, useLocation } from 'react-router-dom'
import { imagesFiles } from '../../styles/images'
import * as S from './styled'

const ListStepsWithImage = ({ data }) => {
  const { search } = useLocation()

  return (
    <S.Wrapper>
      <S.Title>{data.title}</S.Title>
      <S.Subtitle>{data.subtitle}</S.Subtitle>
      {data.rows.map((row, index) => (
        <S.GridElement key={index}>
          <S.RowImage src={imagesFiles[row.image]} alt={row.image}></S.RowImage>
          <S.TextContent $iter={index}>
            <S.Step>{row.step}</S.Step>
            <S.RowTitle>{row.title}</S.RowTitle>
            <S.RowParagraph>{row.paragraph}</S.RowParagraph>
            <Link to={`${row.linkHref}${search}`}>
              <S.LinkText>{row.linkText}</S.LinkText>
            </Link>
          </S.TextContent>
        </S.GridElement>
      ))}
    </S.Wrapper>
  )
}

export default ListStepsWithImage
