import React from 'react'
import styled from 'styled-components'
import parse, { domToReact } from 'html-react-parser'
import propPath from 'crocks/Maybe/propPath'
// import domToReact from 'html-react-parser/lib/dom-to-react';
import { P1 } from './../atomic/Typography'
import { breakpoint } from './../../styles/global.style'

const Table = styled.table`
  margin-bottom: 30px;
  padding: 10px 5px;
  border-collapse: collapse;
  thead tr th {
    padding: 15px 5px;
    border-top: 3px solid black;
    border-bottom: 3px solid black;
  }
  tbody tr td {
    padding: 15px 5px;
  }
  caption {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`
const OList = styled.ol`
  list-style: decimal outside none;
  padding-inline-start: 20px;
  line-height: 1.5;
  margin-bottom: 30px;
  ol {
    list-style: lower-alpha inside none;
    padding-left: 20px;
  }
  li {
    font-size: 18px;
    padding-left: 5px;
    line-height: 30px;
  }
`
const UList = styled.ul`
  list-style: disc inside none;
  padding-left: 0px;
  margin-left: 0px;
  line-height: 1.5;
  margin-bottom: 30px;
  li {
    font-size: 18px;
    line-height: 30px;
  }
`
const Wrapper = styled.div`
  color: ${(props) => (props.text_colour ? props.text_colour : 'white')};
  padding-top: 25px;
  ${(props) => (props.border === 'above' ? 'border-top: 2px solid black;' : '')}
  ${(props) =>
    props.border === 'below' ? 'border-bottom : 2px solid black;' : ''}
  ${(props) =>
    props.border === 'above_below'
      ? `
      border-top : 2px solid black;
      border-bottom : 2px solid black;
    `
      : ''}
  ${(props) => (props.twoCol ? 'column-count: 2;' : '')}
  @media(max-width: ${breakpoint.tablet}) {
    padding-top: 0px;
  }
`

const H2 = styled.h2`
  text-align: ${(props) => props.align};
`

const H3 = styled.h3`
  text-align: ${(props) => props.align};
`

const CoreHtmlParser = ({ data }) => {
  const type = propPath(['body', 'format'], data).option('plain_text')
  const parsedBody = propPath(['body', 'content'], data).option('') //purifier.purify(data.body)
  if (type === 'plain_text') {
    return (
      <Wrapper>
        <P1 extraSpace={30} extraSpaceMobile={10}>
          {parsedBody}
        </P1>
      </Wrapper>
    )
  }
  return (
    <Wrapper
      border={data.border}
      twoCol={data.two_column_flow}
      text_colour={data.text_colour}
    >
      {parse(parsedBody, {
        replace: (domNode) => {
          // if (domNode.name && domNode.name === 'p') console.log(domNode);

          switch (domNode.name) {
            case 'h2':
              const alignH2 = propPath(['attribs', 'class'], domNode)
                .map((item) => item.slice(11))
                .option('')
              return <H2 align={alignH2}>{domToReact(domNode.children)}</H2>
            case 'h3':
              const alignH3 = propPath(['attribs', 'class'], domNode)
                .map((item) => item.slice(11))
                .option('')
              return <H3 align={alignH3}>{domToReact(domNode.children)}</H3>
            case 'ol':
              return <OList>{domToReact(domNode.children)}</OList>
            case 'p':
              const align = propPath(['attribs', 'class'], domNode)
                .map((item) => item.slice(11))
                .option('')
              return (
                <P1 align={align} extraSpace={30} extraSpaceMobile={10}>
                  {domToReact(domNode.children)}
                </P1>
              )
            case 'table':
              return <Table>{domToReact(domNode.children)}</Table>
            case 'ul':
              return <UList>{domToReact(domNode.children)}</UList>
            default:
              return <React.Fragment />
          }
        },
      })}
    </Wrapper>
  )
}

export default CoreHtmlParser
