import { languageData, getCurrentLanguage } from '../../languages'

export const getData = () => {
  const lang = getCurrentLanguage()
  return {
    title: languageData[lang]['header.menu.logged.link.2.title'],
    list: [
      {
        id: 'name',
        label: languageData[lang]['header.menu.logged.link.2.content.0'],
      },
      {
        id: 'contact',
        label: languageData[lang]['header.menu.logged.link.2.content.1'],
        content: [{ id: 'email' }, { id: 'call' }],
      },
      {
        id: 'settings',
        label: languageData[lang]['header.menu.logged.link.2.content.2'],
        content: [
          {
            id: 'password',
            label:
              languageData[lang]['header.menu.logged.link.2.content.2.link.0'],
            href: '/account/password-change',
          },
          {
            id: 'terms',
            label:
              languageData[lang]['header.menu.logged.link.2.content.2.link.1'],
            href: '/terms',
          },
        ],
      },
      {
        id: 'company',
        label: languageData[lang]['header.menu.logged.link.2.content.4'],
      },
    ],
  }
}
