import styled from 'styled-components'
import { breakpoint, colors } from '../../styles/global.style'

import { Person } from '@styled-icons/bootstrap/Person'

export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`

export const Wrapper = styled.div`
  width: 100%;
  margin: 40px 50px 35px 0px;
  padding: 25px 50px;
  background: #ffffff;
  /* Neutrals/500 */

  border: 1px solid #e0e0e0;
  border-radius: 12px;
  @media (max-width: ${breakpoint.medium}) {
    padding: 25px 20px;
  }
  @media (max-width: ${breakpoint.tablet}) {
    padding: 20px 15px;
  }
  @media (max-width: ${breakpoint.mobile}) {
    padding: 20px 10px;
    margin: 20px 0px;
  }
`

export const AccountWrapper = styled.div`
  width: 100%;
  background: ${colors.kfcPageBg};
  display: flex;
  gap: 80px;
  @media (max-width: ${breakpoint.mobile}) {
    padding: 70px 0;
  }
`

export const TripListTitle = styled.h3`
  margin-bottom: 30px;
  text-transform: none;
  border-bottom: 2px solid ${colors.kfcRed};
  padding-bottom: 10px;
  width: 290px;
  letter-spacing: initial;
  @media (max-width: ${breakpoint.tablet}) {
    box-sizing: border-box;
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-bottom: 20px;
    padding-left: 15px;
  }
`

export const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  margin-bottom: 40px;
  gap: 10px;
  > div {
    font-size: 16px;
  }

  @media (max-width: ${breakpoint.tablet}) {
    padding-left: 30px;
    display: flex;
    flex-direction: column;
  }
`

export const Title = styled.h5`
  font-size: 18px;
  margin-bottom: 10px;
  margin-right: auto;
`

export const CWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > span {
    display: flex;
    align-items: center;
    gap: 15px;
    > svg {
      width: 30px;
      color: #ea7b8f;
    }
  }
`

export const Link = styled.a`
  margin: 0px 5px 10px;
`

export const Div = styled.div`
  height: 100vh;
  width: 100vw;
`

export const AccountType = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  button {
    font-size: 12px;
    padding: 0px 8px;
  }
`

export const AccountInfo = styled.div`
  display: flex;
  gap: 10px;
`

export const PersonalIcon = styled(Person)``
