import React, { useEffect, useState, useContext } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import {
  getCurrentLanguage,
  getPath,
  getPathWithoutLang,
  populatePathWithLang,
} from './../../languages'

import { sendPageViewEvent } from './../../hooks/useAnalyticsEventTracker'

import { AuthContext } from './../auth-context'

import { PageWrapper } from './../atomic/Layout'
import SuperMegaWrapper from './../super-mega-wrapper'
import FooterNew from './../footer-new'
import HeaderNew from './../header_new'

const paddingSchema = {
  'sm-normal': 140,
  'sm-tablet': 110,
  'sm-mobile': 100,
  normal: 80,
  tablet: 54,
  mobile: 54,
}

const getPaddingTop = (modalOpen, subMenu, type = 'normal') => {
  let padding = 0
  if (modalOpen && type === 'normal') return padding
  if (subMenu) padding += paddingSchema[`sm-${type}`]
  else padding += paddingSchema[type]
  return padding
}

export const DefaultLayoutNew = ({
  children,
  //redirect = 'no',
  //pageType = 'viewer',
  lang = 'en',
}) => {
  const navigate = useNavigate()
  const { logout, userAttributes } = useContext(AuthContext)
  const [accountBox, setAccountBox] = useState(false)

  const handleCloseMenu = () => {
    if (accountBox) setAccountBox(false)
  }

  useEffect(() => {
    const path = getPath()
    const pathWithoutLang = getPathWithoutLang()
    const currentLang = getCurrentLanguage()
    const newPath = populatePathWithLang(currentLang, pathWithoutLang)
    if (path.split('/').join('') !== newPath.split('/').join('')) {
      navigate(newPath === '/' ? '/' : '/' + newPath)
    }
  }, [lang])

  const { pathname } = useLocation()
  useEffect(() => {
    const currentPath = getPath()
    window.scrollTo(0, 0)
    sendPageViewEvent(currentPath)

    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', process.env.REACT_APP_GA_ID)
    if (process.env.NODE_ENV === 'production') {
      gtag('config', process.env.REACT_APP_GA_ID, {
        page_path: currentPath,
      })
    }
  }, [pathname])

  return (
    <>
      <PageWrapper
        paddingTop={getPaddingTop()}
        paddingTopTablet={getPaddingTop(false, false, 'tablet')}
        paddingTopMobile={getPaddingTop(false, false, 'mobile')}
        onClick={handleCloseMenu}
      >
        <SuperMegaWrapper>
          <HeaderNew
            firstName={userAttributes?.name || ''}
            lastName={userAttributes?.familyName || ''}
            handleLogout={() => {
              logout()
            }}
            accountBox={accountBox}
            setAccountBox={setAccountBox}
            lang={lang}
          />
          {/*<div className="childrenWrapper">{children}</div>*/}
          {children}
          <FooterNew lang={lang} />
        </SuperMegaWrapper>
      </PageWrapper>
    </>
  )
}
