import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import propPath from 'crocks/Maybe/propPath'
import { breakpoint } from './../../styles/global.style'

export const Uh1 = styled.h1`
  position: relative;
  display: inline-block;
  padding-bottom: ${(props) => props.paddingBottom || '0px'};
  color: ${(props) => (props.invertColors ? '#ffffff' : props.theme.secondary)};
  margin-bottom: ${(props) =>
    props.extraSpace ? `${props.extraSpace}px` : '0px'};
  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-bottom: 3px solid
      ${(props) => (props.borderWhite ? 'white' : props.theme.primary)};
    content: '';
  }
`

export const Uh1Good = styled.h1`
  text-align: center;
  position: relative;
  padding-bottom: ${(props) => props.paddingBottom || '0px'};
  display: inline-block;
  color: ${(props) =>
    props.invertColors ? '#ffffff' : props.color || 'inherit'};
  margin: 0;
  border-bottom: 3px solid
    ${(props) => (props.borderWhite ? 'white' : props.theme.primary)};
`

export const Contain = styled.div`
  padding-bottom: 16px;
  margin-bottom: 5px;
`

export const Uh2 = styled.h2`
  position: relative;
  padding-bottom: ${(props) => props.paddingBottom || '0'}px;
  display: inline-block;
  color: ${(props) => (props.invertColors ? '#ffffff' : props.theme.secondary)};
  margin-bottom: ${(props) =>
    props.extraSpace ? `${props.extraSpace}px` : '0px'};
  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    border-bottom: 3px solid
      ${(props) => (props.borderWhite ? 'white' : props.theme.primary)};
    content: '';
  }
`

export const UH2asH1 = styled.h1`
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  position: relative;
  padding-bottom: ${(props) => props.paddingBottom || '0px'};
  display: inline-block;
  color: ${(props) =>
    props.invertColors ? '#ffffff' : props.color || 'inherit'};
  margin: 0;
  border-bottom: 3px solid
    ${(props) => (props.borderWhite ? 'white' : props.theme.primary)};
`

export const Uh2Good = styled.h2`
  text-align: center;
  position: relative;
  padding-bottom: ${(props) => props.paddingBottom || '0px'};
  display: inline-block;
  color: ${(props) =>
    props.invertColors ? '#ffffff' : props.color || 'inherit'};
  margin: 0;
  border-bottom: 3px solid
    ${(props) => (props.borderWhite ? 'white' : props.theme.primary)};
`

const Line = styled.hr`
  width: ${(props) => props.width};
  height: 0px;
  color: ${(props) => (props.borderWhite ? 'white' : props.theme.primary)};
  border: none;
  background-color: ${(props) => {
    if (props.borderWhite === 'white') return 'white'
    return props.theme.primary
  }};
  margin: 0px 0px ${(props) => props.extraSpace || '0'}px;
  @media (max-width: ${breakpoint.tablet}) {
    margin: 0px 0px
      ${(props) => props.extraSpaceMobile || props.extraSpace || '0'}px;
  }
  @media (max-width: ${breakpoint.mobile}) {
    margin: 0px 0px ${(props) => (props.hideMobile ? '40' : '15')}px;
  }
`

const calculateNewLine = (width, previousline, newline, wconst) => {
  const previouslineWidth = previousline.length * wconst
  if (previouslineWidth > width) {
    const words = previousline.split(' ')
    const composedNewline = `${words[words.length - 1]} ${newline}`
    return calculateNewLine(
      width,
      words.slice(0, -1).join(' '),
      composedNewline,
      wconst,
    )
  } else {
    return newline ? newline : previousline
  }
}

const getCharConst = (type, wC = 1, hC = 50) => {
  switch (type) {
    case 'h1':
      return { widthConst: 24.5, heightConst: 70 }
    case 'h1-small':
      return { widthConst: 15, heightConst: 43 }
    case 'h2':
      return { widthConst: 16.3, heightConst: 48 }
    case 'h2-small':
      return { widthConst: 12.2, heightConst: 38 }
    default:
      return { widthConst: wC, heightConst: hC }
  }
}
const calculateWidth = (type, width, height, label) => {
  const { widthConst, heightConst } = getCharConst(type)
  if (height < heightConst) return width
  const newline = calculateNewLine(width, label, '', widthConst)
  return newline.length * widthConst
}

/***
 * Use this component to create underline heading with the adapting
 * underline for all the heading sizes h1, h2. (h3, h4) not done yet
 * @property {boolean} hideMobile - optional, hide for screens < breakpoin.mobile
 * @property {boolean} borderWhite - optional, force the white color no matter what
 * @property {string} paddingBottom - optionall, set the padding, inlcude unit in the string
 * @property {color} color - optional, set the heading color
 * @property {label} label - optional, set the heading text
 * @property {number} extraSpace - optional, add additional margin bottom pixels
 * @property {string} type - mandatory, select the type of heading
 * @property {boolean} invertColors - optional, set a default color scheme for dark backgrounds
 */
export const UhDyn = (props) => {
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(50)
  const [, setWindowWidth] = useState(1000)
  const ref = useRef(null)

  const cH = propPath(['current', 'clientHeight'], ref).option(0)
  const cW = propPath(['current', 'clientWidth'], ref).option(50)
  const wW = process.browser
    ? propPath(['innerWidth'], window).option(50)
    : 1000

  useEffect(() => {
    setHeight(ref.current.clientHeight)
  }, [cH])
  useEffect(() => {
    setWidth(ref.current.clientWidth)
  }, [cW])
  useEffect(() => {
    let handleResize = () => {}
    if (process.browser) {
      handleResize = () => setWindowWidth(window.innerWidth)
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])
  const lineArray = props.label ? props.label.split('<br>') : []
  const lastline =
    lineArray && lineArray.length > 0 ? lineArray[lineArray.length - 1] : ''
  const newWidth = calculateWidth(
    `${props.type}${wW < 480 ? '-small' : ''}`,
    width,
    height,
    lastline,
  )

  return (
    <>
      {props.type === 'h1' && (
        <Uh1Good
          ref={ref}
          {...props}
          dangerouslySetInnerHTML={{ __html: props.label }}
        />
      )}
      {props.type === 'h2' && !props.first && (
        <Uh2Good
          ref={ref}
          first={props.first}
          {...props}
          dangerouslySetInnerHTML={{ __html: props.label }}
        />
      )}
      {props.type === 'h2' && !!props.first && (
        <UH2asH1
          ref={ref}
          first={props.first}
          {...props}
          dangerouslySetInnerHTML={{ __html: props.label }}
        />
      )}
      {/* here I'm taking out this as requested, keeping it for the spacing */}
      {<Line width={newWidth} {...props} height="0" />}
    </>
  )
}

export const Uh2Test = (props) => {
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(50)
  const [, setWindowWidth] = useState(1000)
  const ref = useRef(null)

  const cH = propPath(['current', 'clientHeight'], ref).option(0)
  const cW = propPath(['current', 'clientWidth'], ref).option(50)
  const wW = process.browser
    ? propPath(['innerWidth'], window).option(50)
    : 1000

  useEffect(() => {
    setHeight(ref.current.clientHeight)
  }, [cH])
  useEffect(() => {
    setWidth(ref.current.clientWidth)
  }, [cW])
  useEffect(() => {
    let handleResize = () => {}
    if (process.browser) {
      handleResize = () => setWindowWidth(window.innerWidth)
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  const lineArray = props.label ? props.label.split('<br>') : []
  const lastline =
    lineArray && lineArray.length > 0 ? lineArray[lineArray.length - 1] : ''
  const newWidth = calculateWidth(
    `h2${wW < 480 ? '-small' : ''}`,
    width,
    height,
    lastline,
  )
  return (
    <>
      <Uh2Good
        ref={ref}
        {...props}
        dangerouslySetInnerHTML={{ __html: props.label }}
      />
      <Line width={newWidth} {...props} />
    </>
  )
}

export const Uh3 = styled.h3`
  padding-bottom: ${(props) => props.padding || '10'}px;
  color: ${(props) => props.color || '#000'};
`

export const TextLink = styled.a`
  color: ${(props) => props.color || props.theme.primary};
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  &:hover {
    opacity: 0.5;
  }
`

export const LinkUnderline = styled.a`
  color: ${(props) => props.theme.secondary};
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  margin-bottom: ${(props) => props.marginBottom || '0'}px;
  width: ${(props) => (props.width ? props.width : 'auto')};
`

export const P1 = styled.p`
  font-size: 18px;
  line-height: 1.3;
  ${(props) =>
    props.align
      ? `
    text-align: ${props.align};
  `
      : ''}
  color: ${(props) => props.color || 'inherit'};
  margin-bottom: ${(props) =>
    props.extraSpace ? `${props.extraSpace}px` : '0px'};
  ${(props) => props.center && 'text-align: center;'}
  @media(max-width: ${breakpoint.mobile}) {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: ${(props) =>
      props.extraSpaceMobile || props.extraSpace || '0'}px;
  }
`

export const P2 = styled.p`
  font-size: 16px;
  line-height: 25px;
  margin-bottom: ${(props) => props.marginBottom || '0'}px;
  color: ${(props) => props.color || 'inherit'};
  ${(props) => props.center && 'text-align: center;'}
`

export const P3 = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: ${(props) => props.marginBottom || '0'}px;
  color: ${(props) => props.color || 'inherit'};
  ${(props) => props.center && 'text-align: center;'}
`

export const Note = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: ${(props) => props.marginBottom || '0'}px;
  color: ${(props) => props.color || 'inherit'};
  ${(props) => props.center && 'text-align: center;'}
`
