import React from 'react'
import styled from 'styled-components'
import propPath from 'crocks/Maybe/propPath'
import { Link, useLocation } from 'react-router-dom'
import { Uh2 } from './../atomic/Typography'
import { DRow, DCol } from './../atomic/Layout'
import { ext } from './../../utils/utils'
import CoreHtmlParser from './../blocks/CoreHtmlParser'
import Button from './../buttons'
import { breakpoint } from './../../styles/global.style'

const StyledEnv = styled.div`
  max-width: 1000px;
  width: 90%;
`

const getBtnStyle = (type) =>
  type ? (type === 'Quaternary' ? 'tertiary' : type.toLowerCase()) : 'primary'

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0px -20px;
  a {
    margin: 5px;
  }
  a button {
    margin: 0px 15px;
  }
`

const ExternalWrapper = styled(ext(DRow))`
  @media (max-width: ${breakpoint.tablet}) {
    ${(props) => (props.background ? 'padding: 40px 20px !important;' : '')}
  }
`

const InternalWrapper = styled(ext(DCol))`
  @media (max-width: ${breakpoint.tablet}) {
    max-width: none;
    ${(props) => (props.background ? 'padding: 40px 20px !important;' : '')}
  }
`

const SafeHtmlParser = ({ data, spaced }) => {
  const { search } = useLocation()
  const imgSrc = propPath(['image', 'original', 'url'], data).option('noImage')
  const incopyImgSrc = propPath(['image2', 'original', 'url'], data).option(
    'noImage',
  )
  const incopyImgMeta = propPath(['image2', 'original'], data).option({})
  const buttons = ['', '2'].map((infix) => ({
    ctaButton: data[`button${infix}_text`],
    ctaButtonStyle: getBtnStyle(data[`button${infix}_style`]),
    ctaButtonLink: data[`button${infix}_link`]
      ? data[`button${infix}_link`].uri
      : null,
    ctaButtonLinkTarget:
      data[`button${infix}_link_targets`] === 'same_tab' ? '_self' : '_blank',
  }))
  const alignment = propPath(['content_alignment'], data).option('center')
  const maxWidth = alignment === 'left' || alignment === 'right' ? '34%' : ''
  const justifyContent = propPath(['content_alignment'], data)
    .map((item) => {
      if (item === 'centre' || imgSrc === 'noImage') return 'center'
      if (item === 'left') return 'flex-start'
      if (item === 'right') return 'flex-end'
      return item
    })
    .option('center')
  return (
    <ExternalWrapper
      justifyContent={justifyContent}
      alignment={alignment}
      spaced={spaced}
      background={imgSrc !== 'noImage' ? imgSrc : ''}
    >
      <InternalWrapper
        alignItems={justifyContent}
        maxWidth={maxWidth}
        background={imgSrc !== 'noImage' ? imgSrc : ''}
        vertical
        padding="10"
      >
        {data.heading && (
          <StyledEnv>
            <Uh2 extraSpace={15} paddingBottom="7">
              {data.heading}
            </Uh2>
          </StyledEnv>
        )}
        <StyledEnv>
          <CoreHtmlParser data={data} />
        </StyledEnv>
        {incopyImgSrc !== 'noImage' && (
          <img
            src={incopyImgSrc}
            alt={incopyImgMeta.alt}
            width={incopyImgMeta.width}
          />
        )}
        <ButtonsWrapper>
          {buttons &&
            buttons.map(
              (btn, iter) =>
                btn.ctaButton && (
                  <Link
                    to={`${btn.ctaButtonLink}${search}`}
                    target={btn.ctaButtonLinkTarget}
                    rel="noreferrer noopener"
                  >
                    <Button
                      style={{ margin: '0px 15px' }}
                      type={btn.ctaButtonStyle}
                      label={btn.ctaButton}
                      fat
                    />
                  </Link>
                ),
            )}
        </ButtonsWrapper>
      </InternalWrapper>
    </ExternalWrapper>
  )
}
export default SafeHtmlParser
