import styled from 'styled-components'
import * as T from '../atomic/Typography'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  display: flex;
  gap: 48px;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  padding: 32px 35px;
  @media (max-width: ${breakpoint.tablet}) {
    flex-direction: column;
  }
`

export const LeftWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`

export const Title = styled(T.Uh3)`
  font-size: 32px;
  line-height: normal;
  font-weight: 700;
`

export const Subtitle = styled(T.P2)``

export const Steps = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
`

export const SingleStep = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`

export const StepText = styled(T.P2)`
  color: #aeaeae;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 131.25% */
  letter-spacing: 0.24px;
`

export const StepTitle = styled(T.Uh3)`
  color: #2e2e2e;
  padding: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakpoint.tablet}) {
    font-size: 1em;
  }
`

export const RightForm = styled.div`
  display: flex;
  max-width: 468px;
  width: 100%;
  padding: 24px;
  gap: 24px;
  flex-direction: column;
  border-radius: 16px;
  background: #fff;
`

export const FormTitle = styled(T.Uh2)``

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 630px;
  margin-bottom: 24px;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Input = styled.input`
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #d4d9eb;
  background: #f7f7f8;
`
