import { getCurrentLanguage, languageData } from './../../languages/index'

export const pageData = () => {
  const lang = getCurrentLanguage()
  return {
    customThreeRowAdv: {
      title: languageData[lang]['page.how.works.three.col.title.main'],
      subtitle: languageData[lang]['page.how.works.three.col.subtitle.main'],
      rows: [
        {
          image: 'colSteps2',
          step: languageData[lang]['page.how.works.three.col.step.0'],
          title: languageData[lang]['page.how.works.three.col.step.0.title'],
          paragraph:
            languageData[lang]['page.how.works.three.col.step.0.paragraph'],
          linkText: languageData[lang]['page.how.works.three.col.step.1.link'],
          linkHref: '/',
        },
        {
          image: 'colSteps3',
          step: languageData[lang]['page.how.works.three.col.step.1'],
          title: languageData[lang]['page.how.works.three.col.step.1.title'],
          paragraph:
            languageData[lang]['page.how.works.three.col.step.1.paragraph'],
          linkText: languageData[lang]['page.how.works.three.col.step.1.link'],
          linkHref: '/',
        },
        {
          image: 'colSteps1',
          step: languageData[lang]['page.how.works.three.col.step.2'],
          title: languageData[lang]['page.how.works.three.col.step.2.title'],
          paragraph:
            languageData[lang]['page.how.works.three.col.step.2.paragraph'],
          linkText: languageData[lang]['page.how.works.three.col.step.2.link'],
          linkHref: '/contact-us',
        },
      ],
    },
    faqPageData: {
      sectionTitle: languageData[lang]['home.faq.footer.list.title'],
      longVersion: false,
      questions: [
        {
          questionTitle: languageData[lang]['home.faq.question.0'],
          questionAnswer: languageData[lang]['home.faq.answer.0'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.1'],
          questionAnswer: languageData[lang]['home.faq.answer.1'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.2'],
          questionAnswer: languageData[lang]['home.faq.answer.2'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.3'],
          questionAnswer: languageData[lang]['home.faq.answer.3'],
        },
        {
          questionTitle: languageData[lang]['home.faq.question.4'],
          questionAnswer: languageData[lang]['home.faq.answer.4'],
        },
      ],
      footer: {
        footerSubtitle: languageData[lang]['home.faq.footer.list.content'],
        linkWord: languageData[lang]['home.faq.footer.list.content.link'],
        linkHref: '/faq',
      },
    },
    ctaCardData: {
      title: languageData[lang]['page.how.works.cta.0.title'],
      subtitle: languageData[lang]['page.how.works.cta.0.subtitle'],
      bottomContent: {
        type: 'button',
        buttonLabel: languageData[lang]['page.how.works.cta.0.button'],
        buttonLink: '/account/register',
      },
    },
  }
}

export const metaPage = [
  {
    component: 'meta_title',
    data: {
      content: 'How it works - Lelecabs',
    },
  },
  {
    component: 'meta_description',
    data: {
      name: 'description',
      content: `At Lelecabs, we have tailored service to be a hassle-free, end-to-end experience from the moment you start planning your trip to the moment you. Find out more about what we provide. `,
    },
  },
]
