import styled from 'styled-components'

const PwWeak = styled.p`
  display: inline-block;
  position: relative;
  left: 0;
  padding: 0;
  margin-bottom: 25px;
  margin: 0;
  color: gray;
`
const PwStrong = styled.p`
  display: inline-block;
  position: relative;
  left: 77%;
  padding: 0;
  margin-bottom: 25px;
  margin: 0;
  color: gray;
`

const PasswordStr = (props) => {
  var strColor
  var strWidth

  switch (props.score) {
    case 1:
      strColor = 'red'
      strWidth = '20%'
      break
    case 2:
      strColor = 'orange'
      strWidth = '40%'
      break
    case 3:
      strColor = 'yellow'
      strWidth = '60%'
      break
    case 4:
      strColor = '#5cff47'
      strWidth = '80%'
      break
    case 5:
      strColor = 'green'
      strWidth = '100%'
      break
    default:
  }

  const style = {
    backgroundColor: strColor,
    height: '5px',
    width: strWidth,
    marginBottom: '30px',
    transition: 'all 300ms ease-in-out',
  }

  return (
    <div>
      <PwStrong>Strong</PwStrong>
      <PwWeak>Weak</PwWeak>
      <div style={style} />
    </div>
  )
}

export default PasswordStr
