import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import html2pdf from 'html2pdf.js/dist/html2pdf.min.js'
import { Spinner } from '../processing-ride'
import { useContext, useState } from 'react'
import * as T from './../atomic/Typography'
import { useNavigate } from 'react-router-dom'
import { saveState } from '../../utils/localStorage'
import { deleteApi, getApi } from '../../utils/restAPIUtils'
import { AuthContext } from '../auth-context'
import ModalCancel from '../cancel-trip-modal'
import { getCurrentLanguage, languageData } from './../../languages/index'
import {
  ArrowIcon,
  BreakdownTitle,
  ButtonRow,
  CardParentWrapper,
  CardWrapper,
  Circle,
  Col,
  Container,
  Content,
  Cost,
  DestinationsWrapper,
  DetailIconWrapper,
  DetailedBreakdown,
  DetailsList,
  DetailsListRow,
  Divider,
  DownloadReceipt,
  EditedLabel,
  FlexWrapper,
  Footer,
  FooterButtons,
  FooterIcon,
  From,
  H3,
  H4,
  Header,
  MoreDetailsWrapper,
  PriceBreakdownWrapper,
  PriceInfo,
  ReceiptIcon,
  TitleWrapper,
  To,
  ToWrapper,
  TripArrowIcon,
  TripDetailWrapper,
  TripLocationsWrapper,
  Wrapper,
} from './styled'
import { Car } from '@styled-icons/boxicons-solid/Car'
import { Road } from '@styled-icons/icomoon/Road'
import { Clock } from '@styled-icons/fa-solid/Clock'
import { PlaneTicket } from '@styled-icons/material-rounded/AirplaneTicket'
import Button from '../buttons'

const dateTimeToStringWithTimezone = (date, timeZone, locale) => {
  const myDate = new Date(date) // we are storing in milliseconds
  return myDate.toLocaleDateString(locale || 'en', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  })
}

const dateToStringWithTimezone = (date, timeZone, locale) => {
  const myDate = new Date(date) // we are storing in milliseconds
  return myDate.toLocaleDateString(locale || 'en', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone,
  })
}

const timeToStringWithTimezone = (date, timeZone, locale) => {
  const myDate = new Date(date) // we are storing in milliseconds
  return myDate.toLocaleTimeString(locale || 'en', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  })
}

const getData = () => {
  const lang = getCurrentLanguage()
  return {
    // cancelModal: {
    //   type: 'originalTrip',
    //   title: languageData[lang]['trips.modal.cancel.title'],
    //   mainSubtitle: languageData[lang]['trips.modal.cancel.subtitle.main'],
    //   subtitles: [
    //     {
    //       subtitle: languageData[lang]['trips.modal.cancel.subtitle.0'],
    //     },
    //     {
    //       subtitle: languageData[lang]['trips.modal.cancel.subtitle.1'],
    //       boldText: languageData[lang]['trips.modal.cancel.subtitle.1.bold'],
    //     },
    //     {
    //       subtitle: languageData[lang]['trips.modal.cancel.subtitle.2'],
    //       boldText: languageData[lang]['trips.modal.cancel.subtitle.2.bold'],
    //     },
    //     {
    //       subtitle: languageData[lang]['trips.modal.cancel.subtitle.3'],
    //     },
    //   ],
    //   error: languageData[lang]['trips.modal.error.message.0'],
    //   checkbox: {
    //     checkboxLabel: languageData[lang]['trips.modal.cancel.checkbox.label'],
    //   },
    //   buttonBlack: {
    //     label: languageData[lang]['trips.modal.cancel.button.keep.label'],
    //     handler: 'cancel',
    //   },
    //   buttonWhite: {
    //     label: languageData[lang]['trips.modal.cancel.button.delete.label'],
    //     handler: 'delete',
    //   },
    // },
    // editModal: {
    //   type: 'originalTrip',
    //   title: languageData[lang]['trips.modal.edit.title'],
    //   subtitles: [
    //     {
    //       subtitle: languageData[lang]['trips.modal.edit.subtitle.0'],
    //       boldText: languageData[lang]['trips.modal.edit.subtitle.0.bold'],
    //     },
    //     {
    //       subtitle: languageData[lang]['trips.modal.edit.subtitle.1'],
    //       boldText: languageData[lang]['trips.modal.edit.subtitle.1.bold'],
    //     },
    //     {
    //       subtitle: languageData[lang]['trips.modal.edit.subtitle.2'],
    //     },
    //   ],
    //   error: languageData[lang]['trips.modal.error.message.0'],
    //   buttonBlack: {
    //     label: languageData[lang]['trips.modal.edit.button.keep.label'],
    //     handler: 'cancel',
    //   },
    //   buttonWhite: {
    //     label: languageData[lang]['trips.modal.edit.button.edit.label'],
    //     handler: 'edit',
    //   },
    // },
    // editModalUpdated: {
    //   type: 'updatedTrip',
    //   title: 'Edit limit reached',
    //   subtitles: [
    //     {
    //       subtitle: 'Please note that you have already edited your trip once.',
    //     },
    //     {
    //       subtitle:
    //         'You have reached the maximum limit for editing your trip, and further modifications are not available.',
    //     },
    //     {
    //       subtitle:
    //         ' If you have any questions or need assistance, please contact our customer support.',
    //     },
    //   ],
    //   buttonBlack: {
    //     label: 'CLOSE',
    //     handler: 'cancel',
    //   },
    // },
    editModalNew: {
      type: 'originalTrip',
      title: languageData[lang]['trips.modify.modal.title'],
      subtitles: [
        {
          subtitle: languageData[lang]['trips.modify.modal.subtitle.0'],
        },
        {
          subtitle: languageData[lang]['trips.modify.modal.subtitle.1'],
        },
      ],
      buttonBlack: {
        label: languageData[lang]['trips.modify.modal.button.label'],
        handler: 'cancel',
      },
    },
  }
}

export const showDate = (date = '', value) => {
  const dateinmil = parseISO(date).getTime()
  const newDate = new Date(dateinmil)
  const time = {
    date: 'dd LLLL yyyy',
    hour: 'p',
  }
  console.log(newDate)

  return format(newDate, time[value])
}

const currencySymbols = {
  eur: '€',
  gbp: '£',
  usd: '$',
  yen: '¥',
}

const SingleTrip = ({ trip, past, setTrips }) => {
  const [isModalCancelOpen, setIsModalCancelOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [loadingDelete, setLoading] = useState(false)
  const [submitMessage, setSubmitMessage] = useState({})
  const { getIdToken } = useContext(AuthContext)
  const [loader, setLoader] = useState(true)
  const [breakdown, setBreakdown] = useState(false)
  const [editing, setEditing] = useState(false)
  const [details, setDetails] = useState(false)

  const formattedQuotes = (trip.cost / 100).toFixed(2)

  const { duration, distance, coverage, direction, triptype } = trip

  const lang = getCurrentLanguage()
  const tripFooter = [
    trip?.flightCode
      ? { id: 'flight', icon: '/images/single-trip/solid-plane-departure.svg' }
      : null,
    trip?.returnFlightCode
      ? { id: 'flight', icon: '/images/single-trip/solid-plane-arrival.svg' }
      : null,
    { id: 'time', icon: '/images/single-trip/hour-trip.svg' },
    { id: 'seats', icon: '/images/single-trip/seats-trip.svg' },
    trip?.driver
      ? { id: 'driver', icon: '/images/single-trip/id-trip.svg' }
      : null,
  ]

  const renderDetailsRow = (IconComponent, content) => (
    <DetailsListRow>
      <DetailIconWrapper>
        <IconComponent />
      </DetailIconWrapper>
      <p>{content}</p>
    </DetailsListRow>
  )

  const generateColumn = (item) => {
    switch (item.id) {
      case 'time':
        return trip.when
          ? timeToStringWithTimezone(trip.when, trip.originTimeZone, lang)
          : ''
      case 'seats':
        return trip.triptype === 'lele' ? '4 seats' : '8 seats'
      case 'flight':
        return trip.flightCode ? ` ${trip.flightCode}` : null
      case 'flightReturn':
        return trip.returnFlightCode ? ` ${trip.returnFlightCode}` : null
      case 'driver':
        return trip.driver || ''

      default:
        return null
    }
  }

  const handleClickDelete = () => {
    setSubmitMessage({})
    setIsModalCancelOpen(true)
  }

  const handleClickEdit = () => {
    setSubmitMessage({})
    setIsModalEditOpen(true)
  }

  const handleClickDetails = () => {
    console.log(trip)
    setDetails((prevDetails) => !prevDetails)
  }

  const handleDeleteTrip = async (e) => {
    e.preventDefault()
    setLoading(true)
    const token = (await getIdToken()) || ''
    const res = await deleteApi('reservations')({
      Authorization: token,
    })({ id: trip.id })((cb) => cb)
    if (!res.length && !res.status) {
      setTrips((trips) =>
        trips?.filter((singleTrip) => singleTrip.id !== trip.id),
      )
      setLoading(false)
    } else {
      setSubmitMessage(res.data)
      setLoading(false)
    }
  }

  const navigate = useNavigate()
  const handleEditTrip = () => {
    // navigate(
    //   lang === 'EN' ? '/?edit=true' : `/${lang.toLowerCase()}/?edit=true`,
    //   { state: { trip } }
    // )
    // window.location.reload()
    setIsModalEditOpen(false)
    navigate(
      lang === 'EN'
        ? '/account?edit=true'
        : `/${lang.toLowerCase()}/account?edit=true`,
      { state: { trip } },
    )
    setEditing(true)
    saveState('originalTripId', trip.id)
    saveState('originalTripCreatedOn', trip.createdOn)
    navigate(
      lang === 'EN'
        ? '/account?edit=true'
        : `/${lang.toLowerCase()}/account?edit=true`,
      { state: { trip } },
    )
  }
  const Upcoming = ({ edit, return: tripReturn }) => (
    <Wrapper edit={edit} return={tripReturn}>
      <FlexWrapper edit={edit}>
        <TitleWrapper>
          <Circle return={tripReturn} />
          <H4>{languageData[lang]['trips.upcoming.reservations.title']}</H4>
        </TitleWrapper>
        {edit && (
          <EditedLabel edit={edit}>
            {languageData[lang]['trips.reservations.edited.label']}
          </EditedLabel>
        )}
        {tripReturn == 'Return' && (
          <EditedLabel edit={edit}>
            {languageData[lang]['trips.reservations.return.label']}
          </EditedLabel>
        )}
      </FlexWrapper>
    </Wrapper>
  )

  const generateReceipt = async () => {
    console.log({ trip })
    try {
      setLoader(false)
      const token = (await getIdToken()) || ''
      const response = await getApi(`receipt`)({
        Authorization: token,
      })(trip)((cb) => cb)
      if (response) {
        html2pdf()
          .set({ html2canvas: { dpi: 300, letterRendering: true } })
          .from(response)
          .save(`receipt_${trip.when}.pdf`)
        setLoader(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const PriceBreakdown = () => {
    let content
    if (!trip.updatedOn) {
      content = (
        <PriceBreakdownWrapper>
          <BreakdownTitle>
            {languageData[lang]['price.resume.subtitle.vat']}
          </BreakdownTitle>
        </PriceBreakdownWrapper>
      )
    } else {
      content = (
        <PriceBreakdownWrapper>
          <BreakdownTitle>
            <T.BoldSpan>
              {languageData[lang]['price.resume.title.bold']}{' '}
            </T.BoldSpan>{' '}
            {languageData[lang]['price.resume.subtitle.vat']}
          </BreakdownTitle>
          <T.P3>
            {
              languageData[lang][
                'checkout.confirmation.edit.original.trip.price'
              ]
            }{' '}
            <T.BoldSpan>{trip.originalPrice} €</T.BoldSpan>
          </T.P3>
          <T.P3>
            {
              languageData[lang][
                'checkout.confirmation.edit.original.trip.price'
              ]
            }{' '}
            <T.BoldSpan>{formattedQuotes} €</T.BoldSpan>
          </T.P3>
          <DetailedBreakdown>
            <T.P3>{`New trip: ${trip.updatedPrice} €`}</T.P3>
            <T.P3>{`Original trip: ${trip.originalPrice} €`}</T.P3>
            <T.P3>{`Edit fee: 15 €`}</T.P3>
          </DetailedBreakdown>
          <T.P1>
            <T.BoldSpan>
              {languageData[lang]['checkout.confirmation.total.price']}{' '}
              {formattedQuotes} €
            </T.BoldSpan>
          </T.P1>
        </PriceBreakdownWrapper>
      )
    }

    return content
  }

  const modalData = getData()
  return (
    <Container>
      {/* {!past ? <Upcoming edit={trip.updatedOn} /> : null}
      {isModalCancelOpen && (
        <ModalCancel
          trip={trip}
          data={modalData.cancelModal}
          setIsModalOpen={setIsModalCancelOpen}
          isModalOpen={isModalCancelOpen}
          date={trip.when}
          handleDeleteTrip={handleDeleteTrip}
          loadingDelete={loadingDelete}
        />
      )}
      {isModalEditOpen && (
        <ModalCancel
          trip={trip}
          data={
            trip.updatedOn !== null
              ? modalData.editModalUpdated
              : modalData.editModal
          }
          handleEditTrip={handleEditTrip}
          date={trip.when}
          setIsModalOpen={setIsModalEditOpen}
          isModalOpen={isModalEditOpen}
        />
      )}
      {!editing ? (
        <CardWrapper>
          <Header>
            <H3>
              {trip.when
                ? dateToStringWithTimezone(trip.when, trip.originTimeZone, lang)
                : ''}
            </H3>
            <Cost>
              {`${trip.cost / 100 || '--'} ${
                currencySymbols[trip.currency] || trip.currency || ''
              }`}{' '}
              <PriceInfo
                size={20}
                onMouseOver={() => setBreakdown(true)}
                onMouseOut={() => setBreakdown(false)}
              />
              {breakdown && <PriceBreakdown />}
            </Cost>
            <DownloadReceipt>
              {loader ? (
                <>
                  <ReceiptIcon size={25} onClick={generateReceipt} />
                  <p>{languageData[lang]['trips.reservation.receipt.label']}</p>
                </>
              ) : (
                <Spinner size={30} />
              )}
            </DownloadReceipt>
          </Header>
          <Content>
            <From>
              <p>{trip.origin || ''}</p>
            </From>
            <ToWrapper>
              <ArrowIcon />
              <To past={past}>
                <p>{trip.destination || ''}</p>
              </To>
            </ToWrapper>
          </Content>
          {trip.direction === 'Return' && (
            <Content return>
              <H3 return>
                {trip.whenReturn
                  ? dateTimeToStringWithTimezone(
                      trip.whenReturn,
                      trip.destinationTimeZone || trip.originTimeZone,
                      lang
                    )
                  : ''}
              </H3>
              <From>
                <p>{trip.destination || ''}</p>
              </From>
              <ToWrapper>
                <ArrowIcon />
                <To past={past}>
                  <p>{trip.origin || ''}</p>
                </To>
              </ToWrapper>
            </Content>
          )}
          <Footer>
            {tripFooter
              .filter((item) => !!item)
              .map((item) => (
                <Col key={item.id}>
                  <FooterIcon src={item.icon} alt={item.id} />
                  {generateColumn(item)}
                </Col>
              ))}
          </Footer>
          {!past && (
            // <ButtonWrapper>
            //   <Button onClick={handleClickDelete} type="danger">
            //     {languageData[lang]['trips.modal.cancel.delete']}
            //   </Button>
            //   {trip.updatedOn === null && (
            //     <Button type="primary" onClick={handleClickEdit}>
            //       {languageData[lang]['trips.modal.cancel.edit']}
            //     </Button>
            //   )}
            // </ButtonWrapper>
          )}
        </CardWrapper>
      ) : (
        <EditForm closeModal={setEditing} setEditSummary={setEditSummary} />
      )}
      {editSummary && (
        <ModalSummary
          editSummary={editSummary}
          trip={trip}
          setEditSummary={setEditSummary}
        />
      )} */}
      <CardParentWrapper past={past}>
        {!past ? (
          <Upcoming edit={trip.updatedOn} return={trip.direction} />
        ) : null}
        <CardWrapper past={past}>
          <TripDetailWrapper>
            <Header>
              <H3>
                {trip.when
                  ? dateToStringWithTimezone(
                      trip.when,
                      trip.originTimeZone,
                      lang,
                    )
                  : ''}
              </H3>
              <Cost>
                {`${formattedQuotes} 
                  ${currencySymbols[trip.currency] || trip.currency || ''}`}
                <PriceInfo
                  size={24}
                  onMouseOver={() => setBreakdown(true)}
                  onMouseOut={() => setBreakdown(false)}
                />
                {breakdown && <PriceBreakdown />}
              </Cost>
            </Header>
            <Divider />
            <TripLocationsWrapper>
              <TripArrowIcon />
              <DestinationsWrapper>
                <p>{trip.origin || ''}</p>
                <p>{trip.destination || ''}</p>
              </DestinationsWrapper>
            </TripLocationsWrapper>
            {details && (
              <>
                <Divider />
                <MoreDetailsWrapper>
                  <H3>Trip information</H3>
                  <DetailsList>
                    {trip.flightCode &&
                      renderDetailsRow(
                        () => <Car size={22} />,
                        `Flight code: ${trip.flightCode}`,
                      )}
                    {trip.triptype &&
                      renderDetailsRow(
                        () => <Car size={22} />,
                        `${trip.triptype === 'lele' ? '4 seats' : '8 seats'}${
                          trip.driver ? ' - ' + trip.driver : ''
                        }`,
                      )}
                    {trip.distance &&
                      renderDetailsRow(
                        () => <Road size={18} />,
                        `${Math.round((trip.distance / 1000) * 10) / 10} km`,
                      )}
                    {trip.duration &&
                      renderDetailsRow(
                        () => <Clock size={20} />,
                        `${
                          Math.floor(trip.duration / 3600) > 0
                            ? Math.floor(trip.duration / 3600) + ' hr '
                            : ''
                        }${Math.round((trip.duration % 3600) / 60)} min`,
                      )}
                  </DetailsList>
                </MoreDetailsWrapper>
              </>
            )}
          </TripDetailWrapper>
          <Divider />
          {trip.direction === 'Return' && (
            <TripDetailWrapper>
              <Header return>
                <H3 return>
                  {trip.whenReturn
                    ? dateTimeToStringWithTimezone(
                        trip.whenReturn,
                        trip.destinationTimeZone || trip.originTimeZone,
                        lang,
                      )
                    : ''}
                </H3>
              </Header>
              <Divider />
              <TripLocationsWrapper>
                <TripArrowIcon />
                <DestinationsWrapper>
                  <p>{trip.destination || ''}</p>
                  <p>{trip.origin || ''}</p>
                </DestinationsWrapper>
              </TripLocationsWrapper>
              {details && (
                <>
                  <Divider />
                  <MoreDetailsWrapper>
                    <H3>Trip information</H3>
                    <DetailsList>
                      {trip.returnFlightCode &&
                        renderDetailsRow(
                          () => <Car size={22} />,
                          `Flight code: ${trip.returnFlightCode}`,
                        )}
                      {trip.triptype &&
                        renderDetailsRow(
                          () => <Car size={22} />,
                          `${trip.triptype === 'lele' ? '4 seats' : '8 seats'}${
                            trip.driver ? ' - ' + trip.driver : ''
                          }`,
                        )}
                      {trip.distance &&
                        renderDetailsRow(
                          () => <Road size={18} />,
                          `${Math.round((trip.distance / 1000) * 10) / 10} km`,
                        )}
                      {trip.duration &&
                        renderDetailsRow(
                          () => <Clock size={20} />,
                          `${
                            Math.floor(trip.duration / 3600) > 0
                              ? Math.floor(trip.duration / 3600) + ' hr '
                              : ''
                          }${Math.round((trip.duration % 3600) / 60)} min`,
                        )}
                    </DetailsList>
                  </MoreDetailsWrapper>
                </>
              )}
            </TripDetailWrapper>
          )}
          <FooterButtons>
            <ButtonRow>
              <DownloadReceipt>
                {loader ? (
                  <Button
                    type="trip"
                    padding="8px 24px"
                    fixedWidth={142}
                    onClick={generateReceipt}
                    icon="receiptLong"
                    iconPosition="left"
                    color="#2E2E2E"
                    label={
                      languageData[lang]['trips.reservation.receipt.label']
                    }
                  />
                ) : (
                  <Spinner size={30} />
                )}
              </DownloadReceipt>
              <Button
                type="trip"
                padding="8px 10px"
                fixedWidth={190}
                onClick={handleClickDetails}
                label={
                  details
                    ? languageData[lang]['trips.reservation.less.details']
                    : languageData[lang]['trips.reservation.more.details']
                }
              />
            </ButtonRow>
            <Button
              type="trip"
              padding="8px 10px"
              fixedWidth={190}
              onClick={handleClickEdit}
              label={languageData[lang]['trip.summary.card.button.label']}
            />
          </FooterButtons>
        </CardWrapper>
      </CardParentWrapper>
      {isModalEditOpen && (
        <ModalCancel
          trip={trip}
          data={modalData.editModalNew}
          handleEditTrip={handleEditTrip}
          date={trip.when}
          setIsModalOpen={setIsModalEditOpen}
          isModalOpen={isModalEditOpen}
        />
      )}
    </Container>
  )
}

export default SingleTrip
