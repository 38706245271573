import styled from 'styled-components'
import ReactFlagsSelect from 'react-flags-select'
import { breakpoint } from '../../styles/global.style'

export const PassangerForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 40px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  margin-top: 25px;
`

export const SingleInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: ${(props) => (props.full ? '100%' : 'unset')};
  @media (max-width: ${breakpoint.mobile}) {
    width: 100%;
  }
`

export const Input = styled.input`
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #d4d9eb;
  background: #f1f2f6;
  &:focus {
    outline: none;
  }
`

export const PhoneInputWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: ${breakpoint.mobile}) {
    flex-wrap: wrap;
  }
`

export const Notes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const TextArea = styled.textarea`
  border-radius: 4px;
  border: 1px solid #d4d9eb;
  background: #f1f2f6;
  padding: 14px;
  &:focus {
    outline: none;
  }
`

export const ReactFlags = styled(ReactFlagsSelect)`
  button {
    border-radius: 4px;
    border: 1px solid #d4d9eb;
    background: #f1f2f6;
    width: 100%;
    min-height: 43px;
  }

  li {
    color: black;
  }
`
