import React from 'react'
import { ErrorBoundary } from './../../components/compositions/Error'
import { PasswordReset } from './../../components/password-reset-form'
import { DefaultLayout } from './../../components/utilities/DefaultLayout'
import { DefaultLayoutNew } from '../../components/utilities/DefaultLayoutNew'

function PasswordResetPage() {
  return (
    <DefaultLayoutNew pageType="viewer">
      <main>
        <ErrorBoundary>
          <PasswordReset />
        </ErrorBoundary>
      </main>
    </DefaultLayoutNew>
  )
}

export default PasswordResetPage
