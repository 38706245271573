import * as S from './styled'
import * as T from './../atomic/Typography'
import Button from '../buttons'
import { imagesFiles } from '../../styles/images'
import { Link, useLocation } from 'react-router-dom'

const CtaTwoColSimple = ({ data, scrollIntoView }) => {
  const { search } = useLocation()

  return (
    <S.Wrapper reversed={data.reversed} asHero={data.asHero}>
      <S.Image src={imagesFiles[data.imgSrc]} alt={data.imgSrc} />
      <S.CtaWrapper>
        <S.Title>{data.title}</S.Title>
        {data.paragraphs.map((paragraph, i) => (
          <T.P1 key={i}>{paragraph}</T.P1>
        ))}
        {data.buttonLabel &&
          (data.buttonHref ? (
            <Link to={`${data.buttonHref}${search}`}>
              <Button label={data.buttonLabel} type="white" />
            </Link>
          ) : (
            <Button
              label={data.buttonLabel}
              type="white"
              onClick={scrollIntoView}
            />
          ))}
      </S.CtaWrapper>
    </S.Wrapper>
  )
}

export default CtaTwoColSimple
