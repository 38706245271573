import styled from 'styled-components'
import * as T from './../atomic/Typography'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 40px 0;
  @media (max-width: ${breakpoint.smallTablet}) {
    padding: 2.5em 0;
  }
`

export const Title = styled(T.Uh3)`
  text-align: center;
  font-weight: 700;
  font-size: 32px;
`

export const CycleWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 180px 1fr 160px 210px;
  justify-items: center;
  margin-top: 40px;
  @media (max-width: ${breakpoint.bigtablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
`

export const Element = styled.div`
  display: flex;
  grid-column-start: ${(props) => props.$start};
  grid-column-end: ${(props) => props.$end};
`

export const Image = styled.img`
  max-width: 128px;
  margin-bottom: 5px;
`

export const ElementTitle = styled(T.P1)`
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 24px;
`

export const ElementText = styled(T.P1)``

export const Arrow = styled.img`
  max-width: 101px;
  justify-self: ${(props) =>
    props.$justifyPosition ? props.$justifyPosition : 'center'};
  align-self: ${(props) =>
    props.alignPosition ? props.alignPosition : 'center'};
  grid-column-start: ${(props) => props.$start};
  grid-column-end: ${(props) => props.$end};
  grid-row-start: ${(props) => props.$rowStart};
  margin: 0 25px;
  @media (max-width: ${breakpoint.bigtablet}) {
    display: none;
  }
`

export const InnerElement = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  text-align: center;
  @media (max-width: ${breakpoint.bigtablet}) {
    max-width: unset;
  }
`

export const Logo = styled.img`
  max-width: 205.24px;
  grid-column-start: ${(props) => props.$start};
  grid-column-end: ${(props) => props.$end};
  grid-row-start: ${(props) => props.$rowStart};
  align-self: center;
`
