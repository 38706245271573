export const loadState = (id) => {
  try {
    const serializedState = localStorage.getItem(id)
    if (serializedState === null) return undefined
    const state = JSON.parse(serializedState)
    return state
  } catch (err) {
    return undefined
  }
}

export const saveState = (id, state) => {
  const stateTemp = state
  try {
    const serializedState = JSON.stringify(stateTemp)
    localStorage.setItem(id, serializedState)
  } catch {
    console.log('error')
    // catching errors to not break the browser process
  }
}

export const saveSessionState = (id, state) => {
  const stateTemp = state
  try {
    const serializedState = JSON.stringify(stateTemp)
    sessionStorage.setItem(id, serializedState)
  } catch {
    console.log('error')
    // catching errors to not break the browser process
  }
}

export const loadSessionState = (id) => {
  try {
    const serializedState = sessionStorage.getItem(id)
    if (serializedState === null) return undefined
    const state = JSON.parse(serializedState)
    return state
  } catch (err) {
    return undefined
  }
}

export const removeState = (id) => {
  localStorage.removeItem(id)
}
