import CarouselReviews from '../../components/carousel-reviews'
import { Wrapper } from './styled'
import { getHomeData, metaPage } from './data'
import HeroRideForm from '../../components/hero-ride-form'
import { DefaultLayout } from '../../components/utilities/DefaultLayout'
import ImageWithTitle from '../../components/image-with-title'
import CtaTwoColSimple from '../../components/cta-two-col-simple'
import BrandedWhyCircle from '../../components/branded-why-circle'
import FaqList from '../../components/faq-list'
import CtaCard from '../../components/cta-card'
import { DefaultLayoutNew } from '../../components/utilities/DefaultLayoutNew'
import { getCurrentLanguage } from '../../languages'
import InjectMeta from '../../components/utilities/InjectMeta'
import PageLoadAnimation from '../../styles/animations'

const PageHome = () => {
  const data = getHomeData()
  const lang = getCurrentLanguage()
  return (
    <>
      <InjectMeta meta={metaPage} lang={lang} />
      <PageLoadAnimation>
        <DefaultLayoutNew pageType="viewer">
          <Wrapper>
            <HeroRideForm data={data.rideFormData} />
            <CtaCard data={data.ctaCardData} />
            <ImageWithTitle data={data.imageBannerData} />
            <BrandedWhyCircle data={data.pageCircleData} />
            <CtaTwoColSimple data={data.ctaSimpleData} />
            {/* <CarouselReviews data={data.pageReviewsData} /> */}
            <FaqList data={data.faqPageData} />
          </Wrapper>
        </DefaultLayoutNew>
      </PageLoadAnimation>
    </>
  )
}

export default PageHome
