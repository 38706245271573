import styled from 'styled-components'
import { breakpoint } from '../../styles/global.style'
import { ext } from '../../utils/utils'

import { CarMoving } from './../car-loader/styled'

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0px 10px 45px;
  @media (max-width: ${breakpoint.mobile}) {
    width: calc(100% - 20px);
  }
`

export const List = styled.ul`
  padding-inline-start: 0;
  margin-block-start: 0.3em;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const TripListTitle = styled.h3`
  margin-bottom: 40px;
  text-transform: none;
  border-bottom: 2px solid #e3012b;
  padding-bottom: 15px;
  width: 290px;
`

export const WarningMsg = styled.p`
  text-align: left;
  padding-bottom: 30px;
  color: orange;
`

export const MoreBtn = styled.button`
  cursor: pointer;
  align-self: center;
  text-transform: none;
  text-decoration: underline;
  text-underline-offset: 1px;
  background: none;
  border: none;
`

export const AccountWrapper = styled.div`
  width: 100%;
  background: #f5f5f5;
  display: flex;
  gap: 80px;
  @media (max-width: ${breakpoint.mobile}) {
    padding: 70px 0;
  }
`

export const TripsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 85px 50px 85px 0;
  @media (max-width: ${breakpoint.tablet}) {
    padding-right: 30px;
  }
  @media (max-width: ${breakpoint.mobile}) {
    padding: 0;
  }
`

export const StyledCarMoving = styled(ext(CarMoving))`
  padding: 0 110px;
  color: #00000080;
`

export const MessageWrapper = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--neutrals-500, #e0e0e0);
  background: #fff;
  @media (max-width: ${breakpoint.tablet}) {
    padding: 15px;
  }
`
