import styled from 'styled-components'
import { breakpoint } from '../../styles/global.style'

export const Wrapper = styled.div`
  max-width: 570px;
  width: 100%;
  padding: 40px 24px;
  border-radius: 16px;
  background: #fff;
  @media (max-width: ${breakpoint.smallTablet}) {
    max-width: unset;
  }
  @media (max-width: ${breakpoint.mobile}) {
    padding: 2em;
  }
`
