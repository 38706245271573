import { ManageAccounts } from '@styled-icons/material-outlined/ManageAccounts'
import { Location } from '@styled-icons/fluentui-system-regular/Location'
import SidebarMenu, { MenuItem } from './sidebar-menu'
import { breakpoint } from '../../styles/global.style'
import { LogOut } from '@styled-icons/ionicons-outline'
import styled, { css } from 'styled-components'
import { AuthContext } from '../auth-context'
import { useContext } from 'react'
import { getCurrentLanguage, languageData } from '../../languages'

const Aside = styled.aside`
  width: 100%;
  max-width: 420px;
  min-height: 520px;
  max-height: calc(100vh - 160px);
  background: #fff;
  margin: 40px 0;
  position: relative;
  & > h4:last-child {
    width: calc(100% - 120px);
    position: absolute;
    bottom: 30px;
  }
  @media (max-width: ${breakpoint.bigtablet}) {
    display: none;
  }
`

const StyledIcon = css`
  width: 30px;
  height: 30px;
  color: #7e7e7e;
`

const TripsIcon = styled(Location)`
  ${StyledIcon};
`

const MyAccountIcon = styled(ManageAccounts)`
  ${StyledIcon};
`

const LogOutIcon = styled(LogOut)`
  ${StyledIcon};
`

const H2 = styled.h2`
  font-family: 'RalewayRegular';
  font-size: 36px;
  line-height: 42px;
  letter-spacing: initial;
  text-transform: none;
  font-weight: ${(props) => (props.bold ? '900' : '300')};
`

const Wrapper = styled.div`
  padding: 40px 60px;
  display: flex;
  gap: 5px;
`

const AccountSidebar = () => {
  const { logout } = useContext(AuthContext)
  const handleLogout = () => logout()
  const getData = () => {
    const lang = getCurrentLanguage()
    return [
      {
        label: languageData[lang]['header.menu.logged.link.1'],
        id: 'trips',
        icon: <TripsIcon />,
        href: '/account',
      },
      {
        label: languageData[lang]['header.menu.logged.link.2'],
        id: 'account',
        icon: <MyAccountIcon />,
        href: '/account/settings',
      },
    ]
  }
  const dataMenu = getData()
  const componentData = () => {
    const lang = getCurrentLanguage()
    return [
      {
        id: 'title',
        type: 'title',
        content: [
          {
            label: languageData[lang]['header.menu.logged.link.0.0'],
            type: 'rider',
            bold: true,
          },
          {
            label: languageData[lang]['header.menu.logged.link.0.1'],
            type: 'text',
          },
        ],
      },
      {
        id: 'menu',
        type: 'menu',
        content: dataMenu,
      },
      {
        id: 'logout',
        type: 'logout',
        content: languageData[lang]['header.menu.logged.link.3'],
        icon: <LogOutIcon />,
        handler: () => handleLogout(),
      },
    ]
  }

  const generateSidebarOptions = ({ id, type, content, handler, icon }) => {
    switch (type) {
      case 'title':
        return Array.isArray(content) ? (
          <Wrapper key={id}>
            {content.map((item, i) => (
              <H2 key={`${id}${i}`} bold={item.bold}>
                {item.label}
              </H2>
            ))}
          </Wrapper>
        ) : null
      case 'menu':
        return <SidebarMenu key={id} data={content} />
      case 'logout':
        return (
          <MenuItem key={id} onClick={handler}>
            {icon}
            {content}
          </MenuItem>
        )
      default:
        ;<></>
    }
  }
  const component = componentData()
  return <Aside>{component.map(generateSidebarOptions)}</Aside>
}

export default AccountSidebar
